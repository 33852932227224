import { faArrowLeftToLine, faBars } from '@fortawesome/pro-regular-svg-icons';
import { DrawerProps } from '@material-ui/core';
import { useStores } from 'containers/App/App';
import { paths } from 'routes';

const useMenuDrawer = () => {
  const { uiStore } = useStores();

  const mobileView = uiStore?.mobileView;
  const open = uiStore?.drawer.open;
  const hide = mobileView;
  const toggleDrawer = uiStore?.toggleDrawer;
  const icon = open ? faArrowLeftToLine : faBars;
  const redirect = paths.root();
  const drawerVariant: DrawerProps['variant'] = !mobileView ? 'permanent' : 'permanent';
  const anchor: DrawerProps['anchor'] = mobileView ? 'top' : 'left';

  const toolbarProps = {
    icon,
    menuOpen: open,
    hide,
    redirect,
    toggleDrawer,
  };

  const userSectionProps = {
    drawerOpen: open,
  };

  const paperStyle = mobileView
    ? {
        width: '100vw',
      }
    : {
        overflow: 'auto',
      };

  const drawerProps = {
    anchor,
    variant: drawerVariant,
    PaperProps: { style: paperStyle },
  };

  return {
    open,
    toolbarProps,
    userSectionProps,
    drawerProps,
    mobileView,
  };
};

export default useMenuDrawer;
