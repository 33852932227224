import Api from 'api';
import { action, autorun, computed, flow, observable, reaction, makeObservable } from 'mobx';
import { now } from 'mobx-utils';
import { Account, AccountKind, ScopeKind, User } from 'models';
import * as LocalStorage from 'services/localStorage';
import { ACL } from 'types';
import BaseStore from './BaseStore';
import RootStore from './RootStore';
import CustomEvents from 'utils/events';
import { refreshJwtToken } from 'utils/refreshTokenHelpers';
import { orderBy } from 'lodash';
import { getAccountName } from 'utils/helper';

export interface LoginData {
  jwt: string;
  exp: number;
  refreshToken: string;
}

interface JWTData {
  access_token: string;
  access_expires: number;
  refresh_token: string;
}

interface SessionToken extends JWTData {
  id: string;
}

export type JWTStore = SessionToken[];

export interface AccessToken {
  token: string;
  expiresAt?: string;
}

export interface RefreshToken {
  token: string;
  expiresAt?: string;
}

export interface UserSession {
  accessToken: AccessToken;
  refreshToken: RefreshToken;
  session?: string;
}

type DefaultLogin = {
  email: string;
  password: string;
};

type LoginProps = DefaultLogin;

const ONE_DAY = 24 * 60 * 60 * 1000;
const STORE_NAME = 'userStore';

/** The 2FA security token for user identity verification */
export interface IdentityVerificationToken {
  code: string;
  expiresAt: number;
  isActive: boolean;
  isValid: boolean;
}

/**
 * The UserStore contains data and logic regarding the current
 * logged-in user.
 */
export default class UserStore extends BaseStore {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);

    reaction(
      () => this.accounts,
      () => {
        this.initAccountsToDisplay();
      },
    );
  }

  /**
   * The duration of the refresh window in ms. If the token is this much or less
   * to the expiration, it will be refreshed.
   */
  private static REFRESH_WINDOW: number = ONE_DAY;
  /** The object that represents the current user */
  @observable public user?: User;

  /** When the JWT token expires, in epoch ms */
  @observable private exp?: number;

  /** The encoded JWT */
  @observable private jwt?: string;

  @observable private jwtStore: JWTStore = [];

  /** Refresh token */
  @observable private refreshToken?: string;

  /** The accounts of the currently logged-in user */
  @observable public accounts: Account[] = [];

  /** Global owners with more than 5 accounts should see only 5 accounts at a time */
  @observable public accountsToDisplay: Account[] = [];

  /** Whether the user is currently logging in */
  @observable public loggingIn = false;

  /** The current user's scope */
  @observable public scope: ScopeKind = { kind: 'none' };

  /** Whether the user is currently coming in from a web-view */
  @observable public webView = false;

  /**
   * The security token, if present. Use the computed value securityToken
   * for accessing this.
   */
  @observable private identityVerificationToken_?: IdentityVerificationToken;

  /**
   * Returns the security token if it exists and isn't expired,
   * otherwise returns undefined.
   */
  @computed public get identityVerificationToken(): IdentityVerificationToken | undefined {
    if (!this.identityVerificationToken_) {
      return undefined;
    }
    if (
      !this.identityVerificationToken_.expiresAt ||
      now() > this.identityVerificationToken_.expiresAt
    ) {
      return undefined;
    }
    return this.identityVerificationToken_;
  }

  @action.bound public setIdentityVerificationToken(t: IdentityVerificationToken) {
    this.identityVerificationToken_ = t;
  }

  @action.bound initAccountsToDisplay() {
    const accounts = orderBy(
      this.accounts,
      [(account) => getAccountName(account).toLowerCase()],
      ['asc'],
    );
    return (this.accountsToDisplay = [...accounts]);
  }

  @action.bound public setAccountsToDisplay(account: Account) {
    if (!account) return;
    let accountsToDisplay = [...this.accountsToDisplay].filter(
      (acc: Account) => acc.id !== account.id,
    );
    accountsToDisplay = [account, ...accountsToDisplay];
    this.accountsToDisplay = accountsToDisplay;

    this.scope = { kind: account.scope, accountId: account.id };
    this.rootStore.routerStore.history.push('/dashboard');
  }

  @computed public get loggedIn(): boolean {
    return Boolean(this.user);
  }

  /**
   * Represents the current authenticated user. Accessing throws an error if the current
   * user is not authenticated, so be careful.
   */
  @computed public get authUser(): User {
    if (!this.user) {
      throw new Error('Accessing auth user when user is not logged in');
    }
    return this.user;
  }

  @computed public get existsAccounts(): boolean {
    return Boolean(this.accounts.length >= 1);
  }

  @computed public get isScopeUserWithAccount(): boolean {
    return Boolean(
      this.scope.kind === 'owner' ||
        this.scope.kind === 'manager' ||
        this.scope.kind === 'employee',
    );
  }

  @computed public get isOwner(): boolean {
    return Boolean(this.scope.kind === 'owner');
  }

  @computed public get isManager(): boolean {
    return Boolean(this.scope.kind === 'manager');
  }

  /** WHether the current user is an admin */
  @computed public get isAdmin(): boolean {
    return Boolean(this.scope.kind === 'admin');
  }

  /**
   * Returns the current selected account in the scope.
   */
  @computed public get currentAccount(): Account | undefined {
    if (this.existsAccounts) {
      return this.accounts.find((acc) => acc.id === (this.scope as AccountKind).accountId);
    }
    return undefined;
  }

  /**
   * Returns the current selected managed location in the scope.
   */

  /** Returns the user's full name */
  @computed public get fullName(): string | undefined {
    return (
      this.authUser.firstName &&
      this.authUser.lastName &&
      `${this.authUser.firstName} ${this.authUser.lastName}`
    );
  }

  /** Returns the name of the current scope */
  @computed public get scopeName(): string | undefined {
    if (this.currentAccount) {
      return getAccountName(this.currentAccount);
    } else {
      return 'Global';
    }
  }

  /** Whether the user has a first name and last name */
  @computed public get hasName(): boolean {
    return Boolean(
      this.user &&
        typeof this.user.firstName === 'string' &&
        typeof this.user.lastName === 'string',
    );
  }

  @computed public get isRequiredResetPassword(): boolean {
    return Boolean(this.user?.isRequiredResetPassword)
  }


  @action.bound public init() {
    // Initialize the store with the values that are currently in local storage
    this.initLocalStorage();
    // Set up watchers to update the local storage whenever user values change
    autorun(() => LocalStorage.set(`${STORE_NAME}/user`, this.user));
    autorun(() => LocalStorage.set(`${STORE_NAME}/accounts`, this.accounts));
    autorun(() => LocalStorage.set(`${STORE_NAME}/accountsToDisplay`, this.accountsToDisplay));
    autorun(() => LocalStorage.set(`${STORE_NAME}/scope`, this.scope));
    // Set up watchers to sync JWT token values to localStorage
    reaction(
      () => this.exp,
      (exp) => LocalStorage.setExp(exp),
    );
    reaction(
      () => this.jwt,
      (jwt) => LocalStorage.setJwt(jwt),
    );
    reaction(
      () => this.refreshToken,
      (refreshToken) => LocalStorage.setRefreshToken(refreshToken),
    );

    reaction(
      () => this.jwtStore,
      (store) => LocalStorage.setJWTStore(store),
    );

    reaction(
      () => this.user,
      (user) => LocalStorage.setUser(user),
    );

    // If the user is logged in, get fresh user data
    if (this.loggedIn) {
      this.getRelatedData();
    }
  }

  /** Initializes the local storage */
  @action.bound public initLocalStorage() {
    this.jwt = LocalStorage.getJwt();
    this.exp = LocalStorage.getExp();
    this.jwtStore = LocalStorage.getJWTStore();
    // We get the user data from local storage so they don't have to wait for it
    // on initial page load.
    this.user = (LocalStorage.get(`${STORE_NAME}/user`) as User) || this.user;
    this.accounts = (LocalStorage.get(`${STORE_NAME}/accounts`) as Account[]) || [];
    this.accountsToDisplay =
      (LocalStorage.get(`${STORE_NAME}/accountsToDisplay`) as Account[]) || [];
    this.scope = (LocalStorage.get(`${STORE_NAME}/scope`) as ScopeKind) || this.scope;
  }

  /**
   * Fetches the user's related data, such as the user's locations and accounts
   */
  @action.bound public async getRelatedData(this: UserStore) {
    if (this.user!.role === 'admin') {
      this.setScope({ kind: this.user!.role });
    } else if (this.user!.role === 'user') {
      await this.getUserAccounts();

      if (this.accounts.length == 1) {
        const { id, scope } = this.accounts[0];
        this.setScope({ kind: scope, accountId: id });
      } else {
        this.setScope({ kind: 'user' });
      }
    } else {
      this.setScope({ kind: 'none' });
    }
  }

  /**
   * Logs the user out.
   */
  @action.bound public logout() {
    CustomEvents.removeLogoutListener(this.logout);

    this.rootStore.notificationStore.onLogout();
    const allSettings = this.rootStore.settingStore.getAll();

    this.exp = undefined;
    this.jwt = undefined;
    this.jwtStore = [];
    this.accounts = [];
    this.accountsToDisplay = [];
    this.scope = { kind: 'none' };
    this.user = undefined;

    LocalStorage.clear();

    allSettings && this.rootStore.settingStore.setAll(allSettings);
  }

  /**
   * Calls the API endpoint to refresh the token and updates the auth
   * data with the new jwt token, user, and expiration.
   */
  @action.bound public refreshTokenAndUpdateUserData = flow(function* (this: UserStore) {
    const resp = yield refreshJwtToken();
    this.setAuthData(resp.data.data);
  });

  /**
   * Logs the user in with the provided email and password.
   * TODO: Error handling
   * @param props The user's email and password or token
   */
  @action.bound public login = flow(async function* (this: UserStore, props: LoginProps) {
    const { email, password } = props as DefaultLogin;
    try {
      this.loggingIn = true;
      const resp = await Api.core.login(email, password);
      // Get the data from the login and set the AuthStore values
      yield this.loginWithData(resp.data);

      this.rootStore.notificationStore.onLogin();
      // eslint-disable-next-line no-useless-catch
    } catch (e: any) {
      throw e;
    } finally {
      this.loggingIn = false;
    }
  });

  @action.bound public loginAccount = flow(async function* (this: UserStore, accountId: string) {
    try {
      const resp = await Api.core.loginAccount(accountId);
      yield this.setAuthData(resp.data);
    } catch (e) {
      throw e as Error;
    }
  });

  /**
   * Logs the user in with the provided login data.
   * @param data The login data returned from a login-like endpoint
   */
  @action.bound public loginWithData = flow(async function* (this: UserStore, data?: UserSession) {
    data && this.setAuthData(data);

    const resp = await Api.core.me();
    this.setUserData(resp.data as User);
    // Get the user's accounts and other data
    yield this.getRelatedData();
  });

  /**
   * Fetches the current user's accounts.
   */
  @action.bound public getUserAccounts = flow(function* (this: UserStore) {
    const resp = yield Api.core.getUserAccounts();
    this.accounts = resp.data;
  });

  /** Sets the current user's scope */
  @action.bound public async setScope(scope: ScopeKind) {
    try {
      if (scope.kind !== 'none' && scope.kind !== 'admin') {
        await this.setTokenScope((scope as AccountKind).accountId);
      }

      this.scope = scope;
    } catch (err) {
      console.log((err as Error).message);
    }
  }

  /**
   * Sets the JWT, expiration and user object in the store according to `data`.
   * @param data The data returned by Api.core.login
   */
  @action.bound public setAuthData(data: UserSession) {
    const { accessToken, refreshToken } = data;
    this.jwt = accessToken.token;
    this.exp = Number(accessToken.expiresAt);
    this.refreshToken = refreshToken.token;
  }

  /** Updates the user object with the provided data */
  @action.bound public setUserData(user: User) {
    this.user = {
      ...user,
      role: user.role.toLowerCase()
    } as User;
  }

  @action.bound public async setTokenScope(accountId?: string) {
    let id = this.user?.id;
    if (accountId) {
      id = accountId;
    }

    const token = this.jwtStore.find((acc) => acc.id === id);
    if (!token && accountId) {
      await this.loginAccount(accountId);
      this.jwtStore = [
        ...this.jwtStore,
        {
          access_token: this.jwt as string,
          access_expires: this.exp as number,
          refresh_token: this.refreshToken as string,
          id: accountId,
        },
      ];
    } else if (token) {
      this.setAuthData({
        accessToken: { token: token.access_token, expiresAt: `${token.access_expires}` },
        refreshToken: { token: token.refresh_token },
      });
    } else {
      this.jwtStore = [
        {
          access_token: this.jwt as string,
          access_expires: this.exp as number,
          refresh_token: this.refreshToken as string,
          id: this.user?.id as string,
        },
      ];
    }
  }

  /** Set the webView observable */
  @action.bound public setWebView(to = true) {
    this.webView = to;
  }

  /** Checks whether the user has the provided permission */
  public hasPermission(p: ACL) {
    const permissions: any = [];
    return Boolean(permissions.includes(p));
  }
}

export interface WithUserStore {
  userStore?: UserStore;
}
