import { WithStyles, withStyles } from '@material-ui/core/styles';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { WithSettingStore, WithToastStore, inject } from 'types/stores';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import DashboardLayout from 'containers/DashboardLayout';
import styles from './styles';
import {
  Box,
  Dialog,
  Link,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
} from '@material-ui/core';
import FilterBar from 'components/FilterBar';
import { Filter } from 'components/FilterBar/FilterBar';
import Title from 'components/Title';
import DataGridInfiniteScroll, { GridColumns } from 'components/DataGridInfiniteScroll';
import PlusFabButton from 'components/PlusFabButton/PlusFabButton';
import { fetchWrapper, setTitle } from 'services';
import Api, { RequestMetaData } from 'api';
import { Tenant } from 'models';
import { paths } from 'routes';
import ChipStatusTag from 'components/ChipStatusTag';
import Overlay from 'components/Overlay';
import clsx from 'clsx';
import Button from 'components/Button/Dialog/Button';
import { Formik } from 'formik';
import { object, string, number } from 'yup';
import OutlinedInput from 'components/Input/OutlinedInput';
import { Address } from 'types';
import AddressField from 'components/AddressField';
import { AxiosError, AxiosResponse } from 'axios';

const PAGE_TITLE = 'Workspaces';

export interface FilterItem {
  label: string;
  value: any;
}

type TenantsProps = WithStyles<typeof styles> &
  RouteComponentProps &
  WithToastStore &
  WithSettingStore;

/**
 * Container displaying active kiosk alert message if present and list of past alerts.
 */

const formatEIN = (value: string) => {
  // Remove all non-digit characters
  const digits = value.replace(/\D/g, '')
  
  // Format as XX-XXXXXXX
  if (digits.length <= 2) {
    return digits
  } else {
    return `${digits.slice(0, 2)}-${digits.slice(2, 9)}`
  }
}

@inject('userStore', 'toastStore', 'settingStore')
@observer
class Tenants extends React.Component<TenantsProps> {
  public constructor(props: TenantsProps) {
    super(props);
    makeObservable(this);
  }

  @observable private activeFilters: Record<string, unknown> = {};
  @observable private open = false;
  @observable private displayOverlay = false;

  public getTenants = fetchWrapper(
    async (rmd: RequestMetaData) => {
      const order: 'ASC' | 'DESC' = 'DESC';

      return await Api.core.getTenants({
        ...rmd,
        filters: {
          ...this.activeFilters,
        },
        sort: { sortBy: 'createdAt', sortOrder: order },
      });
    },

    (tenant: Tenant) => ({
      ...tenant,
      code: (tenant.code as string).toUpperCase(),
    }),
  );

  public async postAddTenant(data: Record<string, unknown>) {
    try {
      await Api.core.addTenant(data);
      this.props.toastStore!.push({
        type: 'success',
        message: `Workspace added successfully`,
      });
    } catch (error) {
      const message = (error as AxiosError).response?.data.message
      this.props.toastStore!.push({
        type: 'error',
        message,
      });
    }
  }

  onClose() {
    this.open = false;
  }

  onOpen() {
    this.open = true;
  }

  filters: Filter[] = [
    { display: 'Name', id: 'name', label: 'Contains', type: 'text' },
    { display: 'Code', id: 'code', label: 'Matches', type: 'text' },
    {
      display: 'Status',
      id: 'isActive',
      label: 'One of',
      type: 'select',
      items: [
        { label: 'ACTIVE', value: 'true' },
        { label: 'INACTIVE', value: 'false' },
      ],
    },
  ];

  renderCellName = (params: any) => (
    <Link component={RouterLink} to={paths.tenantDetails(params.id).info()}>
      {params.value}
    </Link>
  );

  renderCellSubscription = ({ value }: any) => {
    let text = 'unlinked';
    if (value) {
      text = value.status;
    }
    return <Typography>{text}</Typography>;
  };

  getFullName({ value }: any) {
    return `${value?.firstName || ''} ${value?.lastName || ''}`;
  }

  renderCellStatus({ value }: any) {
    return <ChipStatusTag status={value} />;
  }

  gridColumns: GridColumns = [
    {
      headerName: 'Name',
      field: 'name',
      minWidth: 200,
      renderCell: this.renderCellName,
      flex: 1,
    },
    { headerName: 'Code', field: 'code', minWidth: 100, flex: 1, sortable: false },
    {
      headerName: 'Owner',
      field: 'primaryOwner',
      minWidth: 150,
      flex: 1,
      valueGetter: this.getFullName,
    },
    { headerName: 'Stores', field: 'storeCount', minWidth: 110, flex: 1, sortable: false },
    { headerName: 'Users', field: 'userCount', minWidth: 100, flex: 1, sortable: false },
    {
      headerName: 'Subscription',
      field: 'subscription',
      renderCell: this.renderCellSubscription,
      minWidth: 100,
      flex: 1,
      sortable: false,
    },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 150,
      sortable: false,
      renderCell: this.renderCellStatus,
    },
  ];

  renderDialog() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.open}
        onClose={() => this.onClose()}
        className={clsx(classes.dialog)}
        fullWidth>
        <Overlay display={this.displayOverlay}>
          <CircularProgress />
        </Overlay>
        <DialogTitle disableTypography>
          <Title mb={0}>Add workspace</Title>
        </DialogTitle>
        <Box>
          <Formik
            initialValues={{
              name: '',
              address: undefined as Address | undefined,
              ein: ''
            }}
            validationSchema={object({
              name: string().required(),
              address: object<Address>().required(),
              ein: string().required()
            })}
            onSubmit={async (values, actions) => {
              await this.postAddTenant(values);
              setTimeout(() => {
                actions.setSubmitting(false);
                this.activeFilters = { ...this.activeFilters };
                this.onClose();
              }, 1000);
            }}>
            {(formik) => {
              return (
                <form onSubmit={formik.handleSubmit}>
                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <OutlinedInput
                          fullWidth
                          id="name"
                          name="name"
                          label="Name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.name && Boolean(formik.errors.name)}
                          helperText={(formik.touched.name && formik.errors.name) || ''}
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <AddressField
                          label={'Address'}
                          onChange={(a: Address | null) => {
                            if (a != null) {
                              formik.setFieldValue('address', a);
                            } else {
                              formik.setFieldValue('address', undefined);
                            }
                          }}
                          enableCustomInput

                          initialCustomAddress={formik.values.address}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <OutlinedInput
                          fullWidth
                          id="ein"
                          name="ein"
                          label="EIN"
                          value={formatEIN(formik.values.ein)}
                          placeholder='XX-XXXXXXX'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.ein && Boolean(formik.errors.ein)}
                          helperText={(formik.touched.ein && formik.errors.ein) || ''}
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button variant="outlined" onClick={() => this.onClose()}>
                      Cancel
                    </Button>
                    <Button variant="contained" type="submit" disabled={!formik.isValid}>
                      OK
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </Box>
      </Dialog>
    );
  }

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
  }

  render() {
    return (
      <DashboardLayout>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Title mb={3}>{PAGE_TITLE}</Title>
        </Box>

        <FilterBar
          filters={this.filters}
          onChange={(filters: Record<string, unknown>) => {
            this.activeFilters = filters;
          }}
        />
        <DataGridInfiniteScroll
          columns={this.gridColumns}
          fetch={this.getTenants}
          refetchKey={this.activeFilters}
          disableColumnMenu
          pathname={this.props.location.pathname}
        />
        <PlusFabButton onClick={() => this.onOpen()} />
        {this.renderDialog()}
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Tenants);
