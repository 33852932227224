import React, { useEffect } from 'react';
import useMobileView from './useMobileView';
import { useStores } from 'containers/App/App';

const MobileView = () => {
  const { uiStore } = useStores();
  const mobileView = useMobileView();
  useEffect(() => {
    uiStore.setMobileView(mobileView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileView]);

  return null;
};

export default MobileView;
