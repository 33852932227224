import React from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import DP from 'components/DashPanel';
import GC from './GraphicContainer';
import { RouteComponentProps } from 'react-router-dom';
import { WithToastStore, inject } from 'types/stores';
import styles from './styles';
import { observer } from 'mobx-react';
import { omit } from 'lodash';
import Button from 'components/Button/Button';
import Api, { RequestMetaData } from 'api';
import { paths } from 'routes';

interface SummaryProps extends WithStyles<typeof styles>, RouteComponentProps, WithToastStore {
  value: Record<string, any>;
}

const Summary = ({ value, classes, ...props }: SummaryProps) => {
  const onBoardTenant = async () => {
    const { data } = await Api.core.onBoardTenant(value);
    console.log(data);
    props.history.push(paths.signIn());
  };

  const renderItem = () =>
    Object.entries(omit(value, ['currentStep'])).map(([k, v]) => {
      return (
        <Box key={k}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <DP.Title panel>{k}</DP.Title>
          </Box>
          <Box
            style={{
              background: '#F2F2F2',
              borderRadius: '15px',
              marginLeft: '1rem',
            }}>
            <DP.Body>
              <Box
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '2rem',
                }}>
                {Object.entries(v).map(([key, val]) => {
                  if (val === '' || key === 'id') {
                    return null;
                  }

                  return (
                    <DP.Row key={key} style={{ marginBottom: '1px' }}>
                      <DP.Value>{val} </DP.Value>
                      <DP.Label>{key}</DP.Label>
                    </DP.Row>
                  );
                })}
              </Box>
            </DP.Body>
          </Box>
        </Box>
      );
    });

  return (
    <Paper elevation={0}>
      <Box minHeight={590} pt={7} pb={6.25} pr={4} pl={8.125}>
        <GC>
          <Box
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              gap: '2rem',
            }}>
            <Box style={{ display: 'flex', width: '50%' }}>
              <DP fullHeight>
                <Box>
                  <GC.Title>{`Summary`}</GC.Title>
                </Box>
                <Box style={{ marginLeft: '1rem' }}>{renderItem()}</Box>
              </DP>
            </Box>
            <Box style={{ display: 'flex', width: '50%' }}>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '100%',
                  }}>
                  <Box style={{ marginBottom: '1rem' }}>
                    <GC.Title>{`Payment`}</GC.Title>
                  </Box>
                  <Box>
                    <Button
                      disableElevation
                      fullWidth
                      variant="contained"
                      type="button"
                      onClick={() => {
                        onBoardTenant();
                      }}>
                      Apply
                    </Button>
                  </Box>
                </Box>
            </Box>
          </Box>
        </GC>
      </Box>
    </Paper>
  );
};

export default withStyles(styles)(inject('toastStore')(observer(Summary)));
