/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import { paths } from 'routes';

import Api from 'api';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Typography, Icon } from '@material-ui/core';

import AnonLayout from 'components/AnonLayout';

import styles from './styles';
import { action, flow, makeObservable } from 'mobx';
import { WithUserStore } from 'types/stores';
import Button from 'components/Button/Button';

type SignUpSuccessProps = WithStyles<typeof styles> & RouteComponentProps & WithUserStore;

/**
 * Displays success message on user signup finalization
 */
@observer
@inject('toastStore', 'userStore')
class SignUpSuccess extends React.Component<SignUpSuccessProps> {
  constructor(props: SignUpSuccessProps) {
    super(props);
    makeObservable(this);
  }

  render() {
    const { classes } = this.props;
    return (
      <AnonLayout>
        <Box height={100}>
          <Typography variant="h3" component="h1" align="center">
            Success
          </Typography>
        </Box>
        <Box
          height={100}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center">
          <Icon color="primary" className={classes.statusIcon}>
            check
          </Icon>
        </Box>
        <Box mt={5} mb={5}>
          <Typography align="center">You have successfully setup your account!</Typography>
        </Box>
        <Box justifyContent="center">
          <Button
            component={RouterLink}
            type="submit"
            variant="contained"
            color="primary"
            to={paths.root()}
            fullWidth>
            GO TO DASHBOARD
          </Button>
        </Box>
      </AnonLayout>
    );
  }
}

export default withStyles(styles)(SignUpSuccess);
