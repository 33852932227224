/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { observable, action, flow, makeObservable } from "mobx";
import validatorjs from "validatorjs";
import { observer } from "mobx-react";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

import { paths } from "routes";
import { inject, WithToastStore, WithUserStore } from "types/stores";
import { User } from "models";
import Api, { getErrorMsg } from "api";

import styles from "./styles";
import CarouselScreenWrapper from "components/CarouselScreenWrapper/CarouselScreenWrapper";
import Button from "components/Button/Button";
import OutlinedInput from "components/Input/OutlinedInput/OutlinedInput";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require("mobx-react-form/lib/validators/DVR");
const MobxReactForm = require("mobx-react-form").default;

type PersonalInfoProps = WithStyles<typeof styles> &
	RouteComponentProps &
	WithToastStore &
	WithUserStore;

const plugins = {
	dvr: dvr({
		package: validatorjs,
		extend: ({ validator }: { validator: any; form: any }) => {
			validator.setMessages("en", {
				...validator.getMessages("en"),
				firstName: "Please input a valid first name",
				lastName: "Please input a valid last name",
			});
		},
	}),
};

interface PersonalInfoHooks {
	onSuccess: (form: any) => void;
	onClear: (form: any) => void;
}

/**
 * Displays the prompt to enter first name, last name and nickname
 */
@inject('toastStore', 'userStore')
@observer
class PersonalInfo extends React.Component<PersonalInfoProps> {
	/** Whether the info is currently being submitted */
	@observable public submitting = false;

	/** The action to submit the current personal info to the server */
	@action.bound public submit = flow(function* (this: PersonalInfo) {
		const userStore = this.props.userStore!;
		try {
			this.submitting = true;

			const resp = yield Api.core.updateUser(userStore.user!.id, {
				firstName: this.form.$("firstName").value,
				lastName: this.form.$("lastName").value,
				nickname: this.form.$("nickname").value || undefined,
			});
			const user: User = resp && resp.data && resp.data;
			userStore.setUserData(user);
			const locationState: any = this.props.history.location.state;
			this.props.history.push({
				pathname: paths.signUp().personalInfo2(),
				state: locationState,
			});
		} catch (error: any) {
			this.props.toastStore!.push({
				type: "error",
				message: getErrorMsg(error) || "An error has occurred",
			});
		} finally {
			this.submitting = false;
		}
	});

	public constructor(props: PersonalInfoProps) {
		super(props);
		makeObservable(this);
		const user = props.userStore!.user!;
		const fields = [
			{
				name: "firstName",
				label: "Legal First Name",
				rules: "required|string|min:2",
				value: user.firstName || "",
			},
			{
				name: "lastName",
				label: "Legal Last Name",
				rules: "required|string|min:2",
				value: user.lastName || "",
			},
			{
				name: "nickname",
				label: "Nickname (Optional)",
				rules: "string",
				value: user.nickname || "",
			},
		];
		this.form = new MobxReactForm({ fields }, { plugins, hooks: this.hooks });
	}

	/** The form object */
	private form: any;

  /** The hooks for the form */
  private hooks: PersonalInfoHooks = {
    onSuccess: () => {
      this.submit();
    },
    onClear: (form: any) => {
      form.clear();
    },
  };

  isRequired(name: string) {
    if (!this.form.$(name).rules) return false;

    return this.form.$(name).rules.includes('required');
  }

  render() {
    const fields = {
      firstName: this.form.$('firstName'),
      lastName: this.form.$('lastName'),
      nickname: this.form.$('nickname'),
    };
    const { classes } = this.props;
    return (
      <CarouselScreenWrapper submitting={this.submitting}>
        <form onSubmit={this.form.onSubmit}>
          <Typography
            className={classes.title}
            variant="h4"
            component="h1"
            align="center"
            gutterBottom>
            Personal Info
          </Typography>
          <Box mt={3.125}>
            <Typography variant="body2" align="center" gutterBottom>
              {`Let's get to know each other!`}
            </Typography>
          </Box>
          <Box mt={2.75}>
            <OutlinedInput
              {...fields.firstName.bind()}
              autoFocus
              error={fields.firstName.error}
              required={this.isRequired('firstName')}
              fullWidth
            />
          </Box>
          <Box mt={2}>
            <OutlinedInput
              {...fields.lastName.bind()}
              error={fields.lastName.error}
              required={this.isRequired('lastName')}
              fullWidth
            />
          </Box>
          <Box mt={2}>
              <OutlinedInput
                {...fields.nickname.bind()}
                error={fields.nickname.error}
                required={this.isRequired('nickname')}
                fullWidth
              />
          </Box>
          <Box mt={5}>
            <Button
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={this.submitting}>
              Continue
            </Button>
          </Box>
        </form>
      </CarouselScreenWrapper>
    );
  }
}

export default withStyles(styles)(PersonalInfo);
