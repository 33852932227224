import clsx from 'clsx';
import { ChipStatusColors } from '.';
import { useStyles } from './styles';

export function useChipStatusTag() {
  const classes = useStyles();

  function getClass(color: ChipStatusColors) {
    const { red, yellow, green, grey, chip, purple, orange, blue } = classes;
    switch (color) {
      case ChipStatusColors.RED:
        return clsx(chip, red);
      case ChipStatusColors.YELLOW:
        return clsx(chip, yellow);
      case ChipStatusColors.GREEN:
        return clsx(chip, green);
      case ChipStatusColors.ORANGE:
        return clsx(chip, orange);
      case ChipStatusColors.BLUE:
        return clsx(chip, blue);
      case ChipStatusColors.GREY:
        return clsx(chip, grey);
      case ChipStatusColors.PURPLE:
        return clsx(chip, purple);
      default:
        return '';
    }
  }

  return [getClass];
}
