import { makeStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default makeStyles((theme) => ({
  root: {},
  green: {
    color: theme.palette.primary.main,
  },
  grey: {
    //TODO: move this to custom theme variables
    color: theme.palette.grey[400],
  },
  red: {
    //TODO: move this to custom theme variables
    color: 'red',
  },
}));
