import { Account } from './Account';

/** Roles that can be invited */

/** Represents a single Invitation item */

export interface Invitation extends Pick<Account, 'scope'|'tenant'|'store'>{
  id: string;
  email: string;
  status: InvitationStatus;
  expiresAt: string;
}

export enum InvitationStatus {
  SENT = 'sent',
  REJECTED = 'rejected',
  ACCEPTED = 'accepted',
  REVOKED = 'revoked'
}
