import { Theme, createStyles, makeStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    renderActions: {
        width: '24px', height: '24px',
        color: theme.palette.primary.main
    },
  }))

