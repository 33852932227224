import React from 'react';
import { observable, flow, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { inject, WithUserStore, WithToastStore } from 'types/stores';
import { User } from 'models';

import Api, { getErrorMsg } from 'api';
import { Address } from 'types';

import EmailPanel from 'components/EmailPanel';
import PasswordPanel from 'components/PasswordPanel';
import UserDetailsPanel from 'components/UserDetailsPanel';
import AddressPanel from 'components/AddressPanel';
import ProfilePicturePanel from 'components/ProfilePicturePanel';

import styles from './styles';

interface ProfileProps extends WithStyles<typeof styles>, WithUserStore, WithToastStore {
  user?: User;
}

/** Displays the workspace settings for the user with :userId */
@inject('userStore', 'toastStore')
@observer
class Profile extends React.Component<ProfileProps> {
  constructor(props: ProfileProps) {
    super(props);
    makeObservable(this);
  }
  // Address panel API update call
  @action.bound public updateUser = flow(function* (this: Profile, addr: Address) {
    console.log('updateUser', addr)
    const user = { address: addr} as User
    try {
      yield Api.core.updateUser(this.props.userStore!.authUser.id, user);
      this.props.toastStore!.success('Address updated!');
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  // Controlled field props for UserDetailsPanel
  private personalDataFields: { name: keyof User; editable: boolean }[] = [
    { name: 'firstName', editable: true },
    { name: 'lastName', editable: true },
    { name: 'nickname', editable: true },
    { name: 'phone', editable: true },
    { name: 'dob', editable: true },
  ];

  render() {
    return (
      <Grid container direction={'row'} spacing={3}>
        <Grid item xs={12} sm={12} md={3}>
          <Grid container direction={'column'} spacing={3}>
            <Grid item sm={12}>
              <EmailPanel>{this.props.userStore!.user}</EmailPanel>
            </Grid>
            <Grid item sm={12}>
              <PasswordPanel />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Grid container direction={'column'} spacing={3}>
            <Grid item sm={12}>
              <UserDetailsPanel
                title="Personal"
                editable={true}
                fields={this.personalDataFields}>
                {this.props.userStore!.user}
              </UserDetailsPanel>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Grid container direction={'column'} spacing={3}>
            <Grid item sm={12}>
              <AddressPanel onUpdateAddress={this.updateUser} addressEditable >
                {this.props.userStore!.user?.address}
              </AddressPanel>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Grid container direction={'column'} spacing={3}>
            <Grid item sm={12}>
              <ProfilePicturePanel>{this.props.userStore!.user}</ProfilePicturePanel>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Profile);
