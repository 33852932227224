import React from 'react';
import { List } from '@material-ui/core';
import useMenu from './useMenu';
import MenuItem from './MenuItem';
import MenuGroup from './MenuGroup';
import { MenuItemType } from 'models/Menu';
import { observer } from 'mobx-react';

const Menu = () => {
  const { showMenuIcon, mobileView, scopedItems, toggleGroup, isGroupOpen } = useMenu();
  return (
    <List disablePadding>
      {scopedItems.map((item) => {
        const key = item.label.replaceAll(' ', '');
        if (item.type === MenuItemType.PAGE) {
          return <MenuItem key={key} {...item} showIcon={true} />;
        } else {
          const { items } = item;
          return (
            <MenuGroup
              key={key}
              open={isGroupOpen(item.label)}
              icon={item.icon}
              toggle={toggleGroup}
              mobileView={mobileView}
              label={item.label}>
              {items.map((item) => {
                return <MenuItem key={item.label} showIcon={showMenuIcon} {...item} />;
              })}
            </MenuGroup>
          );
        }
      })}
    </List>
  );
};

export default observer(Menu);
