import { Theme, makeStyles, createStyles } from '@material-ui/core';

interface IStyleProps {
  menuOpen: boolean;
  mobileView: boolean;
}
export const useStyles = makeStyles<Theme, IStyleProps>((theme) =>
  createStyles({
    scopeSwitcherButton: {
      marginBottom: theme.spacing(3),
      border: ({ mobileView, menuOpen }) =>
        mobileView || menuOpen ? `1px solid ${theme.palette.primary.main}` : 'none',
      borderRadius: theme.shape.borderRadius,
      height: 48,
      width: '100%',
      textTransform: 'none',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'flex-start',
      padding: theme.spacing(1.25, 2),
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    avatarButton: {
      borderRadius: theme.spacing(0.625),
      backgroundColor: theme.palette.common.white,
      width: 30,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      jsutifyContent: 'center',
      fontSize: theme.typography.body1.fontSize,
    },
    avatarSmallDrawerAnimation: {
      animation: `$myEffect ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.sharp}`,
      animationFillMode: 'forwards',
    },
    chevron: {
      height: 24,
    },
    menuItem: {
      display: 'flex',
      flexDirection: 'column',
      width: ({ mobileView }) => (mobileView ? '100%' : 380),
      '&.MuiListItem-gutters': {
        padding: 0,
      },
    },
    scopeName: {
      padding: 0,
      flexGrow: 1,
      textAlign: 'left',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginLeft: theme.spacing(1),
    },
    '@keyframes myEffect': {
      from: {
        transform: 'scale(1)',
      },
      to: {
        transform: 'scale(1.6)',
      },
    },
    scopeSwitcherMenu: {
      '& .MuiPopover-paper': {
        filter: 'drop-shadow(0px 6px 24px #DDDDDD)',
        maxHeight: 'calc(100% - 320px)',
        overflowY: 'hidden',
        width: ({ mobileView }) => (mobileView ? '100%' : 'inherit'),
      },
      '& .MuiList-padding': {
        paddingTop: 0,
      },
    },
    scopeSwitcherButtonAnimation: {
      animation: `$scopeSwitcherButton ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.easeOut}`,
      animationFillMode: 'forwards',
      animationDirection: 'forwards',
    },
    '@keyframes scopeSwitcherButton': {
      from: {
        opacity: 1,
      },
      to: {
        opacity: 0,
      },
    },
  }),
);
