import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { observer } from 'mobx-react';
import OutlinedInput from 'components/Input/OutlinedInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons';

interface CompanyFormProps extends WithStyles<typeof styles> {
  formik: any;
}

const CompanyForm = ({ classes, formik }: CompanyFormProps) => {
  return (
    <Box>
      <Box pb={2}>
        <OutlinedInput
          fullWidth
          id="name"
          name="name"
          label="Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={(formik.touched.name && formik.errors.name) || ''}
          InputProps={{
            disableUnderline: true,
            classes: { input: classes.resize },
          }}
        />
      </Box>
      <Box pb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OutlinedInput
              id="address"
              name="address"
              label="Address line 1"
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={(formik.touched.address && formik.errors.address) || ''}
              fullWidth
              autoFocus
              required
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <FontAwesomeIcon icon={faLocationDot} className={classes.magnifyIcon} />
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <OutlinedInput
              id="address2"
              name="address2"
              label="Address line 2"
              value={formik.values.address2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.address2 && Boolean(formik.errors.address2)}
              helperText={(formik.touched.address2 && formik.errors.address2) || ''}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <OutlinedInput
              id="city"
              name="city"
              label="City"
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={(formik.touched.city && formik.errors.city) || ''}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6}>
            <OutlinedInput
              id="zip"
              name="zip"
              label="Zip"
              value={formik.values.zip}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.zip && Boolean(formik.errors.zip)}
              helperText={(formik.touched.zip && formik.errors.zip) || ''}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6}>
            <OutlinedInput
              id="state"
              name="state"
              label="State"
              value={formik.values.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={(formik.touched.state && formik.errors.state) || ''}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedInput
              id="country"
              name="country"
              label="Country"
              value={formik.values.country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.country && Boolean(formik.errors.country)}
              helperText={(formik.touched.country && formik.errors.country) || ''}
              required
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(observer(CompanyForm));
