import { Theme, createStyles, makeStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    label:{
      color: theme.palette.text.secondary
    },
  });

export default styles;

export const useStyles = makeStyles(styles);
