import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { observable, action, computed, flow, reaction, toJS, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import axios, { AxiosResponse } from 'axios';

import { User } from 'models';
import { RequestMetaData } from 'api';
import { inject, WithToastStore, WithUserStore } from 'types/stores';
import { paths } from 'routes';

import TextAndPhoneSearch from 'components/TextAndPhoneSearch';
import UserCard from 'components/UserCard';
import UserFilterDrawer, * as UserFilterDrawerTypes from './UserFilterDrawer';

import styles from './styles';
import Title from 'components/Title';
import { SourceMerge } from 'mdi-material-ui';
import { optionSearchFilter } from 'types/enums';
import { ACL } from 'types';
import InfiniteScrollWrapper from 'components/InfiniteScrollWrapper';

interface UserCardListProps extends WithStyles<typeof styles>, WithToastStore, WithUserStore {
  fetch: (
    rmd: RequestMetaData,
  ) => Promise<{ rows: any[]; sortable?: string[]; totalElements: number }>;
}

/**
 * Renders a list of users. The list of users can either be
 * passed via the children prop, or provided via a fetch function,
 * so that the component can work with sources that return paginated
 * data.
 */
@inject('toastStore', 'userStore')
@observer
class UserCardList extends React.Component<UserCardListProps> {
  constructor(props: UserCardListProps) {
    super(props);
    makeObservable(this);
  }

  @observable private activeFilters: Record<string, unknown> = {};

  @observable public search = '';

  @observable public pageSize = 3;

  @observable public optionSearch: optionSearchFilter = optionSearchFilter.NAME;

  @observable public loading = true;

  @observable public count?: number;

  @observable public fetchCount = 0;

  @observable public users: User[] = [];

  @observable public showFilterDrawer = false;

  @computed public get isAdmin(): boolean {
    return this.props.userStore!.isAdmin;
  }

  @computed public get isOwner(): boolean {
    return this.props.userStore!.isOwner;
  }

  @computed public get isManager(): boolean {
    return this.props.userStore!.isManager;
  }

  @action.bound public reset() {
    this.fetchCount = 0;
    this.users = [];
    this.count = undefined;
    this.activeFilters = {};
  }

  @computed public get initialLoading() {
    return this.loading;
  }

  componentDidMount() {
    // this.fetchUsers();
  }

  /** Renders a card for a user */
  @action.bound renderUser(user: User) {
    return (
      <Grid
        key={user.id}
        className={this.props.classes.userItem}
        item
        xs={12}
        sm={12}
        md={6}
        lg={4}
        xl={3}>
        <UserCard>{user}</UserCard>
      </Grid>
    );
  }

  /** Renders the loading skeleton */
  renderLoadingSkeleton(): React.ReactElement {
    return (
      <Grid container spacing={3}>
        {Array.from(Array(3).keys()).map((i) => (
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={i}>
            <UserCard>{undefined}</UserCard>
          </Grid>
        ))}
      </Grid>
    );
  }

  @action.bound renderNoMatch() {
    const { classes } = this.props;
    return (
      <Grid className={classes.message} item xs={12}>
        There are no users matching your search
      </Grid>
    );
  }

  @action.bound renderData(rows: any[]) {
    const { classes } = this.props;
    if (rows.length === 0) {
      return this.renderNoMatch();
    }
    return (
      <Grid container spacing={3}>
        {rows.map((user, index) => {
          return (
            <Grid
              key={`${index}_${user.id}`}
              className={classes.userItem}
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={3}>
              <UserCard>{user}</UserCard>
            </Grid>
          );
        })}
      </Grid>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Box display="flex" justifyContent="space-between" flexDirection="row" alignItems="center">
          <Title mb={3} count={this.loading ? undefined : `${this.users.length}/${this.count}`}>
            Users
          </Title>
        </Box>
        <Box mb={3} className={classes.searchBox}>
          <TextAndPhoneSearch
            initialValue={this.search}
            onTextChange={() => {}}
            onOptionSearchChange={() => {}}
            onPhoneChange={() => {}}
            autoFocus
            debounce={500}
            toggleFilters={() => {}}
            filtersActive={false}
          />
        </Box>

        <InfiniteScrollWrapper
          render={this.renderData}
          fetch={this.props.fetch}
          loader={this.renderLoadingSkeleton}
        />
        {/* <Drawer
          style={{ width: '439px' }}
          open={this.showFilterDrawer}
          onClose={this.hideFilters}
          anchor="right"
          variant="temporary">
          <UserFilterDrawer
            onClose={this.hideFilters}
            onReset={this.resetFilters}
            filters={toJS(this.filters)}
            hideOwnersCheckbox={this.props.ownerView}
            showInvitationStatuses={this.props.ownerView}
            showAccountSearch={this.props.userStore!.isAdmin}
            showReset={this.filtersModified}
            onChange={this.updateFilters}
          />
        </Drawer> */}
      </>
    );
  }
}

export default withStyles(styles)(UserCardList);
