import { alpha } from '@material-ui/core/styles/colorManipulator';
import { Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    cardContainer: {
      width: '288px',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '16px',
      paddingTop: '12px',
    },
    textPrimary: {
      fontWeight: 700,
      fontSize: '16px',
    },
    textSecondary: {
      textAlign: 'center',
    },
    price: {
      fontWeight: 700,
    },
    cardRecommended: {
      borderRadius: '10px 10px 0 0',
      borderWidth: '1px 1px 0 1px',
      borderStyle: 'solid',
      borderColor: '#E5EAF2',
      background: 'linear-gradient(0deg, rgba(250, 250, 250, 1) 0%, rgba(255, 255, 255, 0) 100%)',
    },
    tabBar: {
      background: theme.palette.divider,
      padding: '4px',
      borderRadius: '25px',
    },
    barModule: {
      justifyContent: 'flex-start',
      borderRadius: '12px',
      padding: '8px',
      transition: 'none 0s ease 0s',
      fontWeight: 700,
      fontSize: '0.875rem',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#F3F6F9',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderImage: 'initial',
      borderColor: '#E5EAF2',
    },
  });

export default styles;

export const useStyles = makeStyles(styles);
