import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    email: {
      fontWeight: 400,
      color: theme.palette.primary.main,
    },
    codeInputWrap: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
    codeInput: {
      padding: theme.spacing(3),
      fontSize: theme.typography.h5.fontSize,
      color: theme.palette.grey[600],
      textTransform: 'uppercase',
      '&::placeholder': {
        textTransform: 'none',
      },
    },
    title: {
      fontSize: theme.spacing(5),
      color: theme.palette.text.primary
    }
  });
