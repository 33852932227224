import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    dialogButton: {
      borderRadius: '8px',
      textTransform: 'capitalize',
    },
    dialogEdit: {
      '& .MuiDialogActions-root': {
        padding: 24,
      },
      '& .MuiPaper-root-MuiDialog-paper': {
        borderRadius: theme.shape.borderRadius,
      },
      '& .MuiDialogContent-root': {
        width: 576,
      },
      // '& .MuiDialogTitle-root': {
      //   paddingTop: '24px',
      //   paddingLeft: '24px',
      //   paddingBottom: '32px',
      //   fontFamily: 'EuclidCircularB',
      //   fontWeight: '400',
      //   lineHeight: '35.5px',
      //   letterSpacing: '0.25px',
      // },
      '& .MuiTypography-root-MuiDialogTitle-root': {
        fontSize: theme.typography.h5.fontSize,
        fontWeight: '400',
        lineHeight: '35.5px',
        letterSpacing: '0.25px',
      },
      '& .MuiTypography-h6': {
        fontFamily: 'EuclidCircularB',
        fontWeight: '400',
        lineHeight: '35.5px',
        letterSpacing: '0.25px',
      },
    },
    dialog: {
      '& .MuiDialogTitle-root': {
        '& .MuiTypography-root': {
          fontFamily: 'EuclidCircularB',
          fontSize: theme.typography.h5.fontSize,
          fontWeight: '400',
          lineHeight: '35.5px',
          letterSpacing: '0.25px',
        },
        paddingTop: '24px',
        paddingLeft: '24px',
        paddingBottom: '32px',
      },
      '& .MuiDialogActions-root': {
        padding: 24,
      },
      '& .css-1hju3x6-MuiPaper-root-MuiDialog-paper': {
        borderRadius: theme.shape.borderRadius,
      },
      '& .MuiDialogContent-root': {
        width: 576,
      },
      // '& .css-bdhsul-MuiTypography-root-MuiDialogTitle-root': {
      //   paddingTop: '24px',
      //   paddingLeft: '24px',
      //   paddingBottom: '32px',
      //   fontFamily: 'EuclidCircularB',
      //   fontSize: theme.typography.h5.fontSize,
      //   fontWeight: '400',
      //   lineHeight: '35.5px',
      //   letterSpacing: '0.25px',
      // },
    },
    textField: {
      '& .MuiFormHelperText-root': {
        color: theme.palette.error.dark,
      },
    },
    dialogActions: {
      padding: 0,
      marginTop: theme.spacing(2),
    },
  });
