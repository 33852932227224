import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import theme from 'containers/App/theme';

/** A container for the graphic and title on the left hand side of the signup screens */
function GraphicContainer(props: { children: React.ReactNode }) {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" position="relative">
      {props.children}
    </Box>
  );
}

function Title(props: { children: React.ReactNode; align?: 'center' | 'left' | 'right' }) {
  const paddingBottom = theme.spacing(3);
  const { children, align } = props;
  return (
    <Typography
      style={{ paddingBottom, display: 'inline' }}
      variant="h5"
      component="h1"
      align={align || 'center'}
      gutterBottom>
      {children}
    </Typography>
  );
}

function Subtitle(props: { children: React.ReactNode; align?: 'center' | 'left' | 'right' }) {
  const classes = useStyles();
  const { children, align } = props;

  return (
    <Typography className={classes.subtitle} align={align || 'center'}>
      {children}
    </Typography>
  );
}

function Image(props: { src: string; alt: string, className?: string}) {
  return (
    <Box mt={3} className={props.className}>
      <img src={props.src} alt={props.alt} />
    </Box>
  );
}

function SvgImage(props: { children: React.ReactNode, className?: string }) {
  const classes = useStyles();
  return <Box mt={3} className={props.className}>{props.children}</Box>;
}

GraphicContainer.Title = Title;
GraphicContainer.Subtitle = Subtitle;
GraphicContainer.Image = Image;
GraphicContainer.SvgImage = SvgImage;

export default GraphicContainer;
