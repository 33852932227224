import {
  faCashRegister,
  faChartLine,
  faChartMixed,
  faChartMixedUpCircleDollar,
  faChartNetwork,
  faChartPieSimpleCircleDollar,
  faChartTreeMap,
  faClipboardCheck,
  faClockRotateLeft,
  faCoin,
  faCreditCard,
  faDisplayChartUp,
  faDollarSign,
  faGaugeHigh,
  faGlobe,
  faHandBackPointUp,
  faHandHoldingDollar,
  faLandmark,
  faLayerGroup,
  faListCheck,
  faMessageExclamation,
  faMoneyBillTransfer,
  faMoneyBillWave,
  faPeopleArrows,
  faPeopleLine,
  faPersonCircleCheck,
  faPersonCirclePlus,
  faShieldCheck,
  faStore,
  faToolbox,
  faUsers,
  faLayerGroupPlus,
  faBallotCheck,
  faIslandTropical,
  faDesktop,
  faMoneyCheckPen,
  faBuilding,
  faBuildings
  
} from '@fortawesome/pro-regular-svg-icons';
import { Scope, Role } from 'models';
import { MenuGroup, MenuItemType, MenuItems } from 'models/Menu';
import { paths } from 'routes';
import { ACL } from 'types/permissions';
export interface IPathProps {
  accountId?: string;
  tenantId?: string;
  storeId?: string;
}
export interface IHideProps {
  hide?: boolean;
}
type MenuItemsFunc = ({ accountId, tenantId, storeId,  hide }: IPathProps & IHideProps) => MenuItems;
const menuItems: MenuItemsFunc = ({ accountId, tenantId, storeId, hide }) => {
  return [
    {
      type: MenuItemType.PAGE,
      label: 'Dashboard',
      icon: faChartTreeMap,
      to: paths.root(),
      scopes: [
        Role.ADMIN,
        Role.USER,
        Scope.OWNER,
        Scope.MANAGER,
        Scope.EMPLOYEE,
      ]
    },
    {
      type: MenuItemType.GROUP,
      label: MenuGroup.MANAGE,
      icon: faDesktop,
      items: [
        {
          type: MenuItemType.PAGE,
          label: 'Plans',
          to: paths.plans(),
          icon: faMoneyCheckPen,
          permissions: [],
          scopes: [Role.ADMIN]
        },
        {
          type: MenuItemType.PAGE,
          label: 'Workspaces',
          to: paths.tenants(),
          icon: faBuildings,
          permissions: [],
          scopes: [Role.ADMIN]
        },
        {
          type: MenuItemType.PAGE,
          label: 'My Workspace',
          to: paths.tenantDetails(tenantId ?? '').info(),
          icon: faBuilding,
          permissions: [],
          scopes: [Scope.OWNER]
        },
        {
          type: MenuItemType.PAGE,
          label: 'Stores',
          to: paths.stores(),
          icon: faStore,
          permissions: [],
          scopes: [Role.ADMIN, Scope.OWNER]
        },
        {
          type: MenuItemType.PAGE,
          label: 'My Store',
          to: paths.storeDetails(storeId ?? '').info(),
          icon: faStore,
          permissions: [],
          scopes: [Scope.MANAGER]
        },
        {
          type: MenuItemType.PAGE,
          label: 'Users',
          to: paths.users().root(),
          icon: faUsers,
          permissions: [],
          scopes: [Role.ADMIN, Scope.OWNER, Scope.MANAGER]
        },
        {
          type: MenuItemType.PAGE,
          label: 'Invitations',
          to: paths.invitations(),
          icon: faUsers,
          permissions: [],
          scopes: [Role.ADMIN, Scope.OWNER, Scope.MANAGER]
        },
        {
          type: MenuItemType.PAGE,
          label: 'Alerts',
          to: paths.alerts(),
          icon: faMessageExclamation,
          scopes: [Role.ADMIN, Scope.OWNER, Scope.MANAGER]
        },
      ],
    },
  ] as MenuItems;
};

export default menuItems;
