import React from 'react';
import { useStores } from 'containers/App/App';

const useElevatedAppBar = () => {
  const { uiStore } = useStores();

  const menuOpen = uiStore?.drawer.open;
  const scrollTrigger = uiStore?.appBar.trigger;
  const mobileView = uiStore?.mobileView;

  return { menuOpen, scrollTrigger, mobileView };
};

export default useElevatedAppBar;
