import React from 'react';
import Logo from 'components/ShopLogo/ShopLogo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMobileToolbar from './useMobileToolbar';
import { Box, IconButton } from '@material-ui/core';
import { Notifications } from 'containers/DashboardLayout/Notifications/Notifications';
import useStyles from './styles';

const MobileToolbar = () => {
  const { openStyles, closeStyles, wrapper, iconButton } = useStyles();
  const { open, icon, toggleDrawer } = useMobileToolbar();
  return (
    <>
      <Logo link />
      <Box className={wrapper}>
        <Notifications />
        <IconButton disableTouchRipple onClick={toggleDrawer} className={iconButton}>
          <FontAwesomeIcon icon={icon} className={open ? closeStyles : openStyles} />
        </IconButton>
      </Box>
    </>
  );
};

export default MobileToolbar;
