import { alpha, createTheme, Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/styles';

const defaultTheme = createTheme();
export const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      active: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light,
        fontWeight: 700,
        border: 'none',
        fontSize: '16px',
        padding: '6px 16px',
        width: '100%',
      },
      inactive: {
        color: theme.palette.error.main,
        backgroundColor: theme.palette.error.light,
        fontWeight: 700,
        border: 'none',
        fontSize: '16px',
        padding: '6px 7px',
        width: '100%',
      },
      chip: {
        fontWeight: 700,
        border: 'none',
        fontSize: '16px',
        padding: '6px 7px',
      },
      red: {
        color: theme.palette.status.red,
        backgroundColor: alpha(theme.palette.status.red, 0.2),
      },
      yellow: {
        color: theme.palette.status.yellow,
        backgroundColor: alpha(theme.palette.status.yellow, 0.2),
      },
      green: {
        color: theme.palette.status.green,
        backgroundColor: alpha(theme.palette.status.green, 0.2),
      },
      orange: {
        color: theme.palette.status.orange,
        backgroundColor: alpha(theme.palette.status.orange, 0.2),
      },
      blue: {
        color: theme.palette.status.blue,
        backgroundColor: alpha(theme.palette.status.blue, 0.2),
      },
      grey: {
        color: theme.palette.status.grey,
        backgroundColor: theme.palette.divider,
      },
      purple: {
        color: theme.palette.status.purple,
        backgroundColor: alpha(theme.palette.status.purple, 0.2),
      },
    }),
  { defaultTheme },
);
