import { makeStyles, createStyles, Theme } from '@material-ui/core';

interface IStyleProps {
  open: boolean;
}
const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: theme.spacing(2),
      position: 'sticky',
      backgroundColor: theme.palette.common.white,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: ({ open }) => (open ? theme.spacing(3) : theme.spacing(1)),
      '&::before': {
        content: "''",
        height: '1px',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        margin: '0 auto',
        backgroundColor: theme.palette.divider,
      },
    },
    menu: {
      '& .MuiPaper-root': {
        width: '100%',
        animation: '$open 0.3s ease-out',
        boxShadow: ` 0px 6px 24px 0px #00000033`,
      },
    },
    menuClose: {
      '& .MuiPaper-root': {
        width: '100%',
        animation: '$close 0.3s ease-out',
      },
    },
    '@keyframes close': {
      '0%': {
        transform: 'translateY(0)',
      },
      '100%': {
        transform: 'translateY(100%)',
      },
    },
    '@keyframes open': {
      '0%': {
        transform: 'translateY(100%)',
      },
      '100%': {
        transform: 'translateY(0)',
      },
    },
  }),
);

export default useStyles;
