import * as React from 'react';
import TagsAutocomplete from './TagsAutocomplete';
import TagsInputField from './TagsInputField';

const TagInputComponent = ({ options, ...otherProps }: any) => {
  return (
    <React.Fragment>
      {options ? (
        <TagsAutocomplete options={options} {...otherProps} />
      ) : (
        <TagsInputField {...otherProps} />
      )}
    </React.Fragment>
  );
};

export default TagInputComponent;
