import React from 'react';
import { Button as MuiButton, ButtonProps } from '@material-ui/core';
import useStyles from './styles';
import clsx from 'clsx';

export default function Button(props: ButtonProps) {
  const classes = useStyles();
  const btnStyle = props.variant !== 'contained' ? classes.outlined : classes.contained;
  return (
    <MuiButton {...props} className={clsx(classes.button, btnStyle, props.className)}></MuiButton>
  );
}
