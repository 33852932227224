import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import useStyles from './styles';

export function OverlayLoader({ display }: { display: boolean }) {
  const { loader } = useStyles();
  if (!display) return <></>;
  return (
    <Box className={loader}>
      <CircularProgress />
    </Box>
  );
}
