import React, { Fragment } from 'react';
import {  makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { inject, WithUserStore } from 'types/stores';
import styles from './styles';
import { User } from 'models';

interface WorkspaceProps extends WithStyles<typeof styles>, WithUserStore {
  user?: User;
}

/** Displays the workspace settings for the user with :userId */
@inject('userStore')
@observer
class Workspace extends React.Component<WorkspaceProps> {
  public constructor(props: WorkspaceProps) {
    super(props);
    makeObservable(this);
  }

  render() {
    return (
      <>
        My Workspace
      </>
    );
  }
}

export default withStyles(styles)(Workspace);
