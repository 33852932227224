import { makeStyles } from '@material-ui/core';
import { Theme } from '@mui/material';

interface IStyleProps {
  mr?: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default makeStyles<Theme, IStyleProps>((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#fff',
    borderRadius: '40px',
    position: 'relative',
  },
  tabBase: {
    fontSize: theme.typography.fontSize,
    textAlign: 'center',
    fontWeight: 400,
    color: theme.palette.text.primary,
    fontFamily: 'EuclidCircularB',
    // paddingLeft: 20
  },
  contained: {
    padding: "16px",
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    lineHeight: '24px',
    letterSpacing: 0.5,
    textTransform: 'capitalize',
    borderRadius: '40px',
    height: '40px',
    color: theme.palette.text.primary,
    '&.selected': {
      borderRadius: '40px',
      backgroundColor: theme.palette.primary.main,
      fontWeight: 400,
    },
  },
  underline: {
    textTransform: 'uppercase',
    '&.selected': {
      fontWeight: 700,
      color: theme.palette.primary.main,
      '&::before': {
        content: "''",
        position: 'absolute',
        bottom: -2,
        left: 0,
        right: 0,
        height: 2,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  spaceRight: {
    marginRight: ({ mr }) => (mr ? theme.spacing(mr) : 0),
  },
}));
