import { makeStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default makeStyles((theme) => ({
  root:{},
  count: {
    color: theme.palette.grey[500],
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  titleDark: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  titleLight: {
    color: theme.palette.common.black,
  },
  titlePanel: {
    color: theme.palette.common.black,
    fontSize: '20px',
    textTransform: 'capitalize',
    fontWeight: 400,
    lineHeigh: '25.36px'
  },
  subTitleDrawer: {
    color: theme.palette.common.black,
    fontSize: '16px',
    textTransform: 'capitalize',
    fontWeight: 400,
    lineHeigh: '20px'
  },
  skeleton: {
    margin: 0,
  },
}));
//   export default makeStyles((theme) => ({
//     root:{},
//     count: {
//       color: theme.palette.grey[500],
//     },
//     subtitle: {
//       color: theme.palette.primary.main,
//       fontWeight: 600
//     },
//     titleDark: {
//       color: theme.palette.text.primary,
//       fontWeight: 600
//     },
//     titleLight: {
//       color: theme.palette.common.black,
//       textTransform: 'capitalize',
//       fontWeight: 400,
//       fontSize: '20px'
//     },
//     skeleton: {
//       margin: 0,
//     },
//   })
// );
