import { WithStyles, withStyles } from '@material-ui/core/styles';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { WithSettingStore, WithToastStore, WithUserStore, inject } from 'types/stores';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import DashboardLayout from 'containers/DashboardLayout';
import styles from './styles';
import {
  Box,
  Dialog,
  Link,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import FilterBar from 'components/FilterBar';
import { Filter } from 'components/FilterBar/FilterBar';
import Title from 'components/Title';
import DataGridInfiniteScroll, { GridColumns } from 'components/DataGridInfiniteScroll';
import PlusFabButton from 'components/PlusFabButton/PlusFabButton';
import { fetchWrapper, setTitle } from 'services';
import Api, { RequestMetaData } from 'api';
import { Plan } from 'models';
import { paths } from 'routes';
import ChipStatusTag from 'components/ChipStatusTag';
import Overlay from 'components/Overlay';
import clsx from 'clsx';
import Button from 'components/Button/Dialog/Button';
import CompanyForm from 'components/JSForm/CompanyForm';
import { Formik } from 'formik';
import { object, string, number } from 'yup';

const PAGE_TITLE = 'Plans';

export interface FilterItem {
  label: string;
  value: any;
}

type PlansProps = WithStyles<typeof styles> &
  RouteComponentProps &
  WithUserStore &
  WithToastStore &
  WithSettingStore;

/**
 * Container displaying active kiosk alert message if present and list of past alerts.
 */
@inject('userStore', 'toastStore', 'settingStore')
@observer
class Plans extends React.Component<PlansProps> {
  public constructor(props: PlansProps) {
    super(props);
    makeObservable(this);
  }

  @observable private activeFilters: Record<string, unknown> = {};
  @observable private open = false;
  @observable private displayOverlay = false;

  public getPlans = fetchWrapper(
    async (rmd: RequestMetaData) => {
      const order: 'ASC' | 'DESC' = 'DESC';

      return await Api.core.getPlans({
        ...rmd,
        filters: {
          ...this.activeFilters,
        },
        sort: { sortBy: 'createdAt', sortOrder: order },
      });
    },

    (plan: Plan) => ({
      ...plan,
    }),
  );

  public async postAddPlan(data: Record<string, unknown>) {
    try {
      await Api.core.addPlan(data);
      this.props.toastStore!.push({
        type: 'success',
        message: `Plan added successfully`,
      });
    } catch (error) {
      this.props.toastStore!.push({
        type: 'error',
        message: `${(error as Error).message}`,
      });
    }
  }

  onClose() {
    this.open = false;
  }

  onOpen() {
    this.open = true;
  }

  filters: Filter[] = [
    { display: 'Name', id: 'name', label: 'Contains', type: 'text' },
    {
      display: 'Type',
      id: 'type',
      label: 'One of',
      type: 'select',
      items: [
        { label: 'FREE', value: 'free' },
        { label: 'PRO', value: 'pro' },
        { label: 'CUSTOM', value: 'custom' },
      ],
    },
    {
      display: 'Status',
      id: 'isActive',
      label: 'One of',
      type: 'select',
      items: [
        { label: 'ACTIVE', value: 'true' },
        { label: 'INACTIVE', value: 'false' },
      ],
    },
  ];

  renderCellName = (params: any) => (
    <Typography>
      {params.value}
    </Typography>
  );

  renderCellStatus({ value }: any) {
    return <ChipStatusTag status={value} />;
  }

  get gridColumns(): GridColumns {
    return [
      {
        headerName: 'Name',
        field: 'name',
        minWidth: 200,
        renderCell: this.renderCellName,
        flex: 1,
      },
      {
        headerName: 'Description',
        field: 'description',
        minWidth: 200,
        flex: 1,
      },
      {
        headerName: 'Type',
        field: 'type',
        minWidth: 200,
        flex: 1,
      },
      {
        headerName: 'Status',
        field: 'isActive',
        minWidth: 150,
        sortable: false,
        renderCell: this.renderCellStatus,
      },
    ]; 
  }

  renderDialog() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.open}
        onClose={() => this.onClose()}
        className={clsx(classes.dialog)}
        fullWidth>
        <Overlay display={this.displayOverlay}>
          <CircularProgress />
        </Overlay>
        <DialogTitle disableTypography>
          <Title mb={0}>Add Plan</Title>
        </DialogTitle>
        <Box>
          <Formik
            initialValues={{
              name: '',
            }}
            validationSchema={object({
              name: string().required(),
            })}
            onSubmit={async (values, actions) => {
              const { name, ...address } = values;
              await this.postAddPlan({ name, address });
              setTimeout(() => {
                actions.setSubmitting(false);
                this.activeFilters = { ...this.activeFilters };
                this.onClose();
              }, 1000);
            }}>
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <DialogContent>
                  {'Add new plan'}
                </DialogContent>
                <DialogActions>
                  <Button variant="outlined" onClick={() => this.onClose()}>
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit" disabled={!props.isValid}>
                    OK
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </Box>
      </Dialog>
    );
  }

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
  }

  render() {
    return (
      <DashboardLayout>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Title mb={3}>{PAGE_TITLE}</Title>
        </Box>

        <FilterBar
          filters={this.filters}
          onChange={(filters: Record<string, unknown>) => {
            this.activeFilters = filters;
          }}
        />

        <DataGridInfiniteScroll
          columns={this.gridColumns}
          fetch={this.getPlans}
          refetchKey={this.activeFilters}
          disableColumnMenu
          pathname={this.props.location.pathname}
        />
        <PlusFabButton onClick={() => this.onOpen()} />
        {this.renderDialog()}
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Plans);
