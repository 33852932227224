import React from 'react';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Link, Typography } from '@material-ui/core';
import { ChevronLeft } from 'mdi-material-ui';

import { inject, WithToastStore, WithUserStore } from 'types/stores';
import Api, { getErrorMsg } from 'api';
import { setTitle } from 'services/title';
import qs from 'qs';

import CodeInput from 'components/CodeInput';

import styles from './styles';
import clsx from 'clsx';
import CarouselScreenWrapper from 'components/CarouselScreenWrapper/CarouselScreenWrapper';
import Button from 'components/Button/Button';
import withAndroidRedirect from 'components/WithAndroidRedirect/withAndroidRedirect';

interface ConfirmEmailProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<{ code?: string }>,
    WithToastStore,
    WithUserStore {
  nextRoute?: () => string;
  prevRoute: () => string;
}

interface ConfirmEmailRouterState {
  email: string;
}

/**
 * Displays the prompt to enter an email confirmation code
 */
@inject('toastStore', 'userStore')
@observer
class ConfirmEmail extends React.Component<ConfirmEmailProps> {
  constructor(props: ConfirmEmailProps) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    setTitle(`Confirm Email`);
  }

  @observable public routerState = this.props.location.state as ConfirmEmailRouterState;
  /** The confirmation code */
  @observable code = '';

  @observable public resendingCode = false;

  @observable public confirming = false;

  /** Event handler to update the code */
  @action.bound public updateCode(e: React.ChangeEvent<HTMLInputElement>) {
    this.code = e.target.value;
  }

  /** Action to resend the code */
  @action.bound public resendCode = flow(function* (this: ConfirmEmail) {
    try {
      this.resendingCode = true;
      yield Api.core.sendEmailConfirmation(this.routerState.email);
      this.resendingCode = false;
      this.props.toastStore!.success('Confirmation email resent!');
    } catch (e: any) {
      this.props.toastStore!.push({
        type: 'error',
        message: 'An error occurred while resending the confirmation email',
      });
    }
  });

  @action.bound public confirmEmail = flow(function* (this: ConfirmEmail) {
    try {
      this.confirming = true;
      const resp: any = yield Api.core.loginTOTP({ email: this.routerState.email, code: this.code.toUpperCase()});
      this.props.userStore!.loginWithData(resp.data);

      if (this.props.nextRoute) {
        this.props.history.replace(this.props.nextRoute());
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.confirming = false;
    }
  });

  /** The form submit handler */
  @action.bound public handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.confirmEmail();
  }

  /** Whether we're currently confirming */
  @computed public get submitting(): boolean {
    return this.resendingCode || this.confirming;
  }

  render() {
    const { classes } = this.props;
    const email = this.routerState.email;
    return (
      <CarouselScreenWrapper submitting={this.submitting}>
        <form onSubmit={this.handleSubmit}>
          <Box mb={15.875}>
            <Typography variant="h4" component="h1" align="center">
              Confirm your Email
            </Typography>
          </Box>
          <Typography className={classes.greyText} align="center" gutterBottom>
            {!email ? (
              `We've sent a confirmation code to your email. `
            ) : (
              <>
                We've sent a confirmation code to&nbsp;
                <Box component={'span'} className={classes.email}>
                  {email}
                </Box>
                .&nbsp;
              </>
            )}
            Please check your inbox and enter the code below
          </Typography>
          <Box mb={2} mt={3}>
            <CodeInput value={this.code} onChange={this.updateCode} />
          </Box>
          <Typography variant="subtitle1" align="center" gutterBottom>
            Didn't receive the code?{' '}
            <Link
              style={{ cursor: 'pointer' }}
              className={clsx(this.resendingCode && classes.greyText)}
              onClick={this.resendingCode ? undefined : this.resendCode}
              underline="always">
              Resend code
            </Link>
          </Typography>
          <Box mt={5} mb={2}>
            <Button color="primary" variant="contained" fullWidth type="submit">
              Continue
            </Button>
          </Box>
          <Link component={RouterLink} to={this.props.prevRoute()} underline="none">
            <Box
              className={classes.greyText}
              display="flex"
              alignItems="center"
              flexDirection="row">
              <ChevronLeft color="inherit" />
              Change email address
            </Box>
          </Link>
        </form>
      </CarouselScreenWrapper>
    );
  }
}

export default withAndroidRedirect(withStyles(styles)(ConfirmEmail));
