import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    field: {
      '& .MuiFormHelperText-root': {
        fontSize: '11px',
      },
    },
    title:{
      '& .MuiTypography-h5':{
        fontSize: '20px'
      }
    }
  });
