import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useStyles } from './styles';
import { RequestMetaData } from '../DataGridInfiniteScroll/InterfaceDataGridPro';

export type Row = any;
type sortDirectionType = 'DESC' | 'ASC';

interface InfiniteScrollWrapperProps {
  render: (rows: Row[]) => JSX.Element;
  fetch: (
    rmd: RequestMetaData,
  ) => Promise<{ rows: Row[]; sortable?: string[]; totalElements: number }>;
  sortByField?: string;
  sortDirection?: sortDirectionType;
  refresh?: Row;
  loader?: ()=> React.ReactElement
  limit?: number
}

const InfiniteScrollWrapper = ({
  fetch,
  render,
  sortByField,
  sortDirection,
  refresh,
  loader,
  limit
}: InfiniteScrollWrapperProps) => {
  const [loadedRows, setLoadedRows] = useState<any[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [sortable, setSortable] = useState<string[]>([]);

  const classes = useStyles();

  const getSort = () => {
    const isSortable = sortable.includes(sortByField ?? '');

    return {
      sortBy: isSortable ? sortByField : 'createdAt',
      sortOrder: sortDirection ?? 'ASC',
    };
  }


  const loadServerRows = async (page: number) => {
      const reload = page == 1;

      if (loadedRows.length < totalElements || reload) {
        const query = {
          pagination: {
            take: limit || 10,
            skip: reload ? 0 : loadedRows.length,
          },
          sort: getSort()
        };

        fetch &&
          fetch(query)
            .then((newData) => {
              if (newData) {
                if (Array.isArray(newData.rows)) {
                  totalElements !== newData.totalElements &&
                    setTotalElements(newData.totalElements);
                  const newRows = reload ? [...newData.rows] : [...loadedRows, ...newData.rows];
                  setLoadedRows(newRows);

                  if (Array.isArray(newData.sortable)) {
                    setSortable(newData.sortable);
                  }
                }
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {});
      }
    }

  useEffect(() => {
    refresh && loadServerRows(1);
  }, [refresh]);

  const renderComponent = useMemo(() => {
    return render(loadedRows);
  }, [loadedRows, render]);

  const renderSkeleton = useMemo(() => {
    return loader ? loader() : <>Loading ...</>;
  }, [loader]);

  const hasMore = useMemo(() => {
    return (loadedRows.length < totalElements) || (totalElements == 0)
  }, [loadedRows, totalElements]);

  return (
    <div className={classes.box}>
      <InfiniteScroll hasMore={hasMore} loadMore={loadServerRows} loader={renderSkeleton} useWindow={false} initialLoad>
        {renderComponent}
      </InfiniteScroll>
    </div>
  );
};

export default InfiniteScrollWrapper
