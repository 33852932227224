import React, { Component } from 'react';
import RootStore from 'types/stores/RootStore';
import { Provider } from 'mobx-react';
import { ThemeProvider } from '@material-ui/styles';
import { LicenseInfo } from '@mui/x-data-grid-pro';

import theme from './theme';
import Layout from 'components/Layout';
import Routes from 'routes/Routes';
// import { NotificationSystem } from 'components/NotificationSystem/NotificationSystem';
import MobileView from 'components/MobileView/MobileView';

LicenseInfo.setLicenseKey(
  '6e5df2674df280ba8a8083f75defd85eT1JERVI6MzE1MjksRVhQSVJZPTE2NjY4ODYxMjcwMDAsS0VZVkVSU0lPTj0x',
);

// Initialize the root store
export const rootStore = new RootStore();
const StoresContext = React.createContext(rootStore);
export const useStores = () => React.useContext(StoresContext);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).rootStore = rootStore;

/**
 * This is the main application component, which is mounted to
 * the root element in index.tsx.
 */
class App extends Component {
  render() {
    return (
      <Provider {...rootStore}>
        <ThemeProvider theme={theme}>
          <MobileView />
          <Layout>
            <Routes />
            {/* <NotificationSystem /> */}
          </Layout>
        </ThemeProvider>
      </Provider>
    );
  }
}

export default App;
