import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    avatar: {
        width: 99,
        height: 99,
      padding: 0,
      '& .MuiAvatar-root': {
        width: 99,
        height: 99,
      },
      '& .MuiAvatar-colorDefault': {
        backgroundColor: '#E8E8E8',
      },
    },
    avatarHover: {
      position: 'relative',
      borderRadius: '50%',
      border: '4px dashed',
      width: 99,
      height: 99,
      borderColor: theme.palette.primary.main,
    },
    typography: {
      textTransform: 'uppercase',
      fontSize: theme.typography.fontSize,
      fontWeight: 600,
      letterSpacing: '0.75px',
    },
    instructionText: {
      textAlign: 'center',
      color: `rgba(0, 0, 0, 0.38)`,
      lineHeight: '36px',
    },
    button: {
      width: '361px',
      position: 'relative',
      color: theme.palette.text.primary,
      fontWeight: 400
    },
    menuButton: {
      position: 'relative',
      '& .MuiIcon-root': {
        position: 'absolute',
        right: 10,
        top: 7,
      },
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        marginRight: '10px',
      },
    },
    uploadButton: {
      position: 'relative',
      '& .MuiSvgIcon-root': {
        position: 'absolute',
        left: 10,
        top: 7,
      },
    },
    icon: {
      color: `rgba(0, 0, 0, 0.12)`,
      width: 52,
      height: 52,
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      margin: 'auto',
    },
    dialog: {
      '& .MuiDialogContent-root': {
        padding: 0,
      },
    },
    cameraIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      border: '2px solid',
      color: theme.palette.primary.main,
      width: '70px',
      height: '70px',
      '& .MuiSvgIcon-root': {
        fontSize: '46px',
        color: theme.palette.primary.main,
      },
    },
    actionContainer: {
      '& .MuiGrid-item': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    actionItem: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    photoIconWrapper: {
      width: 99,
      height: 99,
      border: `4px solid ${theme.palette.primary.main}`,
      borderRadius: '50%',
      position: 'relative',
    },
    photoIcon: {
      '-webkit-transform': 'scaleX(-1)',
      transform: 'scaleX(-1)',
      width: 52,
      height: 52,
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      margin: 'auto',
    },
    fileDrop: {
        width: 99, height: 99, padding: 0
    }
  });
