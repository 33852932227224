import { Theme, createStyles, makeStyles, alpha } from '@material-ui/core';
import { textFieldInput } from 'styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(3),
      right: theme.spacing(2),
    },
    root: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(4),
    },
    centerVertical: {
      justifyContent: 'center',
    },
    smallText: {
      fontSize: 12,
      color: theme.palette.text.secondary,
    },
    paper: {
      position: 'relative',
      paddingTop: '48px',
      paddingLeft: '54px',
      paddingRight: '32px',
      paddingBottom: '56px',
    },
    whiteText: {
      color: theme.palette.common.white,
    },
    blackText: {
      color: theme.palette.common.black,
    },
    miniText: {
      fontSize: 12,
    },
    check: {
      height: theme.spacing(12),
      width: theme.spacing(12),
    },
    pig: {
      height: theme.spacing(12),
      marginBottom: theme.spacing(4),
    },
    img: {
      height: '50%',
    },
    nextStepsRoot: {
      position: 'absolute',
      top: theme.spacing(3),
      right: 0,
      left: 0,
      bottom: theme.spacing(3),
    },
    nextStepsLeft: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      borderRight: `1px solid ${theme.palette.grey[200]}`,
      [theme.breakpoints.down('sm')]: {
        borderRight: `0`,
      },
    },
    nextStepsRight: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    successImg: {
      height: theme.spacing(20),
    },
    bold: {
      fontWeight: 600,
    },
    secondary: {
      color: theme.palette.secondary.main,
    },
    primary: {
      color: theme.palette.primary.main,
    },
    appStoreSection: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-around',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    downloadSticker: {
      width: theme.spacing(12),
    },
    container: {
      width: theme.spacing(59.875),
      height: theme.spacing(65.125),
      padding: 0,
      '&::-webkit-scrollbar': {
        display: 'none',
        width: '0 !important',
      },
      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none',
    },
    insideBox: {
      position: 'relative',
      padding: theme.spacing(4),
      paddingTop: theme.spacing(6.875),
      paddingBottom: theme.spacing(10),
      paddingRight: theme.spacing(7.375),
      paddingLeft: theme.spacing(7.375),
    },
    loader: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: alpha(theme.palette.common.white, 0.5),
      borderRadius: theme.shape.borderRadius,
      zIndex: 200,
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
    },
    checkoutCardsForm: {
      width: '100%',
      overflow: 'auto',
      backgroundColor: '#EEEEEE',
      paddingTop: '14px',
      paddingRight: '15px',
      paddingBottom: '11px',
      paddingLeft: '17px',
      borderRadius: theme.shape.borderRadius,
    },
    textFieldInput,
    backDrop: {
      backdropFilter: 'blur(3px)',
      backgroundColor: 'rgba(0,0,30,0.4)',
    },
    noShadow: {
      '& .MuiDialog-paper': {
        boxShadow: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
          width: '0 !important',
        },
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',
      },
    },
  });

export default styles;
export const useStyles = makeStyles(styles);

export const stylesCForm = makeStyles((theme: Theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(2),
  },
  root: {
    backgroundColor: theme.palette.primary.main,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
  centerVertical: {
    justifyContent: 'center',
  },
  smallText: {
    fontSize: 12,
  },
  paper: {
    position: 'relative',
  },
  whiteText: {
    color: theme.palette.common.white,
  },
  miniText: {
    fontSize: 12,
  },
  check: {
    height: theme.spacing(12),
    width: theme.spacing(12),
  },
  pig: {
    height: theme.spacing(12),
    marginBottom: theme.spacing(4),
    stroke: theme.palette.primary.main,
  },
  img: {
    height: '50%',
  },
  nextStepsRoot: {
    position: 'absolute',
    top: theme.spacing(3),
    right: 0,
    left: 0,
    bottom: theme.spacing(3),
  },
  nextStepsLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    [theme.breakpoints.down('sm')]: {
      borderRight: `0`,
    },
  },
  nextStepsRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  successImg: {
    height: theme.spacing(20),
  },
  bold: {
    fontWeight: 600,
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  appStoreSection: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  downloadSticker: {
    width: theme.spacing(12),
  },
}));
