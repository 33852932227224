import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    button: {
      padding: 0,
      color: theme.palette.text.secondary,
      fontSize: theme.typography.body1.fontSize,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    skipButton: {
      height: '16px',
      position: 'absolute',
      marginLeft: 'auto',
      marginRight: 'auto',
      color: theme.palette.text.primary,
      left: 0,
      right: 0,
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: '0.25em',
      width: 50,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    leftButton: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
    title: {
      fontSize: theme.spacing(5),
      color: theme.palette.text.primary,
    },
  });
