import { Theme, createStyles } from '@material-ui/core';
import {textFieldInput} from 'styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    icon: {
      color: theme.palette.text.secondary
    },
    textFieldInput
  });
