export enum DrawerType {
  ADD = 'add',
  QR = 'qr',
  EDIT = 'edit',
}

export enum optionSearchFilter {
  NAME = 'name',
  EMAIL = 'email',
  PHONE = 'phone',
}
