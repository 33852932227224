import React, { useState } from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { faSearch, faPhone } from '@fortawesome/pro-regular-svg-icons';
import styles from './styles';
import { observer } from 'mobx-react';
import { Typography, Box, Button, Link } from '@material-ui/core';
import clsx from 'clsx';
import TabBar from 'components/TabBar';
import { Autocomplete } from '@material-ui/lab';
import OutlinedInput from 'components/Input/OutlinedInput';
import { Plan, PlanRequest, Cycle } from 'models';

interface PlanCardProps extends WithStyles<typeof styles> {
  plan: Plan;
  onChange: (id: PlanRequest) => void;
}

const PlanCard = ({ onChange, plan, classes }: PlanCardProps) => {
  const [cycle, setCycle] = useState<Cycle>(plan.defaultBillingCycle);
  const [show, setShow] = useState<boolean>(false);

  const { id, name, description, billing, type, ...props } = plan;

  const renderBillingSelected = () => {
    const billingSelected = billing.find((b) => b.cycle === cycle);
    return (
      <>
        <Box display="flex" style={{ gap: '0.25rem' }}>
          <Typography variant="h4" className={classes.price}>
            ${billingSelected ? billingSelected.price : 0}
          </Typography>
          <Box display={'flex'}>
            <Typography component={'span'}>/</Typography>
            <Typography component={'span'}>{cycle}</Typography>
          </Box>
        </Box>

        <Typography variant="body2">
          {billingSelected ? billingSelected.description : 'Free forever!'}
        </Typography>
      </>
    );
  };

  const getVariant = (): {
    color: 'inherit' | 'primary' | 'secondary' | 'default';
    variant: 'text' | 'outlined' | 'contained';
  } => {
    switch (type) {
      case 'custom':
        return { color: 'default', variant: 'contained' };
      case 'pro':
        return {
          color: 'primary',
          variant: 'contained',
        };
      default:
        return { color: 'primary', variant: 'outlined' };
    }
  };
  const variantStyle = getVariant();

  const handleSubmit = () => {
    onChange({ id: plan.id as string, billingCycle: cycle, name: plan.name });
  };

  return (
    <Box
      className={clsx(classes.cardContainer, {
        [classes.cardRecommended]: Boolean(type === 'pro'),
      })}>
      <Box style={{ display: 'flex' }}>
        <Typography className={classes.textPrimary}>{name}</Typography>
      </Box>

      <Typography variant="body2" className={classes.textSecondary}>
        {description}
      </Typography>

      {billing.length > 0 && (
        <TabBar mb={2} className={classes.tabBar}>
          {billing.map((b) => (
            <TabBar.Tab
              key={b.cycle}
              selected={b.cycle === cycle}
              onClick={() => {
                setCycle(b.cycle);
              }}>
              {b.cycle}
            </TabBar.Tab>
          ))}
        </TabBar>
      )}

      {type !== 'custom' && renderBillingSelected()}

      {type === 'custom' && show && (
        <Autocomplete
          fullWidth
          options={[{ value: '123', label: 'Leo custom' }]}
          getOptionSelected={(option, value) => option.value === value.label}
          getOptionLabel={(o) => o.label || ''}
          onChange={(e, i) => {
            console.log(e, i);
          }}
          renderInput={(params) => <OutlinedInput {...params} fullWidth label={'Plan'} />}
        />
      )}
      <Button
        color={show ? 'primary' : variantStyle.color}
        variant={show ? 'contained' : variantStyle.variant}
        onClick={() => {
          handleSubmit();
        }}>
        {type === 'basic' && billing.length == 0
          ? 'Get started'
          : type === 'custom' && !show
          ? 'Contact sales'
          : 'Buy now'}
        {'>'}
      </Button>
      {type === 'custom' && (
        <Link
          color="primary"
          style={{ cursor: 'pointer', fontSize: 'small' }}
          onClick={() => {
            setShow(!show);
          }}>
          {show ? 'Design a plan tailored to my needs' : 'Choose an existing enterprise plan'}
        </Link>
      )}
    </Box>
  );
};

export default withStyles(styles)(observer(PlanCard));
