/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  // Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Api from 'api';
import Button from 'components/Button/Dialog/Button';
import DP from 'components/DashPanel';
import PasswordField from 'components/PasswordField';
import OutlinedInput from 'components/Input/OutlinedInput';
import Title from 'components/Title/Dialog/Title';
import { Check, Close, Pencil, Refresh } from 'mdi-material-ui';
import {
  action,
  computed,
  flow,
  IReactionDisposer,
  observable,
  reaction,
  toJS,
  makeObservable,
} from 'mobx';
import { observer } from 'mobx-react';
import { User } from 'models';
import React, { Fragment } from 'react';
import { inject, WithToastStore, WithUserStore } from 'types/stores';
import validatorjs from 'validatorjs';
import styles from './styles';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

// Define mobx-react-form fields:
const fields = [
  {
    name: 'email',
    label: 'Email',
    type: 'email',
    rules: 'required|email',
  },
];

// Define and extend mobx-react-form DVR plugin:
const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }: { validator: any }) => {
      /* Overwrite the specific validation errors */
      const customValidationMessages = {
        ...validator.getMessages('en'),
        same: 'Please input the same email again',
        email: 'Not a valid email format',
        required: 'This field is required',
      };
      validator.setMessages('en', customValidationMessages);
    },
  }),
};

interface EmailPanelProps extends WithStyles<typeof styles>, WithToastStore, WithUserStore {
  children?: User;
  title?: string;
}
/**
 * Displays user's current email and new email pending for confirmation if any.
 * @param children The user object to display. Pass undefined if it's still loading
 * @param title Optional title, `Emails` by default
 */
@inject('toastStore', 'userStore')
@observer
class EmailPanel extends React.Component<EmailPanelProps> {
  static defaultProps = {
    title: 'Email',
  };
  constructor(props: EmailPanelProps) {
    super(props);
    makeObservable(this);
    // Set up an autorun so that when the user in the props changes, we copy it
    // to this component's state. This achieves the same thing as implementing a
    // componentDidUpdate and seeing if the user prop has changed.
    // We push the autorun onto a disposers array so that we can dispose of all
    // of them before the component unmounts.
    this.disposers.push(
      reaction(
        () => this.props.children,
        (newUser) => {
          this.user = toJS(newUser);
        },
      ),
    );

    // Define mobx-react-form hooks
    this.hooks = {
      onSuccess: (form: any) => {
        const { email, password } = form.values();
        this.updateEmail(email, password);
      },
      onClear: (form: any) => {
        this.editing = false;
        form.clear();
      },
    };
    // Create mobx-react-form instance using 'field[]', 'plugins' and event 'hooks'
    this.form = new MobxReactForm({ fields }, { plugins, hooks: this.hooks });
  }

  /** It's good practice to dispose of any autoruns that we set up during */
  private disposers: IReactionDisposer[] = [];

  /** The user object is copied to this property */
  @observable private user?: User = toJS(this.props.children);

  /** Form and form dependencies */
  private form: any;
  private hooks: any;

  /** Is user updating her email address in the form dialog */
  @observable private editing = false;

  /** Is component updating API */
  @observable private updating = false;

  /** Confirmation code controlled input */
  @observable private confirmationCode = '';

  @observable private confirmationCodeError: null | string = null;

  /** On confirmation code input value change */
  @action.bound private onCodeChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.confirmationCodeError = this.confirmationCodeError && null;
    const value = event.target.value;
    this.confirmationCode = value;
  }

  /** Starts editing */
  @action.bound private edit() {
    this.editing = true;
  }

  /** On confirmation code submit */
  @action.bound private onSubmitConfirmationCode(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    this.confirmEmail();
  }

  /** Update user */
  @action.bound public updateEmail = flow(function* (this: EmailPanel, email, password) {
    try {
      this.updating = true;
      yield Api.core.updateEmail(email, password);
      this.form.clear();
      this.props.userStore!.refreshTokenAndUpdateUserData();
      this.editing = false;
      this.props.toastStore!.push({
        type: 'success',
        message: `Confirmation email was sent to ${email}`,
      });
    } catch (e: any) {
      const errorCode = e.response.status;
      errorCode === 422 && this.form.$('password').invalidate(e.response.data.error.message);
      errorCode === 403 && this.form.$('email').invalidate(e.response.data.error.message);
    } finally {
      this.updating = false;
    }
  });

  /** Confirm email address using confirmation code/token */
  @action.bound public confirmEmail = flow(function* (this: EmailPanel) {
    try {
      this.updating = true;
      yield Api.core.verifyEmail(this.confirmationCode, this.form.$('email'));
      this.props.userStore!.refreshTokenAndUpdateUserData();
      this.props.toastStore!.push({
        type: 'success',
        message: `New email address was successfully confirmed`,
      });
      this.confirmationCode = '';
    } catch (e: any) {
      this.confirmationCodeError = 'Invalid code';
    } finally {
      this.updating = false;
    }
  });

  @action.bound public cancelPendingUpdate = flow(function* (this: EmailPanel) {
    yield Api.core.cancelEmailChange();
    this.props.userStore!.refreshTokenAndUpdateUserData();
    this.props.toastStore!.push({
      type: 'success',
      message: 'Email change request canceled',
    });
  });

  @action.bound public resendConfirmationEmail = flow(function* (this: EmailPanel) {
    yield Api.core.sendEmailConfirmation(this.props.userStore!.user!.email);
    this.props.toastStore!.push({
      type: 'success',
      message: `Confirmation email was resent to ${this.props.userStore!.user!.email}`,
    });
  });

  /** The props that are passed to the Indicator component */
  @computed private get emailIndicatorProps(): { color: 'green' | 'red'; tooltip: string } {
    const emailConfirmed = Boolean(this.user && this.user.isEmailConfirmed);
    return {
      color: emailConfirmed ? 'green' : 'red',
      tooltip: emailConfirmed ? 'Email confirmed' : `Email unconfirmed`,
    };
  }

  /** Before unmounting the component, dispose of all autoruns created */
  componentWillUnmount() {
    this.disposers.map((disposer) => disposer());
  }

  render() {
    const { title, classes } = this.props;
    const canSubmitCode = !this.confirmationCodeError && this.confirmationCode.length > 0;
    if (!this.user) {
      return (
        <DP>
          <DP.Header>
            <DP.Title light size="small">
              {title}
            </DP.Title>
          </DP.Header>
          <DP.Body>
            <DP.Loading items={1} />
          </DP.Body>
        </DP>
      );
    }
    return (
      <>
        <DP>
          <DP.Header>
            <DP.Title light size="small">
              {title}
            </DP.Title>
            <DP.Actions>
              {!this.user.email && (
                <DP.IconButton primary onClick={this.edit} icon={Pencil} tooltip="Edit" />
              )}
            </DP.Actions>
          </DP.Header>
          <DP.Body>
            <DP.Row>
              <DP.Value>
                {this.user.email}
                <DP.Indicator {...this.emailIndicatorProps} />
              </DP.Value>
              <DP.Label>Email</DP.Label>
            </DP.Row>
            {!this.user.isEmailConfirmed && (
              <>
               <form onSubmit={this.onSubmitConfirmationCode}>
                  <DP.Row>
                    <DP.Value>
                      <Box display={'flex'}>
                      <FormControl className={classes.codeInput} margin="none">
                        {/* <Input
                          value={this.confirmationCode}
                          onChange={this.onCodeChange}
                          placeholder={'Confirmation code'}
                          error={Boolean(this.confirmationCodeError)}
                        /> */}
                        <OutlinedInput
                        fullWidth
                          value={this.confirmationCode}
                          onChange={this.onCodeChange}
                          placeholder={'Confirmation code'}
                          error={Boolean(this.confirmationCodeError)}
                        />
                        {Boolean(this.confirmationCodeError) && (
                          <FormHelperText error>{this.confirmationCodeError}</FormHelperText>
                        )}
                      </FormControl>
                      <Box display={'flex'}>
                      <DP.IconButton
                        primary
                        icon={Check}
                        disabled={!canSubmitCode}
                        submit
                        tooltip="Confirm email address"
                      />
                      <DP.IconButton
                        primary
                        onClick={this.resendConfirmationEmail}
                        icon={Refresh}
                        tooltip="Resend confirmation email"
                      />
                      </Box>

                      </Box>
                    </DP.Value>
                  </DP.Row>
                </form>
              </>
            )}
          </DP.Body>
        </DP>
        <Dialog classes={{ paper: classes.dialog }} open={this.editing} onClose={this.form.onClear}>
          {this.editing && (
            <>
              <DialogTitle className={classes.dialogTitle}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between">
                  <Title>Change Email</Title>
                  {this.updating && <DP.LoadSpinner />}
                </Box>
              </DialogTitle>
              <form onSubmit={this.form.onSubmit}>
                <input type="hidden" value="something" />
                <DialogContent className={classes.dialogContent}>
                  {Array.from(this.form.fields).map(([name, field]: any) => {
                    const hasError = Boolean(field.error);
                    const validateField = (e: any) => {
                      e.preventDefault();
                      field.validate({ showErrors: true });
                    };
                    return (
                      <Fragment key={name}>
                        {field.type === 'password' ? (
                          <PasswordField
                            mobxField={field}
                            onBlur={validateField}
                            error={field.error}
                          />
                        ) : (
                          <OutlinedInput
                            {...field.bind()}
                            helperText={field.error}
                            error={hasError}
                            fullWidth
                          />
                        )}
                      </Fragment>
                    );
                  })}
                  <p>{this.form.error}</p>
                  <DialogActions className={classes.dialogActions}>
                    <Button onClick={this.form.onClear} color="primary" variant="outlined">
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      onClick={this.form.onSubmit}
                      color="primary"
                      variant="contained"
                      autoFocus>
                      Change
                    </Button>
                  </DialogActions>
                </DialogContent>
              </form>
            </>
          )}
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(EmailPanel);
