import { AxiosResponse } from 'axios';
import config from 'config';
import {
  Account,
  IUserMerge,
  Invitation,
  NewAlertRequest,
  Tenant,
  User,
  UserLoginResponseV2,
} from 'models';
import { ApiResponse, PagedApiResponse, RequestMetaData } from './interface';
import { createApi, makeQS } from './utils';

const api = {
  v2: createApi({ baseURL: 'http://localhost:4000/api' }),
  v1: createApi({ baseURL: 'https://gateway-b2ctools.up.railway.app/api' }),
};

//AUTH

export const login = (email: string, password: string) =>
  api.v1.post(`auth/login`, {
    email,
    password,
  });

export const loginAccount = (accountId: string) =>
  api.v1.post('auth/account', {
    accountId,
  });

export const loginTOTP = (payload:{ email?: string, userId?:string, code: string}) =>
  api.v1.post(`auth/totp`, payload);

export const loginGoogle = (token: string) =>
  api.v1.post(`auth/google`, {
    token,
  });

export const refreshToken = () =>
  api.v1.get(`auth/refresh`, {
    headers: {
      'Cache-Control': 'no-cache',
    },
  });

export const verifyEmail = (email: string, code: string) => loginTOTP({email, code});

export const setPassword = (newPassword: string, currentPassword?:string) =>
  api.v1.patch(`users/set-password`, {
    newPassword,
    ...(currentPassword && { currentPassword })
  });

//NOTIFICATION
export const sendEmailConfirmation = (email: string) =>
  api.v1.post('users/send-email-confirmation', { email });
export const sendEmailResetPassword = (email: string) =>
  api.v1.post('users/send-email-reset-password', { email });

// PLAN API
export const getPlans = (rmd?: RequestMetaData, extraData?: Record<string, unknown>) =>
  api.v1.get(`plans?${makeQS(rmd, extraData)}`);

export const addPlan = (data: Record<string, unknown>) => api.v1.post('plans', data);

export const updatePlan = (id: string, data: Partial<Tenant>) => api.v1.patch(`plans/${id}`, data);

// INVITATION API

export function getInvitations(rmd?: RequestMetaData) {
  return api.v1.get(`/invitations?${makeQS(rmd)}`);
}

export function inviteUsers(invitations: any) {
  return api.v1.post(`/invitations`, invitations);
}

export function updateInvitation(id: string, invitation: Pick<Invitation, 'status'>) {
  return api.v1.patch(`/invitations/${id}`, invitation);
}

export function acceptInvitation(id: string) {
  return api.v1.post(`/invitations/acept/${id}`);
}

// TENANT API

export const onBoardTenant = (data: Record<string, unknown>) => api.v1.post(`onboard/tenant`, data);

export const getTenants = (rmd?: RequestMetaData, extraData?: Record<string, unknown>) =>
  api.v1.get(`tenants?${makeQS(rmd, extraData)}`);

export const addTenant = (data: Record<string, unknown>) => api.v1.post('tenants', data);

export const getMeTenant = () => api.v1.get(`tenants/me`);

export const getTenant = (id: string) => api.v1.get(`tenants/${id}`);

export const updateTenant = (id: string, data: Partial<Tenant>) =>
  api.v1.patch(`tenants/${id}`, data);

export const uploadTenantLogo = (
  tenantId: string,
  file: File,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) => {
  const formData = new FormData();
  formData.append('file', file);
  return api.v1.post(`tenants/${tenantId}/logo`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
    onUploadProgress,
  });
};

export const getUsersByTenant = (rmd?: RequestMetaData) =>
  api.v1.get(`tenants/users?${makeQS(rmd)}`);

// STORE

export const getStores = (rmd?: RequestMetaData) => api.v1.get(`stores?${makeQS(rmd)}`);

export const addStore = (data: Record<string, unknown>) => api.v1.post('stores', data);

export const getStore = (id: string) => api.v1.get(`stores/${id}`);

export const updateStore = (id: string, data: Partial<Tenant>) =>
  api.v1.patch(`stores/${id}`, data);

export const uploadStoreLogo = (
  tenantId: string,
  file: File,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) => {
  const formData = new FormData();
  formData.append('file', file);
  return api.v1.post(`stores/${tenantId}/logo`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
    onUploadProgress,
  });
};

export const getUsersByStore = (rmd?: RequestMetaData) => api.v1.get(`stores/users?${makeQS(rmd)}`);

//ACCOUNT API

export const getAccounts = (rmd?: RequestMetaData, extraData?: Record<string, unknown>) =>
  api.v1.get(`accounts?${makeQS(rmd, extraData)}`);

export const updateAccount = (id: number, data: Partial<Account>) =>
  api.v1.patch(`accounts/${id}`, data);

export const searchAccounts = (query: string) => api.v1.get(`accounts/search?q=${query}`);

export const getNonOwnedAccounts = (rmd: RequestMetaData) =>
  api.v1.get(`accounts/search-non-owned?${makeQS(rmd)}`);

export const addOwnershipForAccounts = (userId: string, accountIds: string[]) =>
  api.v1.post(`accounts/add-ownership`, { userId, accountIds });

export const searchAccountLocations = (accountId: number, params?: { name?: string }) =>
  api.v1.get(`accounts/${accountId}/locations`, { params });

export const getAccount = (id: number) => api.v1.get(`accounts/${id}`);

export function getAccountUsers(metaData?: RequestMetaData, controller?: AbortController) {
  return api.v1.get(`/accounts/users?${makeQS(metaData)}`, {
    signal: controller?.signal,
  });
}

/** Gets an account by code */
export function getAccountByCode(code: string): Promise<AxiosResponse<ApiResponse<Account>>> {
  return api.v1.get(`accounts/by-code/${code}`);
}

/** Post request for new account code */
export const generateNewAccountCode = (accountId: number) =>
  api.v1.post(`accounts/${accountId}/regenerate-code`);

// USERS API

export const getUserAccounts = (rmd?: RequestMetaData, extraData?: Record<string, unknown>) =>
  api.v1.get(`users/accounts?${makeQS(rmd, extraData)}`);

export function getUsers(
  metaData?: RequestMetaData,
  controller?: AbortController,
): Promise<AxiosResponse<PagedApiResponse<User>>> {
  return api.v1.get(`users?${makeQS(metaData)}`, {
    signal: controller?.signal,
  });
}

export const getUser = (id: string) => api.v1.get(`users/${id}`);

/* Get locations by proximity, which is defined by zip code as center and radius */
export const getLocationsByProximity = (zip: string, radius: string) => {
  const extra = { zip, radius };
  return api.v1.get(`locations/proximity?${makeQS({}, extra)}`);
};

/* Get list of users that this user has access to */

/* Get list of users for tips distribution that this user has access to */
export function getDistributeTipsUsers(
  rmd?: RequestMetaData,
  extraData?: Record<string, unknown>,
): Promise<AxiosResponse<PagedApiResponse<User>>> {
  return api.v1.get(`/users/distribute-tips?${makeQS(rmd, extraData)}`);
}

/**
 * Get list of users who are of type 'owners'
 */
export function getOwners(
  metaData?: RequestMetaData,
  search?: string,
  accountId?: number,
  locationId?: number,
): Promise<AxiosResponse<PagedApiResponse<User>>> {
  const url = `users/owners`;
  const extraData = search ? { search, accountId, locationId } : { accountId, locationId };
  return api.v1.get(`${url}?${makeQS(metaData, extraData)}`);
}

/**
 * Get list of users who are of type 'managers'
 */
export function getManagers(
  metaData?: RequestMetaData,
  search?: string,
  accountId?: number,
  locationId?: number,
): Promise<AxiosResponse<PagedApiResponse<User>>> {
  const url = `users/managers`;
  const extraData = search ? { search, accountId, locationId } : { accountId, locationId };
  return api.v1.get(`${url}?${makeQS(metaData, extraData)}`);
}

/**
 * Get list of users who are of type 'talent'
 */
export function getTalent(
  metaData?: RequestMetaData,
  search?: string,
  accountId?: number,
  locationId?: number,
): Promise<AxiosResponse<PagedApiResponse<User>>> {
  const url = `users`;
  const queryParams = {
    type: 'talent',
    accountId,
    locationId,
  };
  const extraData = search ? { search, ...queryParams } : queryParams;
  return api.v1.get(`${url}?${makeQS(metaData, extraData)}`);
}

/** Get employee info for user on a specific account */
export const getEmployeeInfo = (userId: string, accountId: number) => {
  const extraData = { accountId };
  return api.v1.get(`users/${userId}/employee-info?${makeQS({}, extraData)}`);
};

/**
 * Initially employee info is not set (no row), so when admin or owner first sets employeeId,
 * status is also set to 'active'. all consecutive updates are made via patch request
 */
export const createEmployeeInfo = (accountId: number, userId: string, employeeId: string) => {
  const postData = { userId, employeeId };
  return api.v1.post(`accounts/${accountId}/employee`, postData);
};

/** Update users employee info (employeeId, status) */
export const updateEmployeeInfo = (
  accountId: number,
  userId: string,
  employeeInfo: Record<string, unknown>,
) => {
  const patchData = { userId, ...employeeInfo };
  return api.v1.patch(`accounts/${accountId}/employee`, patchData);
};

/** Get all locations that this user is associated with */
export const getAssociatedLocations = (userId: string) =>
  api.v1.get(`users/${userId}/associated-locations`);

/** Gets all the users associated with specific account */
export const getUsersForAccount = (
  accountId: number,
  metaData?: RequestMetaData,
  search?: string,
  type?: string,
) => {
  const url = `accounts/${accountId}/users`;
  const extraData = search ? { search, type } : { type };
  return api.v1.get(`${url}?${makeQS(metaData, extraData)}`);
};

/** Gets all the locations for an account */
export const getAccountLocations = (accountId: number) =>
  api.v1.get(`accounts/${accountId}/locations?take=500`);

export const getAllLocationsByAccount = (accountId: number, rmd?: RequestMetaData) =>
  api.v1.get(`accounts/${accountId}/locations?${makeQS(rmd)}`);

/** Gets all the locations (admin scope only) */
export const getAllLocations = (rmd?: RequestMetaData) => {
  return api.v1.get(`locations?${makeQS(rmd)}`);
};

/** Get all locations owned by a global user */
export const getGloballyOwnedLocations = (rmd?: RequestMetaData) => {
  return api.v1.get(`globally-owned/locations?${makeQS(rmd)}`);
};

/** Searches through all the locations (admin scope only) */
export const searchAllLocations = (
  rmd?: RequestMetaData,
  extraData?: Record<string, unknown>,
  controller?: AbortController,
) => {
  return api.v1.get(`locations?${makeQS(rmd, extraData)}`, {
    signal: controller?.signal,
  });
};

/** Searches through all the unconverted locations (admin scope only) */
export const searchUnconvertedLocations = (extraData?: Record<string, unknown>) => {
  return api.v1.get(`locations/unconverted?${makeQS({}, extraData)}`);
};

/** Gets all the owners for an account */
export const getAccountOwners = (accountId: number) => api.v1.get(`accounts/${accountId}/owners`);

/** Get user info */
export const me = () => api.v1.get('users/me');

/** Creates a new user */
export const createUser = (
  email: string,
  password: string,
  extraData?: {
    accountSignup?: boolean;
    registrationToken?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
  },
) =>
  api.v1.post(`users/register`, {
    email,
    password,
    ...extraData,
  });

/** Update user */
export const updateUser = (userId: string, user: Partial<User>) =>
  api.v1.patch(`users/${userId}`, user);

/** Update email */
export const updateEmail = (email: string, password: string) =>
  api.v1.post(`users/email`, { email, password });

export const uploadProfilePicture = (
  userId: string,
  file: File,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) => {
  const formData = new FormData();
  formData.append('file', file);
  return api.v1.post(`users/${userId}/image`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
    onUploadProgress,
  });
};

/** Upload users profile picture  */
export const deleteProfilePicture = (userId: string) => {
  return api.v1.delete(`users/${userId}/image`);
};

export const uploadAccountLogo = (
  accountId: string,
  file: File,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) => {
  const formData = new FormData();
  formData.append('file', file);
  return api.v1.post(`accounts/${accountId}/logo`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
    onUploadProgress,
  });
};

/** Send email containing invite link to a location manager */
export const sendManagerInvite = async (locationId: number, email: string) => {
  return api.v1.post(`locations/${locationId}/managers/invite`, { email });
};

/** Remove new email that is still pending for confirmation */
export const cancelEmailChange = () => api.v1.post(`users/email/cancel-change`);

/** Gets the user with the provided id */

/** Gets the devices for a user */
export const getUserDevices = (id: number) => api.v1.get(`users/${id}/devices`);

export const getUserLocationsV1 = (userId: string) => api.v1.get(`users/${userId}/locations`);

/** Get the locations for a user */
export const getUserLocationsV2 = (id: number) => api.v1.get(`users/${id}/locations`);

/** Get all cards for a user */
export const getUserCards = (userId: string) => api.v1.get(`users/${userId}/cards`);

/** Create a new */
export const createCard = (accountNumber: string) => api.v1.post(`/cards`, { accountNumber });

/** Link an existing card */
export const linkCard = (pan: string, agreedToTermsOn: Date) =>
  api.v1.post(`/cards`, { pan, agreedToTerms: true, agreedToTermsOn });

/** Delete a card */
export const deleteCard = (cardId: number) => api.v1.delete(`/cards/${cardId}`);

/** Sets a card as a primary payout destination  */
export function makeCardPrimary(cardId: number) {
  return api.v1.post(`/cards/${cardId}/make-primary`);
}

/** enroll */
export const enroll = (ssn: string, cardId: number, agreedToTermsOn: Date) =>
  api.v1.post(`/cards/${cardId}/enroll`, { ssn, agreedToTermsOn, agreedToTerms: true });

/** Add owner for account */
// RENAME
export const addAccountOwner = (accountId: number, userId: string) =>
  api.v1.post(`accounts/${accountId}/owners`, {
    userId,
  });

/** Removes owner for account */
// RENAME
export const removeAccountOwner = (accountId: number, userId: string) =>
  api.v1.delete(`accounts/${accountId}/owners/${userId}`);

/** Get the location */
export const getLocation = (id: number, rmd?: RequestMetaData) =>
  api.v1.get(`locations/${id}?${makeQS(rmd)}`);

/** Get locations for google map */
export const getLocationsMap = () => api.v1.get(`locations/map`);

/** Update the location */
export const updateLocation = (id: number, data: Partial<Location>) =>
  api.v1.patch(`locations/${id}`, data);

export const getLocationDormant = (id: number) => {
  return api.v1.get(`locations/${id}/dormant`);
};

export const setLocationDormant = (id: number, isDormant: boolean) => {
  return api.v1.patch(`locations/${id}/dormant`, { isDormant });
};

/** Get all users for a location */
export const getAllLocationUsers = (id: number) => api.v1.get(`locations/${id}/users`);

/** Get all devices for a location */
export const getLocationDevices = (id: number) => api.v1.get(`locations/${id}/kiosks`);

/** Get all devices for an account */
export function getAccountDevices(id: number | string, rmd?: RequestMetaData) {
  return api.v1.get(`accounts/${id}/kiosks?${makeQS(rmd)}`);
}

/** Adds the current user to the location */
export function addMeToLocation(
  locationId: number,
  accountCode: string,
  userId: string,
): Promise<AxiosResponse<ApiResponse<unknown>>> {
  return api.v1.post(`locations/${locationId}/user`, {
    code: accountCode,
    userId,
  });
}

/** Adds the user with the provided id to a location */
export function addUserToLocation(userId: string, locationId: number) {
  return api.v1.post(`/locations/${locationId}/user`, {
    userId,
  });
}

export function createBankEvent(userId: string, name: string, metadata: any) {
  return api.v1.post(`/banks/event`, {
    userId,
    name,
    metadata,
  });
}

/** Gets the banks for a user */
export const getUserBanks = async (userId: string) => {
  return api.v1.get(`users/${userId}/banks`);
};

export const getAccountBanks = async (accountId: number) => {
  return api.v1.get(`/accounts/${accountId}/bank-accounts`);
};

export const getUserBranchData = async (userId: string) => {
  return api.v1.get(`/users/${userId}/branch`);
};

export const updateAccountBankLocations = async (
  accountId: number,
  bankAccountId: number,
  locationIds: number[],
) => {
  const data = { bankAccountId, locationIds };
  return api.v1.patch(`/accounts/${accountId}/bank-accounts`, data);
};

export const setAccountEntityName = async (accountId: number, name: string) => {
  return api.v1.post(`/accounts/${accountId}/entity-name`, { name });
};

export const getBankAccountAuthorization = async (accountId: number) => {
  return api.v1.get(`/accounts/${accountId}/bank-accounts/on-demand-authorization`);
};

/** Gets a bank with this specific */
export function getBankAccount(bankId: number) {
  return api.v1.get(`/banks/${bankId}`);
}

/** Activates a bank account after verification */
export function activateBankAccount(bankId: number) {
  return api.v1.post(`/banks/${bankId}/activate`);
}

/** Activates a bank account after verification */
export function activateBankAccountV2(bankId: number) {
  return api.v1.post(`/banks/${bankId}/activate`);
}

/** Send email containing url link for linking account to Dwolla by bypassing Plaid */
export const sendLinkBankEmail = async (userId: string) => {
  return api.v1.post(`users/${userId}/bank-entry-link`);
};

/** Get Dwolla founding source token for linking Bank account directly with Dwolla  */
export const getDwollaToken = async (userId: string) => {
  return api.v1.post(`users/${userId}/funding-source-token`);
};

export function getUserByEmail(email: string) {
  return api.v1.get(`/users/by-email/${email}`);
}

/** Creates a new account */
export function createAccount(account: Partial<Account>) {
  return api.v1.post(`/accounts/`, account);
}

/** Get web payment processor */
export const getWebSystemSettings = () => {
  return api.v1.get(`system-settings/web`);
};

/** Update account or location settings */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateSettings = (
  entity: 'account' | 'location' | 'station',
  id: number,
  settings: any,
) => api.v1.patch(`${entity}s/${id}/settings`, settings);

export function createLocation(location: Partial<Location> | Partial<Location>[]) {
  return api.v1.post(`/locations`, location);
}

export function deleteLocation(locationId: number) {
  return api.v1.delete(`/locations/${locationId}`);
}

export function getDashboardStats() {
  return api.v1.get(`/dashboard-stats`);
}

export function getBankEvents(userId: string) {
  return api.v1.get(`/users/${userId}/bank-events`);
}

export function getAccountBankEvents(businessAccountId: number) {
  return api.v1.get(`/accounts/${businessAccountId}/bank-events`);
}

export function makeBankAccountPrimary(bankAccountId: number) {
  return api.v1.post(`/banks/${bankAccountId}/make-primary`);
}

export function reconnectBankAccount(bankAccountId: number) {
  return api.v1.post(`/banks/${bankAccountId}/validate`);
}

export function removeBank(bankAccountId: number) {
  return api.v1.delete(`/banks/${bankAccountId}`);
}

export function linkKiosk(deviceId: number, data: { tabletId?: number; readerId?: number }) {
  return api.v1.post(`/kiosks/${deviceId}/link`, data);
}

export function getKiosks(rmd: RequestMetaData) {
  return api.v1.get(`/kiosks?${makeQS(rmd)}`);
}

export function getAccountNotes(accountId: number) {
  return api.v1.get(`/accounts/${accountId}/notes`);
}

export function getNoteDetails(noteId: number) {
  return api.v1.get(`/notes/${noteId}`);
}

export function createNote(accountId: number, data: { topic: string; note: string }) {
  return api.v1.post(`/accounts/${accountId}/note`, data);
}

export function updateNote(noteId: number, data: { topic: string; note: string }) {
  return api.v1.patch(`/notes/${noteId}`, data);
}

export function createNoteReminder(noteId: number, dateTime: string) {
  return api.v1.post(`/notes/${noteId}/reminder`, { dateTime });
}

export function deleteNoteReminder(reminderId: number) {
  return api.v1.delete(`/reminders/${reminderId}`);
}

export function getKioskAccount(id: string) {
  return api.v1.get(`/web/accounts/${id}`);
}

export function getKioskLocation(id: string) {
  return api.v1.get(`/web/locations/${id}`);
}

export function getKioskUser(id: string) {
  return api.v1.get(`/web/users/${id}`);
}

export function getQrKioskUser(id: string) {
  return api.v1.get(`/web/qr/users/${id}`);
}

/**
 * Fetches the payout methods for an account.
 */
export function getAccountPayoutMethods(accountId: number) {
  return api.v1.get(`/accounts/${accountId}/programs`);
}

export function getAccountIncorporationData(accountId: number) {
  return api.v1.get(`/accounts/${accountId}/incorporation`);
}

/** Identity verification */

export function getIdentityVerification(userId: string, code: string) {
  return api.v1.get(`/users/${userId}/identity/${code}`);
}

export function sendIdentityVerification(userId: string, destination?: 'phone' | 'email') {
  return api.v1.post(`/users/${userId}/identity`, { destination });
}

export function mergeUsers({ sourceUserId, destUserId, deleteSource }: IUserMerge) {
  return api.v1.post(`/users/merge`, {
    source: sourceUserId,
    destination: destUserId,
    deleteSource,
  });
}

export function getAlerts(rmd: RequestMetaData) {
  return api.v1.get(`/alerts?${makeQS(rmd)}`);
}

export function getActiveAlerts(rmd?: RequestMetaData) {
  return api.v1.get(`/alerts/active?${makeQS(rmd)}`);
}

export function createAlert(a: NewAlertRequest) {
  return api.v1.post(`/alerts`, a);
}

export function updateAlert(alertId: number, a: Partial<NewAlertRequest>) {
  return api.v1.patch(`/alerts/${alertId}`, a);
}

export function getAccountTalent(accountId: number, rmd?: RequestMetaData) {
  const suffix = rmd ? `?${makeQS(rmd)}` : ``;
  return api.v1.get(`/accounts/${accountId}/talents${suffix}`);
}

export const searchLocations = (query: string) => api.v1.get(`locations/search?q=${query}`);

export function getLocationTalent(locationId: number, rmd?: RequestMetaData) {
  const suffix = rmd ? `?${makeQS(rmd)}` : ``;
  return api.v1.get(`/locations/${locationId}/talents${suffix}`);
}

export function createPosSystem(name: string) {
  return api.v1.post(`/pos`, { name });
}

export function getPosSystems() {
  return api.v1.get(`/pos/list`);
}

export function createAccountPos(accountId: number, posId: number) {
  return api.v1.post(`/accounts/${accountId}/pos`, { posId });
}

export function getLinkToken() {
  return api.v1.get(`/banks/link-token`);
}

export const getPoolsList = (extraData?: Record<string, unknown>) => {
  return api.v1.get(`pools?${makeQS({}, extraData)}`);
};

export const deactivatePool = (poolId: number) => {
  return api.v1.delete(`pools/${poolId}`);
};

export const activatePool = (poolId: number) => {
  return api.v1.post(`pools/${poolId}/activate`);
};

export const getGloballyOwnedUsers = (
  rmd?: RequestMetaData,
  extraData?: Record<string, unknown>,
): Promise<AxiosResponse<PagedApiResponse<User>>> => {
  return api.v1.get(`/globally-owned/users?${makeQS(rmd, extraData)}`);
};

export const getHasVerifiedBusinessBankAccount = (
  accountId: number,
): Promise<AxiosResponse<ApiResponse<{ hasVerifiedBusinessBankAccount: boolean }>>> => {
  return api.v1.get(`accounts/${accountId}/non-verified-banks`);
};

export const updateShippingAddress = (locationId: number, id: number, data: Partial<Location>) =>
  api.v1.patch(`/locations/${locationId}/shipping-addresses/${id}`, { ...data, locationId });

export const deleteStation = (id: string): Promise<void> => {
  return api.v1.delete(`stations/${id}`);
};

/** Get Owned Accounts */
export const getUserOwnedAccounts = (userId: string) => api.v1.get(`users/${userId}/accounts`);
