import { Theme, createStyles, makeStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    noDataText:{
      color: theme.palette.text.secondary
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {},
  addLicensesDialog: {
    width: 800,
  },
  noDataText:{
    color: theme.palette.text.secondary
  },
  textFirst:{
    fontSize: '16px',
    fontWeight: 400,
    lineHeight:'20,29',
  },
  textSecondary:{
    color: theme.palette.grey[600],
    fontSize: '14px',
    fontWeight: 400,
    lineHeight:'17,75',
  },
  textTertiary:{
    color: theme.palette.grey[400],
    fontSize: '14px',
    fontWeight: 400,
    lineHeight:'17,75',
    fontStyle:'italic'
  }
}),
);
