import React from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import GC from './GraphicContainer';
import { TStep } from './TenantOnBoard';
import { RouteComponentProps } from 'react-router-dom';
import { WithToastStore, inject } from 'types/stores';
import styles from './styles';
import { observer } from 'mobx-react';
import { Text } from 'components/Typotext';
import SpanAnimated from 'components/AnimatedHeadlines';
import PlanPanel from 'components/PlanPanel';

interface PlanProps extends WithStyles<typeof styles>, RouteComponentProps, WithToastStore {
  nextRoute: () => string;
  onSubmit: (type: TStep, payload: unknown) => void;
}

const Plan = ({ onSubmit, nextRoute, classes, ...props }: PlanProps) => {
  
  const handleSubmit = (v: any) => {
    onSubmit('plan', v)
    props.history.push(nextRoute());
  }

  return (
    <Paper elevation={0}>
      <Box minHeight={590} pt={7} pb={6.25} pr={4} pl={8.125}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <GC>
              <GC.Title>
                {` `}

                <Box display={'flex'}>
                  <Text className={classes.textSecondary} variant="h5">
                    Start using our
                  </Text>
                  <Text className={classes.textPrimary} marginLeft="0.7rem" variant="h5">
                    <SpanAnimated list={['products for free!', 'all-in-one toolkit']} />
                  </Text>
                </Box>
                <GC.Subtitle>
                  Switch to a commercial plan to access advanced features & technical support.
                </GC.Subtitle>
              </GC.Title>
              <Grid item xs={12}>
              <PlanPanel onChange={handleSubmit} />
              </Grid>
            </GC>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default withStyles(styles)(inject('toastStore')(observer(Plan)));
