/**
 * This file contains various validation functions used in the dashboard
 */

/** The US phone regex */
export const usPhoneRegex =
  /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/;

/** The US zip code regex */
const zipRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;

/** The US currency regex */
const usCurrencyRegex = /^[+-]?[0-9]{1,9}(?:\.[0-9]{1,2})?$/;

/** Tests whether a given string is a valid US phone number */
export const isPhone = (s: string) => new RegExp(usPhoneRegex).test(s);

/** Tests whether a given string is a valid US zip code */
export const isZip = (s: string) => new RegExp(zipRegex).test(s);

/** Tests whether a given string is a valid US currency format */
export const isCurrency = (s: string) => new RegExp(usCurrencyRegex).test(s);
