import React from 'react';
import { computed, observable, action, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { Grid } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { User } from 'models';
import { inject, WithUserStore, WithToastStore } from 'types/stores';
import Api, { getErrorMsg, ApiResponse } from 'api';

import UserDetailsPanel from 'components/UserDetailsPanel';
import AddressPanel from 'components/AddressPanel';

import styles from './styles';
import { AxiosResponse } from 'axios';
import { Dialog } from '@mui/material';
import SearchAndSelectModal from './SearchAndSelectModal/SearchAndSelectModal';
import PlusFabButton from 'components/PlusFabButton/PlusFabButton';
import { Masonry } from '@mui/lab';

enum DebitCardStatus {
  CREATING = 'creating',
  REJECTED = 'rejected',
  APPROVED = 'approved',
  CONDITIONAL = 'conditional',
  UPGRADED = 'upgraded',
}

interface Card {
  id: number;
  accountBrand: string;
  cardBrand: string;
  processor: string;
  status: DebitCardStatus;
  balance: string;
}

interface InfoProps extends WithStyles<typeof styles>, WithUserStore, WithToastStore {
  userId: string;
  user?: User;
  handleUserChanged: (u: User) => void;
  canViewWallet?: boolean;
}

/** The content of the info tab of the user details page */
@inject('userStore', 'toastStore')
@observer
class Info extends React.Component<InfoProps> {
  constructor(props: InfoProps) {
    super(props);
    makeObservable(this);
  }

  @observable public showSearchAndSelectModal = false;

  @computed public get isAdmin(): boolean {
    return this.props.userStore!.isAdmin;
  }

  @action.bound private openShowAndSelectModal() {
    this.showSearchAndSelectModal = true;
  }

  @action.bound private closeShowAndSelectModal() {
    this.showSearchAndSelectModal = false;
  }

  render() {
    const { user, handleUserChanged, userId } = this.props;
    return (
      <>
        <Grid container direction={'row'} spacing={3}>
          {user && (
            <Masonry columns={2} spacing={3} style={{ margin: 0 }}>
              <UserDetailsPanel editable={false} handleUserChanged={handleUserChanged}>
                {user}
              </UserDetailsPanel>
              <AddressPanel addressEditable={false}>{user!.address}</AddressPanel>

              <Dialog
                PaperProps={{ style: { borderRadius: 16 } }}
                className={this.props.classes.dialog}
                open={this.showSearchAndSelectModal}>
                <SearchAndSelectModal
                  userId={userId}
                  close={this.closeShowAndSelectModal}
                  toastStore={this.props.toastStore!}
                />
              </Dialog>
            </Masonry>
          )}
        </Grid>
        {this.isAdmin && <PlusFabButton onClick={this.openShowAndSelectModal} />}
      </>
    );
  }
}

export default withStyles(styles)(Info);
