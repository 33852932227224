import { Store } from "./Store";
import { Tenant } from "./Tenant";

export type TAccountScope = 'owner' | 'manager' | 'employee';
export enum Scope {
  OWNER = 'owner',
  MANAGER = 'manager',
  EMPLOYEE = 'employee',
}

export interface Account {
  id: string;
  userId: string;
  scope: Scope;
  store?: Store;
  tenant: Tenant
  isActive: boolean;
}

export interface UserAccount {
    id:    string;
    accountId: string;
    firstName: string;
    lastName:  string;
    email:     string;
    scope:     string;
    isOwner:   boolean;
}