import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        justifyContent: 'end',
      },
    },
    icon: {
      marginRight: theme.spacing(2),
    },
    root: {
      color: theme.palette.grey[500],
      height: '53px',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    searchWrapper: {
      display: 'flex',
      flexBasis: '100%',
    },
    textSearch: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      paddingRight: theme.spacing(2),
    },
    phoneSearch: {
      display: 'flex',
      alignItems: 'center',
      // flex: '0 0 33.333%',
    },
    menu: {
      '&:hover': {
        background: 'none',
      },
      padding: 0,
    },
    menuIcon: {
      color: theme.palette.text.primary,
      width: '20px',
      height: '20px',
    },
    paperMenu: {
      width: '53px',
      height: '53px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(1),
    },
    divider: {
      '&.MuiDivider-root': {
        border: `1px solid ${theme.palette.divider}`,
        height: '37px',
        color: theme.palette.divider,
        borderRadius: 1,
        backgroundColor: 'transparent',
      },
    },
    magnifyIcon: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      marginRight: theme.spacing(2),
      color: theme.palette.divider,
    },
    clearIcon: {
      width: '15px',
      height: '15px',
      marginRight: theme.spacing(1),
      color: theme.palette.divider,
    },
    noHover: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    textColor: {
      '&.MuiInputBase-input': {
        color: theme.palette.text.secondary,
      },
    },
  });
