import { makeStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default makeStyles((theme) => ({
  root:{},
  count: {
    color: theme.palette.grey[500],
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  titleDark: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: '28px',
    lineHeight:'35.5px',
    letterSpacing:'0.25px'
  },
  titleLight: {
    color: theme.palette.common.black,
    fontWeight: 400,
    fontSize: '28px',
    lineHeight:'35.5px',
    letterSpacing:'0.25px'
    // textTransform: 'capitalize',
  },
  titleDrawer: {
    color: theme.palette.common.black,
    fontWeight: 400,
    fontSize: '28px',
    lineHeight:'35.5px',
    textTransform: 'capitalize',
  },
  skeleton: {
    margin: 0,
  },
}));
