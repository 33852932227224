import { useStores } from 'containers/App/App';
import { IUserProfileProps } from './UserProfile';

const useUserProfile = ({ onClick }: IUserProfileProps) => {
  const { uiStore, userStore } = useStores();
  const isMenuOpen = uiStore?.drawer.open;
  const fullName = userStore?.fullName || 'N/A'; //TODO: use valurOrNa function
  const email = userStore?.user?.email || 'N/A';

  const userDetailsProps = {
    email,
    fullName,
    menuDrawerOpen: isMenuOpen,
  };

  const avatarProps = userStore?.loggedIn ? { src: userStore?.authUser?.avatar } : {};

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onClick && onClick(event);
  };

  return {
    userDetailsProps,
    avatarProps,
    handleOnClick,
  };
};

export default useUserProfile;
