import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    dialogActions: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(5.25),
    },
    dialogContent: {
      paddingTop: theme.spacing(2),
    },
    label: {
      color: theme.palette.text.primary,
    },
    resendCode: {
      position: 'relative',
      color: theme.palette.primary.main,
      '&:hover': {
        cursor: 'pointer',
      },
      '&::after': {
        content: "''",
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: theme.palette.primary.main,
        height: 1,
      },
    },
  });
