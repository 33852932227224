import { JWTStore } from "types/stores";

interface ILocalStorage {
  key: string;
  value: string | null;
}

export enum LocalStorageItem {
  FINGERPRINT = 'fingerprint'
}

/**
 * Gets the JWT token from local storage.
 */
export function getJwt(): string | undefined {
  return localStorage.getItem('jwt') || undefined;
}

/**
 * Sets the JWT token, writing it to local storage.
 * @param jwt The JWT token to save
 */
export function setJwt(jwt: string | undefined) {
  if (jwt) {
    localStorage.setItem('jwt', jwt);
  } else {
    localStorage.removeItem('jwt');
  }
}

/**
 * Sets the JWT token, writing it to local storage.
 * @param refreshToken The JWT token to save
 */
export function setRefreshToken(refreshToken: string | undefined) {
  if (refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
  } else {
    localStorage.removeItem('refreshToken');
  }
}


export function setJWTStore(store: JWTStore|undefined) {
  if (store) {
    localStorage.setItem('jwtStore', JSON.stringify(store));
  } else {
    localStorage.removeItem('jwtStore');
  }
}

export function getJWTStore(): JWTStore {
  const store = localStorage.getItem('jwtStore');
  if(store) return JSON.parse(store) as JWTStore
  return [];
}

export function getExp(): number | undefined {
  const exp = parseInt(localStorage.getItem('exp') || '');
  return isNaN(exp) ? undefined : exp;
}

export function setExp(exp: number | undefined) {
  if (exp) {
    localStorage.setItem('exp', `${exp}`);
  } else {
    localStorage.removeItem('exp');
  }
}

export function setUser(user: object | undefined) {
  if (user) {
    localStorage.setItem('user', JSON.stringify(user));
  } else {
    localStorage.removeItem('user');
  }
}

export function getUser(): object | undefined {
  const user = localStorage.getItem('user');
  return user !== null && JSON.parse(user);
}

export function get(key: string): object | undefined {
  const value = localStorage.getItem(key);
  if (value !== null) {
    return JSON.parse(value);
  }
}

export function set<T>(key: string, value: T | undefined) {
  if (value !== undefined) {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.removeItem(key);
  }
}

export function remove(key: string) {
  localStorage.removeItem(key);
}

export function clear() {
  localStorage.clear();
}

/**
 * Searches the localstorage for properties which include given search string
 * */
export function getProperties(search: string) {
  const keys = Object.keys(localStorage).filter((v) => v.includes(search));
  if (keys.length > 0) {
    return keys.map((key: string) => {
      return { key, value: localStorage.getItem(key) };
    });
  }
  return;
}

export function setProperties(properties: ILocalStorage[]) {
  properties.forEach(({ key, value }) => {
    if (value) {
      localStorage.setItem(key, value);
    }
  });
}
