/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { WithStyles, withStyles } from '@material-ui/styles';
import { ReactStripeElements } from 'react-stripe-elements';

import { useTheme } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles/colorManipulator';

import styles from './styles';
import OutlinedInput from 'components/Input/OutlinedInput';

interface StripeInputProps {
  component: React.ComponentType<any>;
  inputRef: (ref: HTMLInputElement | null) => void;
  id?: string;
  className?: string;
  disabled?: boolean;
  onBlur: (e: React.FocusEvent<HTMLFormElement>) => void;
  onFocus: (e: React.FocusEvent<HTMLFormElement>) => void;
  onChange: (e: ReactStripeElements.ElementChangeResponse) => void;
  placeholder?: string;
}

/**
 * The inner wrapper for a stripe input, so that we can pass this
 * as the component to an Input component.
 */
function StripeInput(props: StripeInputProps) {
  const {
    component: Component,
    inputRef,
    id,
    className,
    disabled,
    onBlur,
    onChange,
    onFocus,
    placeholder,
  } = props;
  const [mountNode, setMountNode] = React.useState<any>(null);
  const theme = useTheme();
  React.useImperativeHandle(
    inputRef,
    () =>
      ({
        focus: () => {
          if (mountNode !== null) {
            mountNode.focus();
          }
        },
      } as any),
    [mountNode],
  );
  return (
    <Component
      onReady={setMountNode}
      style={{
        base: {
          color: theme.palette.text.primary,
          fontSize: `${theme.typography.fontSize}px`,
          '::placeholder': {
            color: alpha(theme.palette.text.primary, 0.42),
          },
        },
        invalid: {
          color: theme.palette.text.primary,
        },
      }}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={placeholder}
      disabled={disabled}
      id={id}
      className={className}
    />
  );
}

interface StripeElementWrapperProps extends WithStyles<typeof styles> {
  component: React.ComponentType<any>;
  label: string;
}

interface StripeElementWrapperState {
  focused: boolean;
  empty: boolean;
  error?: ReactStripeElements.ElementChangeResponse['error'];
}

/**
 * A wrapper for a stripe input. Takes a component from
 * the react-stripe-elements library and renders it as
 * a nice material-ui input.
 */
class StripeElementWrapper extends React.Component<
  StripeElementWrapperProps,
  StripeElementWrapperState
> {
  state = {
    focused: false,
    empty: true,
    error: undefined,
  };

  handleBlur = () => {
    this.setState({ focused: false });
  };

  handleFocus = () => {
    this.setState({ focused: true });
  };

  handleChange = (changeObj: ReactStripeElements.ElementChangeResponse) => {
    this.setState({
      error: changeObj.error,
      empty: changeObj.empty,
    });
  };

  render() {
    const { component, label, classes } = this.props;
    const { error } = this.state;

    return (
      <div>
        <OutlinedInput
          fullWidth
          InputProps={{ inputComponent: StripeInput as any }}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          error={error && (error as any).message}
          onChange={this.handleChange as any}
          placeholder={label}
          inputProps={{ component }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(StripeElementWrapper);
