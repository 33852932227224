import React, { RefObject, createRef } from 'react';
import { observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';

import { FileDrop } from 'react-file-drop';
import Webcam from 'react-webcam';

import clsx from 'clsx';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Box,
  Button,
  Link,
  ButtonBase,
  Dialog,
  DialogContent,
  Grid,
  Icon,
  Menu,
  MenuItem,
  SvgIcon,
  Typography,
  DialogActions,
  DialogTitle,
  LinearProgress,
} from '@material-ui/core';
import { ProgressDownload, ProgressUpload, Laptop, Camera } from 'mdi-material-ui';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { default as DialogButton } from 'components/Button/Dialog/Button';
import { WithToastStore, inject } from 'types/stores';

import { resizeImage, dataURLtoFile } from '../ProfilePicturePanel/utils';

import styles from '../ProfilePicturePanel/styles';
import { AnyAaaaRecord } from 'dns';

export type PictureUploadLayoutType = 'horizontal' | 'vertical';

interface PictureUploadProps extends WithStyles<typeof styles>, WithToastStore {
  profilePictureUrl?: string;
  layout?: PictureUploadLayoutType;
  onUpload?: (uploadedImg: File) => void;
  onClose: () => void;
  open: boolean;
  loading: boolean;
  progress: number;
}
/**
 * This component displays users profile image and it can also be used to update
 * it. It supports three different ways of uploading an image; 1) drag'n'drop,
 * 2) from computer or 3) via webcam. It also has three different render states
 * based on upload process progress. Initial render shows instructions for drag
 * and drop and select menu options for other two upload methods. Dragging mouse
 * with file over the component will render it into active drop zone. Pending
 * state asks user to confirm her choice of image or reset the state.
 *
 * @param profilePictureUrl User's current profile picture url
 * @param layout Optional Used to control components layout, 'horizontal' by default
 * @param onUpload Optional callback with uploaded file as a parameter
 */
@inject('toastStore')
@observer
class PictureUpload extends React.Component<PictureUploadProps> {
  constructor(props: PictureUploadProps) {
    super(props);
    makeObservable(this);
  }
  static defaultProps: { layout: PictureUploadLayoutType } = {
    layout: 'horizontal',
  };
  /** File is set when user chooses a file for upload */
  @observable private file: null | File = null;

  /** Url reference to image pending for upload */
  @observable private imageUrl: undefined | string = this.props.profilePictureUrl;

  /** For rendering switch and upload progress bar value */
  @observable private uploading = false;
  @observable private uploadProgress = 0;

  /** Is webcam dialog open? */
  @observable private cameraDialog = false;

  /** Ref for Webcam component, so we can use its methods */
  @observable public webcamRef: RefObject<Webcam> = createRef();

  /** Temporary Webcam image, stored before user confirms the photo she took */
  @observable public tmpWebcamImage: null | HTMLImageElement = null;

  /** Is user dragging file over drop zone - render switch */
  @observable private dragging = false;

  /** Material-ui anchor for select upload method menu */
  @observable private menuAnchorEl: null | HTMLElement = null;

  /** Validate and and resize the image */
  @action.bound public prepareImage = (file: File) => {
    const reader = new FileReader();
    if (!file.type.match(/image.*/)) {
      this.dragging = false;
      this.props.toastStore!.push({
        type: 'error',
        message: `File is not in any supported image format`,
      });
      return;
    }
    reader.onloadend = async () => {
      this.dragging = false;
      const resizedImage = await resizeImage(file, 1000);
      const newUrl = window.URL.createObjectURL(resizedImage);
      this.file = resizedImage;
      this.imageUrl = newUrl;
    };
    reader.readAsDataURL(file);
  };

  @action.bound public handleMenuOpen(event: React.MouseEvent<HTMLButtonElement>) {
    this.menuAnchorEl = event.currentTarget;
  }

  @action.bound public handleMenuClose() {
    this.menuAnchorEl = null;
  }

  /** On select image from system's select file prompt window */
  @action.bound public handleImageChange = (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];
    this.prepareImage(file);
  };

  /** Render logic reacts to mouse drag */
  @action.bound public onDragOver = () => {
    this.dragging = true;
  };

  /** Render logic reacts to mouse drag */
  @action.bound public onDragLeave = () => {
    this.dragging = false;
  };

  /** Called when user drops a file inside the drop zone */
  @action.bound public handleDrop = (files: any) => {
    const file = files[0];
    this.prepareImage(file);
  };

  /** Called when user takes a photo of himself using Webcam component */
  @action.bound public onScreenshot = () => {
    if (this.webcamRef.current) {
      const cameraElement = this.webcamRef.current;
      const imageSrc = cameraElement.getScreenshot();
      this.tmpWebcamImage = new Image();
      if (imageSrc) {
        this.tmpWebcamImage.src = imageSrc;
      }
    }
  };

  /** Called when user confirms the photo he took of herself in the Webcam dialog */
  @action.bound public onChooseScreenshot = () => {
    this.cameraDialog = false;
    const file = this.tmpWebcamImage && dataURLtoFile(this.tmpWebcamImage.src);
    file && this.prepareImage(file);
    this.tmpWebcamImage = null;
  };

  @action.bound public openWebcamDialog = () => {
    this.menuAnchorEl = null;
    this.cameraDialog = true;
  };

  @action.bound public closeWebcamDialog = () => {
    this.cameraDialog = false;
  };

  /** If user cancels upload process, we set initial component state */
  @action.bound public reset = () => {
    this.file = null;
    this.tmpWebcamImage = null;
    this.imageUrl = this.props.profilePictureUrl;
  };

  /** Trigger upload action callback and set component to initial state */
  @action.bound public onUpload = () => {
    this.props.onUpload && this.file && this.props.onUpload(this.file);
    this.tmpWebcamImage = null;
    this.file = null;
  };

  @action.bound public onClose = () => {
    this.props.onClose();
    this.reset();
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Dialog
          onClose={this.onClose}
          aria-labelledby="customized-dialog-title"
          open={this.props.open}>
          <DialogTitle id="customized-dialog-title">
            <Typography className={classes.textLogo}>Logo</Typography>
          </DialogTitle>
          <DialogContent>
            <Box style={{ width: '528px' }}>
              <FileDrop
                onDrop={this.handleDrop}
                onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}>
                <Grid
                  container
                  className={clsx({
                    [classes.containerCenter]: this.imageUrl && !this.props.loading,
                  })}>
                  <Grid
                    item
                    className={clsx(classes.containerUploadAvatar, {
                      [classes.containerFullWidth]: this.props.loading || !this.imageUrl,
                    })}>
                    <Box
                      className={clsx(classes.dragbox, {
                        [classes.avatarHover]: this.dragging,
                      })}>
                      {this.props.loading ? (
                        <Box width="100%" marginBottom="1rem" marginTop="1rem">
                          <Typography
                            align="justify"
                            className={clsx(classes.instructionText, classes.typography)}>
                            {'Uploading...'}
                          </Typography>

                          <Box display="flex" alignItems="center" flexDirection="column">
                            <Box minWidth={35}>
                              <Typography
                                variant="body2"
                                color="textSecondary">{`${this.props.progress}%`}</Typography>
                            </Box>
                            <Box width="100%" mr={1}>
                              <LinearProgress variant="determinate" value={this.props.progress} />
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <>
                          {this.imageUrl ? (
                            <Box className={clsx(classes.avatar)}>
                              <Avatar
                                alt="Profile Picture"
                                src={this.imageUrl as string}
                                variant="rounded"
                                onClick={(e: any) => this.handleMenuOpen(e)}
                              />
                            </Box>
                          ) : (
                            <>
                              <AddPhotoAlternateOutlinedIcon fontSize="large" />
                              {!this.dragging && !this.file && (
                                <Typography
                                  align="justify"
                                  className={clsx(classes.instructionText, classes.typography)}
                                  style={{ marginTop: '10px' }}>
                                  <Link
                                    // component='button'
                                    color="primary"
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className={clsx(
                                      classes.button,
                                      classes.menuButton,
                                      classes.typography,
                                      classes.textUnderline,
                                    )}
                                    onClick={(e: any) => this.handleMenuOpen(e)}
                                    style={{ marginRight: '10px' }}>
                                    Click to upload
                                  </Link>
                                  {'photo or drag and drop it'}
                                  <br />
                                </Typography>
                              )}
                            </>
                          )}
                        </>
                      )}

                      <>
                        <form style={{ display: 'none' }}>
                          <input
                            type="file"
                            id="avatar"
                            value=""
                            onChange={this.handleImageChange}
                          />
                        </form>
                        <Menu
                          id="simple-menu"
                          anchorEl={this.menuAnchorEl}
                          keepMounted
                          open={Boolean(this.menuAnchorEl)}
                          onClose={this.handleMenuClose}>
                          <MenuItem className={classes.menuItem} onClick={this.handleMenuClose}>
                            <Laptop />
                            <label htmlFor="avatar">Computer</label>
                          </MenuItem>
                          <MenuItem className={classes.menuItem} onClick={this.openWebcamDialog}>
                            <Camera />
                            Camera
                          </MenuItem>
                        </Menu>
                      </>
                    </Box>
                  </Grid>
                </Grid>
              </FileDrop>
            </Box>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Box className={classes.containerAction}>
              <DialogButton
                autoFocus
                onClick={this.onClose}
                variant="outlined"
                className={classes.buttonAction}
                color="primary">
                <Typography className={classes.buttonText} color="primary">
                  Cancel
                </Typography>
              </DialogButton>
              {this.props.profilePictureUrl && !this.file && !this.props.loading ? (
                <DialogButton
                  variant="contained"
                  className={classes.buttonAction}
                  color="primary"
                  onClick={(e: any) => this.handleMenuOpen(e)}>
                  Change logo
                </DialogButton>
              ) : (
                <DialogButton
                  variant="contained"
                  disabled={!this.file}
                  className={classes.buttonAction}
                  color="primary"
                  onClick={this.onUpload}>
                  Save
                </DialogButton>
              )}
            </Box>
          </DialogActions>
        </Dialog>
        {/* --------------- Webcam dialog : -------------- */}
        <Dialog
          classes={{ paper: classes.dialog }}
          maxWidth={false}
          open={this.cameraDialog}
          onClose={this.closeWebcamDialog}>
          {this.cameraDialog && (
            <>
              <DialogContent>
                <Box width="640px" height="480px">
                  {this.tmpWebcamImage ? (
                    <img alt="Profile" src={this.tmpWebcamImage.src as string} />
                  ) : (
                    <Webcam ref={this.webcamRef} audio={false} />
                  )}
                </Box>
                <Box
                  height="104px"
                  className={classes.actionContainer}
                  display="flex"
                  flexDirection="row">
                  <Grid container>
                    {!this.tmpWebcamImage && (
                      <>
                        <Grid item md={4}>
                          <Button
                            className={clsx(classes.typography)}
                            color="secondary"
                            onClick={this.closeWebcamDialog}>
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item md={4}>
                          <ButtonBase>
                            <Box className={classes.cameraIcon} onClick={this.onScreenshot}>
                              <Camera />
                            </Box>
                          </ButtonBase>
                        </Grid>
                        <Grid item md={4}></Grid>
                      </>
                    )}
                    {this.tmpWebcamImage && (
                      <>
                        <Grid item md={6}>
                          <Button
                            className={clsx(classes.typography)}
                            color="secondary"
                            onClick={this.reset}>
                            Retake
                          </Button>
                        </Grid>
                        <Grid item md={6}>
                          <Button
                            className={clsx(classes.typography)}
                            variant="contained"
                            color="secondary"
                            onClick={this.onChooseScreenshot}>
                            Choose
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </DialogContent>
            </>
          )}
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(PictureUpload);
