import { makeStyles, colors } from '@material-ui/core';

export const avatarSize = 14.5;
export const borderWidth = 0.5;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default makeStyles((theme) => ({
  avatar: {
    height: theme.spacing(avatarSize),
    width: theme.spacing(avatarSize),
    borderColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    opacity: 0.9,
    marginBottom: theme.spacing(2),
    transitionDuration: '200ms',
    transitionProperty: 'all',
    '&:hover': {
      transform: `scale(1.05)`,
      opacity: 1,
    },
    '&:focus': {
      outline: 0,
      transform: `scale(1.05)`,
      opacity: 1,
    },
  },
  accountIcon: {
    height: theme.spacing(11),
    width: theme.spacing(11),
  },
  cornerIcon: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    color: '#200E32',
  },
  avatarLoading: {
    height: theme.spacing(avatarSize),
    width: theme.spacing(avatarSize),
  },
  root: {
    position: 'relative',
    height: theme.spacing(38),
    minWidth: 246,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  corner: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  fullName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 600,
    lineHeight: '30px',
  },
  email: {
    overflow: 'hidden',
    maxWidth: '90%',
    textOverflow: 'ellipsis',
    color: theme.palette.grey[600],
    fontSize: theme.typography.subtitle2.fontSize,
    lineHeight: '18px',
    fontWeight: 400,
    marginTop: theme.spacing(0.75),
  },
  lightGrey: {
    color: colors.grey[300],
  },
  darkGrey: {
    color: colors.grey[500],
  },
  bankLogo: {
    width: 30,
    height: 30,
    display: 'inline-block !important',
    backgroundSize: 'contain',
  },
  badge: {
    '& .MuiBadge-anchorOriginBottomRightCircular': {
      right: '5%',
      bottom: '15%',
      transform: 'scale(3.5) translate(50%,50%)',
      borderWidth: '1px',
      border: 'solid',
    },
  },
}));
