import React from 'react';
import { observer } from 'mobx-react';

import { Avatar } from '@material-ui/core';
import { Account } from 'mdi-material-ui';
import theme from 'containers/App/theme';

// import useStyles from './styles';

interface ImageIconProps {
  src?: string;
  radius?: number;
  type?: 'avatar' | 'logo';
}

/**
 * Used to display account avatar. If there is no avatar path prop we
 * display a generic avatar using Account icon from 'mdi-material-ui' icon set
 * @param avatar URL formatted path to users avatar image
 */
const ImageIcon = observer(({ src, radius = 8, type = 'avatar' }: ImageIconProps) => {
  const style = { borderRadius: radius };
  const backgroundColor = theme.palette.common.white
  return src ? (
    <Avatar style={{ ...style, backgroundColor}} variant="rounded" src={src} />
  ) : (
    <Avatar style={style} variant="rounded">
      {type === 'avatar' && <Account />}
    </Avatar>
  );
});

export default ImageIcon;
