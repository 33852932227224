import { professionColors } from 'containers/App/theme';
import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    fabContainer: {
      position: 'fixed',
      bottom: theme.spacing(3),
      right: theme.spacing(2),
    },
    affiliate: {
      color: professionColors.affiliate,
    },
    assistant: {
      color: professionColors.assistant,
    },
    stylist: {
      color: professionColors.stylist,
    },
    esthetician: {
      color: professionColors.esthetician,
    },
    nailTechnician: {
      color: professionColors.nail,
    },
    massageTherapist: {
      color: professionColors.massage,
    },
    barber: {
      color: professionColors.barber,
    },
    makeupArtist: {
      color: professionColors.makeup,
    },
    colorist: {
      color: professionColors.colorist,
    },
    other: {
      color: professionColors.other,
    },
  });
