import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reviewCard: {
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.common.white,
      background: 'inherit',
      height: 'auto !important',
      paddingLeft: theme.spacing(6.125),
      paddingRight: theme.spacing(3.75),
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      width: 536,
      margin: '0 auto',
      position: 'relative',
      backgroundColor: 'rgba(255, 255, 255, .15)',
      backdropFilter: 'blur(20px)',
      filter: '20px blur',
    },
    rating: {
      fontWeight: 600,
    },
    text: {
      fontSize: 20,
      margin: 0,
      color: theme.palette.common.white,
    },
    ratingWrapper: {
      height: 'auto !important',
      paddingRight: theme.spacing(2),
      marginTop: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

export default useStyles;
