import { Theme, makeStyles, createStyles } from '@material-ui/core';

interface IStyleProps {
  trigger: boolean;
  mobileView: boolean;
}

export const useStyles = makeStyles<Theme, IStyleProps>((theme) =>
  createStyles({
    iconButton: {
      padding: 0,
    },
    notificationsIcon: {
      color: theme.palette.text.primary,
      height: 20,
    },
  }),
);
