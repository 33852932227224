import clsx from 'clsx';
import React from 'react';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';

interface IEmptyPanelMessage {
  panelTitle: string,
}

export const EmptyPanelMessage = ({ panelTitle }: IEmptyPanelMessage) => {
  const classes = useStyles();
  const title = panelTitle.toLowerCase()
  return <Typography className={clsx(classes.label)}>No {title} to display</Typography>;
};
