import { Box } from '@material-ui/core';
import useStyles from './styles';
import React from 'react';

interface ICoverImage {
  imgPath: string;
  children: React.ReactNode;
}

function CoverImage({ imgPath, children }: ICoverImage) {
  const classes = useStyles({ url: imgPath });
  return <Box className={classes.coverImage}>{children}</Box>;
}

export default CoverImage;
