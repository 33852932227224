import React, { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { Box, CircularProgress, Popper } from '@material-ui/core';
import OutlinedInput from 'components/Input/OutlinedInput';
import { useStyles } from 'components/TagInputComponent/styles';
import Autocomplete, { AutocompleteChangeReason, AutocompleteProps } from '@material-ui/lab/Autocomplete';
import { AdditionalValue } from 'components/FilterBar';

interface ValueOptions {
  additional?: AdditionalValue;
  label: string;
  value: string;
}

export interface FieldOptions {
  fetch: (text: string) => any;
  displayField: ValueOptions;
}

interface AutocompleteFieldProps {
  options: FieldOptions;
  selected?: string;
  label: string;
  value?: any;
  onChange: (x: string) => void;
}

const AutocompleteField = ({
  options,
  value,
  onChange,
  ...restProps
}: AutocompleteFieldProps) => {
  const [selected, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const [collection, setCollection] = useState([]);

  const classes = useStyles();
  const fetchCollection = useCallback(
    async (text: string) => {
      setLoading(true);
      try {
        const result = await options.fetch(text);
        setCollection(result);
        return result;
      } finally {
        setLoading(false);
      }
    },
    [options],
  );

  const handleChange = useCallback((e,v) => {
    setValue(v);
    // onChange && onChange(v[options.displayField?.value]);
  }, [onChange, options.displayField?.value]);

  return (
    <Autocomplete
      value={selected}
      onChange={handleChange}
      onInputChange={(e, v) => fetchCollection(v)}
      getOptionLabel={(v) => v[options.displayField?.label] || v}
      getOptionSelected={(option, value) =>  option[options.displayField?.value] === value }
      renderOption={(v) => {
        return (
          <Box flexDirection="column">
            <Box component="span">{v[options.displayField?.label]}</Box>
            {options.displayField?.additional && (
              <Box component="span" style={{ display: 'block', opacity: '50%', fontSize: '0.8em' }}>
                {Array.isArray(options.displayField?.additional.value)
                  ? [...options.displayField?.additional.value]
                      .map((i) => v[i])
                      .join(
                        options.displayField?.additional.separator
                          ? options.displayField?.additional.separator
                          : ' ',
                      )
                  : v[options.displayField?.additional.value]}
              </Box>
            )}
          </Box>
        );
      }}
      renderInput={(params) => (
        <OutlinedInput
          className={classes.field}
          {...params}
          placeholder={'Contains'}
          fullWidth
          label={restProps.label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      options={collection}
      {...restProps}
    />
  );
};

export default AutocompleteField;
