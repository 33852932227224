import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    carousel: {
      height: '100vh',
      '& div': {
        height: '100%',
      },
    },
    ratingsCardWrapper: {
      height: 'auto !important',
      width: '100%',
    },
    gridItem: {
      height: '100vh',
      width: '100%',
      margin: 0,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
    },
  }),
);

export default useStyles;
