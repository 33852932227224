import React from 'react';
import { observable, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import Api, { getErrorMsg, PagedApiResponse } from 'api';
import { Invitation } from 'models';

import { inject, WithUserStore, WithModalStore, WithToastStore } from 'types/stores';

import styles from './styles';

import { AxiosResponse } from 'axios';
import { RouteComponentProps } from 'react-router-dom';
type NoRoleHomeProps = WithStyles<typeof styles> & WithUserStore & WithModalStore & WithToastStore & RouteComponentProps;

/**
 * For logged in users that are not affiliates or part of any account/location.
 */
@inject('userStore', 'modalStore', 'toastStore')
@observer
class NoRoleHome extends React.Component<NoRoleHomeProps> {
  constructor(props: NoRoleHomeProps){
    super(props);
    // makeObservable(this);
  }
  
  render() {
    return (
      <>
        NO ROLE USER
      </>
    );
  }
}

export default withStyles(styles)(NoRoleHome);
