import React from 'react';
import { observer } from 'mobx-react';
import { action, observable, reaction, toJS, IReactionDisposer, flow, makeObservable } from 'mobx';
import { User } from 'models';
import Api from 'api';

import { WithStyles, withStyles } from '@material-ui/core/styles';

import { WithToastStore, WithUserStore, inject } from 'types/stores';

import DP from 'components/DashPanel';
import PictureUpload, {
  PictureUploadLayoutType,
} from 'components/ProfilePicturePanel/PictureUploadPhoto';

import styles from './styles';
import ActionsMenu, { PositionMenu } from 'components/ActionsMenu';

interface ProfilePicturePanelProps
  extends WithStyles<typeof styles>,
    WithToastStore,
    WithUserStore {
  children?: User;
  title?: string;
}
/**
 * Panel for updating user's profile picture.
 *
 * @param children User object needed for id and avatar properties
 * @param title Optional title, `Profile Picture` by default
 */
@inject('toastStore', 'userStore')
@observer
class ProfilePicturePanel extends React.Component<ProfilePicturePanelProps> {
  static defaultProps = {
    title: 'Profile Picture',
  };

  constructor(props: ProfilePicturePanelProps) {
    super(props);
    makeObservable(this);
    this.disposers.push(
      reaction(
        () => this.props.children,
        (newUser) => {
          this.user = toJS(newUser);
        },
      ),
    );
  }

  @observable private openChangePhotoDialog = false;

  /** It's good practice to dispose of any autoruns that we set up */
  private disposers: IReactionDisposer[] = [];

  /** The user object is copied to this property */
  @observable private user?: User = toJS(this.props.children);

  /** For rendering switch and upload progress bar value */
  @observable private uploading = false;
  @observable private uploadProgress = 0;

  @observable private open = false;

  /** Event hook passed to axios post request so we can render upload progress */
  @action.bound public onUploadProgress = (progressEvent: any) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    this.uploadProgress = percentCompleted;
  };

  /** Upload new profile image, set component to initial state and update the user */
  @action.bound public handleProfileImageUpload = flow(function* (
    this: ProfilePicturePanel,
    avatar: File,
  ) {
    this.uploading = true;
    yield Api.core.uploadProfilePicture(this.user!.id, avatar, this.onUploadProgress);
    this.props.userStore!.refreshTokenAndUpdateUserData();
    this.props.toastStore!.push({
      type: 'success',
      message: `Profile picture was successfully changed`,
    });
    this.uploading = false;
    // this.openChangePhotoDialog = false;
    this.uploadProgress = 0;
  });

  @action.bound public handleProfileImageRemove = flow(function* (
    this: ProfilePicturePanel,
  ) {
    yield Api.core.deleteProfilePicture(this.user!.id);
    this.props.userStore!.refreshTokenAndUpdateUserData();
    this.props.toastStore!.push({
      type: 'success',
      message: 'Profile picture was successfully removed',
    });
  });

  private options = [
    {
      label: 'Remove Photo',
      action: () => this.user!.id ?  this.handleProfileImageRemove(): null,
    },
    {
      label: 'Change Photo',
      action: () => (this.user?.avatar ? (this.openChangePhotoDialog = true) : null),
    },
  ];

  render() {
    const { title, classes } = this.props;
    const uploadComponentLayout: PictureUploadLayoutType = 'vertical';
    return (
      <DP>
        <DP.Header>
          <DP.Title panel>{title}</DP.Title>
          {this.user?.avatar && (
            <DP.Actions>
              <ActionsMenu options={this.options} position={PositionMenu.VERTICAL} />
            </DP.Actions>
          )}
        </DP.Header>
        <DP.Body>
          <PictureUpload
            layout={uploadComponentLayout}
            profilePictureUrl={this.user!.avatar}
            onUpload={this.handleProfileImageUpload}
            loading={this.uploading}
            progress={this.uploadProgress}
            onClose={() => (this.openChangePhotoDialog = false)}
            open={this.openChangePhotoDialog}
          />
        </DP.Body>
      </DP>
    );
  }
}

export default withStyles(styles)(ProfilePicturePanel);