import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    leftButton: {
      padding: 0,
      color: theme.palette.text.secondary,
      fontSize: theme.typography.body1.fontSize,
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    email: {
      fontWeight: 400,
      color: theme.palette.primary.main,
    },
    codeInputWrap: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
    codeInput: {
      padding: theme.spacing(3),
      fontSize: theme.typography.h5.fontSize,
      color: theme.palette.grey[600],
      textTransform: 'uppercase',
      '&::placeholder': {
        textTransform: 'none',
      },
    },
    title: {
      fontSize: '40px',
      color: theme.palette.text.primary,
    },
  });
