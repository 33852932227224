import React from 'react';
import useStyles, { avatarSize } from './styles';
import { Paper, Box, Avatar, IconButton, Tooltip, Badge } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import { OpenInNew, Account } from 'mdi-material-ui';
import clsx from 'clsx';

import { User } from 'models';
import { paths } from 'routes';

import theme from 'containers/App/theme';
// import UserIconDetails from './UserIconDetails';

interface UserCardProps {
  className?: string;
  children?: User;
}

/** Displays a card for a single user */
function UserCard({ children: user, className }: UserCardProps) {
  const classes = useStyles();
  const fullName = user && user.firstName && user.lastName && `${user.firstName} ${user.lastName}`;

  const loadingSkeleton = (
    <>
      <Skeleton
        variant="rect"
        height={theme.spacing(avatarSize)}
        width={theme.spacing(avatarSize)}
      />
      <Skeleton variant="text" width="60%" style={{ marginTop: 8, marginBottom: 8 }} />
      <Skeleton variant="text" width="40%" style={{ margin: 0 }} />
    </>
  );



  return (
    <Paper className={clsx(classes.root, className)} elevation={0}>
      <Box className={classes.corner}>
        {user && (
          <IconButton component={RouterLink} to={paths.userDetails(user.id).root()}>
            <OpenInNew className={classes.cornerIcon} />
          </IconButton>
        )}
      </Box>
      <Box
        p={3}
        pt={5}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%">
        {user === undefined ? (
          loadingSkeleton
        ) : (
          <>
            <Tooltip title={'Other'} placement="right-start">
              {user.avatar ? (
                <Badge
                  className={classes.badge}
                  overlap="circular"
                  badgeContent=" "
                  variant="dot"
                  color="primary"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}>
                  <Avatar
                    component={RouterLink}
                    to={paths.userDetails(user.id).root()}
                    className={classes.avatar}
                    src={user.avatar}
                  />
                </Badge>
              ) : (
                <Badge
                  className={classes.badge}
                  overlap="circular"
                  badgeContent=" "
                  variant="dot"
                  color="primary"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}>
                  <Avatar
                    component={RouterLink}
                    to={paths.userDetails(user.id).root()}
                    className={classes.avatar}>
                    <Account className={classes.accountIcon} />
                  </Avatar>
                </Badge>
              )}
            </Tooltip>
            {fullName && <Box className={classes.fullName}>{fullName}</Box>}
            <Tooltip title={user.email}>
              <Box className={classes.email}>{user.email}</Box>
            </Tooltip>
          </>
        )}
      </Box>
    </Paper>
  );
}

export default UserCard;
