import { Box, Drawer } from '@mui/material';
import DynamicPanel from 'components/DynamicPanel';
import { FieldRules } from 'components/DynamicPanel/DynamicPanel';
import { Category } from 'models';
import React, { useCallback, useState } from 'react';
import Api, { RequestMetaData } from 'api';

export type Action = 'edit' | 'preview';

interface CategoryDetailsDrawerProps {
  category?: Category;
  open: boolean;
  action: Action;
  onClose: () => void;
}

const CategoryDetailsDrawer = ({ category, open, onClose }: CategoryDetailsDrawerProps) => {

  const getCategoriesByName = async (text: string) => {
    const { data } = await Api.product.getAllCategories({}, { name: text});
    return data.data.map((category: { id: string; name: string; [key: string]: string }) => {
      return category;
    });
  };

  const fieldsOutline: FieldRules = {
    name: {
      label: 'Name',
      type: 'text',
      rules: 'string',
      extra: {
        editable: true,
      },
    },
    description: {
      label: 'Description',
      type: 'text',
      rules: 'string',
      extra: {
        editable: true,
      },
    },
    parent: {
      label: 'Description',
      type: 'text',
      rules: 'string',
      extra: {
        editable: true,
      },
    },
    // parent: {
    //   label: 'Parent',
    //   type: 'async-select',
    //   rules: 'string',
    //   extra: {
    //     editable: true,
    //   },
    //   options: {
    //     fetch: getCategoriesByName,
    //     displayField: {
    //       value: 'id',
    //       label: 'name'
    //     },
    //   },
    // },
    status: {
      label: 'Status',
      type: 'radio-group',
      rules: 'string',
      extra: {
        editable: true,
      },
      options: [
        { label: 'ACTIVE', value: 'active' },
        { label: 'INACTIVE', value: 'inactive' },
      ],
    },
  };

  const handleClose = () => {
    onClose();
  };

  const updatedCategory = (data: any) => {
    console.log(data);

    handleClose();
  };

  return (
    <Drawer open={open} onClose={handleClose} anchor="right" variant="temporary">
      <Box width={420}>
        <DynamicPanel
          title="Details"
          editable={true}
          fields={fieldsOutline}
          onUpdate={updatedCategory}
          payload={category}
          fullHeight
        />
      </Box>
    </Drawer>
  );
};

export default CategoryDetailsDrawer;
