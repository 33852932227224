import { faBars, faXmarkLarge } from '@fortawesome/pro-regular-svg-icons';
import { useStores } from 'containers/App/App';

const useMobileToolbar = () => {
  const { uiStore } = useStores();
  const open = uiStore?.drawer.open;

  const toggleDrawer = uiStore?.toggleDrawer;
  const icon = open ? faXmarkLarge : faBars;

  return { open, icon, toggleDrawer };
};

export default useMobileToolbar;
