export enum AlertType {
  ISSUE = 'issue',
  GENERAL = 'general',
  PROMOTION = 'promotion',
  INFO = 'info',
}

export enum AlertSeverity {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export interface Alert {
  id: number;
  isActive?: boolean;
  message: string;
  type: AlertType;
  severity?: AlertSeverity;
  createdAt: string;
  updatedAt?: string;
  deactivatedAt?: string;
  cause?: string;
  createdById: number;
  createdBy: {
    id: number;
    firstName: string;
    lastName: string;
  };
}

export type NewAlertRequest = Pick<Alert, 'message' | 'type' | 'severity' | 'cause' | 'isActive'>;
