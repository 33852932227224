import React from 'react';
import { action, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Api, { RequestMetaData } from 'api';
import { WithUserStore, inject } from 'types/stores';
import DashboardLayout from 'containers/DashboardLayout';
import styles from './styles';
import UserCardList from 'components/UserCardList';
import { fetchWrapper, setTitle } from 'services';
import { User } from 'models';
import PlusFabButton from 'components/PlusFabButton/PlusFabButton';

const PAGE_TITLE = 'Users';

let abortController = new AbortController();

type UsersProps = WithStyles<typeof styles> & // Adds the classes prop
  WithUserStore;

@inject('userStore')
@observer
class Users extends React.Component<UsersProps> {
  constructor(props: UsersProps) {
    super(props);
    makeObservable(this);
  }

  @computed public get isAdmin(): boolean {
    return this.props.userStore!.isAdmin;
  }

  public fetchUsers = fetchWrapper(
    async (rmd: RequestMetaData) => {
      // this.abortApiCallController();

      return Api.core.getUsers(
        {
          ...rmd,
        },
        abortController,
      );
    },

    (user: User) => ({
      ...user,
    }),
  );

  public fetchAccountUsers = fetchWrapper(
    async (rmd: RequestMetaData) => {
      // this.abortApiCallController();

      return Api.core.getAccountUsers(
        {
          ...rmd,
        },
        abortController,
      );
    },

    (user: User) => ({
      ...user,
    }),
  );

  abortApiCallController(): void {
    abortController.abort();
  }

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
  }

  render() {
    const fetch = this.isAdmin ? this.fetchUsers : this.fetchAccountUsers;
    return (
      <DashboardLayout>
        <UserCardList fetch={fetch} />
        <PlusFabButton onClick={() => {}} />
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Users);
