import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

interface IStyleProps {
  clickable: boolean;
}

const cursor = ({ clickable }: IStyleProps) => (clickable ? 'pointer' : 'auto');
const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      cursor,
    },
    avatar: {
      borderRadius: theme.shape.borderRadius,
      width: '47px',
      height: '47px',
    },
  }),
);

export const useUserDetailsStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      marginLeft: theme.spacing(2),
    },
    emailText: {
      fontSize: '14px',
      color: theme.typography.subtitle1.color,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: `calc(${theme.menu.drawer.width.open}px - 95px)`,
    },
    fullNameText: {
      fontSize: 14,
    },
  }),
);

export default useStyles;
