/**
 * This function takes image File and resizes it to given size based on the
 * bigger side of the original image.
 *
 * @param file image file to resize
 * @param maxSize max size in pixels
 */
export function resizeImage(file: File, maxSize: number): Promise<File> {
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement('canvas');

  const dataURItoBlob = (dataURI: string) => {
    const bytes =
      dataURI.split(',')[0].indexOf('base64') >= 0
        ? atob(dataURI.split(',')[1])
        : unescape(dataURI.split(',')[1]);
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], { type: mime });
  };

  const blobToFile = (theBlob: Blob, fileName: string): File => {
    const b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName;
    return theBlob as File;
  };

  const resize = () => {
    let width = image.width;
    let height = image.height;

    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      }
    } else {
      if (height > maxSize) {
        width *= maxSize / height;
        height = maxSize;
      }
    }

    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d')!.drawImage(image, 0, 0, width, height);
    const dataUrl = canvas.toDataURL('image/jpeg');

    const blob = dataURItoBlob(dataUrl);
    return blobToFile(blob, 'profile_picture');
  };

  return new Promise((ok, no) => {
    if (!file.type.match(/image.*/)) {
      no(new Error('Not an image'));
      return;
    }

    reader.onload = (readerEvent: any) => {
      image.onload = () => ok(resize());
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  });
}

/**
 * This function takes base64 encoded string of the image and creates a
 * new file for further manipulation; e.g. resize, upload, ....
 *
 * @param dataUrl base64 encoded string
 */
export function dataURLtoFile(dataUrl: string) {
  const deconstructedDataUrl = dataUrl.split(',');
  const validDataUrl = deconstructedDataUrl[0] && deconstructedDataUrl[1];
  if (validDataUrl) {
    const mime = deconstructedDataUrl[0].match(/:(.*?);/)![1];
    const bstr = atob(deconstructedDataUrl[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) u8arr[n] = bstr.charCodeAt(n);
    return new File([u8arr], 'profile_picture', { type: mime });
  }
}
