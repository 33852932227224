import { useStores } from 'containers/App/App';
import { useState } from 'react';

export function useScopeSwitcher() {
  const [scopeSwithcerEl, setScopeSwithcherEl] = useState<HTMLElement | null>(null);
  const { userStore } = useStores();
  /** Handle the onClick event on the scope switcher */
  function handleScopeSwitcherClick(event: React.MouseEvent<HTMLButtonElement>) {
    setScopeSwithcherEl(event.currentTarget);
  }

  /** Handle the closing of the scope switcher */
  function handleScopeSwitcherClose() {
    setScopeSwithcherEl(null);
  }

  const { existsAccounts } = userStore;
  const showScopeSwitcher = existsAccounts

  const top = scopeSwithcerEl
    ? scopeSwithcerEl.getBoundingClientRect().top +
      scopeSwithcerEl.getBoundingClientRect().height +
      16
    : 0;

  return {
    showScopeSwitcher,
    scopeSwithcerEl,
    top,
    handleScopeSwitcherClick,
    handleScopeSwitcherClose,
  };
}
