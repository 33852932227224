import { makeStyles, createStyles, Theme } from '@material-ui/core';
import theme from 'containers/App/theme';

interface IStyleProps {
  mobileView: boolean;
  menuOpen: boolean;
}

const height = ({ mobileView }: IStyleProps) =>
  mobileView ? theme.appBar.mobile.height : theme.appBar.height;
const justifyContent = ({ mobileView }: IStyleProps) => (mobileView ? 'space-between' : 'flex-end');
const zIndex = ({ mobileView }: IStyleProps) => {
  return mobileView ? theme.zIndex.drawer + 3 : theme.zIndex.drawer;
};
const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) =>
  createStyles({
    appBar: {
      height,
      color: theme.menu.main,
      backgroundColor: theme.palette.background.default,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      borderBottom: ({ mobileView, menuOpen }) =>
        mobileView && menuOpen ? `1px solid ${theme.palette.divider}` : 'none',
      zIndex,
    },
    appBarShift: {
      marginLeft: theme.menu.drawer.width.open,
      width: `calc(100% - ${theme.menu.drawer.width.open}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      color: theme.menu.main,
    },
    toolbar: {
      width: '100%',
      display: 'flex',
      justifyContent,
      alignItems: 'center',
      padding: theme.spacing(2),
      gap: theme.spacing(2),
      boxShadow: 'none',
      backgroundColor: 'transparent',
      transition: theme.transitions.create('backgroundColor', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    toolbarScroll: {
      backgroundColor: `${theme.palette.common.white}!important`,
      transition: theme.transitions.create('backgroundColor', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxShadow: `0px 4px 60px 0 rgba(0, 0, 0, 0.08)`,
    },
  }),
);

export default useStyles;
