import React, { useEffect, useState } from 'react';
import LoadingPage from 'routes/LoadingPage';
import { generateDeepLink, isOsAndroid } from 'utils/helper';

const withAndroidRedirect = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const WithAndroidRedirect: React.FC<P> = (props) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      redirectToApp();
    }, []);

    const redirectToApp = () => {
      if (isOsAndroid()) {
        const url = window.location.href;
        const newUrl = generateDeepLink(url, 'shop:/');
        window.location.assign(newUrl);
      }
      setIsLoading(false);
    };

    if (isLoading) {
      return <LoadingPage />;
    }

    return <WrappedComponent {...props} />;
  };

  return WithAndroidRedirect;
};

export default withAndroidRedirect;
