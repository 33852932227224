import React from 'react';
import { observer } from 'mobx-react';


import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import { CheckCircleOutline, CircleOutline, AlertOutline, Pencil } from 'mdi-material-ui';
import DP from 'components/DashPanel';
import { useStyles } from './styles';
import CreditCardIcon from 'components/CreditCardIcon';
import moment from 'moment';

/** Represents a payment method in the list */
export const PaymentMethodItem = observer(
  ({
    children,
    itemProps,
    removePaymentMethod,
    editPaymentMethod,
    sendUpdateRequest,
    isAdmin,
    flexSecondary,
  }: {
    flexSecondary?: boolean;
    children: any;
    itemProps?: any;
    isAdmin?: boolean;
    removePaymentMethod?: (billingEntityId: number) => void;
    editPaymentMethod?: (billingEntityId: number) => void;
    sendUpdateRequest?: (billingEntityId: number) => void;
  }) => {
    const classes = useStyles();

    const billingEntity = children as any & {
      needsUpdating?: boolean;
    };
    const { paymentMethod } = billingEntity;
    const menu = [
      {
        label: <Typography>Edit</Typography>,
        onClick: () => editPaymentMethod && editPaymentMethod(billingEntity.id),
      },
      {
        label: <Typography color="error">Remove</Typography>,
        onClick: () => removePaymentMethod && removePaymentMethod(billingEntity.id),
      },
    ];

    if (isAdmin) {
      menu.push({
        label: <Typography>Send update request</Typography>,
        onClick: () => sendUpdateRequest && sendUpdateRequest(billingEntity.id),
      });
    }

    const brand = paymentMethod.brand || 'Other';
    const lastFour = paymentMethod.lastFour;
    const validThru = paymentMethod.validThru;
    const formattedAddDate = moment(paymentMethod.createdAt).format('MM/DD/YYYY');
    const [month, year] = (paymentMethod.validThru || '').split('/');
    let secondaryText = null;
    if (month && year) {
      const expirationDate = new Date();
      expirationDate.setMonth(parseInt(month) - 1);
      expirationDate.setFullYear(parseInt(year));

      const isExpired = new Date() > expirationDate;
      secondaryText = `${isExpired ? 'Expired' : 'Expires'} on ${validThru}`;
    }

    const addDate = `Added on ${formattedAddDate}`;
    return (
      <DP.ListItem
        key={paymentMethod.id}
        icon={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ fontSize: '21px' }}>
            <CreditCardIcon brand={brand.toLowerCase()} />
          </Box>
        }
        primary={
          <Box display="flex" flexDirection="row">
            <Typography>
              {brand} {lastFour}
            </Typography>
            {billingEntity.needsUpdating && (
              <Box ml={1} display="flex" flexDirection="row" alignItems="center">
                <Tooltip title={'Warning! Payment method needs to be updated'}>
                  <AlertOutline fontSize="small" color="error" />
                </Tooltip>
                <IconButton
                  size="small"
                  onClick={() => editPaymentMethod && editPaymentMethod(billingEntity.id)}>
                  <Pencil color="primary" fontSize="small" />
                </IconButton>
              </Box>
            )}
          </Box>
        }
        secondary={
          <Box
            paddingRight="2rem"
            color={billingEntity.isExpired && billingEntity.isExpired ? 'red' : ''}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Typography>{secondaryText}</Typography>
              <Typography className={classes.textTertiary}>{addDate}</Typography>
            </Box>
          </Box>
        }
        className={
          flexSecondary ? { containerSecondary: classes.listTextSecondaryFlex } : undefined
        }
        menu={itemProps && itemProps?.hideMenu !== true ? menu : undefined}
        rightIcon={
          itemProps &&
          itemProps!.selected &&
          (itemProps!.selected === paymentMethod.id ? (
            <CheckCircleOutline color="primary" />
          ) : (
            <CircleOutline color="primary" />
          ))
        }
        onClick={
          itemProps && itemProps!.onItemClick
            ? () => {
                itemProps!.onItemClick!(paymentMethod);
              }
            : undefined
        }
      />
    );
  },
);

