import { Theme, createStyles, makeStyles, alpha } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    searchAndSelectModalWrapper: {
      width: theme.spacing(60),
      minHeight: theme.spacing(30),
      display: 'flex',
      flexDirection: 'column',
    },
    titleWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    dialogContentWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '20px',
    },
    address: {
      display: 'block',
      opacity: '50%',
      fontSize: '0.8em',
    },
    loader: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: alpha(theme.palette.common.white, 0.5),
      borderRadius: theme.shape.borderRadius,
      zIndex: 200,
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
    },
  });

export default styles;

export const useStyles = makeStyles(styles);
