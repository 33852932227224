import { Address } from 'types';
import { TAccountScope} from './Account';

export enum Role {
  ADMIN = 'admin',
  USER = 'user',
}

export type TRole = 'admin' | 'user';

export type TScope = TRole | TAccountScope | 'none';

export interface AccountKind {
  kind: TAccountScope;
  accountId: string;
}
export interface UserKind {
  kind: TRole;
}

/** Represent as what kind of user someone is currently viewing the dashboard */
export type ScopeKind = AccountKind | UserKind | { kind: 'none' };

export interface IUserMerge {
  sourceUserId: string;
  destUserId: string;
  deleteSource: boolean;
}

/** Represents a user */
export interface User {
  id: string;
  firstName: string;
  lastName: string;
  nickname: string;
  email: string;
  status: string;
  role: Role;
  phone: string;
  isEmailConfirmed: boolean;
  isPhoneConfirmed: boolean;
  avatar: string;
  dob: string;
  address: Address;
  isActive: boolean;
  isRequiredResetPassword?: boolean;
}
export interface UserLoginResponseV2 {
  /** Authenticated user object */
  user: User;
  /** JWT token field */
  jwt: string;
  /** JWT token field */
  exp: number;
  /** JWT token field */
  iat: number;
  /** refresh token */
  refreshToken: string;
  /** Refresh token expiration time */
  refreshTokenExp: number;
}

export interface PrimaryOwner {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}
