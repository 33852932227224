import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

interface IStyleProps {
  right: number;
}

const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) =>
  createStyles({
    fabButton: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.primary,
      position: 'fixed',
      bottom: theme.spacing(2),
      right: ({ right }) => theme.spacing(right),
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: theme.palette.primary.hover,
      },
    },
  }),
);

export default useStyles;
