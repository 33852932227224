import { memo, useEffect, useMemo, useCallback } from 'react'
import './style.css'

type animationVariant =
  | 'clip'
  | 'rotate-1'
  | 'type'
  | 'rotate-2'
  | 'loading-bar'
  | 'slide'
  | 'zoom'
  | 'rotate-3'
  | 'scale'
  | 'push'

interface styleProps {
  headline?: string | Record<string, string | number>
  wrapper?: string | Record<string, string | number>
}

interface AnimatedProps {
  list: string[]
  variant?: animationVariant
  css?: styleProps
}

const SpanAnimated = ({ list, variant, css }: AnimatedProps) => {
  const items = useMemo(() => list, [list])

  const getVariant = useCallback((type) => {
    switch (type) {
      case 'clip':
        return 'clip is-full-width'
      case 'letters type':
        return 'type'
      case 'rotate-2':
        return 'letters rotate-2'
      case 'loading-bar':
        return 'loading-bar'
      case 'slide':
        return 'slide'
      case 'zoom':
        return 'zoom'
      case 'rotate-3':
        return 'letters rotate-3'
      case 'scale':
        return 'letters scale'
      case 'push':
        return 'push'
      default:
        return 'rotate-1'
    }
  }, [])

  const animationDelay = 2500
  const barAnimationDelay = 3800
  const barWaiting = barAnimationDelay - 3000
  const lettersDelay = 50
  const typeLettersDelay = 150
  const selectionDuration = 500
  const typeAnimationDelay = selectionDuration + 800
  const revealDuration = 600
  const revealAnimationDelay = 1500

  let duration = animationDelay

  const switchWord = useCallback((oldWord: any, newWord: any) => {
    oldWord.classList.remove('is-visible')
    oldWord.classList.add('is-hidden')
    newWord.classList.remove('is-hidden')
    newWord.classList.add('is-visible')
  }, [])

  const takeNext = useCallback((word: any) => {
    const lastChild = word.parentElement.lastChild === word
    return !lastChild ? word.nextSibling : word.parentElement.childNodes[0]
  }, [])

  const takePrev = useCallback((word: any) => {
    const firstChild = word.parentElement.firstChild === word
    return !firstChild ? word.previousSibling : word.parentElement.lastChild
  }, [])

  const showWord = useCallback(
    (word: any) => {
      const parentWord = word.parentElement.parentElement

      if (parentWord.classList.contains('type')) {
        console.log('showWord-type')
      } else if (parentWord.classList.contains('clip')) {
        new Promise((resolve) => {
          resolve(
            parentWord.firstChild.animate(
              [{ width: '2px' }, { width: `${word.offsetWidth + 10}px` }],
              revealDuration
            ).finished
          )
        }).finally(() => {
          setTimeout(() => {
            // eslint-disable-next-line no-use-before-define
            hideWord(word)
          }, revealAnimationDelay)
        })
      }
    },
    [revealAnimationDelay, revealDuration]
  )

  const hideWord = useCallback(
    (word: any) => {
      if (word) {
        const parentWord = word.parentElement.parentElement
        const nextWord = takeNext(word)
        if (parentWord.classList.contains('type')) {
          console.log('hideWord-type')
        } else if (parentWord.classList.contains('letters')) {
          console.log('hideWord-letters')
        } else if (parentWord.classList.contains('clip')) {
          new Promise((resolve) => {
            resolve(
              parentWord.firstChild.animate(
                [{ width: `${word.offsetWidth + 10}px` }, { width: '2px' }],
                revealDuration
              ).finished
            )
          }).finally(() => {
            switchWord(word, nextWord)
            showWord(nextWord)
          })
        } else if (parentWord.classList.contains('loading-bar')) {
          parentWord
            .getElementsByClassName('cd-words-wrapper')[0]
            .classList.remove('is-loading')
          switchWord(word, nextWord)
          setTimeout(() => {
            hideWord(nextWord)
          }, barAnimationDelay)
          setTimeout(() => {
            parentWord
              .getElementsByClassName('cd-words-wrapper')[0]
              .classList.add('is-loading')
          }, barWaiting)
        } else {
          switchWord(word, nextWord)
          setTimeout(() => {
            hideWord(nextWord)
          }, animationDelay)
        }
      }
    },
    [animationDelay, barAnimationDelay, barWaiting]
  )

  const animateHeadline = useCallback(() => {
    const collection = document.getElementsByClassName('cd-headline')
    for (let i = 0; i < collection.length; i += 1) {
      const headline = collection[i]
      const visibleSpan = headline.querySelector('.is-visible')

      if (headline.classList.contains('loading-bar')) {
        duration = barAnimationDelay
        setTimeout(() => {
          headline
            .getElementsByClassName('cd-words-wrapper')[0]
            .classList.add('is-loading')
          hideWord(visibleSpan)
        }, barWaiting)
      } else if (headline.classList.contains('clip')) {
        hideWord(visibleSpan)
      } else if (!headline.classList.contains('type')) {
        setTimeout(() => {
          hideWord(visibleSpan)
        }, duration)
      }
    }
  }, [barAnimationDelay, barWaiting])

  useEffect(() => {
    animateHeadline()
  }, [])

  return (
    <span
      className={`cd-headline ${getVariant(variant)} ${
        typeof css?.headline === 'string' ? css.headline : ''
      }`}
      style={typeof css?.headline === 'object' ? css?.headline : {}}
    >
      <span
        className={`cd-words-wrapper ${
          typeof css?.wrapper === 'string' ? css.wrapper : ''
        }`}
        style={typeof css?.wrapper === 'object' ? css?.wrapper : {}}
      >
        {[...items].map((text, index) =>
          index === 0 ? (
            <b key={index} className="is-visible">
              {text}
            </b>
          ) : (
            <b key={index}>{text}</b>
          )
        )}
      </span>
    </span>
  )
}

export default memo(SpanAnimated)
