import React, { useState, useCallback, useMemo } from 'react';
import { IconButton, Divider, Box, InputAdornment, Typography } from '@material-ui/core';
import { CalendarMonthOutline, ChevronLeft } from 'mdi-material-ui';
import moment from 'moment';

import { DateRange as MuiDateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { IntervalOptions } from '../FilterBar';
import { DateRange, isoDateFormat } from './CustomPicker';
import OutlinedInput from 'components/Input/OutlinedInput';
import { default as DialogButton } from 'components/Button/Dialog/Button';

interface TimerPickerProps {
  onChangeMode: (mode: string) => void;
  onChangeValue: (date: MuiDateRange<Date>) => void;
  onChange: (range: DateRange) => void;
  onOpen?: (open: boolean) => void;
  value: MuiDateRange<Date>;
  children: React.ReactNode;
  interval?: IntervalOptions;
  isMobile: boolean;
}
const TimerPicker = ({
  value,
  onChangeMode,
  onChangeValue,
  onChange,
  onOpen,
  interval,
  classes,
  children,
  isMobile
}: TimerPickerProps & WithStyles<typeof styles>) => {
  const [openStart, setOpenStart] = useState(false);
  const [openEnd, setOpenEnd] = useState(false);

  const isStartValue = useMemo(()=> moment(value[0]).isValid(), [value])
  const isEndValue = useMemo(()=> moment(value[1]).isValid(), [value])

  const handleTimerRange = useCallback(
    (comp: string, val: Date | null) => {
      // if (!value.includes(null) && value[1] !== null && comp === 'start') {
      //   onChangeValue([val, null]);
      // } else {


        if (comp === 'start' && moment(val).isValid()) {
          const endDate =  moment(value[1]).isValid() ? value[1] : null
          onChangeValue([val, endDate]);
        }
        if (comp === 'end' && moment(val).isValid() && moment(value[0]).isValid()) {
          const isAfter = moment(val).isAfter(value[0]);
          onChangeValue([value[0], isAfter ? val : value[0]]);
        }
      // }



      if (moment(value[0]).isValid() && moment(value[1]).isValid() ) {
        // (Boolean(onChange) && !isMobile) &&
        //   onChange({
        //     fromDate: value[0],
        //     toDate: val,
        //     type: 'date-timer',
        //   });
      }
    },
    [value, onChangeValue, onChange, isMobile],
  );

  return (
    <>
      <Box display="flex" alignItems="center" width={'100%'} py={2}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box className={classes.actionMenuContainer}>
            <Box>
              <IconButton onClick={() => onChangeMode('menu')}>
                <ChevronLeft />
              </IconButton>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              width={'100%'}
              className={classes.timerPickerContainer}>
              <Box width={isMobile ? '100%' : '45%'}>
                <DateTimePicker
                  label="Start"
                  open={openStart}
                  // onOpen={() => {
                  //   setOpenStart(true);
                  // }}
                  // onClose={() => {
                  //   setOpenStart(false);
                  //   setOpenEnd(true);
                  // }}
                  inputFormat={isoDateFormat}
                  value={value[0]}
                  onChange={(newValue) => {
                    handleTimerRange('start', newValue);
                  }}
                  renderInput={(params) => {
                    const labelStart = interval ? interval.from.label : params.label;
                    return (
                      <OutlinedInput
                        fullWidth
                        inputProps={params.inputProps}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <CalendarMonthOutline />
                            </InputAdornment>
                          ),
                        }}
                        label={labelStart}
                        value={params.value}
                        onChange={params.onChange}
                      />
                    );
                  }}
                />
              </Box>
              {!isMobile && (
                <Box width={'10%'} px={2} alignSelf={'center'} style={{ textAlign: 'center' }}>
                  to
                </Box>
              )}

              <Box width={isMobile ? '100%' : '45%'}>
                <DateTimePicker
                  label="End"
                  open={openEnd}
                  // onOpen={() => {
                  //   setOpenEnd(true);
                  // }}
                  // onClose={() => {
                  //   setOpenEnd(false);
                  //   onOpen && onOpen(false);
                  // }}
                  inputFormat={isoDateFormat}
                  value={value[1]}
                  onChange={(newValue) => {
                    handleTimerRange('end', newValue);
                  }}
                  renderInput={(params) => {
                    const labelEnd = interval ? interval.to.label : params.label;
                    return (
                      <OutlinedInput
                        fullWidth
                        inputProps={params.inputProps}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <CalendarMonthOutline />
                            </InputAdornment>
                          ),
                        }}
                        label={labelEnd}
                        value={params.value}
                        onChange={params.onChange}
                      />
                    );
                  }}
                />
              </Box>
            </Box>
          </Box>
        </LocalizationProvider>
      </Box>
      <Box>
        <Divider />
          {children}
        <Divider />
        <DialogButton
          style={{ margin: '16px'}}
          variant="contained"
          disabled={!(isStartValue && isEndValue)}
          className={classes.buttonAction}
          color="primary"
          onClick={() => {
            onChange({
              fromDate: value[0],
              toDate: value[1],
              type: 'date-timer',
            });
            onOpen && onOpen(false);
          }}>
          Apply
        </DialogButton>
      </Box>
    </>
  );
};

export default withStyles(styles)(TimerPicker);
