import { Theme, createStyles, makeStyles } from '@material-ui/core';

interface IStyleProps {
  menuOpen: boolean;
  mobileView: boolean;
  groupOpen?: boolean;
}
const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) =>
  createStyles({
    menu: {
      fontSize: theme.typography.body1.fontSize,
    },
    item: {
      height: 48,
      width: '100%',
      borderRadius: 'inherit',
      padding: ({ menuOpen, mobileView }) =>
        mobileView || menuOpen ? theme.spacing(1.5, 2) : theme.spacing(1.5, 2.125),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textDecoration: 'none',
      color: theme.palette.text.primary,
    },
    activeItem: {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },

    listItem: {
      padding: 0,
      borderRadius: ({ menuOpen, mobileView }) =>
        mobileView || menuOpen ? theme.shape.borderRadius : 0,
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
    },
    buttonBase: {
      color: ({ groupOpen }) =>
        groupOpen ? theme.palette.primary.main : theme.palette.text.primary,
    },

    chevron: {
      height: 24,
    },
  }),
);

interface IUseMenuGroupStylesProps {
  open: boolean;
  menuOpen: boolean;
  mobileView?: boolean;
}
export const useMenuGroupStyles = makeStyles<Theme, IUseMenuGroupStylesProps>((theme: Theme) =>
  createStyles({
    buttonBase: {
      height: 48,
      padding: ({ menuOpen, mobileView }) =>
        mobileView || menuOpen ? theme.spacing(1.5, 2) : theme.spacing(1.5, 2.125),
      borderRadius: ({ open }) => (open ? theme.shape.borderRadius : 0),

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      textDecoration: 'none',
      color: ({ open }) => (open ? theme.palette.primary.main : theme.palette.text.primary),
    },
    listItem: {
      padding: 0,
      borderRadius: ({ menuOpen }) => (menuOpen ? theme.shape.borderRadius : 0),
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
    },
    chevron: {
      height: 24,
    },
  }),
);

export default useStyles;
