import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';

import { paths } from 'routes';

import Owner from './Owner';
import Stepper from './Stepper';
import { inject } from 'types/stores';
import Company from './Company';
import Plan from './Plan';
import Summary from './Summary';
import { Box } from '@material-ui/core';

export type TStep = 'owner' | 'company' | 'plan' | 'review';

type TenantSignupProps = RouteComponentProps<{ accountId?: string }>;

const TenantSignup = (props: TenantSignupProps) => {
  const [step, setStep] = useState<any>({ currentStep: 0 });
  const path = props.match.path;
  const as = paths.onBoardTenant();

  const steps = ['owner', 'company', 'plan', 'review'];

  const handleSubmit = (type: TStep, payload: unknown): void => {
    setStep({ ...step, currentStep: step.currentStep + 1, [type]: payload });
  };

  useEffect(() => {
    const stepName = steps[step.currentStep] as TStep;
    switch (stepName) {
      case 'company':
        props.history.push(as.company());
        return;
      case 'plan':
        props.history.push(as.plan());
        return;

      case 'review':
        props.history.push(as.review());
        return;

      default:
        props.history.push(as.root());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box style={{ paddingLeft: '2rem', paddingRight: '2rem', paddingBottom: '2rem' }}>
      <Stepper showProductStep steps={steps} currentStep={step.currentStep} />
      <Switch>
        <Route
          path={path}
          exact
          render={(props) => (
            <Owner {...props} value={step.owner} onSubmit={handleSubmit} nextRoute={as.company} />
          )}></Route>
        <Route
          path={as.company()}
          exact
          render={(props: any) => (
            <Company {...props} value={step.company} onSubmit={handleSubmit} nextRoute={as.plan} />
          )}></Route>
        <Route
          path={as.plan()}
          exact
          render={(props: any) => (
            <Plan {...props} value={step.plan} onSubmit={handleSubmit} nextRoute={as.review} />
          )}></Route>
        <Route
          path={as.review()}
          exact
          render={(props: any) => <Summary {...props} value={step} />}></Route>
      </Switch>
    </Box>
  );
};

export default inject()(observer(TenantSignup));
