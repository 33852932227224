import axios, { AxiosResponse } from 'axios';
import config from 'config';
import * as LocalStorage from 'services/localStorage';
import CustomEvents from './events';
import { RefreshToken } from 'models/Authentication';
import { ApiResponse } from 'api';

let api = axios.create({ baseURL: `${config.api.core}/v2` });
api.interceptors.request.use(async (c: any) => {
  const jwt = LocalStorage.getJwt();
  if (jwt !== undefined) {
    c.headers['Authorization'] = `Bearer ${jwt}`;
  }

  return c;
});
api.interceptors.response.use(undefined, async (error) => {
  window.dispatchEvent(CustomEvents.LOGOUT);
});

let isRefreshingToken = false;
let refreshPromise: Promise<AxiosResponse<ApiResponse<RefreshToken>>> | null = null;

export async function refreshJwtToken() {
  if (isRefreshingToken) {
    // Return existing promise if refresh token call is already in progress
    return refreshPromise;
  }

  isRefreshingToken = true;

  const user = localStorage.getItem('userStore/user');
  const email = user && JSON.parse(user).email;
  try {
    refreshPromise = api.post('auth/refresh', {
      email,
      refreshToken: localStorage.getItem('refreshToken'),
    });

    const response = await refreshPromise;
    if (!response?.data?.data?.jwt) {
      throw new Error();
    }
    const { jwt, exp, user } = response.data.data;

    LocalStorage.setJwt(jwt);
    LocalStorage.setExp(exp);
    LocalStorage.setUser(user);

    refreshPromise = null;
    isRefreshingToken = false;

    return response;
  } catch (error) {
    console.error('Failed to refresh JWT token', error);

    refreshPromise = null;
    isRefreshingToken = false;

    throw error;
  }
}
