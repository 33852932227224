import React from 'react';
import { ButtonBase, List, ListItem } from '@material-ui/core';
import clsx from 'clsx';

import { useStores } from 'containers/App/App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { MenuGroup as Group } from 'models/Menu';
import MenuIcon from './MenuIcon/MenuIcon';
import { MenuLabel } from './MenuItem';
import useStyles from './styles';

interface MenuGroupProps {
  open: boolean;
  icon: IconDefinition; // TODO: react mui component
  toggle: (group: Group) => void;
  label: Group;
  children: JSX.Element[];
  mobileView: boolean;
}

/**
 * A group in the side drawer menu. Its children should be MenuItem elements.
 * @param open Whether the group is open or closed
 * @param toggle The function to toggle open/closed
 * @param icon FontAwesome icon
 * @param label The name of the group to be displayed
 * @param mobileView Whether we are in mobileView mode
 * @param children MenuItem elements
 */
const MenuGroup = ({ toggle, open, icon, label, children, mobileView }: MenuGroupProps) => {
  const { uiStore } = useStores();
  const menuOpen = uiStore.drawer.open;
  const hide = !mobileView && !menuOpen;
  const showIcon = mobileView ? true : menuOpen ? true : false;
  const { item, listItem, chevron, buttonBase } = useStyles({
    menuOpen,
    mobileView,
    groupOpen: open,
  });

  function handleToggle() {
    toggle(label);
  }
  return (
    <List disablePadding>
      <ListItem className={listItem}>
        <ButtonBase
          disableRipple
          className={clsx(item, buttonBase)}
          onClick={handleToggle}>
          <MenuIcon icon={icon} />
          <MenuLabel hide={hide}>{label}</MenuLabel>
          {showIcon && (
            <FontAwesomeIcon className={chevron} icon={open ? faChevronUp : faChevronDown} />
          )}
        </ButtonBase>
      </ListItem>
      {open && <List disablePadding>{children}</List>}
    </List>
  );
};

export default MenuGroup;
