import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { observable, action, computed, toJS, makeObservable } from 'mobx';

import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { paths } from 'routes';

import { UserAccount } from 'models';
import { inject, WithUserStore, WithToastStore } from 'types/stores';

import { PagedApiResponse, RequestMetaData } from 'api';

import DP from 'components/DashPanel';
import TabBar, { TabVariant } from 'components/TabBar';
import UsersTab from './UsersTab';

import styles from './styles';
import { AxiosResponse } from 'axios';
import { Box, Divider, IconButton } from '@material-ui/core';
import { AccountPlus } from 'mdi-material-ui';

export type TabType = 'employee' | 'manager' | 'owner';
/**
 * Represents a list of users of a certain type. A tab will
 * appear for each of those.
 */
interface UsersPanelProps extends WithStyles<typeof styles>, WithUserStore, WithToastStore {
  variant?: TabVariant;
  fullHeight?: boolean;
  displayInviteManagersButton?: boolean;
  fetch: (meta?: RequestMetaData) => Promise<AxiosResponse<PagedApiResponse<UserAccount>>>;
  renderScope: TabType[];
}

@inject('userStore', 'toastStore')
@observer
class UsersPanel extends React.Component<UsersPanelProps> {
  constructor(props: UsersPanelProps) {
    super(props);
    makeObservable(this);
  }

  @observable private selectedTab: TabType = 'employee';

  @observable public managerInviteOpen = false;

  /** If current user can send email invitation to onboard a new manager */
  @computed public get canSendInvite(): boolean {
    const isAdmin = this.props.userStore?.isAdmin;
    const isOwner = this.props.userStore!.isOwner;
    return Boolean((isOwner || isAdmin) && this.props.displayInviteManagersButton);
  }

  /** Selects a tab */
  @action.bound private selectTab = (value: TabType) => () => {
    this.selectedTab = value;
  };

  render() {
    return (
      <DP fullHeight={this.props.fullHeight}>
        <DP.Header>
          <DP.Title panel>Users</DP.Title>
          {this.canSendInvite && (
            <IconButton
              size="small"
              color="primary"
              component={RouterLink}
              to={{
                pathname: paths.invitations(),
              }}>
              <AccountPlus />
            </IconButton>
          )}
        </DP.Header>
        <Box mt={2} mb={2}>
          <TabBar>
            {this.props.renderScope.map((scope) => (
              <TabBar.Tab
                key={scope}
                variant={TabVariant.UNDERLINE}
                mr={7}
                onClick={this.selectTab(scope)}
                selected={this.selectedTab === scope}>
                {`${scope}s`}
              </TabBar.Tab>
            ))}
          </TabBar>
        </Box>
        <Divider />
        <UsersTab fetch={this.props.fetch} type={this.selectedTab} pageSize={20} />
      </DP>
    );
  }
}

export default withStyles(styles)(UsersPanel);
