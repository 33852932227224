import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  observable,
  computed,
  toJS,
  makeObservable,
} from 'mobx';
import { observer } from 'mobx-react';

import Api from 'api';
import { inject, WithModalStore, WithToastStore, WithUserStore } from 'types/stores';
import { paths } from 'routes';
import { Account, Scope } from 'models';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Link, Tooltip } from '@material-ui/core';
import { OpenInNew } from 'mdi-material-ui';

import DP from 'components/DashPanel';

import styles from './styles';
import ChipStatusTag from 'components/ChipStatusTag';
import { ChipStatusColors } from 'components/ChipStatusTag';
import { getAccountCode } from 'utils/helper';

interface UserAccountPanelProps
  extends WithStyles<typeof styles>,
    WithModalStore,
    WithToastStore,
    WithUserStore {
  children?: Account;
  editable?: boolean;
}

/**
 * Displays location user panel, where user can change settings for specific location user.
 * Configurable values are 'isManager', 'isVisible', `isTalent` and 'status'
 */
@inject('modalStore', 'toastStore', 'userStore')
@observer
class UserAccountPanel extends React.Component<UserAccountPanelProps> {
  constructor(props: UserAccountPanelProps) {
    super(props);
    makeObservable(this);
  }

  @observable private updating = false

  /** The user object is copied to this property */
  @observable private account? = toJS(this.props.children);

  @computed private get isManager() {
    return this.account && this.account.scope == Scope.MANAGER;
  }

  @computed private get currentAccount() {
    const { store, tenant } = this.account as Account;
    return store ? store : tenant;
  }

  @computed private get isAccountStore(): boolean {
    return Boolean(this.account?.store);
  }

  renderLink() {
    const { classes } = this.props;
    return (
      <>
        {this.isAccountStore ? (
          <Link
            className={classes.linkIcon}
            component={RouterLink}
            to={paths.tenantDetails(this.currentAccount!.id).root()}>
            <Tooltip title="WORKSPACE">
              <OpenInNew color="primary" fontSize="small" />
            </Tooltip>
          </Link>
        ) : (
          <Link
            className={classes.linkIcon}
            component={RouterLink}
            to={paths.storeDetails(this.currentAccount!.id).root()}>
            <Tooltip title="STORE">
              <OpenInNew color="primary" fontSize="small" />
            </Tooltip>
          </Link>
        )}
      </>
    );
  }

  render() {
    if (!this.account) {
      return (
        <DP>
          <DP.Header>
            <DP.Loading items={1} />
          </DP.Header>
          <DP.Body>
            <DP.Loading items={4} />
          </DP.Body>
        </DP>
      );
    }
    return (
      <DP>
        <DP.Header>
          <DP.Title panel>{this.currentAccount.name}</DP.Title>
          <DP.Actions>
            { this.updating && <DP.LoadSpinner /> }
            { this.renderLink() }
          </DP.Actions>
        </DP.Header>
        <DP.Body>
          <Box display="flex">
            <Box width={'60%'}>
              <DP.Row>
                <DP.Row>
                  <DP.Value>{ getAccountCode(this.account) }</DP.Value>
                  <DP.Label>Account</DP.Label>
                </DP.Row>
                <Box>
                  <DP.Value>
                    { this.account.scope }
                  </DP.Value>
                  <DP.Label>Scope</DP.Label>
                </Box>
              </DP.Row>
            </Box>

            <Box>
              <DP.Row>
                <DP.Row>
                  {this.props.editable ? (
                    <DP.Switch
                      checked={this.account.isActive}
                      onChange={()=>{}}
                    />
                  ) : (
                    <ChipStatusTag label="YES" color={ChipStatusColors.GREEN} />
                  )}
                  <DP.Label>Visible</DP.Label>
                </DP.Row>
              </DP.Row>
            </Box>
          </Box>
        </DP.Body>
      </DP>
    );
  }
}

export default withStyles(styles)(UserAccountPanel);
