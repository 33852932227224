/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { action, observable, flow, makeObservable } from 'mobx';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Pencil } from 'mdi-material-ui';

import Api from 'api';
import { WithToastStore, WithUserStore, inject } from 'types/stores';
import validatorjs from 'validatorjs';

import DP from 'components/DashPanel';
import PasswordField from 'components/PasswordField';

import styles from './styles';
import Button from 'components/Button/Dialog/Button';
import Title from 'components/Title/Dialog/Title';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

// Define mobx-react-form fields:
const fields = [
  {
    name: 'currentPassword',
    label: 'Current Password',
    type: 'password',
    rules: 'required|string|min:8',
  },
  {
    name: 'newPassword',
    label: 'New Password',
    type: 'password',
    rules: 'required|string|min:8',
  },
  {
    name: 'newPasswordConfirm',
    label: 'Confirm Password',
    type: 'password',
    rules: 'required|string|same:newPassword|min:8',
  },
];

// Define and extend mobx-react-form DVR plugin:
const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator, form }: { validator: any; form: any }) => {
      /* Overwrite the specific validation errors */
      const customValidationMessages = {
        ...validator.getMessages('en'),
        same: 'Please input the same password again',
        required: 'This field is required',
        min: ':attribute must be at least :min characters long',
      };
      validator.setMessages('en', customValidationMessages);
    },
  }),
};

interface PasswordPanelProps extends WithStyles<typeof styles>, WithToastStore, WithUserStore {
  title?: string;
}
/**
 * Displays blank dots that imitate password masking.
 * On edit shows form dialog that takes newPassword and confirmPassword.
 * @param title Optional title, `Emails` by default
 */
@inject('toastStore', 'userStore')
@observer
class PasswordPanel extends React.Component<PasswordPanelProps> {
  static defaultProps = {
    title: 'Password',
  };
  constructor(props: PasswordPanelProps) {
    super(props);
    makeObservable(this);
    // Define mobx-react-form hooks
    this.hooks = {
      onSuccess: (form: any) => {
        const { currentPassword, newPassword } = form.values();
        this.updatePassword(currentPassword, newPassword);
      },
      onClear: (form: any) => {
        this.editing = false;
        form.clear();
      },
    };
    // Create mobx-react-form instance using 'field[]', 'plugins' and event 'hooks'
    this.form = new MobxReactForm({ fields }, { plugins, hooks: this.hooks });
  }

  private form: any;

  private hooks: any;

  @observable private editing = false;

  @observable private updating = false;

  /** Starts editing */
  @action.bound private edit() {
    this.editing = true;
  }

  @action.bound public updatePassword = flow(function* (
    this: PasswordPanel,
    currentPassword,
    newPassword,
  ) {
    try {
      this.updating = true;
      yield Api.core.setPassword(newPassword, currentPassword);
      this.form.clear();
      this.editing = false;
      this.props.toastStore!.push({
        type: 'success',
        message: `Your password was successfully updated`,
      });
    } catch (e: any) {
      const errorCode = e.response.status;
      errorCode === 400 && this.form.$('currentPassword').invalidate(e.response.data.message);
    } finally {
      this.updating = false;
    }
  });

  render() {
    const { title, classes } = this.props;
    return (
      <>
        <DP>
          <DP.Header>
            <DP.Title panel>{title}</DP.Title>
            <DP.Actions>
              <DP.IconButton primary onClick={this.edit} icon={Pencil} tooltip="Edit" />
            </DP.Actions>
          </DP.Header>
          <DP.Body>
            <DP.Row>
              <DP.Label>Password</DP.Label>
              <DP.Value>••••••••••••••</DP.Value>
            </DP.Row>
          </DP.Body>
        </DP>
        <Dialog
          className={classes.dialogContent}
          classes={{ paper: classes.dialog }}
          open={this.editing}
          onClose={this.form.onClear}>
          {this.editing && (
            <>
              <DialogTitle disableTypography className={classes.dialogTitle}>
                <Title>Change Password</Title>
                {this.updating && <DP.LoadSpinner />}
              </DialogTitle>
              <form onSubmit={this.form.onSubmit}>
                <DialogContent>
                  {Array.from(this.form.fields).map(([name, field]: any) => {
                    const validateField = (e: any) => {
                      e.preventDefault();
                      field.validate({ showErrors: true });
                    };
                    return (
                      <React.Fragment key={field.name}>
                        <Box key={field.name} mt={2}>
                          <PasswordField
                            {...field.bind()}
                            onBlur={validateField}
                            error={field.error}
                            required
                          />
                        </Box>
                      </React.Fragment>
                    );
                  })}
                  <p>{this.form.error}</p>
                  <DialogActions className={classes.dialogActions}>
                    <Button onClick={this.form.onClear} color="primary">
                      Cancel
                    </Button>
                    <Button type="submit" color="primary" variant="contained" autoFocus>
                      Change
                    </Button>
                  </DialogActions>
                </DialogContent>
              </form>
            </>
          )}
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(PasswordPanel);
