import React from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { object, string, number } from 'yup';
import { useFormik } from 'formik';
import GC from './GraphicContainer';
import { ReactComponent as CompanyImg } from './company.svg';
import { TStep } from './TenantOnBoard';
import { RouteComponentProps } from 'react-router-dom';
import { WithToastStore, inject } from 'types/stores';
import styles from './styles';
import { observer } from 'mobx-react';
import CompanyForm from 'components/JSForm/CompanyForm';
import SubmitButton from 'components/Button/SubmitButton';

interface ICompany {
  name: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  lat: string;
  long: string;
}

interface CompanyProps extends WithStyles<typeof styles>, RouteComponentProps, WithToastStore {
  nextRoute: () => string;
  onSubmit: (type: TStep, payload: unknown) => void;
  noPaper?: boolean;
  value?: ICompany;
}

const Company = ({ value, noPaper, classes, onSubmit, nextRoute, ...props }: CompanyProps) => {
  const formik = useFormik({
    initialValues: value
      ? value
      : {
          name: '',
          address: '',
          address2: '',
          city: '',
          state: '',
          country: '',
          zip: '',
          lat: '',
          long: '',
        },
    validationSchema: object({
      name: string().required(),
      address: string().required(),
      address2: string().optional(),
      city: string().required(),
      state: string().required(),
      zip: number().required(),
      lat: string().optional(),
      long: string().optional(),
    }),
    onSubmit: (values: unknown) => {
      onSubmit('company', values);
      props.history.push(nextRoute());
    },
  });

  const Wrap = noPaper ? React.Fragment : Paper;
  return (
    <Wrap elevation={0}>
      <Box minHeight={590} pt={7} pb={6.25} pr={4} pl={8.125}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <GC>
              <GC.Title>{`Company Info`}</GC.Title>
              <GC.Subtitle>Give us information about your company</GC.Subtitle>
              <GC.SvgImage className={classes.imageHidden}>
                <CompanyImg />
              </GC.SvgImage>
            </GC>
          </Grid>
          <Grid item xs={12} md={9} lg={6}>
            <form onSubmit={formik.handleSubmit} className={classes.accountStepForm}>
              <CompanyForm formik={formik} />
              <SubmitButton
                style={{ marginTop: 70 }}
                className={classes.nextStepButton}
                disabled={!formik.isValid}
                inProgress={formik.isSubmitting}>
                Next
              </SubmitButton>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Wrap>
  );
};

export default withStyles(styles)(inject('toastStore')(observer(Company)));
