import { Box, IconButton, Paper, Tooltip, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import Api, { getErrorMsg } from 'api';
import { Cached } from 'mdi-material-ui';
import { action, computed, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { inject, WithModalStore, WithToastStore } from 'types/stores';
import styles from './styles';

interface CodePanelProps extends WithStyles<typeof styles>, WithToastStore, WithModalStore {
  title: string;
  canRegenerateCode?: boolean;
  code: string;
  onRegenerateCode?: () => void;
}

@inject('toastStore', 'modalStore')
@observer
class CodePanel extends React.Component<CodePanelProps> {
  public constructor(props: CodePanelProps) {
    super(props);
    makeObservable(this);
  }

  /** code */
  @computed public get code() {
    return this.props.code;
  }

  /** Can this code be updated */
  @computed public get canRegenerateCode() {
    return this.props.canRegenerateCode;
  }

  render() {
    return (
      <Paper elevation={0}>
        <Box
          p={2}
          pb={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          style={{ minHeight: '181px', minWidth: '194px' }}>
          {this.canRegenerateCode && (
            <Box display="flex" alignSelf={'flex-end'}>
              <Tooltip placement="right-end" title="Generate new account code">
                <IconButton
                  size="small"
                  onClick={() => this.props.onRegenerateCode && this.props.onRegenerateCode()}>
                  <Cached color="primary" fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          <Box display="flex" flexGrow={1} alignItems="flex-end">
            <Typography variant="h6" color="primary">
              {this.code.toUpperCase()}
            </Typography>
          </Box>
          <Box display="flex" flexGrow={1} alignItems="flex-end">
            <Typography color="textSecondary">{this.props.title}</Typography>
          </Box>
        </Box>
      </Paper>
    );
  }
}

export default withStyles(styles)(CodePanel);
