import React from 'react';
import { observer } from 'mobx-react';
import { Typography, Box } from '@material-ui/core';
import useStyles from './styles';
import Skeleton from '@material-ui/lab/Skeleton';
import ImageIcon from 'components/ImageIcon';

interface TitleProps {
  children?: React.ReactNode;
  src?: string;
  showLogo?: boolean;
  count?: number | string;
  subtitle?: number | string;
  size?: 'small' | 'medium' | 'large';
  light?: boolean;
  mb?: number;
  panel?: boolean;
  drawer?: boolean;

}

/**
 * Display a large or small title, with an optional logo prepended.
 */
const Title = observer(
  ({ children, src, count, subtitle, showLogo, size = 'large', light, mb, panel, drawer }: TitleProps) => {
    const styles = useStyles();
    const variant = size === 'large' ? 'h4' : size === 'medium' ? 'h5' : 'h6';

    const marginBottom = size === 'large' ? 4 : 0;
    const countEl = count !== undefined && <span className={styles.count}>({count})</span>;
    const subtitleEl = subtitle !== undefined && (
      <span className={styles.subtitle}>{subtitle}</span>
    );

    const iconSrc = children ? src : undefined;

    return (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        mb={mb || mb === 0 ? mb : marginBottom}>
        {showLogo && (
          <Box mr={2}>
            <ImageIcon src={iconSrc} type="logo" />
          </Box>
        )}
        <Typography
          variant={variant}
          component="h2"
          className={light ? styles.titleLight : panel ? styles.titlePanel : drawer ? styles.subTitleDrawer:  styles.titleDark}>
          {children ? (
            <>
              {children} {subtitleEl} {countEl}
            </>
          ) : (
            <Skeleton className={styles.skeleton} width={320} variant="text" height={32} />
          )}
        </Typography>
      </Box>
    );
  },
);

export default Title;
