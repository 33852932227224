import { Theme, createStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles/colorManipulator';

export default (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'transparent',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
        padding: theme.spacing(2),
      },
    },
    container: {
      maxWidth: theme.spacing(66),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    insideBox: {
      position: 'relative',
      paddingLeft: theme.spacing(9),
      paddingRight: theme.spacing(9),
      paddingBottom: theme.spacing(6),
      paddingTop: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(4),
        borderRadius: `0 0 0 0`,
      },
    },
    loader: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: alpha(theme.palette.common.white, 0.5),
      borderRadius: theme.shape.borderRadius,
      zIndex: 200,
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
    },
    pig: {
      height: theme.spacing(12),
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(2),
        height: theme.spacing(8),
      },
    },
    footer: {
      marginTop: 'auto',
      color: theme.palette.common.white,
      letterSpacing: '.5px',
    },
    miniText: {
      fontSize: 12,
    },
  });
