import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { ACL } from 'types';
import { Role, Scope } from 'models';

export enum MenuGroup {
  MANAGE = 'Manage',
  PRODUCTS = 'Products',
  TOOLS = 'Tools',
}

export enum MenuItemType {
  GROUP = 'group',
  PAGE = 'page',
}

export interface IMenuItem {
  type: MenuItemType.PAGE;
  resources?: Array<ACL>;
  scopes?: Array<Role|Scope>;
  hide?: boolean;
  icon: IconDefinition;
  to: string;
  paths?: Array<string>; // paths for which the menu item should stay active
  label: string;
}

export interface IMenuGroup {
  type: MenuItemType.GROUP;
  hide?: boolean;
  resources?: Array<ACL>;
  scopes?: Array<Role|Scope>;
  label: MenuGroup;
  icon: IconDefinition;
  items: Array<IMenuItem>;
}

export type MenuItems = Array<IMenuItem | IMenuGroup>;
