import BaseStore from './BaseStore';
import { observable, action, makeObservable, computed } from 'mobx';
import RootStore from './RootStore';
import moment from 'moment';
import * as LocalStorage from 'services/localStorage';
import { ACL } from 'types';

const STORE_NAME = 'notificationStore';
const ITEM_NAME = 'item';
const KEY = `${STORE_NAME}/${ITEM_NAME}-`;

interface IPendingNotification {
  remindOn: string;
}

export default class NotificationStore extends BaseStore {
  constructor(props: RootStore) {
    super(props);
    makeObservable(this);
  }
  @observable public openNotification = false;
  @observable public login = false;
  @observable public forceClose = false;

  @computed get openNotificationsDialog() {
    let open = false;
    if (!this.numberOfNotifications) {
      open = false;
    } else if (this.openNotification) {
      open = true;
    } else if (this.login && this.showPendingNotification() && !this.forceClose) {
      open = true;
    }
    this.setOpenNotification(open);
    return open;
  }

  @computed get numberOfNotifications() {
    let number = 0;
    return number;
  }



  @action.bound showPendingNotification() {
    if (this.rootStore.userStore!.isAdmin) {
        return false;
      }
    
    // check pending notifications for owners
    const pendingNotification = this.checkForPendingNotification();
    if (!pendingNotification) return true;
    if (moment(pendingNotification.remindOn).isBefore(new Date())) {
      return true;
    }
    return false;
  }

  @action.bound setOpenNotification(open: boolean) {
    this.openNotification = open;
  }

  @action.bound setForceClose(close: boolean) {
    this.forceClose = close;
  }


  @action.bound async onLogin() {
    this.login = true;
  }

  @action.bound async onLogout() {
    this.login = false;
    this.forceClose = false;
    this.openNotification = false;
  }

  @action.bound notifyLater() {
    this.removeNotificationReminderFromLocalstorage();
    this.createPendingNotification();
    this.resetFlags();
  }

  checkForPendingNotification(): IPendingNotification | undefined {
    const userId = this.rootStore.userStore.user!.id;
    const pendingNotifications = LocalStorage.get(`${KEY}${userId}`);
    if (!pendingNotifications) return;
    return pendingNotifications as IPendingNotification;
  }

  private createPendingNotification() {
    const date = this.getFutureDate();
    const remindOn = { remindOn: date };

    this.addNotificationReminderToLocalstorage(remindOn);
  }

  private addNotificationReminderToLocalstorage(remindOn: { remindOn: string }) {
    const userId = this.rootStore.userStore.user!.id;
    LocalStorage.set(`${KEY}${userId}`, remindOn);
  }

  private removeNotificationReminderFromLocalstorage() {
    const userId = this.rootStore.userStore.user!.id;
    LocalStorage.remove(`${KEY}${userId}`);
  }

  private getFutureDate() {
    return moment(new Date(), 'DD-MM-YYYY')
      .add(+10, 'days')
      .toISOString();
  }

  private resetFlags() {
    this.login = false;
    this.setOpenNotification(false);
  }

  async initializeNotificationSystem() {
  }

  public async init() {
    this.resetFlags();
  }
}

export interface WithNotificationStore {
  notificationStore?: NotificationStore;
}
