/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observable, action, flow, makeObservable } from 'mobx';
import validatorjs from 'validatorjs';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  FormControl,
  InputAdornment,
  IconButton,
  FormHelperText,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment'; // Material-ui date-picker dependency
import { CakeVariant, PhoneClassic, MapMarker, ChevronLeft } from 'mdi-material-ui';
import moment from 'moment';

import { paths } from 'routes';
import { inject, WithToastStore, WithUserStore } from 'types/stores';
import { User } from 'models';
import Api, { getErrorMsg } from 'api';
import styles from './styles';
import CarouselScreenWrapper from 'components/CarouselScreenWrapper/CarouselScreenWrapper';
import Button from 'components/Button/Button';
import OutlinedInput from 'components/Input/OutlinedInput';
import OutlinedDatePicker from 'components/Input/OutlinedDatePicker';
import MaskedInput from 'react-text-mask';
import { Address } from 'types';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

type PersonalInfo2Props = WithStyles<typeof styles> &
  RouteComponentProps &
  WithToastStore &
  WithUserStore;

const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }: { validator: any; form: any }) => {
      const usPhoneRegex =
        /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/;
      const phoneFieldRule = {
        function: (value: any) => new RegExp(usPhoneRegex).test(value),
        message: 'The phone number is not a valid format.',
      };
      validator.register('phone', phoneFieldRule.function, phoneFieldRule.message);

      validator.register(
        'zip',
        (value: string) => new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/).test(value),
        'Please input a valid zip code',
      );
      validator.setMessages('en', {
        ...validator.getMessages('en'),
        phone: 'Please input a valid phone',
        dob: 'Please input a valid last name',
      });
    },
  }),
};

interface PersonalInfoHooks {
  onSuccess: (form: any) => void;
  onClear: (form: any) => void;
}

/**
 * Displays the prompt to enter additional user data
 */
@inject('toastStore', 'userStore')
@observer
class PersonalInfo2 extends React.Component<PersonalInfo2Props> {
  /** Whether the info is currently being submitted */
  @observable public submitting = false;

  /** The action to submit the current personal info to the server */
  @action.bound public submit = flow(function* (this: PersonalInfo2) {
    const userStore = this.props.userStore!;
    try {
      this.submitting = true;
      const resp = yield Api.core.updateUser(userStore.user!.id, {
        // If the user is an affiliate, dob will be "", so we pass undefined
        dob: this.form.$('dob').value || undefined,
        phone: this.form.$('phone').value,
        address: { zip: this.form.$('zip').value } as Address
      });
      const user: User = resp && resp.data && resp.data;
      userStore.setUserData(user);

      const locationState: any = this.props.history.location.state;
      // If the user is an affiliate, go to the organization step
      
      // Else if the user is being onboarded as manager, go to profile picture
        this.props.history.push({
          pathname: paths.signUp().profilePicture(),
          state: locationState,
        });

    } catch (error: any) {
      this.props.toastStore!.push({
        type: 'error',
        message: getErrorMsg(error) || 'An error has occurred',
      });
    } finally {
      this.submitting = false;
    }
  });

  public constructor(props: PersonalInfo2Props) {
    super(props);
    makeObservable(this);
    const user = props.userStore!.user!;
    const minDate = moment();
    const dobRules = 'required|date';
    minDate.subtract(16, 'years');
    const fields = [
      {
        name: 'phone',
        label: 'Phone',
        type: 'tel',
        rules: ['string', 'phone', 'required'],
        value: user.phone || '',
      },
      {
        name: 'dob',
        label: 'Date of Birth',
        value: user.dob ? moment(user.dob).format('YYYY-MM-DD') : '',
        rules: dobRules,
      },
      {
        name: 'zip',
        label: 'Zip',
        value: user?.address?.zip || '',
        rules: ['zip', 'required'],
      },
    ];
    this.form = new MobxReactForm({ fields }, { plugins, hooks: this.hooks });
  }

  public renderMaskedPhoneInput(props: any) {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => inputRef(ref ? ref.inputElement : null)}
        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        hideMask
      />
    );
  }

  /** The form object */
  private form: any;

  /** The hooks for the form */
  private hooks: PersonalInfoHooks = {
    onSuccess: () => {
      this.submit();
    },
    onClear: (form: any) => {
      form.clear();
    },
  };
  render() {
    const { classes } = this.props;
    const fields = {
      phone: this.form.$('phone'),
      dob: this.form.$('dob'),
      zip: this.form.$('zip'),
    };
    const maxDate = moment();
    maxDate.subtract(16, 'years');
    return (
      <CarouselScreenWrapper submitting={this.submitting}>
        <Box className={classes.root}>
          <form onSubmit={this.form.onSubmit}>
            <Typography
              className={classes.title}
              variant="h4"
              component="h1"
              align="center"
              gutterBottom>
              Personal Info
            </Typography>
            <Box mt={3.125}>
              <Typography variant="body2" align="center" gutterBottom>
                {`Almost there, just a few more things!`}
              </Typography>
            </Box>
            <Box mt={2}>
              <OutlinedInput
                {...fields.phone.bind()}
                error={fields.phone.error}
                fullWidth
                autoFocus
                InputProps={{
                  inputComponent: this.renderMaskedPhoneInput,
                  endAdornment: (
                    <InputAdornment position="end">
                      <PhoneClassic fontSize="small" color="action" />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
              <Box mt={2}>
                <FormControl fullWidth>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <OutlinedDatePicker
                      variant="dialog"
                      value={fields.dob.value === '' ? null : fields.dob.value}
                      clearable
                      format="MM/DD/YYYY"
                      openTo="year"
                      views={['year', 'month', 'date']}
                      label={fields.dob.label}
                      maxDate={maxDate}
                      initialFocusedDate={maxDate}
                      disableFuture
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CakeVariant fontSize="small" color="action" />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(date) => date && fields.dob.set(moment(date).format('YYYY-MM-DD'))}
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                  <FormHelperText error={true}>{fields.dob.error}</FormHelperText>
                </FormControl>
              </Box>
            <Box mt={2}>
              <OutlinedInput
                {...fields.zip.bind()}
                error={fields.zip.error}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MapMarker fontSize="small" color="action" />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box mt={5}>
              <Button
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={this.submitting}>
                Continue
              </Button>
            </Box>
          </form>
          <Box mt={2} display={'flex'} alignContent={'center'}>
            <IconButton className={classes.leftButton} onClick={() => this.props.history.goBack()}>
              <ChevronLeft /> Back to previous step
            </IconButton>
          </Box>
        </Box>
      </CarouselScreenWrapper>
    );
  }
}

export default withStyles(styles)(PersonalInfo2);
