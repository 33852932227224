/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Box, Fade } from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react';

import useStyles from './styles';

const Overlay = observer(
  ({
    transparent,
    display,
    children,
  }: {
    display: boolean;
    transparent?: boolean;
    children?: React.ReactNode;
  }) => {
    const classes = useStyles();
    return (
      <Fade in={display}>
        <Box className={clsx(classes.root, transparent && classes.lighten)}>{children}</Box>
      </Fade>
    );
  },
);

export default Overlay;
