import { Theme, createStyles, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    resize: {
      fontSize: 12,
    },
  });

export default styles;
export const useStyles = makeStyles(styles);
