import React, { Fragment } from 'react';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { observable, action, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Link, Typography } from '@material-ui/core';

import { paths } from 'routes';
import Api, { getErrorMsg } from 'api';

import styles from './styles';
import CarouselScreenWrapper from 'components/CarouselScreenWrapper/CarouselScreenWrapper';
import { ChevronLeft } from 'mdi-material-ui';
import Button from 'components/Button/Button';
import OutlinedInput from 'components/Input/OutlinedInput';
import { has } from 'lodash';
import { WithToastStore, WithUserStore, inject } from 'types/stores';

type ForgotPasswordProps = WithStyles<typeof styles> & RouteComponentProps & WithToastStore & WithUserStore;

/**
 * The login screen container component.
 */

@inject('userStore', 'toastStore')
@observer
class ForgotPassword extends React.Component<ForgotPasswordProps> {
  constructor(props: ForgotPasswordProps) {
    super(props);
    makeObservable(this);
  }
  @observable public routeState = this.props.location.state as {email: string}
  @observable public routeParams = this.props.match.params as {userId: string, code:string}
  

  /** The email input value */
  @observable public email = this.routeState?.email ?? '';

  /** Whether the forgot password API call is in progress */
  @observable public inProgress = false;

  /** Whether the passowrd API call is done */
  @observable public done = false;

  /** The current error */
  @observable public error = '';

  /**
   * Handles the email change from an input component
   * @param e The onChange event
   */
  @action.bound public handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.email = e.target.value;
  }

  /**
   * Handles the main form submission.
   * @param e The form submitted event
   */
  @action.bound public handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.postForgotPassword();
  }

  @action.bound public postForgotPassword = flow(function* (this: ForgotPassword) {
    try {
      this.error = '';
      this.inProgress = true;
      yield Api.core.sendEmailResetPassword(this.email);
      this.done = true;
    } catch (err: any) {
      const errMsg = err.response ? err.response.data.message : 'An error has occurred.';
      this.error = errMsg;
    } finally {
      this.inProgress = false;
    }
  });

  @action.bound public verifyCodeResetPassword = flow(function* (this: ForgotPassword) {
    try {
      const resp = yield Api.core.loginTOTP({userId: this.routeParams!.userId, code: this.routeParams!.code});
      this.props.userStore!.loginWithData(resp.data);
      this.props.history.replace(paths.resetPassword());
    } catch (e) {
      this.props.toastStore!.error(getErrorMsg(e as Error));
    }
  });


  componentDidMount(): void {
      if(has(this.props.match.params, 'userId')){
        this.verifyCodeResetPassword();
      }
  }

  renderForgotPasswordView() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Typography variant="h3" component="h1" gutterBottom align="center">
          Reset Password
        </Typography>
        <Box pt={14.125} mb={4}>
          <Typography className={classes.greyText} align="center">
            Enter your email address and we&apos;ll send you a link to reset your password.
          </Typography>
        </Box>
        <form onSubmit={this.handleSubmit}>
          <OutlinedInput
            label={'Email'}
            autoFocus
            required
            error={this.error.length > 0}
            type="email"
            id="email-input"
            value={this.email}
            onChange={this.handleEmailChange}
            fullWidth
          />
          <Box mt={9} mb={2}>
            <Button
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              disabled={this.inProgress}
              fullWidth>
              Send me a link
            </Button>
          </Box>
        </form>
        <Typography align="center" component="div">
          <Link component={RouterLink} to={paths.signIn()} className={classes.backLink}>
            <ChevronLeft /> Back to sign in
          </Link>
        </Typography>
      </Fragment>
    );
  }

  renderSuccessView() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Typography
          className={classes.title}
          variant="h3"
          component="h1"
          gutterBottom
          align="center">
          Check your email
        </Typography>

        <Box mb={4} mt={14.125}>
          <Typography className={classes.greyText} align="center">
            An email has been sent to {this.email} with a link to reset your password.
          </Typography>
        </Box>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          component={RouterLink}
          to={paths.signIn()}>
          Sign in
        </Button>
      </Fragment>
    );
  }

  render() {
    return (
      <CarouselScreenWrapper>
        {this.done ? this.renderSuccessView() : this.renderForgotPasswordView()}
      </CarouselScreenWrapper>
    );
  }
}

export default withStyles(styles)(ForgotPassword);
