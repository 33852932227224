import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface IStyleProps {
  url: string;
}

const useStyles = makeStyles<Theme, IStyleProps>(() =>
  createStyles({
    coverImage: {
      height: '100%',
      backgroundImage: ({ url }) => `url(${url})`,
      backgroundSize: `cover`,
      backgroundPosition: `center`,

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      paddingBottom: 56,
    },
  }),
);

export default useStyles;
