import { alpha, createTheme, Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/styles';

const defaultTheme = createTheme();

export const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      box: {
        maxHeight: '475px',
        width: '100%',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }
    }),
  { defaultTheme },
);
