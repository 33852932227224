import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(3),
      right: theme.spacing(2),
    },
    root: {},
    dialog: {
      '& .MuiDialogActions-root':{
        padding: 24
      },
      '& .MuiDialogTitle-root':{
        '& .MuiTypography-root':{
          fontFamily: 'EuclidCircularB',
          fontSize: theme.typography.h5.fontSize,
          fontWeight: '400',
          lineHeight: '35.5px',
          letterSpacing: '0.25px',
        },
        paddingTop: '24px',
        paddingLeft: '24px',
      },
      '& .MuiDialogContent-root': {
        width: 576,
      },
      '& .MuiTypography-root-MuiDialogTitle-root': {
        paddingTop: '24px',
        paddingLeft: '24px',
        fontFamily: 'EuclidCircularB',
        fontSize: theme.typography.h5.fontSize,
        fontWeight: '400',
        lineHeight: '35.5px',
        letterSpacing: '0.25px',
      },
    },
    dialogButton: {
      borderRadius: '8px',
      textTransform: 'capitalize',
    },
  });
