import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    dragbox: {
      display: 'flex',
      // height: '167px',
      borderRadius: theme.shape.borderRadius,
      border: '2px dashed',
      borderColor: theme.palette.common.black,
      minHeight: '147px',
      padding: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    dragboxNotBorder: {
      display: 'flex',
      // height: '167px',
      minHeight: '147px',
      padding: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    containerFullWidth: {
      width: '528px',
    },
    containerCenter: {
      justifyContent: 'center',
    },
    containerUploadAvatar: {
      maxWidth: '528px'
    },
    avatar: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      '& .MuiAvatar-root': {
        width: 147,
        height: 147,
      },
      '& .MuiAvatar-colorDefault': {
        backgroundColor: '#E8E8E8',
      },
    },
    avatarFullSize: {
      '& .MuiAvatar-root': {
        width: '100%',
        height: '100%',
      },
    },
    avatarHover: {
      borderRadius: theme.shape.borderRadius,
      border: '3px dashed',
      borderColor: '#A2DCC8',
    },
    typography: {
      textTransform: 'none',
      fontSize: theme.typography.fontSize,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 400,
      alignContent: 'justify',
      // letterSpacing: '0.75px',
    },
    instructionText: {
      textAlign: 'center',
      color: `rgba(0, 0, 0, 0.38)`,
      lineHeight: '24px',
    },
    button: {
      width: '195px',
      // marginTop: '20px',
      position: 'relative',
    },
    dialogButton: {
      borderRadius: '8px',
      textTransform: 'capitalize',
    },
    menuButton: {
      width: '195px',
      cursor: 'pointer',
      position: 'relative',
      '& .MuiIcon-root': {
        position: 'absolute',
        right: 10,
        top: 7,
      },
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        marginRight: '10px',
      },
    },
    uploadButton: {
      width: '195px',
      marginTop: '20px',
      position: 'relative',
      '& .MuiSvgIcon-root': {
        position: 'absolute',
        left: 10,
        top: 7,
      },
    },
    icon: {
      color: `rgba(0, 0, 0, 0.12)`,
      width: '65px',
      height: '65px',
    },
    dialog: {
      '& .css-hlj6pa-MuiDialogActions-root': {
        padding: 24,
      },
      '& .css-1hju3x6-MuiPaper-root-MuiDialog-paper': {
        borderRadius: theme.shape.borderRadius,
      },
      '& .MuiDialogContent-root': {
      },
      '& .css-bdhsul-MuiTypography-root-MuiDialogTitle-root': {
        paddingTop: '24px',
        paddingLeft: '24px',
        paddingBottom: '32px',
        fontFamily: 'EuclidCircularB',
        fontSize: theme.typography.h5.fontSize,
        fontWeight: '400',
        lineHeight: '35.5px',
        letterSpacing: '0.25px',
      },
    },
    dialogActions: {
      paddingBottom: theme.spacing(3),
    },

    cameraIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      border: '2px solid',
      color: theme.palette.primary.main,
      width: '70px',
      height: '70px',
      '& .MuiSvgIcon-root': {
        fontSize: '46px',
        color: theme.palette.secondary.main,
      },
    },
    actionContainer: {
      '& .MuiGrid-item': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    actionItem: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    borderImage: {
      borderImage: 'url(https://i.stack.imgur.com/wLdVc.png)',
      borderImageWidth: '1px',
      borderImageSlice: 1,
      borderImageRepeat: 'round',
    },
    buttonAction: {
      '&.MuiButton-root': {
        padding: '6px 13px',
        height: 'auto',
        borderRadius: '8px',
        textTransform: 'none',
      },
    },
    buttonText: {
      fontWeight: 500,
      fontSize: 14,
      textTransform: 'none',
    },
    containerAction: {
      display: 'flex',
      gap: '0.5rem',
      paddingRight: '16px',
    },
    textLogo: {
      fontSize: 28,
      fontWeight: 400,
    },
    textUnderline: {
      textDecoration: 'underline',
    },
  });