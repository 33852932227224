import { Avatar, Box, Typography } from '@material-ui/core';
import React from 'react';
import useStyles, { useUserDetailsStyles } from './styles';
import { observer } from 'mobx-react';
import useUserProfile from './useUserProfile';

export interface IUserProfileProps {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}
const UserProfile = ({ onClick }: IUserProfileProps) => {
  const { wrapper, avatar } = useStyles({ clickable: Boolean(onClick) });
  const { userDetailsProps, avatarProps, handleOnClick } = useUserProfile({ onClick });

  return (
    <Box className={wrapper} onClick={handleOnClick}>
      <Avatar className={avatar} {...avatarProps} />
      <UserDetails {...userDetailsProps} />
    </Box>
  );
};

interface IUserDetails {
  fullName: string;
  email: string;
  menuDrawerOpen: boolean;
}
function UserDetails(props: IUserDetails) {
  const { emailText, fullNameText, wrapper } = useUserDetailsStyles();
  let { fullName, email, menuDrawerOpen } = props;
  if (!menuDrawerOpen) {
    return <></>;
  }

  return (
    <Box className={wrapper}>
      <Typography className={fullNameText}>{fullName}</Typography>
      <Typography className={emailText}>{email}</Typography>
    </Box>
  );
}

export default observer(UserProfile);
