import { Box } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

// api import for tips report email and download for QA testing:

import { WithModalStore, WithSettingStore, WithToastStore, WithUserStore, inject } from 'types/stores';

import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import styles from './styles';

import FilterBar from 'components/FilterBar';
import Title from 'components/Title';
import { RouteComponentProps } from 'react-router-dom';

interface DashboardHomeProps
  extends WithStyles<typeof styles>,
    WithUserStore,
    WithModalStore,
    WithToastStore,
    WithSettingStore,
    RouteComponentProps {}

/**
 * The dashboard home page, displayed when you log in.
 */
@inject('userStore', 'modalStore', 'toastStore', 'settingStore')
@observer
class AdminHome extends React.Component<DashboardHomeProps> {
  constructor(props: DashboardHomeProps) {
    super(props);
    makeObservable(this);
  }

  /** The selected date range */
  @observable public dateRange: DateRangeExternalPicker.DateRange =
    this.props.settingStore!.getDate(this.props.location.pathname);

  /** Sets the date range */
  @action.bound private onDateRangeChange(range: DateRangeExternalPicker.DateRange) {
    this.props.settingStore!.setDate(this.props.location.pathname, range);
    this.dateRange = range;
  }

  render() {
    return (
      <>
        <Title mb={3}>Dashboard</Title>
        <Box display="flex" justifyContent="flex-end">
          <FilterBar
            filters={[]}
            onChange={(filters: Record<string, unknown>) => {}}
            actions={{
              dateRange: {
                predefined: this.dateRange,
                onChange: this.onDateRangeChange,
              },
            }}
          />
        </Box>
        <Box>{'My Dashborad'}</Box>
      </>
    );
  }
}

export default withStyles(styles)(AdminHome);
