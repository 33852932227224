import React from 'react';
import { GoogleOAuthProvider, useGoogleLogin, googleLogout } from '@react-oauth/google';
import Api from 'api';
import { UserSession, WithToastStore, WithUserStore, inject } from 'types/stores';
import { observer } from 'mobx-react';

const GoogleButton = ({ onClick }: { onClick: ()=> void }) => {
  return (
    <div
      id="container"
      onClick={onClick}
      style={{
        display: 'inline-block',
        padding: '2px 10px',
      }}>
      <div
        role="button"
        style={{
          borderRadius: '4px',
          boxSizing: 'border-box',
          transition: 'background-color .218s, border-color .218s',
          backgroundColor: '#fff',
          backgroundImage: 'none',
          border: '1px solid #dadce0',
          color: '#3c4043',
          cursor: 'pointer',
          fontSize: '14px',
          height: '40px',
          letterSpacing: '0.25px',
          outline: 'none',
          overflow: 'hidden',
          padding: '0 12px',
          position: 'relative',
          textAlign: 'center',
          verticalAlign: 'middle',
          whiteSpace: 'nowrap',
          width: 'auto',
        }}>
        <div
          style={{
            transition: 'background-color .218s',
            bottom: 0,
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
          }}></div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
            height: '100%',
            position: 'relative',
            width: '100%',
          }}>
          <div
            style={{
              height: '18px',
              marginRight: '8px',
              minWidth: '18px',
              width: '18px',
            }}>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              style={{
                display: 'block',
              }}>
              <g>
                <path
                  fill="#EA4335"
                  d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                <path
                  fill="#4285F4"
                  d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                <path
                  fill="#FBBC05"
                  d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                <path
                  fill="#34A853"
                  d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                <path fill="none" d="M0 0h48v48H0z"></path>
              </g>
            </svg>
          </div>
          <span
            style={{
              flexGrow: 1,
              fontWeight: 500,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              verticalAlign: 'top',
            }}>
            Sign in with Google
          </span>
          <span
            style={{
              display: 'none',
            }}
            id="button-label">
            Sign in with Google
          </span>
        </div>
      </div>
    </div>
  );
};

interface GoogleLoginProps {
  onSuccess: (data: UserSession) => Promise<void>;
  onError: (error: Error) => void;
}
const GoogleLogin = ({ onSuccess, onError }: GoogleLoginProps) => {
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const resp = await Api.core.loginGoogle(tokenResponse.access_token);
        onSuccess(resp.data);
      } catch (error) {
        onError(error as Error);
      } finally {
        googleLogout();
      }
    },
    onError: () => {
      onError(new Error('Login failed'));
    },
  });
  return <GoogleButton onClick={() => login()} />;
};

interface GoogleAuthProps extends WithToastStore, WithUserStore {}

const GoogleAuth = ({ userStore, toastStore }: GoogleAuthProps) => {
  return (
    <GoogleOAuthProvider
      clientId={'796177832340-bqtshb5tsm29punjts91hfo7er9hm6mb.apps.googleusercontent.com'}>
      <GoogleLogin
        onSuccess={async (data) => {
          await userStore!.loginWithData(data);
        }}
        onError={(error) => {
          toastStore?.push({ type: 'error', message: (error as Error).message });
        }}
      />
    </GoogleOAuthProvider>
  );
};

export default inject('userStore', 'toastStore')(observer(GoogleAuth));
