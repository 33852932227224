import { WithStyles, withStyles } from '@material-ui/core/styles';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { WithSettingStore, WithToastStore, WithUserStore, inject } from 'types/stores';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import DashboardLayout from 'containers/DashboardLayout';
import styles from './styles';
import theme from 'containers/App/theme';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import { DeleteOutline, Eye, FormatListBulleted, Pencil, SourceMerge } from 'mdi-material-ui';
import FilterBar from 'components/FilterBar';
import { Filter } from 'components/FilterBar/FilterBar';
import Title from 'components/Title/Title';
import DataGridInfiniteScroll, { GridColumns } from 'components/DataGridInfiniteScroll';
import { paths } from 'routes';
import PlusFabButton from 'components/PlusFabButton/PlusFabButton';
import TreeViewPanel from 'components/TreeViewPanel';
import { fetchWrapper } from 'services';
import Api, { RequestMetaData } from 'api';
import { Category } from 'models';
import { AxiosError } from 'axios';
import CategoryDrawer, { Action } from './CategoryDetailsDrawer';

const PAGE_TITLE = 'Categories';

export interface FilterItem {
  label: string;
  value: any;
}

type CategoriesProps = WithStyles<typeof styles> & // Adds the classes prop
  RouteComponentProps & // Adds the router props (history, match, location)
  WithUserStore & // Adds the userStore prop
  WithToastStore &
  WithSettingStore;

export interface ViewItem {
  id: string;
  name: string;
  parent: string;
  isParent?: boolean;
  subcategories?: ViewItem[];
}

/**
 * Container displaying active kiosk alert message if present and list of past alerts.
 */
@inject('userStore', 'toastStore', 'settingStore')
@observer
class Categories extends React.Component<CategoriesProps> {
  public constructor(props: CategoriesProps) {
    super(props);
    makeObservable(this);
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private activeTree = false;
  @observable private openDrawer = false;
  @observable private actionDrawer: Action = 'preview';
  @observable private categorySelected: Category | undefined = undefined;

  @action.bound private handlerOpenDrawer = (category: Category, action: Action) => {
    this.actionDrawer = action;
    this.categorySelected = category;
    this.openDrawer = true;
  };

  @action.bound private handlerCloseDrawer = () => {
    this.categorySelected = undefined;
    this.openDrawer = false;
  };

  public getAllCategories = fetchWrapper(
    async (rmd: RequestMetaData) => {
      const order: 'ASC' | 'DESC' = 'DESC';

      return await Api.product.getAllCategories({
        ...rmd,
        filters: {
          ...this.activeFilters,
        },
        sort: { sortBy: 'createdAt', sortOrder: order },
      });
    },

    (category: Category) => ({
      ...category,
      ...(!category.description && { description: category.name }),
    }),
  );

  private getCategoriesByName = async (query?: string) => {
    const { data } = await Api.product.getAllCategories({}, { name: query });
    return data.data.map((category: { id: string; name: string; [key: string]: string }) => {
      return { id: category.id, name: category.name };
    });
  };

  private handleClickDeleteRow = async (row: Category) => {
    try {
      const { data } = await Api.product.deleteCategory(row.id);
      this.props.toastStore?.push({ message: data.message });
    } catch (err) {
      console.log(JSON.stringify(err, null, 2));
      this.props.toastStore?.push({ message: (err as AxiosError).name });
    } finally {
      this.activeFilters = { ...this.activeFilters };
    }
  };

  renderCellActions = ({ row }: any) => {
    return (
      <>
        <IconButton color="primary" onClick={() => this.handlerOpenDrawer(row, 'edit')}>
          <Tooltip title="Edit">
            <Pencil fontSize="small" />
          </Tooltip>
        </IconButton>
        <IconButton color="primary" onClick={() => this.handlerOpenDrawer(row, 'preview')}>
          <Tooltip title="Preview">
            <Eye fontSize="small" />
          </Tooltip>
        </IconButton>
        <IconButton onClick={() => this.handleClickDeleteRow(row)}>
          <Tooltip title="Delete row">
            <DeleteOutline style={{ color: theme.menu.main }} fontSize="small" />
          </Tooltip>
        </IconButton>
      </>
    );
  };

  filters: Filter[] = [
    {
      display: 'Name',
      id: 'name',
      label: 'Contains',
      type: 'tags',
      options: {
        multipleChoice: false,
        fetch: this.getCategoriesByName,
        displayField: {
          value: 'name',
          keySearch: { name: 'name' },
        },
      },
    },
  ];

  gridColumns: GridColumns = [
    { headerName: 'Name', field: 'name', flex: 1 },
    { headerName: 'Description', field: 'description', minWidth: 350 },
    { headerName: 'Tenant', field: 'tenantId', flex: 1 },
    { headerName: 'Status', field: 'status', flex: 1 },
    {
      headerName: 'Actions',
      headerAlign: 'right',
      field: 'resend',
      type: 'actions',
      flex: 1,
      align: 'right',
      renderCell: this.renderCellActions,
    },
  ];

  render() {
    return (
      <DashboardLayout>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Title mb={3}>{PAGE_TITLE}</Title>
        </Box>

        <FilterBar
          filters={this.filters}
          onChange={(filters: Record<string, unknown>) => {
            this.activeFilters = filters;
          }}
          actions={{
            button: {
              type: 'action',
              icon: this.activeTree ? FormatListBulleted : SourceMerge,
              isActive: this.activeTree,
              title: this.activeTree ? 'Show List' : 'Show Tree',
              onActive: () => {
                this.activeTree = !this.activeTree;
              },
            },
          }}
        />

        {/* {this.activeTree ? (
          <TreeViewPanel fetch={this.getCategoriesByParent} selected={this.categorySelected} />
        ) : ( */}
        <DataGridInfiniteScroll
          columns={this.gridColumns}
          fetch={this.getAllCategories}
          refetchKey={this.activeFilters}
          disableColumnMenu
          pathname={this.props.location.pathname}
        />
        <CategoryDrawer
          category={this.categorySelected}
          open={this.openDrawer}
          action={this.actionDrawer}
          onClose={this.handlerCloseDrawer}
        />

        <PlusFabButton onClick={() => {}} />
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Categories);
