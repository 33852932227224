import React from 'react';
import { Tooltip } from '@material-ui/core';
import { CircleMedium } from 'mdi-material-ui';
import { observer } from 'mobx-react';

import useStyles from './styles';

interface IndicatorProps {
  color: 'green' | 'grey' | 'red';
  tooltip?: string;
}

/**
 * Used to display an indicator circle.
 * @param color The color of the circle: 'green' | 'yellow' | 'red'
 * @param tooltip An optional tooltip to display
 */
const Indicator = observer(({ color, tooltip }: IndicatorProps) => {
  const styles = useStyles();
  return tooltip ? (
    <Tooltip title={tooltip} placement="top">
      <CircleMedium className={styles[color]} />
    </Tooltip>
  ) : (
    <CircleMedium className={styles[color]} />
  );
});

export default Indicator;
