import React from 'react';
import { observer } from 'mobx-react';

import { CircularProgress } from '@material-ui/core';
import { CircularProgressProps } from '@material-ui/core/CircularProgress';

/**
 * Used to display loading spinner.
 */
const LoadingSpinner = observer((props: CircularProgressProps) => {
  return <CircularProgress {...props} />;
});

export default LoadingSpinner;
