/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Api, { PagedApiResponse, RequestMetaData, getErrorMsg } from 'api';
import DP from 'components/DashPanel';
import { action, computed, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Invitation, InvitationStatus } from 'models';
import moment from 'moment';
import React from 'react';
import styles from './styles';
import { WithToastStore, WithUserStore, inject } from 'types/stores';
import { fetchWrapper } from 'services';
import InfiniteScrollWrapper from 'components/InfiniteScrollWrapper';
import { AxiosResponse } from 'axios';

interface InvitationsListPanelProps
  extends WithStyles<typeof styles>,
    WithToastStore,
    WithUserStore {
  isAdmin?: boolean;
  fetch: (rmd: RequestMetaData) => Promise<AxiosResponse<PagedApiResponse<any>>>;
}

/**
 * Component for listing pending and rejected invitations. By default we show
 * pending invitations. User can also toggle list of rejected invitations.
 *
 * @param invitations array of invitations
 * @param onRevoke revoke invitation callback function, accepts invitation id
 */
@inject('userStore', 'toastStore')
@observer
class InvitationsListPanel extends React.Component<InvitationsListPanelProps> {
  constructor(props: InvitationsListPanelProps) {
    super(props);
    makeObservable(this);
  }

  @observable private invitations: Invitation[] = [];
  @observable private inProgress = false;

  @observable private invitationsCount = 0;

  @observable private showRejected = false;

  @action.bound public fetchInvitations = fetchWrapper(
    async (rmd: RequestMetaData) => {
      return this.props.fetch({
        ...rmd,
      });
    },

    (invitation: Invitation) => ({
      ...invitation,
    }),
  );

  @action.bound public accept = async (invitationId: string) => {
    try {
      await Api.core.acceptInvitation(invitationId);
      this.props.toastStore!.push({
        type: 'success',
        message: `Invitation accepted`,
      });
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  };

  @action.bound public reject = async (invitationId: string) => {
    try {
      await Api.core.updateInvitation(invitationId, { status: InvitationStatus.REJECTED });
      this.props.toastStore!.push({
        type: 'success',
        message: 'Invitation was rejected',
      });
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  };

  @action.bound public revoke = async (invitationId: string) => {
    try {
      await Api.core.updateInvitation(invitationId, { status: InvitationStatus.REVOKED });
      this.props.toastStore!.push({
        type: 'success',
        message: 'Invitation revoked successfully',
      });
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  };

  renderListItem = ({ id, tenant, store, status, expiresAt, email, scope }: Invitation) => {
    const expiryDate = moment(expiresAt).format('MMM, DD');
    const message = `${
      this.props.isAdmin ? `Invitation to ${email} to join` : `Invited to join`
    } ${store ? store.name : tenant.name} | ${scope.toUpperCase()} | Expires on ${expiryDate}`;


    const menu = []

      if((this.props.isAdmin && !this.showRejected) || this.props.userStore!.isAdmin){
        menu.push({
          label: 'Revoke',
          onClick: () => this.revoke(id),
        })
      } 
      if (!this.props.isAdmin && status === InvitationStatus.SENT || this.props.userStore!.isAdmin){
        menu.push(...[
          {
            label: 'Accept',
            onClick: () => this.accept(id),
          },
          {
            label: 'Reject',
            onClick: () => this.reject(id),
          },
        ]

        )
      }
    return (
      <DP.ListItem
        key={id}
        primary={this.props.isAdmin ? message : email}
        secondary={!this.props.isAdmin && message}
        menu={menu}
      />
    );
  };

  render() {
    const title = this.props.isAdmin ? 'Invitations' : this.showRejected ? 'Rejected' : 'Pending';
    if (this.inProgress) {
      return (
        <DP>
          <DP.Header>
            <DP.Title>{title}</DP.Title>
          </DP.Header>
          <DP.Body>
            <DP.Loading size="large" items={4} />
          </DP.Body>
        </DP>
      );
    }
    return (
      <>
        <DP>
          <DP.Header>
            <DP.Title count={this.invitationsCount}>{title}</DP.Title>
            {/* {!this.props.isAdmin && (
              <DP.Actions>
                <Button color="primary" onClickCapture={() => {}}>
                  {this.showRejected ? 'show pending' : 'show rejected'}
                </Button>
              </DP.Actions>
            )} */}
          </DP.Header>
          <DP.Body>
            <InfiniteScrollWrapper
              render={(invitations) => {
                if (invitations.length == 0) return <>No rows</>;
                return (
                  <>
                    {invitations.map((invitation: Invitation) => this.renderListItem(invitation))}
                  </>
                );
              }}
              fetch={this.fetchInvitations}
              limit={5}
            />
          </DP.Body>
        </DP>
      </>
    );
  }
}

export default withStyles(styles)(InvitationsListPanel);
