import React from 'react';
import { Observer } from 'mobx-react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { inject, WithModalStore } from 'types/stores';
import useStyles from './styles';
import Button from 'components/Button/Dialog/Button';

type ConfirmDialogProps = WithModalStore;

/** The component used to display the currently opened confirmation dialog */
function ConfirmDialog({ modalStore }: ConfirmDialogProps) {
  const { title, dialog, content } = useStyles();
  return (
    <Observer>
      {() => {
        const confirmDialog = modalStore!.confirmDialog;
        return (
          <div>
            {confirmDialog && (
              <Dialog
                PaperProps={{ className: dialog, elevation: 0 }}
                open={Boolean(confirmDialog)}
                onClose={confirmDialog && confirmDialog.cancel}>
                <>
                  {confirmDialog.title && (
                    <DialogTitle disableTypography className={title}>
                      {confirmDialog.title}
                    </DialogTitle>
                  )}
                  <DialogContent className={content}>
                    <DialogContentText>{confirmDialog.msg}</DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    {confirmDialog.cancelLabel && (
                      <Button onClick={confirmDialog.cancel}>{confirmDialog.cancelLabel}</Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={confirmDialog.confirm}
                      autoFocus>
                      {confirmDialog.confirmLabel}
                    </Button>
                  </DialogActions>
                </>
              </Dialog>
            )}
          </div>
        );
      }}
    </Observer>
  );
}

export default inject('modalStore')(ConfirmDialog);
