import React from 'react';
import { action, computed, observable, makeObservable, reaction } from 'mobx';
import { observer } from 'mobx-react';

import Api, { getErrorMsg, PagedApiResponse, RequestMetaData } from 'api';
import { Invitation, Role, InvitationStatus } from 'models';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import DashboardLayout from 'containers/DashboardLayout';
import InvitePanel from 'components/InvitePanel';
import InvitationsListPanel from 'components/InvitationsListPanel';

import styles from './styles';

import { RouteComponentProps } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import Title from 'components/Title/Title';
import { inject, WithToastStore, WithUserStore } from 'types/stores';
import { setTitle } from 'services';
import PlusFabButton from 'components/PlusFabButton/PlusFabButton';

type InviteUsersProps = WithStyles<typeof styles> &
  RouteComponentProps &
  WithToastStore &
  WithUserStore;

  const PAGE_TITLE = 'Invitations'

@inject('userStore', 'toastStore')
@observer
class InviteUsers extends React.Component<InviteUsersProps> {
  constructor(props: InviteUsersProps) {
    super(props);
    makeObservable(this);
  }

  @observable private invitations: Invitation[] = [];

  @observable private invitationsCount = 0;

  @observable private invitationsType: InvitationStatus = InvitationStatus.SENT;

  @observable public addInvitationsToExistingList = false;

  @observable public loadingInvitations = false;

  @action.bound public getInvitations = (rmd: RequestMetaData) => {
    return Api.core.getInvitations({
      ...rmd,
    });
  };

  // @action.bound public inviteUsers = (rmd: RequestMetaData) => {
  //   return Api.core.inviteUsers();
  // };

  // @action.bound public invite = async (
  //   emails: string[],
  //   selectedRoles: Role[],
  //   location: Location | null,
  // ) => {
  //   try {
  //     const invitations = emails.map((email) => ({
  //       email: email.toLowerCase(),
  //       accountId: this.accountId!,
  //       locationId: location ? location.id : undefined,
  //       isManager: selectedRoles.includes('manager'),
  //       isTalent: selectedRoles.includes('talent'),
  //     }));
  //     if (this.accountId) {
  //       await Api.core.inviteUsers(invitations);
  //       this.skip === 0 ? this.getInvitations() : (this.skip = 0);
  //       this.props.toastStore!.push({
  //         type: 'success',
  //         message: `Invitations sent successfully`,
  //       });
  //     } else {
  //       throw new Error('Invitation is missing account destination');
  //     }

  //     return { error: false };
  //   } catch (e: any) {
  //     this.props.toastStore!.error(getErrorMsg(e));
  //     return { error: true };
  //   }
  // };

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
  }
  
  render() {
    return (
      <DashboardLayout>
        <Title mb={3}>{'Invitations'}</Title>
        <Box mb={3} mt={3}>
          {/* <InvitePanel onCreate={this.inviteUsers} /> */}
        </Box>
        <Box mb={3} mt={3}>
          <InvitationsListPanel fetch={this.getInvitations} isAdmin />
        </Box>
        <PlusFabButton onClick={() => {}} />
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(InviteUsers);
