import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 28,
      padding: 0,
      paddingLeft: theme.spacing(1),
    },
    dialog: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    content: {
      marginTop: theme.spacing(4),
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
  }),
);

export default useStyles;
