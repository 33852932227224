import { createApi, makeQS } from './utils';
import { RequestMetaData } from './interface';

import config from 'config';

const api = {
  v1: createApi({ baseURL: 'https://gateway-b2ctools.up.railway.app/api' }),
};

export const getAllCategories = (rmd?: RequestMetaData, extraData?: Record<string, unknown>) =>
  api.v1.get(`product-category?${makeQS(rmd, extraData)}`);

export const deleteCategory = (categoryId: string) =>
  api.v1.delete(`product-category/${categoryId}`);

export const getCategoryById = (categoryId: string) =>
api.v1.get(`product-category/${categoryId}`);