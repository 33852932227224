/**
 * A utility function that returns the value of an env variable.
 * If the env variable is not found, throws an error.
 * @param key The env variable to look up
 */
function required(key: string): string {
  const envValue = process.env[key];
  if (envValue === undefined) {
    throw new Error(
      `Missing environment variable ${key}. Check if your .env file has all the variables defined in sample.env`,
    );
  }
  return envValue;
}

function optional(key: string): string | undefined {
  return process.env[key];
}

/**
 * The interface that defines our configuration object
 */

const config = {
  api: {
    core: required('REACT_APP_CORE_BASE_URL'),
    tips: required('REACT_APP_TIPS_BASE_URL'),
    marketing: required('REACT_APP_MARKETING_BASE_URL'),
    billing: required('REACT_APP_BILLING_BASE_URL'),
    fulfillment: required('REACT_APP_FULFILLMENT_BASE_URL'),
    analytics: required('REACT_APP_ANALYTICS_BASE_URL'),
    developer: required('REACT_APP_DEVELOPER_BASE_URL'),
  },
  stripe: {
    publicKey: required('REACT_APP_STRIPE_PUBLIC_KEY'),
  },
  dwolla: {
    env: (optional('REACT_APP_DWOLLA_ENV') || 'sandbox') as 'sandbox' | 'production',
  },
  tracking: {
    fbPixelId: optional(`REACT_APP_FACEBOOK_PIXEL_ID`),
    gaId: optional(`REACT_APP_GOOGLE_PIXEL_ID`),
  },
  google: {
    apiKey: optional(`REACT_APP_GOOGLE_API_KEY`),
    recaptchaSiteKey: optional(`REACT_APP_RECAPTCHA_SITE_KEY`),
    recaptchaSecretKey: optional(`REACT_APP_RECAPTCHA_SECRET_KEY`),
    clientId: optional('REACT_APP_GOOGLE_CLIENT_ID')
  },
  square: {
    environment: required(`REACT_APP_SQUARE_ENVIRONMENT`),
    applicationId: required(`REACT_APP_SQUARE_APPLICATION_ID`),
    locationId: required(`REACT_APP_SQUARE_LOCATION_ID`),
  },
  openEdge: {
    apiKey: required(`REACT_APP_OPEN_EDGE_API_KEY`),
    environment: required(`REACT_APP_OPEN_EDGE_ENVIRONMENT`),
  },
  shopGo: {
    baseUrl: required(`REACT_APP_GO_BASE_URL`),
  },
  branch: {
    scriptSrc: required(`REACT_APP_BRANCH_SRC`),
    orgId: required('REACT_APP_BRANCH_ORG_ID'),
  },
  dashboard: {
    baseUrl: required('REACT_APP_DASHBOARD_BASE_URL'),
  },
  shoptree: {
    baseUrl: required('REACT_APP_PORTAL'),
  },
  version: required('REACT_APP_VERSION'),
};

export default config;
