import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      height: 32,
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      paddingTop: theme.spacing(0.75),
      paddingBottom: theme.spacing(0.75),
      textTransform: 'none',
      borderRadius: 8,
      fontWeight: 500,
      fontSize: 14,
      float: 'right',
      '&:hover': {
        backgroundColor: theme.palette.primary.hover,
      },
    },
    contained: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.primary.main,
    },
    outlined: {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,

      '&:hover': {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.primary.hover}`,
        color: theme.palette.primary.hover,
      },
    },
  }),
);

export default useStyles;
