import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    loadingSkeleton: {
      margin: '6px 0',
    },
    userLink: {
      width: '100%',
    },
    noDataText: {
      color: theme.palette.text.secondary,
    },
    noUsersFoundText: {
      color: theme.palette.text.secondary,
    },
  });
