import { Theme, createStyles, makeStyles } from '@material-ui/core';

interface IStyleProps {
  mobileView?: boolean;
}
const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: ({ mobileView }) => (mobileView ? '100%' : 380),
    },
    list: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      marginTop: '7px',
      padding: theme.spacing(0, 2.6875),
      maxHeight: ({ mobileView }) => (mobileView ? `calc(100vh - 320px)` : '60vh'),
    },
    scopeItemRoot: {
      borderRadius: theme.shape.borderRadius,
      paddingLeft: theme.spacing(2.375),
      paddingRight: theme.spacing(2),
      marginTop: theme.spacing(0.3125),
      marginBottom: theme.spacing(0.3125),
    },
    scopeItemActive: {
      backgroundColor: theme.palette.background.default,
    },
    avatar: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      borderRadius: '10px',
      backgroundColor: theme.palette.common.white,
    },
    scopeSwitcherAvatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      borderRadius: theme.spacing(0.25),
    },
    scopeItemWrapper: {
      maxHeight: theme.spacing(59.375),
      minHeight: '0px',
    },
  }),
);

export default useStyles;
