import React from 'react';
import { Box } from '@material-ui/core';
import { Menu, MenuItem, Fade } from '@mui/material';
import { ExternalActionsProps } from './InterfaceDataGridPro';
import { useStyles } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faPlus } from '@fortawesome/pro-regular-svg-icons';
import Button from 'components/Button/Dialog/Button';

const ToolbarContainer = ({ onAdd, onExport }: ExternalActionsProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display={'flex'} gridGap={16}>
      {onAdd && onAdd?.action && (
        <Button
          onClick={() => {
            onAdd?.action && onAdd.action();
          }}
          aria-label={onAdd?.name || 'Add New'}
          startIcon={<FontAwesomeIcon icon={faPlus} style={{ fontSize: 18 }} />}>
          {onAdd?.name}
        </Button>
      )}

      {onExport && (
        <>
          <Button
            color="primary"
            startIcon={<FontAwesomeIcon icon={faDownload} style={{ fontSize: 18 }} />}
            onClick={handleClick}>
            Export
          </Button>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose} TransitionComponent={Fade}>
            {(onExport || []).map(({ name, action }, idx) => (
              <MenuItem
                key={idx}
                onClick={() => {
                  action && action();
                  handleClose();
                }}>
                {name}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </Box>
  );
};

export default ToolbarContainer;
