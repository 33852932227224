import React, { Component } from 'react';
import { observable, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { InputAdornment, IconButton, Icon } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import styles from './styles';
import OutlinedInput from 'components/Input/OutlinedInput';

interface PasswordFieldProps extends WithStyles<typeof styles> {
  value?: string;
  mobxField?: any;
  id?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  required?: boolean;
  disabled?: boolean;
}

/**
 * A password input field with the option to toggle the
 * password visiblity.
 * @param mobxField use this field when using mobx forms to bind the mobx password field to the input
 */
@observer
class PasswordField extends Component<PasswordFieldProps> {
  constructor(props: PasswordFieldProps) {
    super(props);
    makeObservable(this);
  }

  static defaultProps = {
    label: 'Password',
    required: false,
  };
  @observable private showPassword = false;
  @action.bound private toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  render() {
    const { classes, label, mobxField, ...rest } = this.props;
    const hasError = Boolean(rest.error);
    const visibility = this.showPassword ? 'visibility' : 'visibility_off';
    return (
      <OutlinedInput
        autoComplete="on"
        error={hasError}
        {...rest}
        {...(mobxField ? mobxField.bind() : {})}
        type={this.showPassword ? 'text' : 'password'}
        label={label || 'Password'}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                aria-label="Toggle password visibility"
                onClick={this.toggleShowPassword}>
                <Icon className={classes.icon} fontSize="small">
                  {visibility}
                </Icon>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
}

export default withStyles(styles)(PasswordField);
