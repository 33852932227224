import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  WithStyles,
} from '@material-ui/core';
import Api, { getErrorMsg } from 'api';
import CodeInput from 'components/CodeInput';
import { action, computed, flow, observable, reaction, runInAction, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { User } from 'models';
import React from 'react';
import { inject, WithModalStore, WithToastStore, WithUserStore } from 'types/stores';
import { default as DialogButton } from 'components/Button/Dialog/Button';
import { withStyles } from '@material-ui/styles';
import styles from './styles';
import { OverlayLoader } from 'components/Loader/OverlayLoader/OverlayLoader';

type IdentityConfirmDialogProps = WithModalStore & WithToastStore & WithUserStore & WithStyles;

type CodeDestination = 'phone' | 'email';

/** The component used to display the identity confirmation dialog */
@inject('modalStore', 'userStore', 'toastStore')
@observer
class IdentityConfirmDialog extends React.Component<IdentityConfirmDialogProps> {
  constructor(props: IdentityConfirmDialogProps) {
    super(props);
    makeObservable(this);
  }

  @observable public code = '';

  @action.bound public updateCode(e: React.ChangeEvent<HTMLInputElement>) {
    const lastElement = e.target.value.slice(-1);
    if (!isNaN(parseInt(lastElement)) || e.target.value.length === 0) {
      this.code = e.target.value;
      this.error = null;
    }
  }

  /** Logged in user object */
  @computed private get user(): User | null {
    return this.props.userStore!.user || null;
  }

  /** Where should the verification code be sent to? */
  @observable public destination?: CodeDestination;

  /** Used to calculate if destination select or code input should be rendered */
  @observable public codeSent = false;

  @observable public inProgress = false;

  public textFieldRef = React.createRef<HTMLInputElement>();

  @observable public error: null | string = null;

  @action.bound public setDestination(e: React.ChangeEvent<HTMLInputElement>) {
    this.destination = e.target.value as CodeDestination;
  }

  @action.bound public submitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.submit();
  }

  @action.bound public sendCode = flow(function* (this: IdentityConfirmDialog) {
    try {
      if (!this.user) return;
      const { id, email, phone } = this.user;
      yield Api.core.sendIdentityVerification(id, this.destination);
      const msg = `Code sent to ${this.destination === 'email' ? email : phone}!`;
      this.props.toastStore!.success(msg);
      this.codeSent = true;
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @action.bound public resendingCode() {
    if (!this.user) return;
    // If user has no phone number just resend the code to her email ...
    if (!this.user.phone) {
      this.props.modalStore!.identityConfirmDialog!.send(this.destination);
    } else {
      // ... else ask for destination again
      this.codeSent = false;
      this.destination = undefined;
    }
  }

  @action.bound public submit = flow(function* (this: IdentityConfirmDialog) {
    if (!this.user) return;
    try {
      this.inProgress = true;
      const { id } = this.user;
      const resp = yield Api.core.getIdentityVerification(id, this.code);
      const token = resp.data.data;
      if (Date.now() > +new Date(token.expiresAt)) {
        this.error = 'The code has expired, click resend to send another one';
      } else if (!token.isValid) {
        this.error = 'The code is invalid, click resend to send another one';
      } else {
        this.props.userStore!.setIdentityVerificationToken(token);
        this.props.modalStore!.identityConfirmDialog &&
          this.props.modalStore!.identityConfirmDialog.confirm(token.code);
      }
    } catch (e: any) {
      if (e.response && e.response.status === 404) {
        this.error = `The code is invalid, make sure you've entered it correctly or click resend to send another one`;
      } else {
        this.props.toastStore!.error(getErrorMsg(e));
      }
    } finally {
      this.inProgress = false;
    }
  });

  @computed public get submitDisabled() {
    return !!(this.code.length === 0 || this.error || this.inProgress);
  }

  /**
   * Set up a reaction on whether the identity confirm dialog is open,
   * so that when it opens, we can focus on the destination and code
   * entry fields, reset them, and send the code to the user's email
   * right away if she has no phone number set
   */
  focusDisposer = reaction(
    () => Boolean(this.props.modalStore!.identityConfirmDialog),
    (dialog) => {
      if (dialog) {
        runInAction(() => {
          if (!this.user) return;
          this.code = '';
          this.error = null;
          this.destination = undefined;
          // If auth user does not have a phone, set email
          // as the code destination and send the code
          if (!this.user.phone) {
            this.destination = 'email';
            this.props.modalStore!.identityConfirmDialog!.send(this.destination);
            this.codeSent = true;
          }
        });
        window.requestAnimationFrame(() => {
          this.textFieldRef.current && this.textFieldRef.current.focus();
        });
      }
    },
  );

  componentDidMount() {
    this.focusDisposer();
  }

  render() {
    if (!this.user) return null;
    const { dialogActions, dialogContent, label, resendCode } = this.props.classes;
    const dialog = this.props.modalStore!.identityConfirmDialog;
    const { email, phone } = this.user;
    return (
      <div>
        {dialog && (
          <Dialog open={Boolean(dialog)} onClose={dialog && dialog.cancel} fullWidth>
            {!this.codeSent ? (
              <form onSubmit={this.submitForm}>
                <DialogTitle>
                  <Typography style={{ fontSize: 28, fontWeight: 400 }}>
                    {' '}
                    Additional Verification
                  </Typography>
                </DialogTitle>
                <DialogContent className={dialogContent}>
                  <OverlayLoader display={this.inProgress} />
                  <DialogContentText>
                    <Box mb={2}>Send verification code to:</Box>
                  </DialogContentText>
                  <FormControl component="fieldset">
                    <RadioGroup
                      name="destination"
                      value={this.destination}
                      onChange={this.setDestination}>
                      <FormControlLabel
                        value="email"
                        control={<Radio color="primary" />}
                        label={<Typography className={label}>{email}</Typography>}
                      />
                      <FormControlLabel
                        value="phone"
                        control={<Radio color="primary" />}
                        label={
                          <>
                            <Typography className={label} component="span">
                              {phone}
                            </Typography>
                          </>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </DialogContent>
                <DialogActions className={dialogActions}>
                  <DialogButton variant="outlined" onClick={dialog.cancel} color="primary">
                    Cancel
                  </DialogButton>
                  <DialogButton
                    variant="contained"
                    onClick={this.sendCode}
                    color="primary"
                    disabled={!this.destination}>
                    Send
                  </DialogButton>
                </DialogActions>
              </form>
            ) : (
              <form onSubmit={this.submitForm}>
                <DialogTitle>
                  <Typography style={{ fontSize: 28, fontWeight: 400 }}>
                    Additional Verification
                  </Typography>
                </DialogTitle>
                <DialogContent className={dialogContent}>
                  <OverlayLoader display={this.inProgress} />
                  <DialogContentText>
                    <Box mb={3}>
                      We{`'`}ve sent a verification code to{' '}
                      <Typography component="span" color="primary">
                        {this.destination === 'email' ? email : phone}
                      </Typography>
                      . Please enter it here to continue.
                    </Box>
                    {dialog.msg && <Box mb={3}>{dialog.msg}</Box>}
                  </DialogContentText>
                  <CodeInput
                    value={this.code}
                    onChange={this.updateCode}
                    error={Boolean(this.error)}
                    helperText={this.error}
                    inputProps={{ maxLength: 6 }}
                    inputRef={this.textFieldRef}
                  />
                  <Box mt={5}>
                    <Typography variant={'body2'}>
                      Didn't receive the code?{' '}
                      <Box component={'span'} onClick={this.resendingCode} className={resendCode}>
                        Resend Code
                      </Box>
                    </Typography>
                  </Box>
                </DialogContent>
                <DialogActions className={dialogActions}>
                  <DialogButton onClick={dialog.cancel} color="primary">
                    Cancel
                  </DialogButton>
                  <DialogButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={this.submitDisabled}>
                    Confirm
                  </DialogButton>
                </DialogActions>
              </form>
            )}
          </Dialog>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(IdentityConfirmDialog);
