import { inject as mobxInject } from 'mobx-react';
import RootStore from './RootStore';

/**
 * A utility function for injecting stores into components. Same
 * as mobx's `inject` function, only it makes sure that the store
 * you're injecting exists on the RootStore
 * @param stores The stores to inject
 */
export function inject(...stores: (keyof RootStore)[]) {
  return mobxInject(...stores);
}
