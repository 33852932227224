import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      width: 132,
      height: 56,
    },
  }),
);

export default useStyles;
