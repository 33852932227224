import { Theme, createStyles } from '@material-ui/core';
import { amber } from '@material-ui/core/colors';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    close: {
      padding: theme.spacing(0.5),
    },
    success: {
      backgroundColor: theme.palette.primary.main,
    },
    error: {
      backgroundColor: theme.palette.error.main,
    },
    info: {
      backgroundColor: theme.palette.secondary.main,
    },
    warning: {
      backgroundColor: amber[700],
    },
  });
