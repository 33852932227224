import { Box, Grid } from '@material-ui/core';
import AnonLayout from 'components/AnonLayout';
import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import useStyles from './styles';

import reviewImg1 from '../../images/promo/photo-1557821552-17105176677c.avif'
import reviewImg2 from '../../images/promo/photo-1556742031-c6961e8560b0.avif'
import reviewImg3 from '../../images/promo/photo-1556742111-a301076d9d18.avif'
import reviewImg4 from '../../images/promo/photo-1556745753-b2904692b3cd.avif'
import reviewImg5 from '../../images/promo/photo-1599658880436-c61792e70672.avif'

import CoverImage from 'components/CoverImage/CoverImage';
import ReviewCard, { IReview } from 'components/ReviewCard/ReviewCard';
import _ from 'lodash';

interface ICarouselScreenWrapper {
  submitting?: boolean;
  anonLayoutIcon?: any;
  children: React.ReactNode;
}

interface IReviewData {
  src: string;
  review: IReview;
}

const reviewData: Array<IReviewData> = [
{
    "src": reviewImg5,
    "review": {
        "message": "We provide a comprehensive solution to unify the management of multiple sales channels, such as Amazon, Walmart, Shopify, TikTok Shop, and more. With Seller Center, all sales data and operations are centralized in one place, making it easy to monitor and make decisions in real time.",
        "customer": "Support team",
        "rating": 5
    }
}
];

function CarouselScreenWrapper(props: ICarouselScreenWrapper) {
  const classes = useStyles();
  const [reviews] = useState(_.shuffle(reviewData));

  const { submitting, anonLayoutIcon } = props;
  return (
    <Grid container style={{ overflowX: 'hidden' }}>
      <Grid className={classes.gridItem} item lg={6} md={12}>
        <AnonLayout inProgress={submitting} icon={anonLayoutIcon}>
          {props.children}
        </AnonLayout>
      </Grid>
      <Grid className={classes.gridItem} item lg={6}>
        <Carousel
          navButtonsAlwaysInvisible={true}
          stopAutoPlayOnHover={false}
          className={classes.carousel}
          indicators={false}
          timeout={400}
          interval={7000}>
          {reviews.map((item: IReviewData, i: number) => (
            <CoverImage key={i} imgPath={item.src}>
              <Box className={classes.ratingsCardWrapper}>
                <ReviewCard review={item.review} />
              </Box>
            </CoverImage>
          ))}
        </Carousel>
      </Grid>
    </Grid>
  );
}

export default CarouselScreenWrapper;
