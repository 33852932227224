import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import AddressField from 'components/AddressField';
import Button from 'components/Button/Dialog/Button';
import DP from 'components/DashPanel';
import { Pencil } from 'mdi-material-ui';
import { action, autorun, IReactionDisposer, observable, toJS, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Address } from 'types';
import styles from './styles';
import Title from 'components/Title/Dialog/Title';

interface AddressPanelProps extends WithStyles<typeof styles> {
  children?: Address;
  title?: string;
  addressEditable?: boolean;
  fullHeight?: boolean;
  onUpdateAddress?: (a: Address) => Promise<unknown>;
}

/**
 * Displays a accounts's details; street, city, state, zip.
 * @param children The account object to display. Pass undefined if it's still loading
 * @param title Optional title, `Details` by default
 */
@observer
class AddressPanel extends React.Component<AddressPanelProps> {
  static defaultProps = {
    title: 'Address',
  };
  constructor(props: AddressPanelProps) {
    super(props);
    makeObservable(this);
    // Set up an autorun so that when the account in the props changes, we copy it
    // to this component's state. This achieves the same thing as implementing a
    // componentDidUpdate and seeing if the account prop has changed.
    // We push the autorun onto a disposers array so that we can dispose of all
    // of them before the component unmounts.
    this.disposers.push(autorun(() => (this.address = toJS(this.props.children))));
  }

  /**
   * It's good practice to dispose of any autoruns that we set up during
   */
  private disposers: IReactionDisposer[] = [];

  @observable private openEditAddressDialog = false;

  /** The account object is copied to this property */
  @observable private address?: Address = this.props.children;

  /** Before unmounting the component, dispose of all autoruns created */
  componentWillUnmount() {
    this.disposers.map((disposer) => disposer());
  }

  

  @action.bound public updateAddress(a: Address | null) {
    console.log('updateAddress', a)
    if (!a) {
      return;
    }
    this.address = toJS(a);
    // if (this.props.onUpdateAddress) {
    //   this.props.onUpdateAddress(a);
    // }
  }

  @action.bound public submitAddress() {
    if (this.props.onUpdateAddress && this.address) {
      this.props.onUpdateAddress(this.address);
    }
    this.openEditAddressDialog = false
  }

  render() {
    const { title, onUpdateAddress, classes } = this.props;
    // If the account is undefined, that means it's still loading, so we display a loading skeleton.
    // if (!this.address ) {
    //   return (
    //     <DP>
    //       <DP.Header>
    //         <DP.Title panel>{title}</DP.Title>
    //       </DP.Header>
    //       <DP.Body>
    //         <DP.Loading items={4} />
    //       </DP.Body>
    //     </DP>
    //   );
    // }
    return (
      <>
        <DP fullHeight={this.props.fullHeight}>
          <DP.Header>
            <DP.Title panel>{title}</DP.Title>
            {this.props.addressEditable && (
              <DP.Actions>
                <DP.IconButton
                  primary
                  onClick={() => (this.openEditAddressDialog = true)}
                  icon={Pencil}
                  tooltip="Edit"
                />
              </DP.Actions>
            )}
          </DP.Header>
          <DP.Body>
            <DP.Row>
              <DP.Value>{this.address && this.address.address}</DP.Value>
              <DP.Label>Street</DP.Label>
            </DP.Row>
            {this.address && this.address.address2 && (
              <DP.Row>
                <DP.Value>{this.address.address2}</DP.Value>
                <DP.Label>Address2</DP.Label>
              </DP.Row>
            )}
            <DP.Row>
              <DP.Value>{this.address && this.address.city}</DP.Value>
              <DP.Label>City</DP.Label>
            </DP.Row>
            <DP.Row>
              <DP.Value>{this.address && this.address.state}</DP.Value>
              <DP.Label>State</DP.Label>
            </DP.Row>
            <DP.Row>
              <DP.Value>{this.address && this.address.zip}</DP.Value>
              <DP.Label>Zip</DP.Label>
            </DP.Row>
            <Dialog
              className={clsx(classes.dialog)}
              maxWidth={false}
              open={this.openEditAddressDialog}
              onClose={() => null}>
              <DialogTitle>
                <Title>Edit {title ? title : 'Address'}</Title>
              </DialogTitle>
              <DialogContent>
                <AddressField
                  label={title ? title : 'Address'}
                  onChange={this.updateAddress}
                  enableCustomInput
                  initialCustomAddress={
                    this.address && this.address.address ? (this.address as Address) : undefined
                  }
                />
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  variant="outlined"
                  autoFocus
                  onClick={() => (this.openEditAddressDialog = false)}>
                  Close
                </Button>
                <Button variant="contained" onClick={() => this.submitAddress()}>
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </DP.Body>
        </DP>
      </>
    );
  }
}

export default withStyles(styles)(AddressPanel);
