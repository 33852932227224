import React from 'react';
import { CardMedia, SvgIconProps } from '@material-ui/core';

import { CreditCard } from 'mdi-material-ui';

import VisaSvg from '../../images/creditcards/visa.svg';
import MastercardSvg from '../../images/creditcards/mastercard.svg';
import AmexSvg from '../../images/creditcards/amex.svg';
import DiscoverSvg from '../../images/creditcards/discover.svg';
import DefaultCard from '@mui/icons-material/CreditCard';

interface CreditCardIconProps {
  brand: string;
  color?: SvgIconProps['color'];
  style?: any;
  onClick?(): void;
  className?: string;
}

class CreditCardIcon extends React.Component<CreditCardIconProps> {
  render() {
    const onClick = this.props.onClick;
    const { color, style, className } = this.props;
    const brand = this.props.brand.toLowerCase();
    const styles = { width: 30, height: 19, ...style };
    switch (brand) {
      case 'discover': {
        return <CardMedia className={className} style={styles} image={DiscoverSvg} />;
      }
      case 'american express': {
        return <CardMedia className={className} style={styles} image={AmexSvg} />;
      }
      case 'mastercard': {
        return <CardMedia className={className} style={styles} image={MastercardSvg} />;
      }
      case 'visa': {
        return <CardMedia className={className} style={styles} image={VisaSvg} />;
      }
      case 'default': {
        return <DefaultCard className={className} style={{ fontSize: styles.height * 1.5 }} />;
      }

      default: {
        return (
          <CreditCard
            className={className}
            fontSize="default"
            color={color || 'action'}
            onClick={onClick ? onClick : undefined}
            style={{ ...style, width: styles.width, cursor: onClick ? 'pointer' : 'default' }}
          />
        );
      }
    }
  }
}

export default CreditCardIcon;
