import React, { useState } from 'react';
import { SvgIcon, Menu, MenuItem, IconButton, Box } from '@material-ui/core';
import { useStyles } from './styles';

interface OptionMenu {
  label: string;
  action: () => void;
}

export enum PositionMenu {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}
interface ActionsMenuProps {
  options: OptionMenu[];
  position?: PositionMenu;
  disabled?: boolean;
}

export default function ActionsMenu({ options, position, disabled }: ActionsMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderIcon = (position: PositionMenu | undefined) => {
    switch (position) {
      case PositionMenu.VERTICAL:
        return (
          <path
            fill="currentColor"
            d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z"
          />
        );
      case PositionMenu.HORIZONTAL:
        return (
          <path
            fill="currentColor"
            d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z"
          />
        );

      default:
        return (
          <path
            fill="currentColor"
            d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
          />
        );
    }
  };

  return (
    <Box display="flex" justifyContent="end">
      <IconButton
        disabled={disabled}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <SvgIcon className={classes.renderActions} viewBox="0 0 24 24">
          {renderIcon(position)}
        </SvgIcon>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {options &&
          options.map(({ label, action }) => (
            <MenuItem
              key={label}
              onClick={() => {
                action();
                handleClose();
              }}>
              {label}
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
}
