import { Theme, createStyles } from '@material-ui/core';

import { alertColors } from 'containers/App/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    fab: {
      position: 'fixed',
      bottom: theme.spacing(3),
      right: theme.spacing(2),
    },
    alertMessageBox: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      borderRadius: 5,
      color: 'white',
      '&.low': { backgroundColor: alertColors.low },
      '&.medium': { backgroundColor: alertColors.medium },
      '&.high': { backgroundColor: alertColors.high },
    },
    label: {
      color: theme.palette.text.secondary,
      fontSize: 16,
      fontWeight: 400,
    },
    templateMessage: {
      textTransform: 'none',
      display: 'flex',
      justifyContent: 'flex-start',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    alertIcon: {
      '&.low': { fill: theme.palette.status.blue },
      '&.medium': { fill: theme.palette.status.yellow },
      '&.high': { fill: theme.palette.status.red },
    },
    customAlertButton: {
      fontWeight: 600,
      fontSize: 16,
    },
    formLabel: {
      marginBottom: theme.spacing(2.5),
    },
    textField: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    dialogActions: {
      paddingTop: theme.spacing(5),
      padding: theme.spacing(3),
    },
  });
