import React from 'mdi-material-ui/React';
import {
  createStyles,
  Switch as MuiSwitch,
  SwitchClassKey,
  SwitchProps,
  Theme,
  withStyles,
} from '@material-ui/core';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

export const Switch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 34,
      height: 21,
      padding: 0,
    },
    switchBase: {
      padding: 5.25,
      '&': {
        color: theme.palette.text.secondary,
      },
      '& .MuiSwitch-thumb': {
        boxShadow: 'none',
      },
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 'none',
        },
        '& .MuiSwitch-thumb': {
          transform: 'scale(1.3333)',
          backgroundColor: theme.palette.common.white,
        },
      },
      '&$focusVisible $thumb': {
        color: theme.palette.primary.main,
      },
    },
    thumb: {
      width: 10.5,
      height: 10.5,
      backgroundColor: theme.palette.common.black,
    },
    track: {
      borderRadius: 35,
      border: `2px solid ${theme.palette.common.black}`,
      backgroundColor: theme.palette.common.white,
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }),
)(({ classes, ...props }: Props) => {
  return (
    <MuiSwitch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
