import { Box, Paper } from '@material-ui/core';
import DashboardLayout from 'containers/DashboardLayout';
import { Observer, observer } from 'mobx-react';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { UserStore, inject } from 'types/stores';
import { WithUserStore } from 'types/stores/UserStore';
import { ACL } from 'types';
import * as paths from './paths';
import qs from 'qs';
import LoadingPage from './LoadingPage';
import jwtDecode from 'jwt-decode';
import { TScope } from 'models';

interface AuthRouteProps extends RouteProps, WithUserStore {
  scopes?: TScope[];
  permissions?: ACL[];
}

/**
 * Same as Route, only makes sure that the current user is logged in.
 * If they're not, redirects to '/login' or to a custom route.
 */
@inject('userStore')
@observer
export default class AuthRoute extends React.Component<AuthRouteProps> {
  render() {
    return (
      <Observer>
        {() => {
          const {
            component: Component,
            render,
            userStore,
            scopes,
            path,
            permissions,
            location,
            ...rest
          } = this.props;

          // Check if there are email and token query params present and login if they are
          if (this.props.location?.search) {
            const { token } = qs.parse(this.props.location.search, {
              ignoreQueryPrefix: true,
            }) as {
              token: string;
              email: string;
              redirect: string;
            };
          }

          if (this.props.userStore?.loggingIn) {
            return <LoadingPage />;
          }

          // Check if the user has all the permissions that are requested in the route
          // const permissionsOk =
          //   !permissions || permissions.every((p) => userStore!.hasPermission(p));
          // If a scopes array is provided, make sure that the current scope
          // is one of the allowed scopes for this route.
          const scopeOk = !scopes || scopes.includes(userStore!.scope.kind);
          const authOk = userStore!.loggedIn;
          const nameOk = userStore!.hasName;
          const pwdOk = userStore!.isRequiredResetPassword;

          // If user is not logged in, redirect to sign-in
          if (!authOk) {
            return <Redirect to={{ pathname: paths.signIn(), state: { from: location } }} />;
          }

          if (pwdOk && path !== paths.resetPassword()) {
            return <Redirect to={{ pathname: paths.resetPassword(), state: { from: location } }} />;
          }

          // If the user doesn't have a first and last name, redirect them to that screen
          if (!nameOk && path !== paths.signUp().personalInfo()) {
            return (
              <Redirect
                to={{ pathname: paths.signUp().personalInfo(), state: { from: location } }}
              />
            );
          }
          // If the permissions aren't OK, display a message
          // if (!permissionsOk) {
          //   const missingPermissions = permissions
          //     ? permissions.filter((p) => !userStore!.hasPermission(p))
          //     : [];
          //   return (
          //     <DashboardLayout>
          //       <Paper>
          //         <Box p={3}>
          //           You do not have permission to view this page. You are missing the following
          //           permissions:
          //           <ul>
          //             {missingPermissions.map((mp) => (
          //               <li key={mp}>{mp.toUpperCase()}</li>
          //             ))}
          //           </ul>
          //         </Box>
          //       </Paper>
          //     </DashboardLayout>
          //   );
          // }
          // If the scope is not ok, redirect to home screen
          if (!scopeOk) {
            return <Redirect to={{ pathname: paths.root(), state: { from: location } }} />;
          }
          return (
            <Route
              {...rest}
              render={(props) => (Component ? <Component {...props} /> : render!(props))}
            />
          );
        }}
      </Observer>
    );
  }
}
