import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observable, action, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
} from '@material-ui/core';

import { AxiosResponse } from 'axios';

import moment from 'moment-timezone';

import { EllipsizedValue, fetchWrapper } from 'services/datagrid';

import Api, { RequestMetaData } from 'api';
import { inject, WithUserStore, WithToastStore, WithSettingStore } from 'types/stores';
import {Alert } from 'models';
import DashboardLayout from 'containers/DashboardLayout';
import styles from './styles';

import { setTitle } from 'services';

import { Filter } from 'components/FilterBar/FilterBar';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import Title from 'components/Title/Title';
import ChipStatusTag, { ChipStatusColors } from 'components/ChipStatusTag';

interface IBadgeConfig {
  LOW: string;
  MEDIUM: string;
  HIGH: string;
}

const PAGE_TITLE = 'Alerts';


/** Define props for this component */
type AlertsProps = WithStyles<typeof styles> & // Adds the classes prop
  RouteComponentProps & // Adds the router props (history, match, location)
  WithUserStore & // Adds the userStore prop
  WithToastStore &
  WithSettingStore;

/**
 * Container displaying active kiosk alert message if present and list of past alerts.
 */
@inject('userStore', 'toastStore', 'settingStore')
@observer
class Alerts extends React.Component<AlertsProps> {
  public constructor(props: AlertsProps) {
    super(props);
    makeObservable(this);
  }

  @observable private activeFilters: Record<string, unknown> = {};

  @observable public dateRange: DateRangeExternalPicker.DateRange =
    this.props.settingStore!.getDate(this.props.location.pathname);

  public getAlertsData = fetchWrapper(
    async (rmd: RequestMetaData) => {
      const order: 'ASC' | 'DESC' = 'DESC';

      return await Api.core.getAlerts({
        ...rmd,
        filters: {
          isActive: false,
          fromDate: this.dateRange.fromDate,
          toDate: this.dateRange.toDate,
          ...this.activeFilters,
        },
        sort: { sortBy: 'createdAt', sortOrder: order },
      });
    },

    (alert: Alert) => ({
      ...alert,
      createdBy: `${alert.createdBy.firstName} ${alert.createdBy.lastName}`,
      type: alert.type.toUpperCase(),
      cause: alert.cause ? alert.cause.toUpperCase() : undefined,
      severity: alert.severity ? alert.severity.toUpperCase() : undefined,
      duration: alert.deactivatedAt
        ? moment.utc(moment(alert.deactivatedAt).diff(moment(alert.createdAt))).format('HH:mm:ss')
        : '',
    }),
  );

  /** Sets the date range */
  @action.bound private updateDateRangeValue(range: DateRangeExternalPicker.DateRange) {
    this.props.settingStore!.setDate(this.props.location.pathname, range);
    this.dateRange = range;
    this.activeFilters = { ...this.activeFilters };
  }

  renderAlertMessage = (rowIndex: any, name: any, value: any, row: any) => (
    <EllipsizedValue value={value} max={44} />
  );

  renderSeverity = ({ value, row }: any) => {
    const background = this.badgeConfig[value as keyof IBadgeConfig] as ChipStatusColors;
    return <ChipStatusTag label={value} color={background} />;
  };

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
  }

  private badgeConfig: IBadgeConfig = {
    LOW: ChipStatusColors.YELLOW,
    MEDIUM: ChipStatusColors.ORANGE,
    HIGH: ChipStatusColors.RED,
  };

  renderCellAlertMessage = ({ value }: any) => <EllipsizedValue value={value} max={44} />;

  gridColumns = [
    { headerName: 'Type', field: 'type', minWidth: 100, flex: 1 },
    {
      headerName: 'Message',
      field: 'message',
      minWidth: 250,
      flex: 1,
      renderCell: this.renderCellAlertMessage,
    },
    {
      headerName: 'Severity',
      field: 'severity',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderSeverity,
    },
    {
      headerName: 'Created At',
      field: 'createdAt',
      minWidth: 250,
      flex: 1,
      type: 'dateTime',
      valueGetter: ({ value }: any) =>
        value && moment(new Date(value)).format('MMM DD, YYYY h:mm A'),
    },
    { headerName: 'Cause', field: 'cause', minWidth: 200, flex: 1 },
    { headerName: 'Created By', field: 'createdBy', minWidth: 200, flex: 1 },
    { headerName: 'Duration', field: 'duration', minWidth: 200, flex: 1 },
  ];

  filters: Filter[] = [
    { display: 'Type', id: 'type', label: 'Contains', type: 'text' },
    { display: 'Severity', id: 'severity', label: 'Contains', type: 'text' },
  ];

  render() {
    return (
      <DashboardLayout>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Title mb={3}>{PAGE_TITLE}</Title>
        </Box>
        <FilterBar
          filters={this.filters}
          onChange={(filters: Record<string, unknown>) => {
            this.activeFilters = filters;
          }}
          actions={{
            dateRange: {
              predefined: this.dateRange,
              onChange: this.updateDateRangeValue,
            },
          }}
        />
        <DataGridInfiniteScroll
          columns={this.gridColumns}
          fetch={this.getAlertsData}
          refetchKey={this.activeFilters}
          disableColumnMenu
          pathname={this.props.location.pathname}
        />
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Alerts);
