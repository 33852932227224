import { alpha } from '@material-ui/core/styles/colorManipulator';
import { Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    stepper: {
      background: 'none',
      width: '60%',
      minWidth: theme.spacing(65),
      margin: '0 auto',
      marginBottom: theme.spacing(0),
    },
    datePicker: {
      border: `1px solid ${theme.palette.common.black}`,
      borderRadius: theme.shape.borderRadius,
      height: 60,
      paddingTop: theme.spacing(2.75),
      paddingLeft: theme.spacing(2),
    },
    inputLoading: {
      height: theme.spacing(1),
      width: theme.spacing(1),
    },
    accountStepForm: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    fullHeight: {
      height: '100%',
    },
    graphicContainer: {
      display: 'flex',
    },
    itemAmount: {
      fontWeight: 600,
    },
    itemSecondaryAction: {
      paddingRight: 0,
    },
    couponItem: {
      height: theme.spacing(7.5),
      backgroundColor: theme.palette.primary.light,
      borderRadius: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    checkbox: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    subtitle: {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.body1.fontSize,
    },
    bigProgress: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      backgroundColor: alpha(theme.palette.common.white, 0.5),
      borderRadius: '16px',
      zIndex: 500,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    upper: {
      textTransform: 'uppercase',
    },
    check: {
      height: theme.spacing(12),
      width: theme.spacing(12),
    },
    whiteBg: {
      backgroundColor: theme.palette.common.white,
    },
    firstItem: {
      paddingTop: 0,
    },
    userCard: {
      height: '100%',
    },
    buttonWrapper: {
      position: 'relative',
      width: '100%',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    backIconButton: {
      padding: 0,
      position: 'absolute',
      left: -36,
      top: 3,
      color: theme.palette.text.primary,
    },
    addLocationButton: {
      background: `${theme.palette.common.white} !important`,
      color: `${theme.palette.primary.main} !important`,
      border: `1px solid ${theme.palette.primary.main}`,
      width: theme.spacing(30.3125),
    },
    existingAccountButton: {
      display: 'block',
    },
    step: {
      '& span span': {
        '& .MuiSvgIcon-root': {
          width: theme.spacing(5),
          height: theme.spacing(5),
          color: 'transparent',
        },
        '& .MuiStepIcon-active': {
          color: `${theme.palette.primary.main}!important`,
          '& .MuiStepIcon-text': {
            fill: 'white !important',
            fontWeight: theme.typography.fontWeightBold,
          },
        },
        '& .MuiStepIcon-completed': {
          color: `${theme.palette.primary.main}!important`,
        },
        '& .MuiStepIcon-root': {
          color: theme.palette.common.white,
        },
        '& .MuiStepIcon-text': {
          fill: theme.palette.common.black,
          fontSize: '0.8rem',
          fontWeight: theme.typography.fontWeightBold,
        },
      },
      '& .MuiStepConnector-horizontal > span.MuiStepConnector-line': {
        display: 'none',
      },
    },
    textFieldInput: {
      border: `1px solid ${theme.palette.common.black}`,
      borderRadius: theme.shape.borderRadius,
      paddingTop: theme.spacing(3.75),
      paddingLeft: theme.spacing(2.625),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(2.25),
      height: theme.spacing(7.5),
      '& .MuiInputBase-root': {
        height: 16,
      },
      '& .MuiInputBase-input.Mui-disabled': {
        color: theme.palette.text.secondary,
      },
      '& .MuiAutocomplete-input:first-child': {
        padding: 0,
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
        padding: 0,
      },
      '& .MuiInputBase-input': {
        padding: 0,
      },
      '& .MuiAutocomplete-endAdornment .MuiIconButton-root': {
        color: theme.palette.common.black,
      },
      '& p': {
        marginTop: theme.spacing(1.2),
      },
    },
    selectField: {
      border: `1px solid ${theme.palette.common.black}`,
      borderRadius: theme.shape.borderRadius,
      paddingTop: theme.spacing(3.75),
      paddingLeft: theme.spacing(2.625),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(2.25),
      height: theme.spacing(7.5),
      '& .MuiInputBase-root': {
        color: theme.palette.text.secondary,
      },
      '& .MuiInputBase-input': {
        padding: 0,
        basckground: 'transparent',
      },
      '& .MuiSelect-icon': {
        top: 'auto',
        position: 'relative',
        color: theme.palette.common.black,
      },
    },
    resize: { fontSize: 16, height: 16 },
    nextStepButton: {
      marginTop: theme.spacing(12),
    },
    locationStepButtonMargin: {
      marginTop: theme.spacing(0),

      [theme.breakpoints.down('lg')]: {
        marginTop: theme.spacing(2),
      },
    },
    magnifyIcon: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      color: theme.palette.divider,
      cursor: 'pointer',
      position: 'relative',
       bottom: '6px',
      '&:hover': {
        color: theme.palette.primary.main,
      }
    },
    imageHidden: {
      [theme.breakpoints.down('md')]: {
        display: 'none'
      },
    },
    textPrimary: {
      fontSize: '50px',
      color: '#00DDB4',
    },
    textSecondary: {
      fontSize: '50px',
      color: '#230C34',
    },
  });

export default styles;

export const useStyles = makeStyles(styles);
