import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { Observer } from 'mobx-react';
import { inject } from 'types/stores';

import UserStore from 'types/stores/UserStore';

interface AnonRouteProps extends RouteProps {
  userStore?: UserStore;
}

/**
 * Same as Route, only makes sure that the current user isn't logged in.
 * Useful for routes that make sense only for non logged-in users.
 */
@inject('userStore')
export default class AnonRoute extends React.Component<AnonRouteProps> {
  render() {
    const { component: Component, userStore, render, location, ...rest } = this.props;
    return (
      <Observer>
        {() => {
          const redirectedFrom =
            location &&
            location.state &&
            (location.state as any).from &&
            (location.state as any).from.pathname;
          const queryString =
            location &&
            location.state &&
            (location.state as any).from &&
            (location.state as any).from.search;
          if (userStore!.loggedIn) {
            return (
              <Redirect
                to={{
                  pathname: redirectedFrom || '/',
                  search: queryString || '',
                  state: { from: location },
                }}
              />
            );
          }
          return (
            <Route
              {...rest}
              render={(props: any) => (Component ? <Component {...props} /> : render!(props))}
            />
          );
        }}
      </Observer>
    );
  }
}
