import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    dialog: {
      '& .MuiDialogActions-root':{
        padding: 24
      },
      '& .MuiDialogTitle-root':{
        '& .MuiTypography-root':{
          fontFamily: 'EuclidCircularB',
          fontSize: theme.typography.h5.fontSize,
          fontWeight: '400',
          lineHeight: '35.5px',
          letterSpacing: '0.25px',
        },
        paddingTop: '24px',
        paddingLeft: '24px',
        paddingBottom: '32px',
      },
      '& .MuiDialogContent-root': {
        width: 576,
      },
      '& .MuiTypography-root-MuiDialogTitle-root': {
        paddingTop: '24px',
        paddingLeft: '24px',
        paddingBottom: '32px',
        fontFamily: 'EuclidCircularB',
        fontSize: theme.typography.h5.fontSize,
        fontWeight: '400',
        lineHeight: '35.5px',
        letterSpacing: '0.25px',
      },
    },
    dialogButton: {
      borderRadius: '8px',
      textTransform: 'capitalize',
    },
    customInputLink: {
      fontSize: theme.typography.subtitle1.fontSize,
      paddingTop: theme.spacing(0),
      cursor: 'pointer',
    },
    magnifyIcon: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      color: theme.palette.divider,
      cursor: 'pointer',
      position: 'relative',
       bottom: '6px',
      '&:hover': {
        color: theme.palette.primary.main,
      }
    },
  });
