import React, { useState } from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { observer } from 'mobx-react';
import { Typography, Box } from '@material-ui/core';
import clsx from 'clsx';

interface ModuleCardProps extends WithStyles<typeof styles> {
  items?: string;
}

const ModuleCard = ({ classes }: ModuleCardProps) => {
  return (
    <Box>
      <Box className={classes.barModule}>
        <Typography className={classes.textPrimary}>Core</Typography>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(observer(ModuleCard));
