/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Typography, IconButton, LinearProgress } from '@material-ui/core';
import { ChevronLeft } from 'mdi-material-ui';

import { paths } from 'routes';
import { inject, WithToastStore, WithUserStore } from 'types/stores';
import Api, { getErrorMsg } from 'api';

import PictureUpload, { PictureUploadLayoutType } from './PictureUpload/PictureUpload';
import styles from './styles';
import CarouselScreenWrapper from 'components/CarouselScreenWrapper/CarouselScreenWrapper';
import Button from 'components/Button/Button';

type ProfilePictureProps = WithStyles<typeof styles> &
  RouteComponentProps &
  WithToastStore &
  WithUserStore;

/**
 * Displays the upload zone for user's profile picture
 */
@inject('toastStore', 'userStore')
@observer
class ProfilePicture extends React.Component<ProfilePictureProps> {
  constructor(props: ProfilePictureProps) {
    super(props);
    makeObservable(this);
  }

  /** Whether the info is currently being submitted */
  @observable public submitting = false;

  /** For rendering switch and upload progress bar value */
  @observable private uploading = false;
  @observable private uploadProgress = 0;


  /** Event hook passed to axios post request so we can render upload progress */
  @action.bound public onUploadProgress = (progressEvent: any) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    this.uploadProgress = percentCompleted;
  };

  @action.bound public handleProfileImageUpload = flow(function* (
    this: ProfilePicture,
    avatar: File,
  ) {
    const userStore = this.props.userStore!;
    try {
      this.uploading = true;
      yield Api.core.uploadProfilePicture(userStore.user!.id, avatar, this.onUploadProgress);
      this.goToNextScreen();
    } catch (error: any) {
      this.props.toastStore!.push({
        type: 'error',
        message: getErrorMsg(error) || 'An error has occurred',
      });
    } finally {
      this.uploading = false;
      this.uploadProgress = 0;
    }
  });

  @action.bound public goToNextScreen = () => {
      this.props.history.push(paths.signUp().success());
  };

  render() {
    const user = this.props.userStore!.user!;
    const { classes } = this.props;
    const uploadComponentLayout: PictureUploadLayoutType = 'vertical';
    return (
      <CarouselScreenWrapper submitting={this.uploading}>
        <Box className={classes.root}>
          <Box height="4px" position="absolute" width="100%" top="0" left="0">
            {this.uploading && <LinearProgress variant="determinate" value={this.uploadProgress} />}
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" className={classes.root}>
            <Box mb={7.5}>
              <Typography className={classes.title} align="center" gutterBottom>
                Profile picture
              </Typography>
            </Box>
            <Typography variant="body2" align="center" gutterBottom>
              {`Give us your best look!`}
            </Typography>
            <PictureUpload
              layout={uploadComponentLayout}
              profilePictureUrl={user.avatar}
              onUpload={this.handleProfileImageUpload}
            />
            <Box
              mt={2}
              width={'100%'}
              display={'flex'}
              alignContent={'center'}
              position={'relative'}
              style={{ height: '16px' }}>
              <IconButton className={classes.button} onClick={() => this.props.history.goBack()}>
                <ChevronLeft /> Back
              </IconButton>
              <Button
                className={classes.skipButton}
                onClick={this.goToNextScreen}
                size="small"
                color="primary"
                variant="text">
                Skip
              </Button>
            </Box>
          </Box>
        </Box>
      </CarouselScreenWrapper>
    );
  }
}

export default withStyles(styles)(ProfilePicture);
