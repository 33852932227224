import React from 'mdi-material-ui/React';
import { Box } from '@material-ui/core';
import { DatePicker, DatePickerProps } from '@material-ui/pickers';
import { observer } from 'mobx-react';
import useStyles from '../OutlinedInput/styles';
import clsx from 'clsx';
import { OutlinedInputVariant } from '../OutlinedInput/OutlinedInput';

type OutlinedDatePickerProps = Omit<DatePickerProps, 'margin'> & {
  inputVariant?: OutlinedInputVariant;
  
};

const OutlinedDatePicker = observer(({ inputVariant, style, ...rest }: OutlinedDatePickerProps) => {
  const _variant = inputVariant ? inputVariant : 'normal';
  const { textField, textFieldWrapper } = useStyles({
    startAdornment: false,
    variant: _variant,
  });
  return (
    <Box width={rest.fullWidth ? '100%' : '222px'} style={style} className={clsx(textFieldWrapper)}>
      <DatePicker className={textField} {...rest} />
    </Box>
  );
});

export default OutlinedDatePicker;
