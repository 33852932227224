import { useStores } from 'containers/App/App';
import { useScope } from 'hooks/useScope';

const useNotifications = () => {
  const { notificationStore, uiStore } = useStores();
  const [isScope] = useScope();

  const trigger = uiStore?.appBar.trigger;
  const mobileView = uiStore?.mobileView;
  const numberOfNotifications = notificationStore.numberOfNotifications;
  const setOpenNotification = notificationStore!.setOpenNotification;

  return { isScope, numberOfNotifications, setOpenNotification, trigger, mobileView };
};

export default useNotifications;
