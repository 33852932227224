import { Theme, createStyles } from '@material-ui/core';
import theme from 'containers/App/theme';

export const textFieldInput = {
  border: `1px solid ${theme.palette.common.black}`,
  borderRadius: theme.shape.borderRadius,
  paddingTop: theme.spacing(3.75),
  paddingLeft: theme.spacing(2.625),
  paddingRight: theme.spacing(1),
  paddingBottom: theme.spacing(2.25),
  height: theme.spacing(7.5),
  '& .MuiInputBase-root': {
    height: 16,
  },
  '& .MuiInputBase-input.Mui-disabled': {
    color: theme.palette.text.secondary,
  },
  '& .MuiAutocomplete-input:first-child': {
    padding: 0,
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
    padding: 0,
  },
  '& .MuiInputBase-input': {
    padding: 0,
  },
  '& .MuiAutocomplete-endAdornment .MuiIconButton-root': {
    color: theme.palette.common.black,
  },
  '& p': {
    marginTop: theme.spacing(1.2),
  },
};

const styles = (theme: Theme) =>
  createStyles({
    textFieldInput
  });

export default styles;
