import { useStores } from 'containers/App/App';
import { MenuGroup } from 'models/Menu';
import { Drawer } from 'types/stores';
import { filterMenuItemsByScope } from './helpers';
import { useMemo } from 'react';

const useMenu = () => {
  const { uiStore, userStore } = useStores();
  const currentScope = userStore.scope.kind;
  const accountId = userStore?.currentAccount?.id;
  const tenantId = userStore?.currentAccount?.tenant.id;
  const storeId = userStore?.currentAccount?.store?.id;
  const itemProps = {
    accountId,
    tenantId,
    storeId,
    hide: false,
  };
  const scopedItems = useMemo(
    () => filterMenuItemsByScope(currentScope, itemProps),
    [currentScope, accountId],
  );

  const mobileView = uiStore.mobileView;
  const menuOpen = uiStore.drawer.open;
  const showMenuIcon = mobileView || menuOpen ? false : true;
  const toggleGroup = uiStore?.toggleDrawerGroup;
  const isGroupOpen = (group: MenuGroup) => {
    return uiStore.drawer[group.toLowerCase() as unknown as keyof Drawer];
  };

  return { showMenuIcon, mobileView, scopedItems, toggleGroup, isGroupOpen };
};

export default useMenu;
