import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: { position: 'relative' },
    backLink: {
      display: 'flex',
      alignitems: 'center',
      color: theme.palette.text.secondary,
    },
    done: {
      backgroundColor: theme.palette.common.white,
      position: 'absolute',
      padding: theme.spacing(2),
      top: -theme.spacing(2),
      left: -theme.spacing(2),
      right: -theme.spacing(2),
      bottom: -theme.spacing(2),
      zIndex: 200,
    },
    greyText: {
      color: theme.palette.text.secondary,
    },
    title: {
      fontSize: 40,
    },
  });
