import { Box, Popper, CircularProgress } from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import React from "react";
import { useStyles } from "./styles";
import OutlinedInput from 'components/Input/OutlinedInput';
import { rest } from "lodash";

export const AutocompleteComponent = ({
  popperWidth, 
  loading, 
  label, 
  placeholder, 
  mapper,
  options, 
  inputChangeHandler, 
  handleChange,
  value,
}: any) => {
  const classes = useStyles();

  return (
    <Autocomplete
      filterOptions={(x) => x}
      multiple
      filterSelectedOptions
      disableCloseOnSelect={true}
      onInputChange={inputChangeHandler}
      onChange={handleChange}
      options={options}
      loading={loading}
      value={value}
      getOptionLabel={(v: any) =>  v[mapper.displayField?.value]|| v}
      getOptionSelected={(option, value) => option.name === value.name}
      renderInput={(params) => (
        <OutlinedInput
          className={classes.field}
          {...params}
          placeholder={placeholder}
          fullWidth
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderTags={() => null}
      renderOption={(v) => {
        return (
          <Box flexDirection="column">
            <Box component="span">{v[mapper.displayField?.value]}</Box>
            {mapper.displayField?.additional && (
              <Box
                component="span"
                style={{ display: 'block', opacity: '50%', fontSize: '0.8em' }}>
                {Array.isArray(mapper.displayField?.additional.value)
                  ? [...mapper.displayField?.additional.value]
                      .map((i) => v[i])
                      .join(
                        mapper.displayField?.additional.separator
                          ? mapper.displayField?.additional.separator
                          : ' ',
                      )
                  : v[mapper.displayField?.additional.value]}
              </Box>
            )}
          </Box>
        );
      }}
      PopperComponent={(props) => 
        <Popper {...props} style={{width: `${popperWidth}px`}} className={classes.popper} placement="bottom-start" />}
    />
  )
}
