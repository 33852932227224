import React, { useState, useCallback } from 'react';
import Downshift from 'downshift';
import OutlinedInput from 'components/Input/OutlinedInput';
import { Box } from '@material-ui/core';
import { ChipsList } from './ChipsList';

const TagsInputField = ({
  selectedTags: setSelectedTags,
  placeholder,
  tags,
  label,
  ...other
}: any) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedItem, setSelectedItem] = useState<string[]>(tags || []);

  const handleSelectedItem = useCallback(
    (value: string[]) => {
      setSelectedItem(value);
      setSelectedTags(value);
    },
    [setSelectedTags],
  );

  const handleKeyDown = useCallback(
    (event: any) => {
      const value = event.target.value;
      const items = selectedItem || [];

      if (event.keyCode === 13) {
        if (items.indexOf(value) !== -1) {
          setInputValue('');
          return;
        }
        if (!value.replace(/\s/g, '').length) return;

        handleSelectedItem([...items, value.trim()]);
        setInputValue('');
      }

      if (items.length > 0 && !inputValue.length && event.keyCode === 8) {
        const lastItemElement = items[items.length - 1];
        handleSelectedItem(items.slice(0, -1));
        setInputValue(lastItemElement);
      }
    },
    [selectedItem, inputValue],
  );

  const handleInputChange = useCallback((event: any) => {
    setInputValue(event.target.value);
  }, []);

  return (
    <Box>
      <ChipsList 
        values={selectedItem} 
        onDelete={(name: string) => {
          handleSelectedItem((selectedItem).filter((it) => name !== it));
        }} 
      />

      <Downshift
        id="downshift-multiple"
        inputValue={inputValue}
        selectedItem={selectedItem}>
        {({ getInputProps }) => {
          const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
            onKeyDown: handleKeyDown,
            placeholder,
          });
          return (
            <div>
              <OutlinedInput
                InputProps={{
                  onBlur,
                  onChange: (event: any) => {
                    handleInputChange(event);
                    onChange && onChange(event);
                  },
                  onFocus,
                }}
                label={label}
                {...other}
                {...inputProps}
                fullWidth
              />
            </div>
          );
        }}
      </Downshift>
    </Box>
  );
};

export default TagsInputField;
