import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { GridFooterContainer } from '@mui/x-data-grid-pro';

const FooterContainer = ({
  count,
  total,
  actions,
}: {
  count: number;
  total: number;
  actions?: React.ReactNode;
}) => {
  return (
    <GridFooterContainer>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={'center'}
        width="100%"
        pl={3}
        pr={3}>
        {actions}
        <Box style={{ display: 'flex', gap: '1rem' }}>
          <Typography>
            Displaying <span>{count ? count : 0}</span> / <span>{total ? total : 0}</span> results
          </Typography>
        </Box>
      </Box>
    </GridFooterContainer>
  );
};

export default FooterContainer;
