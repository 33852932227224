import { action, autorun, computed, makeObservable, observable, reaction } from 'mobx';
import BaseStore from './BaseStore';
import RootStore from './RootStore';
import * as LocalStorage from 'services/localStorage';
import { DateRange, getDateRange } from 'components/DateRangeExternalPicker';

type ValueScope = any;

type KeyValue = {
  [x: string]: any;
};

interface ObjectState {
  source: string;
  value: KeyValue;
}

interface Scope {
  scope: ValueScope;
  status: ObjectState[];
}

interface Setting {
  user: string | undefined;
  options: Scope[];
}

interface ValueOptions {
  keyName: string;
  value: Setting;
}

export default class SettingStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @observable public setting!: Setting;
  @observable public scope: Scope = {
    scope: this.rootStore.userStore.scope,
    status: [],
  };

  @action.bound init(): void {
    autorun(() => {
      this.setting = this.getSetting();
    });

    reaction(
      () => this.setting,
      () => {
        this.scope = this.getScope();
      },
    );
  }

  getAll():ValueOptions[]|undefined {
    const keyNameSettings = Object.keys(localStorage).filter((v) => v.includes('setting-'));
    if (keyNameSettings.length > 0) {
      return keyNameSettings.map((key) => ({ keyName: key, value: LocalStorage.get(key) as Setting }));
    }
    return undefined;
  }

  setAll(value: ValueOptions[]|undefined) {
    if(Array.isArray(value)){
      value.forEach(({ keyName, value }: ValueOptions) => {
          LocalStorage.set(keyName, value);
      });
    }
  }

  private setSetting(value: Setting) {
    LocalStorage.set(`setting-${value.user}`, value);
  }

  private getSetting(): Setting {
    const defaultSetting: Setting = {
      user: this.rootStore.userStore.user?.id,
      options: [],
    };
    const value = LocalStorage.get(`setting-${defaultSetting.user}`) as Setting;

    if (value) {
      const notStatus = value.options.some(setting => !setting.status)
      if(notStatus){
        this.setSetting(defaultSetting);
        return defaultSetting;
      }
      return value;
    } else {
      this.setSetting(defaultSetting);
      return defaultSetting;
    }
  }

  private setScope(scopeValue: Scope) {
    const scopes = this.setting.options.filter(
      ({ scope }) => JSON.stringify(scope) !== JSON.stringify(scopeValue.scope),
    );
    this.setting.options = [...scopes, scopeValue];
    this.setSetting(this.setting);
  }

  private getScope() {
    const initialScope = {
      scope: this.rootStore.userStore.scope,
      status: [],
    };
    const scope = this.setting.options.find(
      ({ scope }) => JSON.stringify(scope) == JSON.stringify(initialScope.scope),
    );
    if (scope) {
      return scope;
    } else {
      this.setScope(initialScope);
      return initialScope;
    }
  }

  private getState(sourceName: string): ObjectState | undefined {
    return this.getScope().status.find(({ source }) => source === sourceName);
  }

  private setStates(sourceName: string, typeName: string, value: unknown) {
    const scope = this.getScope();
    const otherStatus = scope.status.filter(({ source }) => source !== sourceName);
    const currentState = scope.status.find(({ source }) => source === sourceName)

    const newState: ObjectState = {
      source: sourceName,
      value: Object.assign(currentState?.value ?? {}, { [typeName]:value })
    };

    scope.status = [...otherStatus, newState];
    this.setScope(scope)
  }

  getSource(sourceName: string, key?: string) {
    const source = this.getState(sourceName)?.value;
    if (source) {
      return key ? source[key] : source;
    }
  }

  //utilities for saved history data

  getDate(sourceName: string, defaultType?:string): DateRange {
    const date = this.getSource(sourceName, 'date') as DateRange|undefined;
    if(date){
       switch(date.type){
         case 'Custom':
          return date
         default: 
           return getDateRange(date.type)
       }
    }
    return getDateRange(defaultType)
  }

  setDate(source: string, value: any): void {
    this.setStates(source, 'date', value);
  }

  getGrid(sourceName: string) {
    return this.getSource(sourceName, 'grid');
  }

  setGrid(source: string, params: any) {
    const sizes = this.getSource(source, 'grid');
    this.setStates(
      source,
      'grid',
      Object.assign(sizes ?? {}, {
        [params.colDef.field]: params.width,
      }),
    );
  }
}

export interface WithSettingStore {
  settingStore?: SettingStore;
}
