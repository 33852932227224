/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { observable, action, makeObservable, flow } from 'mobx';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  IconButton,
  DialogTitle,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPen
} from '@fortawesome/pro-regular-svg-icons';

import ImageIcon from 'components/ImageIcon';
import { inject, WithUserStore, WithToastStore } from 'types/stores';
import styles from './styles';
import PictureUpload from 'components/PictureUpload';
import { Entity } from 'models';
import { default as DialogButton } from 'components/Button/Dialog/Button';
import OutlinedInput from 'components/Input/OutlinedInput';

interface EditableTitleProps extends WithStyles<typeof styles>, WithToastStore, WithUserStore {
  entity: Entity;
  editableLogo: boolean;
  onRefresh?: () => void;
  onUpload?: (
    id: string,
    file: File,
    onUploadProgress: (progressEvent: ProgressEvent) => void,
  ) => void;

  onChange: (newTitle: string) => void;
}

@inject('userStore', 'toastStore')
@observer
class EditableTitle extends React.Component<EditableTitleProps> {
  constructor(props: EditableTitleProps) {
    super(props);
    makeObservable(this);
  }

  /** The current edited title */
  @observable public editedValue: string = this.props.entity.name;

  /** Determine if title is being edited  */
  @observable public editing = false;

  @observable logoUploadDialogOpen = false;

  @observable private uploadProgress = 0;

  @action.bound public onUploadProgress = (progressEvent: any) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    this.uploadProgress = percentCompleted;
  };

  /** Handle input edit */
  @action.bound private handleEdit(event: React.ChangeEvent<HTMLInputElement>) {
    this.editedValue = event.target.value;
  }
  /** handle reset title change */
  @action.bound private handleReset() {
    this.editing = false;
    this.editedValue = this.props.entity.name;
  }

  /** handle on submit */
  @action.bound private handleSubmit() {
    this.props.onChange(this.editedValue);
    this.editing = false;
  }

  @action.bound private handleLogoClick() {
    this.logoUploadDialogOpen = true;
  }

  @action.bound private handleLogoUploadClose() {
    this.logoUploadDialogOpen = false;
  }

  @observable private uploading = false;

  @action.bound public handleLogoUpload = flow(function* (this: EditableTitle, logo: File) {
    this.uploading = true;
    yield this.props.onUpload &&
      this.props.onUpload(this.props.entity!.id, logo, this.onUploadProgress);

    if (this.props.onRefresh) {
      this.props.onRefresh();
    }

    this.props.toastStore!.push({
      type: 'success',
      message: `Logo was successfully changed`,
    });
    this.uploading = false;
    this.uploadProgress = 0;
  });

  renderDialog() {
    const { classes } = this.props;
    return (
      <Dialog onClose={this.handleReset} open={this.editing}>
        <DialogTitle>
          <Typography className={classes.textLogo}>Edit title</Typography>
        </DialogTitle>
        <DialogContent>
          <Box style={{ width: '528px' }}>
            <OutlinedInput
              name="newTitle"
              label="New title"
              value={this.editedValue}
              onChange={this.handleEdit}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Box className={classes.containerAction}>
            <DialogButton
              autoFocus
              onClick={this.handleReset}
              variant="outlined"
              className={classes.buttonAction}
              color="primary">
              <Typography className={classes.buttonText} color="primary">
                Cancel
              </Typography>
            </DialogButton>
            <DialogButton
              variant="contained"
              className={classes.buttonAction}
              color="primary"
              onClick={() => this.handleSubmit()}>
              Change
            </DialogButton>
          </Box>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Box display="flex" flexDirection="row" alignItems="center" mb={3}>
        <Box mr={2}>
          {this.props.editableLogo && (
            <IconButton disableRipple className={classes.button} onClick={this.handleLogoClick}>
              <ImageIcon
                src={this.props.entity.logo && this.props.entity.logo}
                radius={12}
                type="logo"
              />
            </IconButton>
          )}
          {!this.props.editableLogo && this.props.entity.logo && (
            <ImageIcon src={this.props.entity.logo && this.props.entity.logo} type="logo" />
          )}
        </Box>

        <Box className={clsx(classes.titleInput, classes.titleLight)}>
          {this.editedValue}
          <Box
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            height="53.42px"
            ml={2}>
            <IconButton onClick={() => (this.editing = true)} className={classes.editIcon}>
              <FontAwesomeIcon color="primary" icon={faPen} />
            </IconButton>
          </Box>
        </Box>
        <PictureUpload
          layout={'horizontal'}
          profilePictureUrl={this.props.entity!.logo}
          onUpload={this.handleLogoUpload}
          loading={this.uploading}
          progress={this.uploadProgress}
          onClose={this.handleLogoUploadClose}
          open={this.logoUploadDialogOpen}
        />
        {this.renderDialog()}
      </Box>
    );
  }
}

export default withStyles(styles)(EditableTitle);
