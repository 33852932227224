import React from 'react';
import { observable, action, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
// import logo from 'images/pig_green_white.svg';
import { StripeProvider, Elements } from 'react-stripe-elements';
import {
  Typography,
  Box,
  Paper,
  CircularProgress,
  Container,
  Grid,
  Fade,
  Link,
  Dialog,
} from '@material-ui/core';
import clsx from 'clsx';

import { inject, WithToastStore, WithUserStore } from 'types/stores';

import { RouteComponentProps } from 'react-router-dom';
import config from 'config';
// import CartPreview from 'components/CartPreview';
import Overlay from 'components/Overlay';
import { createStripe } from 'services/stripe';
import { setTitle } from 'services/title';
import CheckoutForm from './CheckoutForm';
import fail from './fail.svg';
import styles from './styles';
import moment from 'moment';
import LoginForm from 'containers/Login/LoginForm';

const ConfettiGenerator = require('confetti-js').default;

interface CheckoutProps
  extends WithStyles<typeof styles>,
    WithUserStore,
    WithToastStore,
    RouteComponentProps<{ cartId: string }> {
  hidePayment?: boolean;
}

/**
 * The Accounts container component. Only users with admin scope can access this view.
 * It uses AccountsTable component which gets Account[] for rendering list of accounts.
 */
@inject('userStore', 'toastStore')
@observer
class Checkout extends React.Component<CheckoutProps> {
  constructor(props: CheckoutProps) {
    super(props);
    makeObservable(this);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public confetti: any;

  @observable private billingEntities: any[] = [];

  /** Whether Stripe has been loaded */
  @observable public stripeLoaded = false;

  /** The cart that we fetched */
  @observable public cart?: any;

  /** Whether the form is submitting */
  @observable public submitting = false;

  /** The account code */
  @observable public accountCode?: string;

  /** The confirmation code */
  @observable public confirmationNumber?: string;

  /** The current error string */
  @observable public error?: string;

  @observable public inProgress = false;

  @observable private openLogin = false;

  @observable private isOwnerPayment = false;

  @observable private resetPasswordToken = '';
  @observable private confirmEmailToken = '';

  @action.bound public handleSetProgress = (status: boolean) => {
    this.inProgress = status;
  };

  handleOpenLogin = (status: boolean) => {
    this.openLogin = status;
  };

  /** Sets stripeLoaded to true */
  @action.bound public setStripeLoaded() {
    this.stripeLoaded = true;
  }


  /** Starts the confetti! 🎉 */
  @action.bound public startConfetti() {
    this.confetti = new ConfettiGenerator({ target: 'confetti-canvas', max: 80 });
    this.confetti.render();
  }

  @computed public get loading() {
    return false
  }

  @computed public get expirationDatePassed() {
    if (!this.cart) {
      return false;
    }

    return moment(this.cart!.expiresAt).unix() < moment(Date.now()).unix();
  }

  @computed public get done() {
    return Boolean(this.confirmationNumber) && Boolean(this.accountCode);
  }
  /** Include the Stripe script when this component mounts */
  componentDidMount() {
    setTitle(`Checkout`);
  }
  componentWillUnmount() {
    this.confetti && this.confetti.clear();
  }
  renderCanvas() {
    return (
      <canvas
        id="confetti-canvas"
        style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0 }}></canvas>
    );
  }
  render() {
    const classes = this.props.classes;
    const { hidePayment } = this.props;
    if (this.loading) {
      return (
        <Box className={clsx(classes.root, classes.centerVertical, classes.blackText)}>
          {this.renderCanvas()}
          <CircularProgress color="primary" />
        </Box>
      );
    }

    return (
      <>
        <Dialog
          className={classes.noShadow}
          open={this.openLogin}
          onClose={() => this.handleOpenLogin(false)}>
          <Container className={classes.container}>
            {/* <Box display="flex" justifyContent="flex-end" marginTop={'15px'}>
              <IconButton onClick={() => this.handleOpenLogin(false)}>
                <Close color="inherit" />
              </IconButton>
            </Box> */}
            <Box className={classes.insideBox}>
              {this.inProgress && (
                <Box className={classes.loader}>
                  <CircularProgress />
                </Box>
              )}
              <LoginForm {...this.props} onStatusSubmit={this.handleSetProgress} />
            </Box>
          </Container>
        </Dialog>
        <Box className={classes.root}>
          {this.renderCanvas()}
          <Box>
            <img src={'logo'} alt="Shop" className={classes.pig} />
          </Box>
          {!this.loading && (
            <Container>
              <Fade in>
                <Paper elevation={0} className={classes.paper}>
                  <Overlay display={this.submitting || this.done}>
                    <Box pb={2}>
                      <CircularProgress />
                    </Box>
                    <Typography variant="h5" component="div">
                      Processing your order ...
                    </Typography>
                  </Overlay>
                  <Overlay display={this.done}>
                   {'Next Step'}
                  </Overlay>
                  <Overlay display={Boolean(this.error)}>
                    <Box pb={2} className={classes.img}>
                      <img src={fail} alt="success!" height="100%" />
                    </Box>
                    <Typography variant="h5" component="div" gutterBottom>
                      Whoops!
                    </Typography>
                    <Typography component="div" gutterBottom>
                      {`Something went wrong during the payment.`}
                    </Typography>
                    <Typography component="div" color="textSecondary">
                      {`Don't worry, your card hasn't been charged.`}
                    </Typography>
                  </Overlay>
                  {this.expirationDatePassed && (
                    <Box
                      p={6}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignContent={'center'}
                      alignItems={'center'}>
                      <Box pb={2} className={classes.img}>
                        <img src={fail} alt="success!" height="200em" />
                      </Box>
                      <Box pb={2}>
                        <Typography variant="h5" component="h1" align="center">
                          Sorry,
                        </Typography>
                      </Box>
                      <Typography align="center">
                        this offer has expired. <br /> Please contact us on{' '}
                        <Link href="mailto:support@shoptree.com">support@shoptree.com</Link>
                      </Typography>
                    </Box>
                  )}
                  {!this.expirationDatePassed && (
                    <Box p={0}>
                      <Grid container spacing={6}>
                        {/* {hidePayment && (
                          <Typography
                            style={{ fontSize: 28, width: '100%', marginBottom: 48 }}
                            align="center"
                            component="h1">
                            Checkout
                          </Typography>
                        )}
                        <Grid style={{ paddingTop: 84 }} item xs={12} md={hidePayment ? 12 : 6}>
                          <CartPreview cart={this.cart!} />
                        </Grid> */}

                        {!hidePayment && (
                          <Grid style={{ marginTop: '0px' }} item xs={12} md={6}>
                            <StripeProvider apiKey={config.stripe.publicKey}>
                              <Elements>
                                <CheckoutForm
                                  onPayment={(v)=> {console.log('onPayment', v)}}
                                  billingEntities={this.billingEntities}
                                  cart={undefined}
                                  isOwner={this.isOwnerPayment}
                                  onOpenLogin={this.handleOpenLogin}
                                />
                              </Elements>
                            </StripeProvider>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  )}
                </Paper>
              </Fade>
              <Box pt={3}>
                <Typography align="center" variant="body2">
                  If you have any questions or concerns, don’t hesitate to contact your Shop
                  Success Agent at 1.855.831.2323 or support@shoptree.com.
                </Typography>
              </Box>
              <Box pt={3}>
                <Typography align="center" className={clsx(classes.blackText, classes.miniText)}>
                  Made by Direct Tips Operations LLC | 2755 E Oakland Park Blvd. Ste 300, Ft.
                  Lauderdale, FL 33306
                </Typography>
              </Box>
            </Container>
          )}
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(Checkout);
