/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { observable, action, flow, computed, reaction, autorun, makeObservable } from 'mobx';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Box,
  Paper,
  InputAdornment,
  CircularProgress,
  LinearProgress,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { object, string } from 'yup';

import Api, { getErrorMsg } from 'api';
import { User } from 'models';
import { usPhoneRegex } from 'services/validators';

import PhoneInput from 'components/PhoneInput';

import GC from './GraphicContainer';
import { ReactComponent as OwnerImg } from './owner.svg';

import styles from './styles';
import theme from 'containers/App/theme';
import Button from 'components/Button/Button';
import OutlinedInput from 'components/Input/OutlinedInput';
import { WithToastStore, inject } from 'types/stores';
import { TStep } from './TenantOnBoard';

interface IOwner {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

interface OwnerProps
  extends WithStyles<typeof styles>,
    RouteComponentProps,
    WithToastStore {
  nextRoute: () => string;
  onSubmit: (type: TStep, payload: unknown) => void;
  value?: IOwner;
}

export type CancellablePromise<T> = Promise<T> & { cancel(): void };

const Owner = ({ value, onSubmit, classes, nextRoute, ...props }: OwnerProps) => {
  const fetchingExistingUser = false;
  const existingUser = false;

  const formik = useFormik({
    initialValues: value
      ? value
      : {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
        },
    validationSchema: object({
      firstName: string().required(),
      lastName: string().required(),
      email: string().email().required(),
      phone: string().matches(usPhoneRegex, 'Phone number is not valid').required(),
    }),
    onSubmit: (values) => {
      onSubmit('owner', values);
      props.history.push(nextRoute());
    },
  });

  const renderOwnerForm = () => {
    return (
      <form onSubmit={formik.handleSubmit}>
        <Box pb={2}>
          <OutlinedInput
            style={{ marginTop: 16 }}
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={(formik.touched.email && formik.errors.email) || ''}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.resize },
              endAdornment: (
                <InputAdornment position="end">
                  {fetchingExistingUser && <CircularProgress size={20} />}
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box pb={2}>
          <OutlinedInput
            id="firstName"
            name="firstName"
            label="First Name"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={(formik.touched.firstName && formik.errors.firstName) || ''}
            disabled={existingUser ? true : false}
            fullWidth
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.resize },
            }}
          />
        </Box>
        <Box pb={2}>
          <OutlinedInput
            id="lastName"
            name="lastName"
            label="Last Name"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={(formik.touched.lastName && formik.errors.lastName) || ''}
            disabled={!!existingUser}
            fullWidth
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.resize },
            }}
          />
        </Box>
        <Box pb={2}>
          <OutlinedInput
            id="phone"
            name="phone"
            label="Phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={(formik.touched.phone && formik.errors.phone) || ''}
            disabled={!!existingUser}
            fullWidth
            InputProps={{
              inputComponent: PhoneInput,
              disableUnderline: true,
              classes: { input: classes.resize },
            }}
          />
        </Box>
        <Button
          className={classes.nextStepButton}
          disableElevation
          fullWidth
          variant="contained"
          type="submit"
          disabled={!formik.isValid}
          data-cy={'next-button'}>
          Next
        </Button>
        {formik.isSubmitting && (
          <Box pt={2}>
            <LinearProgress />
          </Box>
        )}
      </form>
    );
  };
  return (
    <Paper elevation={0}>
      <Box minHeight={590} pt={7} pb={6.25} pr={4} pl={8.125}>
        <Grid container spacing={6} justifyContent="center">
          <Grid item xs={12} lg={6}>
            <GC>
              <GC.Title>{`Let's start with the Owner's info`}</GC.Title>
              <GC.Subtitle>
                <Box component={'span'} style={{ color: theme.palette.common.black }}>
                  The Owner will receive a confirmation email and be prompted to create a password
                </Box>
              </GC.Subtitle>
              <GC.SvgImage className={classes.imageHidden}>
                <OwnerImg />
              </GC.SvgImage>
            </GC>
          </Grid>
          <Grid item xs={12} md={9} lg={6}>
            {renderOwnerForm()}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default withStyles(styles)(inject('toastStore')(observer(Owner)));
