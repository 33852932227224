import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: { backgroundColor: theme.palette.common.white },
    smallText: {
      fontSize: 12,
    },
    title: {
      fontSize: '40px',
    },
  });
