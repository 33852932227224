import { useStores } from 'containers/App/App';
import theme from 'containers/App/theme';
import useStyles from './styles';
import { useEffect, useState } from 'react';
import { PopoverReference } from '@material-ui/core';

export default function useUserSection() {
  const { uiStore, userStore } = useStores();
  const styles = useStyles({ open: uiStore.drawer.open });
  /** The HTML element of the user menu button */
  const [userMenuButton, setUserMenuButton] = useState<HTMLElement | null>(null);
  const [animation, setAnimation] = useState<any>('');

  useEffect(() => {
    if (uiStore.drawer.open) {
      setAnimation(styles.avatarOpenDrawerAnimation);
    } else {
      setAnimation(styles.avatarSmallDrawerAnimation);
    }
    setTimeout(() => setAnimation(''), theme.transitions.duration.leavingScreen + 100);
  }, [uiStore.drawer.open]);

  /** Handles the onClick event on the user avatar, opening the user menu */
  const handleUserMenuClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    //React.MouseEvent<HTMLButtonElement>
    setUserMenuButton(event.currentTarget);
  };

  /** Handles the closing of the user menu */
  const handleUserMenuClose = () => {
    setUserMenuButton(null);
  };

  const logout = userStore.logout;
  const mobileView = uiStore?.mobileView;
  const anchorReference: PopoverReference | undefined = mobileView ? undefined : 'anchorPosition';
  const anchorPosition = mobileView
    ? undefined
    : {
        top: userMenuButton?.getBoundingClientRect().top || 0,
        left: 65,
      };

  return {
    userMenuButton,
    mobileView,
    logout,
    animation,
    anchorReference,
    anchorPosition,
    handleUserMenuClick,
    handleUserMenuClose,
  };
}
