import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    chip: {
      width: '80px',
    },
    statusValue: {
      textTransform: 'uppercase',
    },
    linkIcon: {
      display: 'flex',
      marginLeft: theme.spacing(1.25),
    },
    select: {
      border: 'none',
    }
  });
