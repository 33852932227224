/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Link, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import AnonLayout from 'components/AnonLayout';
import { observer } from 'mobx-react';
import React from 'react';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import { paths } from 'routes';
import styles from './styles';

type PageNotFoundProps = WithStyles<typeof styles> & RouteComponentProps;

/**
 * Displays a static 404 error message using AnonLayout
 */
@observer
class PageNotFound extends React.Component<PageNotFoundProps> {
  render() {
    const { classes } = this.props;
    return (
      <AnonLayout>
        <Box mt={4} mb={4}>
          <Typography variant="h4" component="h1" align="center">
            OOps!
          </Typography>
        </Box>
        <Box mt={4} mb={4}>
          <Typography align="center">(sad piggy)</Typography>
        </Box>
        <Typography>We can't seem to find the page you're looking for.</Typography>
        <Typography>Error code: 404</Typography>

        <Box mt={6}>
          <Typography align="left" component="div">
            <Link component={RouterLink} to={paths.root()} className={classes.backLink}>
              ← Back to dashboard
            </Link>
          </Typography>
        </Box>
      </AnonLayout>
    );
  }
}

export default withStyles(styles)(PageNotFound);
