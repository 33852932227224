/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { observer } from 'mobx-react';
import {
  action,
  observable,
  computed,
  reaction,
  toJS,
  IReactionDisposer,
  makeObservable,
} from 'mobx';
import validatorjs from 'validatorjs';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { Pencil, Check, Close } from 'mdi-material-ui';
import MaskedInput from 'react-text-mask';
import MobxReactForm from 'mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment'; // Material-ui date-picker dependency
import moment from 'moment';

import { inject } from 'types/stores';

import DP from 'components/DashPanel';

import styles from './styles';
import OutlinedInput from 'components/Input/OutlinedInput/OutlinedInput';
import OutlinedDatePicker from 'components/Input/OutlinedDatePicker';
import AutocompleteField from 'components/Autocomplete';
import { FieldOptions } from 'components/Autocomplete/Autocomplete';

// eslint-disable-next-line @typescript-eslint/no-var-requires
// const dvr = require('mobx-react-form/lib/validators/DVR');
// const MobxReactForm = require('mobx-react-form').default;

/* Define and extend mobx-react-form DVR plugin */
const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }: { validator: any; form: any }) => {
      /* Add custom rule for validating US phone numbers */
      // NOTE: regex is from validatorjs library:
      // https://github.com/validatorjs/validator.js/blob/master/lib/isMobilePhone.js
      const usPhoneRegex =
        /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/;
      const phoneFieldRule = {
        function: (value: any) => new RegExp(usPhoneRegex).test(value),
        message: 'The phone number is not a valid format.',
      };
      validator.register('phone', phoneFieldRule.function, phoneFieldRule.message);

      /* Overwrite specific validation errors */
      const customValidationMessages = {
        ...validator.getMessages('en'),
        required: 'This field is required',
      };
      validator.setMessages('en', customValidationMessages);
    },
  }),
};

interface FilterItem {
  label: string;
  value: string;
}

interface BaseProps {
  label: string;
  rules: string;
  extra: {
    editable: boolean;
  };
  value?: any;
}

interface TextField extends BaseProps {
  type: 'text' | 'tel' | 'date';
}

interface RadioGroupField extends BaseProps {
  type: 'radio-group';
  options: FilterItem[];
}

interface SelectField extends BaseProps {
  type: 'select';
  options: FilterItem[];
}

interface AsyncSelectField extends BaseProps {
  type: 'async-select';
  options: FieldOptions;
}

export interface FieldRules {
  [x: string]: TextField | RadioGroupField | SelectField | AsyncSelectField;
}

type Payload = Record<string, any>;

interface DynamicPanelProps extends WithStyles<typeof styles> {
  title: string;
  editable?: boolean;
  onUpdate: (v: Payload) => void;
  fields: FieldRules;
  payload?: Payload;
  fullHeight?: boolean;
}

@inject('toastStore', 'userStore')
@observer
class DynamicPanel extends React.Component<DynamicPanelProps> {
  constructor(props: DynamicPanelProps) {
    super(props);
    makeObservable(this);

    this.disposers.push(
      reaction(
        () => this.props.payload,
        (newPayload) => {
          this.payload = toJS(newPayload);
        },
      ),
    );
  }

  /** It's good practice to dispose of any autoruns that we set up during */
  private disposers: IReactionDisposer[] = [];

  /** Form and form dependencies */
  @observable private hooks = {
    onSuccess: (form: any) => {
      this.editing = false;
      this.props.onUpdate(this.prepareFormData(form));
    },
    onClear: () => {
      this.editing = false;
    },
  };

  @observable private editing = false;
  @observable private updating = false;

  @observable private payload?: Record<string, any> = toJS(this.props.payload);

  @computed private get form() {
    if (this.payload) {
      /* Create mobx-react-form instance using 'field[]', 'plugins' and event 'hooks' */
      return new MobxReactForm(
        { fields: this.createFormFields() },
        { plugins: plugins, hooks: this.hooks },
      );
    }
    return undefined;
  }

  private createFormFields() {
    const fields = Object.entries(this.props.fields).reduce((acc: FieldRules, [k, v]) => {
      acc[k] = {
        value: this.payload![k],
        ...v,
      };
      return acc;
    }, {});

    return fields;
  }

  private prepareFormData(form: any) {
    return form
      .map(({ name, value, type }: any) => {
        if (type === 'date' && value) value = moment(value).format('YYYY-MM-DD');
        return { name, value };
      })
      .reduce((dataObj: any, field: any) => {
        return { ...dataObj, [field.name]: field.value };
      }, {});
  }

  @action.bound private edit() {
    this.editing = true;
  }

  @computed public get displayActions() {
    return Boolean(this.props.editable);
  }

  public renderMaskedPhoneInput(props: any) {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => inputRef(ref ? ref.inputElement : null)}
        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        hideMask
      />
    );
  }

  componentWillUnmount() {
    this.disposers.map((disposer) => disposer());
  }

  render() {
    const { title, editable, classes } = this.props;
    const updating = this.updating;
    let showLabel = true;
    let showField = true;

    // Content of action menu based on component state; static || editing.
    // Either edit icon, or confirm and cancel icons.
    let actionContent;
    if (updating) {
      actionContent = <DP.LoadSpinner />;
    } else if (this.editing) {
      actionContent = (
        <>
          <DP.IconButton onClick={this.form.onClear} icon={Close} tooltip="Discard" />
          <DP.IconButton primary submit onClick={this.form.onSubmit} icon={Check} tooltip="Save" />
        </>
      );
    } else {
      actionContent = (
        <>
          {editable && <DP.IconButton primary onClick={this.edit} icon={Pencil} tooltip="Edit" />}
        </>
      );
    }

    /** Render plain text input field */
    const renderTextInput = (field: any) => (
      <OutlinedInput {...field.bind()} error={field.error} fullWidth />
    );

    const renderAutocompleteInput = (field: any) => {
      return (
        <AutocompleteField
          placeholder="Contains"
          options={field.options}
          {...field.bind()}
          error={field.error}
          fullWidth
        />
      );
    };

    const renderRadioGroupInput = (field: any) => (
      <Box className={classes.radioContainer}>
        <FormControl component="fieldset" style={{ paddingLeft: '15px' }}>
          <FormLabel>
            <DP.Label>{field.label}</DP.Label>
          </FormLabel>
          <RadioGroup value={field.value || ' '} {...field.bind()} error={field.error}>
            {field.options.map((item: FilterItem) => (
              <FormControlLabel
                className={classes.formControlLabel}
                key={item.label}
                value={item.value}
                control={<Radio className={classes.radio} color="primary" />}
                label={item.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    );

    /** Render date input field using DatePicker*/
    const renderDateInput = (field: any) => (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <OutlinedDatePicker
          style={{ width: '100%' }}
          value={field.value}
          label={'DOB'}
          onChange={(date) => date && field.set(moment(date).format('YYYY-MM-DD'))}
          onAccept={() => field.validate()}
          error={field.error}
          helperText={field.error}
          format={'MMM D YYYY'}
          variant="dialog"
          disableFuture
          fullWidth
          openTo="year"
          views={['year', 'month', 'date']}
        />
      </MuiPickersUtilsProvider>
    );

    /** Render phone input with masking */
    const renderPhoneInput = (field: any) => (
      <OutlinedInput
        {...field.bind()}
        error={field.error}
        InputProps={{ inputComponent: this.renderMaskedPhoneInput }}
        fullWidth
        style={{ width: '100%' }}
      />
    );

    if (!this.payload || !this.form) {
      return (
        <DP fullHeight={this.props.fullHeight}>
          <DP.Header>
            <DP.Title panel>{title}</DP.Title>
          </DP.Header>
          <DP.Body>
            <DP.Loading items={Object.keys(this.props.fields).length} />
          </DP.Body>
        </DP>
      );
    }
    return (
      <DP fullHeight>
        <form onSubmit={this.form.onSubmit}>
          <DP.Header>
            <Box display="flex" flexDirection="row" alignItems="center">
              <DP.Title panel>{title}</DP.Title>
            </Box>
            {this.displayActions && <DP.Actions>{actionContent}</DP.Actions>}
          </DP.Header>
          <DP.Body>
            {Array.from(this.form.fields).map(([name, field]: any) => {
              let fieldValue = field.value;
              showLabel = !!fieldValue && !this.editing; //? true : this.editing ? true : false;
              showField = this.editing ? field.extra.editable : fieldValue ? true : false;
              if (field.type === 'date') {
                fieldValue = field.value ? moment(field.value).format('MMM D YYYY') : '';
              }

              return (
                showField && (
                  <DP.Row key={name}>
                    {field.extra.editable && this.editing ? (
                      <>
                        {field.type == 'text' && renderTextInput(field)}
                        {field.type === 'date' && renderDateInput(field)}
                        {field.type === 'tel' && renderPhoneInput(field)}
                        {field.type === 'radio-group' && renderRadioGroupInput(field)}
                        {/* {field.type === 'async-select' && renderAutocompleteInput(field)} */}
                      </>
                    ) : (
                      <DP.Value>{fieldValue} </DP.Value>
                    )}
                    {showLabel && <DP.Label>{field.label}</DP.Label>}
                  </DP.Row>
                )
              );
            })}
          </DP.Body>
        </form>
      </DP>
    );
  }
}

export default withStyles(styles)(DynamicPanel);
