import BaseStore from './BaseStore';
import { observable, action, makeObservable } from 'mobx';
import RootStore from './RootStore';

/** This store contains info about the current route */
export default class AccountInfoStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }
  @observable public checkingForPendingIntegrations = false;
  @action.bound public setCheckingForPendingIntegrations(value: boolean) {
    this.checkingForPendingIntegrations = value;
  }
  public init() {}
}

export interface WithAccountInfoStore {
  accountInfoStore?: AccountInfoStore;
}
