import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `12px ${theme.spacing(3)}px`,
    },
    boxPaper: {
      marginBottom: theme.spacing(3),
      gap: '1.5rem',
    },
    paperBoxShadow: {
      boxShadow: 'none',
    },
    select: {
      border: 'none',
      height: 'auto',
      padding: 0,
      borderRadius: 0,
      '& .MuiSelect-icon': {
        display: 'none',
      },
      '& .MuiSelect-root': {
        backgroundColor: 'transparent !important',
        padding: 0,
      },
      '&.MuiInput-root': {
        paddingBottom: '0 !important',
      },
    },
    selectLabel: {
      color: theme.palette.text.primary,
    },
    filterPanel: {
      position: 'absolute',
      top: 52,
      left: 18,
      zIndex: 10,
      overflow: 'hidden',
      minWidth: theme.spacing(40),
      maxWidth: theme.spacing(87.5),
      filter: 'drop-shadow(0px 6px 24px #DDDDDD)',
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        left: theme.spacing(2),
        minWidth: '0',
        right: theme.spacing(2),
        top: theme.spacing(12),
      },
    },
    filterPanelHeader: {
      backgroundColor: theme.palette.primary.main,
    },
    headerTitle: {
      color: theme.palette.text.primary,
      fontSize: 24,
    },
    closeIconStyles: {
      width: 22,
      height: 22,
      paddingRight: theme.spacing(0.5),
    },
    closeIcon: {
      color: theme.palette.text.primary,
    },
    addFilterChip: {
      backgroundColor: 'transparent',
      '& .MuiChip-icon': {
        color: theme.palette.text.primary,
      },
    },
    filterChip: {
      marginRight: theme.spacing(2),
      cursor: 'pointer',
      '& .MuiChip-label': {
        fontSize: '14px',
        fontWeight: 400,
        color: theme.palette.text.primary,
      },
      '& .MuiChip-deleteIconColorPrimary': {
        color: theme.palette.text.primary,
      },
    },
    clearFilters: {
      height: 32,
      padding: '3px 9px',
      textTransform: 'initial',
      fontSize: '16px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      fontWeight: 400,
    },
    paperBoxShadowActive: {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
    graphButton: {
      justifyContent: 'center !important',
    },
    radio: {
      paddingLeft: theme.spacing(3),
    },
    formControlLabel: {
      '& .MuiFormControlLabel-label': {
        color: theme.menu.main,
      },
    },
    externalDateRange: {
      marginLeft: 'auto',
    },
  });
