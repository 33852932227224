import {
  Box,
  Grid,
} from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import Api from 'api';
import { action, computed, flow, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Account } from 'models';
import React, { Fragment } from 'react';
import { inject, WithToastStore, WithUserStore } from 'types/stores';
import styles from './styles';
import UserAccountPanel from 'components/UserAccountPanel';



interface WorkspaceProps extends WithStyles<typeof styles>, WithUserStore, WithToastStore {
  userId: string;
}

@inject('userStore', 'toastStore')
@observer
class Workspace extends React.Component<WorkspaceProps> {
  constructor(props: WorkspaceProps) {
    super(props);
    makeObservable(this);
  }
  @observable accounts?: Account[];

  @observable public dialogOpen = false;

  @observable public search = '';


  @action.bound public getUserAccounts = flow(function* (this: Workspace) {
      const resp = yield Api.core.getAccounts({
        filters: {
          userId: this.props.userId
        }
      });
      this.accounts = resp.data && resp.data.data;
  });

  componentDidMount(): void {
      this.getUserAccounts()
  }


  render() {
    if (!this.accounts) {
      return (
        <Grid container spacing={3}>
          <Grid item md={6} sm={12}>
            <Skeleton variant="rect" height={280} />
          </Grid>
          <Grid item md={6} sm={12}>
            <Skeleton variant="rect" height={280} />
          </Grid>
          <Grid item md={6} sm={12}>
            <Skeleton variant="rect" height={280} />
          </Grid>
          <Grid item md={6} sm={12}>
            <Skeleton variant="rect" height={280} />
          </Grid>
        </Grid>
      );
    }
    return (
      <Box>
        <Grid container spacing={3}>
          {this.accounts.map((account) => (
            <Grid item md={12} sm={12} key={account.id}>
              <UserAccountPanel>{account}</UserAccountPanel>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }
}

export default withStyles(styles)(Workspace);
