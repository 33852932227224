/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import qs from 'qs';

import { observer } from 'mobx-react';
import { WithStyles, Typography, Box, Link, withStyles } from '@material-ui/core';
import validatorjs from 'validatorjs';

import { paths } from 'routes';
import Api, { getErrorMsg } from 'api';
import { WithToastStore, WithUserStore, inject } from 'types/stores';
import { setTitle } from 'services/title';
import styles from './styles';
import CarouselScreenWrapper from 'components/CarouselScreenWrapper/CarouselScreenWrapper';
import Button from 'components/Button/Button';
import OutlinedInput from 'components/Input/OutlinedInput/OutlinedInput';
import PasswordField from 'components/PasswordField';
import { User } from 'models';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }: { validator: any; form: any }) => {
      const customValidationMessages = {
        ...validator.getMessages('en'),
        email: 'The email format is invalid',
        same: 'Please input the same password again',
        required: 'This field is required',
        min: ':attribute must be at least :min characters long',
      };
      validator.setMessages('en', customValidationMessages);
    },
  }),
};

interface SignUpProps
  extends WithStyles<typeof styles>,
    WithToastStore,
    WithUserStore,
    RouteComponentProps {}

interface SignUpFormHooks {
  onSuccess: (form: any) => void;
  onClear: (form: any) => void;
}

@inject('toastStore', 'userStore')
@observer
class SignUp extends React.Component<SignUpProps> {
  public fields = [
    {
      name: 'email',
      label: 'Email',
      rules: 'required|email|string',
    },
    {
      name: 'password',
      label: 'Password',
      type: 'password',
      rules: 'required|string|min:8',
    },
    {
      name: 'passwordConfirm',
      label: 'Confirm password',
      type: 'password',
      rules: 'required|string|same:password',
    },
  ];
  public constructor(props: SignUpProps) {
    super(props);
    makeObservable(this);
    // Initialize the form
    this.form = new MobxReactForm({ fields: this.fields }, { plugins, hooks: this.hooks });
  }
  /** Set the title on mount */
  componentDidMount() {
    setTitle(`Sign Up`);
  }

  public params: Record<string, any> = qs.parse(this.props.location.search, {
    ignoreQueryPrefix: true,
  });

  @observable public submitting = false;

  @action.bound public submit = flow(function* (this: SignUp) {
    try {
      this.submitting = true;
      const email = this.form.$('email').value;
      const password = this.form.$('password').value;

      let continueTo = paths.signUp().confirmEmailScreen();
      const { data } = yield this.createUser(email, password);

      if ((data as User).isEmailConfirmed) {
        continueTo = paths.signUp().personalInfo();

        yield this.props.userStore!.login({
          email: email,
          password: password,
        });
      }

      this.props.history.push({
        pathname: continueTo,
        state: { email },
        search: this.props.location.search,
      });

    } catch (e: any) {
      const { message, code } = e.response && e.response.data && e.response.data.error;
      if (code === 400) {
        this.form.$('email').invalidate(message);
      } else {
        this.props.toastStore!.error(getErrorMsg(e));
      }
    } finally {
      this.submitting = false;
    }
  });

  /** Does the POST call to create the user */
  @action.bound public createUser = flow(function* (this: SignUp, email: string, password: string) {
    try {
      return yield Api.core.createUser(email, password);
    } catch (e) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  /** The mobx-react-form instance */
  private form: any;
  /** The hooks for the form */
  private hooks: SignUpFormHooks = {
    onSuccess: () => {
      this.submit();
    },
    onClear: (form: any) => {
      form.clear();
    },
  };

  render() {
    const { classes } = this.props;
    // Get the fields from the form
    const emailField = this.form.$('email');
    const passwordField = this.form.$('password');
    const passwordConfirmField = this.form.$('passwordConfirm');
    // We need to pass these to onBlur manually because the onBlur for PasswordField
    // is not working. Probably has something to do with refs. TODO: Fix that in PasswordField
    const validatePasswordField = () => passwordField.validate({ showErrors: true });
    const validateConfirmPasswordField = () => passwordField.validate({ showErrors: true });
    return (
      <CarouselScreenWrapper submitting={this.submitting}>
        <Typography
          className={classes.title}
          variant="h4"
          component="h1"
          gutterBottom
          align="center">
          Sign Up
        </Typography>
        <form onSubmit={this.form.onSubmit}>
          <Box mt={5}>
            <OutlinedInput
              id={emailField.id}
              {...emailField.bind()}
              autoFocus
              fullWidth
              error={emailField.error}
            />
          </Box>
          <Box mt={2}>
            <PasswordField
              id={passwordField.id}
              {...passwordField.bind()}
              error={passwordField.error}
              onBlur={validatePasswordField}
            />
          </Box>
          <Box mt={2}>
            <PasswordField
              id={passwordConfirmField.id}
              {...passwordConfirmField.bind()}
              error={passwordConfirmField.error}
              onBlur={validateConfirmPasswordField}
            />
          </Box>
          <Box mt={2}>
            <Typography align="center" className={classes.smallText}>
              By signing up you agree to <br />
              <Link href="">&nbsp; Terms of Service</Link>
              &nbsp; and
              <Link href="">&nbsp; Privacy Policy</Link>
            </Typography>
          </Box>
          <Box mt={2} mb={2}>
            <Button
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={this.submitting}>
              Sign Up
            </Button>
          </Box>
          <Typography variant="subtitle1" align="center">
            {`Already have an account? `}
            <Link component={RouterLink} to={paths.signIn()} underline="always">
              Sign in
            </Link>
          </Typography>
        </form>
      </CarouselScreenWrapper>
    );
  }
}

export default withStyles(styles)(SignUp);
