import { Theme, alpha, createStyles, makeStyles } from '@material-ui/core';
import theme from 'containers/App/theme';

export const menuDrawerWidth = theme.spacing(33.25);
const whiteMenuBorder = (theme: Theme) => `1px solid ${alpha(theme.palette.common.white, 0.2)}`;

interface IStyleProps {
  menuOpen: boolean;
  mobileView: boolean;
}

const drawerOpenTransition = ({ mobileView }: IStyleProps) => {
  if (mobileView) {
    return theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    });
  }
  return theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  });
};
const drawerCloseTransition = ({ mobileView }: IStyleProps) => {
  if (mobileView) {
    return theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    });
  }
  return theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  });
};
const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) =>
  createStyles({
    drawer: {
      width: theme.menu.drawer.width.open,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      display: 'flex',
      flexDirection: 'column',
    },
    toolbar: {
      height: 80,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
    },
    border: {
      borderRight: whiteMenuBorder(theme),
    },
    drawerOpen: {
      width: theme.menu.drawer.width.open,
      transition: drawerOpenTransition,
    },
    drawerClose: {
      transition: drawerCloseTransition,
      overflowX: 'hidden',
      width: theme.menu.drawer.width.closed,
    },
    menuWrapper: {
      flexGrow: 1,
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
        width: '0 !important',
      },

      padding: ({ menuOpen, mobileView }) =>
        mobileView || menuOpen ? theme.spacing(0, 1.5) : theme.spacing(0),
      marginTop: theme.spacing(3),
      fontSize: theme.typography.body1.fontSize,
    },
  }),
);

interface IUseToolbarStylesProps {
  menuOpen: boolean;
}
export const useToolbarStyles = makeStyles<Theme, IUseToolbarStylesProps>((theme) =>
  createStyles({
    toolbar: {
      height: 80,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: ({ menuOpen }) => (menuOpen ? theme.spacing(2, 2, 1, 3) : theme.spacing(0, 2)),
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  }),
);

interface IUserSectionStyleProps {
  open: boolean;
  scrollable?: boolean;
}
export const useUserSectionStyles = makeStyles<Theme, IUserSectionStyleProps>((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      position: 'sticky',
      backgroundColor: theme.palette.common.white,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: ({ open }) => (open ? theme.spacing(3) : theme.spacing(1)),
      cursor: 'pointer',
      '&::before': {
        content: "''",
        display: ({ scrollable }) => (scrollable ? 'none' : 'block'),
        height: '1px',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        margin: '0 auto',
        backgroundColor: theme.palette.divider,
        width: '90%',
      },
      boxShadow: ({ scrollable }) => (scrollable ? `0px 4px 60px 0 rgba(0, 0, 0, 0.1)` : 'none'),
    },
  }),
);

const drawerHeight = `calc(100dvh - ${theme.appBar.mobile.height}px)`; // Adjust the height as needed
const drawerTopMargin = theme.appBar.mobile.height;
export const useMobileMenuDrawerStyles = makeStyles(() =>
  createStyles({
    drawer: {
      width: '100vw',
      height: drawerHeight,
      position: 'absolute',
      top: drawerTopMargin,
      backgroundColor: theme.palette.background.paper,
      zIndex: theme.zIndex.drawer,

      flexShrink: 0,
      whiteSpace: 'nowrap',
      display: 'flex',
      flexDirection: 'column',
    },
    drawerClose: {
      height: 0,
      overflow: 'hidden',
      transition: theme.transitions.create(['height'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    drawerOpen: {
      height: drawerHeight,
      transition: theme.transitions.create(['height'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  }),
);

export default useStyles;
