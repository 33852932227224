import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    workspaceRoot: {},
    divider: {
      margin: '24px 0',
    },
    label: {
      backgroundColor: 'lightgray',
      padding: '8px 24px',
      textTransform: 'uppercase',
    },
    logo: {
      height: '24.23px',
      width: '24.23px',
      marginRight: '8px',
    },
    logoBranch: {
      height: '8px',
      marginRight: '8px',
    },
    backdrop: {
      backgroundColor: theme.palette.primary.main,
      zIndex: theme.zIndex.drawer + 1,
    },
    depositsBackdrop: {
      backgroundColor: '#fff',
      zIndex: theme.zIndex.drawer + 1,
    },
    stepList: {
      paddingLeft: theme.spacing(2),
    },
    branchTitle: {
      textAlign: 'center',
      fontSize: theme.typography.body1.fontSize,
      fontWeight: 700,
      lineHeight: '22.82px',
    },
    branchHeaderTitle: {
      fontSize: theme.typography.h6.fontSize,
      fontWeight: 400,
      lineHeight: '20.29px',
    },
    branchSubTitle: {
      textAlign: 'center',
      fontWeight: 400,
      lineHeight: '17.75px',
    },
    branchContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: '272px',
      justifyContent: 'space-between',
    },
    avatar: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      '& .MuiAvatar-root': {
        width: 89,
        height: 89,
      },
      '& .MuiAvatar-colorDefault': {
        backgroundColor: '#E8E8E8',
      },
      dialogButton: {
        borderRadius: '8px',
        textTransform: 'capitalize',
      },
    },
    branchIcon: {
      paddingRight: '9px',
      '& .MuiAvatar-root': {
        width: '22px',
        height: '22px',
      },
    },
    button: {
      borderRadius: '14px',
      color: theme.palette.common.white,
      fontSize: '14px',
      width: '111px',
      height: '41px',
    },
    branchButton: {
      backgroundColor: '#4CB258',
      '&:hover': {},
    },
    addBankButton: {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.hover,
      },
    },
    primaryText: {
      textAlign: 'center',
      borderRadius: theme.shape.borderRadius,
      borderStyle: 'solid, 1px',
      color: theme.palette.primary.main,
      fontSize: '10px',
      width: '49px',
      height: '18px',
    },
    iconGreen: {
      // position: 'relative',
      // bottom: '6px',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
      borderRadius: '50px',
      width: '67px',
      height: '67px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    select: {
      '& .MuiInput': {
        root: {
          border: `0px`,
          paddingLeft: 0,
        },
      },
    },
  });
