import React from 'react';
import { Badge, IconButton, Tooltip } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useStyles } from './styles';
import useNotifications from './useNotifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { ToolbarItemWrapper } from '../ElevatedAppBar.tsx/WebToolbar/WebToolbar';

export const Notifications = observer(() => {
  const { isScope, numberOfNotifications, setOpenNotification, trigger, mobileView } =
    useNotifications();
  const classes = useStyles({ trigger, mobileView });
  if (!isScope(['owner', 'manager', 'admin'])) return <></>;
  return (
    <ToolbarItemWrapper>
      <Tooltip title={numberOfNotifications ? '' : 'no pending notifications'}>
        <IconButton
          className={classes.iconButton}
          onClick={() => setOpenNotification(numberOfNotifications ? true : false)}>
          <Badge badgeContent={numberOfNotifications} color="error">
            <FontAwesomeIcon icon={faBell} className={classes.notificationsIcon} />
          </Badge>
        </IconButton>
      </Tooltip>
    </ToolbarItemWrapper>
  );
});
