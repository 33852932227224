import React from 'react';
import { TextFieldProps } from '@material-ui/core/TextField';
import OutlinedInput from 'components/Input/OutlinedInput';

type CodeInputProps = Omit<TextFieldProps, 'variant'>;

/** A field for inputting confirmation codes */
function CodeInput(props: CodeInputProps) {
  return <OutlinedInput fullWidth label="Enter code" {...props} />;
}

export default CodeInput;
