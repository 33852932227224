import { alpha } from '@material-ui/core/styles/colorManipulator';
import { Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    form: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    resize: { fontSize: 16, height: 16 },
    magnifyIcon: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      color: theme.palette.divider,
      cursor: 'pointer',
      position: 'relative',
       bottom: '6px',
      '&:hover': {
        color: theme.palette.primary.main,
      }
    },
    button: {
      marginTop: theme.spacing(12),
    },
  });

export default styles;

export const useStyles = makeStyles(styles);
