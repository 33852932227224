import BaseStore from './BaseStore';
import { Location, History } from 'history';
import { observable, action, makeObservable } from 'mobx';
import RootStore from './RootStore';

/** This store contains info about the current route */
export default class RouterStore extends BaseStore {
  constructor(rootStore: RootStore){
    super(rootStore);
    makeObservable(this);
  }
  @observable public location!: Location;
  @observable public history!: History;
  @action.bound public setRoute(location: Location, history: History) {
    this.location = location;
    this.history = history;
  }
  public init() {}
}

export interface WithRouterStore {
  routerStore?: RouterStore;
}
