import React from 'react';
import { Box } from '@material-ui/core';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useStyles from './styles';
import clsx from 'clsx';

interface IMenuIconProps {
  className?: string;
  icon: IconDefinition;
  showIcon?: boolean;
}
const MenuIcon = ({ className, icon, showIcon = true }: IMenuIconProps) => {
  const { wrapper, iconStyles } = useStyles({ showIcon });
  return (
    <Box className={clsx(wrapper, className)}>
      <FontAwesomeIcon className={iconStyles} icon={icon} />
    </Box>
  );
};

export default MenuIcon;
