import RootStore from './RootStore';

/**
 * The base class for creating stores that aren't the root store.
 * All stores should inherit from this store.
 */
export default abstract class BaseStore {
  public rootStore: RootStore; // The reference to the root store
  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  /**
   * Does initialization of the store.
   */
  public abstract init(): void;
}
