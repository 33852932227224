import { makeStyles, createStyles, Theme } from '@material-ui/core';

interface IStyleProps {
  showIcon: boolean;
}
const useStyles = makeStyles<Theme, IStyleProps>(() =>
  createStyles({
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 30,
      height: 24,
    },
    iconStyles: {
      height: '100%',
      visibility: ({ showIcon }) => (showIcon ? 'visible' : 'hidden'),
    },
  }),
);

export default useStyles;
