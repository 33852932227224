import React from 'react';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { observable, action, flow, makeObservable, computed } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Link, Typography } from '@material-ui/core';

import { inject, WithUserStore, WithToastStore } from 'types/stores';
import { paths } from 'routes';

import PasswordField from 'components/PasswordField';

import styles from './styles';
import { getErrorMsg, makeQS } from 'api';
import theme from 'containers/App/theme';
import Button from 'components/Button/Button';
import OutlinedInput from 'components/Input/OutlinedInput';
import { WithRouterStore } from 'types/stores/RouterStore';
import { LocationState } from './Login';
import { FormHelperText } from '@mui/material';
import config from 'config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import GoogleLogin from 'components/GoogleLogin';

interface ILoginProps {
  title?: string;
  onStatusSubmit?: (status: boolean) => void;
}

enum LoginStep {
  EMAIL = 'email',
  PASSWORD = 'password',
}

type LoginProps = ILoginProps &
  WithStyles<typeof styles> &
  RouteComponentProps &
  WithRouterStore &
  WithUserStore &
  WithToastStore;
/**
 * The login screen container component.
 */
@inject('userStore', 'toastStore')
@observer
class LoginForm extends React.Component<LoginProps> {
  constructor(props: LoginProps) {
    super(props);
    makeObservable(this);
  }

  /** The email input value */
  @observable public email = '';
  /** The password input value */
  @observable public password = '';
  /** The error string, if present */
  @observable public error?: string;
  /** Whether we're currently logging in */
  @observable public loading = false;
  /** Current login step */
  @observable public step = LoginStep.EMAIL;

  /**
   * Handles the email change from an input component
   * @param e The onChange event
   */
  @action.bound public handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.email = e.target.value;
  }

  /**
   * Handles the password change from an input component
   * @param e The onChange event
   */
  @action.bound public handlePasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.password = e.target.value;
  }

  /** Logs the user in */
  @action.bound public login = flow(function* (this: LoginForm) {
    try {
      this.loading = true;
      yield this.props.userStore!.login({ email: this.email, password: this.password });
    } catch (error: any) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  });

  // @action.bound public async getSsoProvider() {
  //   try {
  //     this.loading = true;

  //     await this.props.userStore?.getSsoProvider(this.email);
  //     const { ssoProvider } = this.props.userStore!;
  //     if (ssoProvider?.name === 'Shop|| !ssoProvider) {
  //       this.loading = false;
  //     }
  //     return ssoProvider;
  //   } catch (error) {
  //     this.error = getErrorMsg(error);
  //     this.loading = false;
  //   }
  // }

  /**
   * Handles the main form submission.
   * @param e The form submitted event
   */
  @action.bound public handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (this.step === LoginStep.EMAIL) {
      // this.getSsoAndRedirect();
      this.step = LoginStep.PASSWORD;
    } else {
      this.login();
    }
  }

  @action.bound handleError(error: any) {
    if (error.response) {
      this.error = error.response.data.message;
    } else if (error.message) {
      this.error = error.message;
    }
  }

  // public async getSsoAndRedirect() {
  //   const ssoProvider = await this.getSsoProvider();
  //   this.error = undefined;
  //   if (!ssoProvider || (ssoProvider.name !== 'Shop' && !ssoProvider.loginRedirectUrl)) {
  //     this.loading = false;
  //     this.error = 'Something went wrong, please try again';
  //     return;
  //   }

  //   const { name, loginRedirectUrl } = ssoProvider;
  //   // If sso provider is not Shop, we should redirect to sso provider login page
  //   if (name !== 'Shop') {
  //     return window.location.replace(
  //       `${loginRedirectUrl}?${makeQS(undefined, {
  //         redirect: `${config.dashboard.baseUrl}${this.redirectedFrom}`,
  //         email: this.email,
  //       })}`,
  //     );
  //   }
  //   this.step = LoginStep.PASSWORD;
  // }

  public resetValues = () => {
    this.step = LoginStep.EMAIL;
    this.password = '';
    this.error = '';
  };

  @computed public get redirectedFrom() {
    return (this.props.location?.state as unknown as LocationState).from?.pathname;
  }

  render() {
    const { loggingIn } = this.props.userStore!;
    const { classes } = this.props;
    return (
      <>
        <Typography
          variant="h3"
          component="h3"
          gutterBottom
          align="center"
          className={classes.title}>
          {this.props.title ? this.props.title : 'Sign In'}
        </Typography>
        <form onSubmit={this.handleSubmit}>
          {this.step === LoginStep.EMAIL ? (
            <EmailStep
              email={this.email}
              loading={this.loading}
              error={this.error}
              onChange={this.handleEmailChange}
            />
          ) : (
            <PasswordStep
              value={this.password}
              loading={loggingIn}
              error={this.error}
              onChange={this.handlePasswordChange}
              onBack={this.resetValues}
              email={this.email}
              {...this.props}
            />
          )}
        </form>
      </>
    );
  }
}

export default withStyles(styles)(LoginForm);

interface IEmailStepProps {
  email: string;
  loading: boolean;
  error?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}
const EmailStep = (props: IEmailStepProps) => {
  const { loading, email, error, onChange } = props;
  return (
    <>
      <OutlinedInput
        label="Email"
        fullWidth
        autoFocus
        required
        type="email"
        id="email-input"
        disabled={loading}
        value={email}
        onChange={onChange}
      />
      <FormHelperText
        style={{
          color: theme.palette.error.main,
          fontSize: theme.typography.subtitle1.fontSize,
        }}
        error={!!error}>
        {error}
      </FormHelperText>
      <Box mt={6} mb={2}>
        <Button
          size="large"
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          loading={loading}
          disabled={!email}>
          Continue
        </Button>
      </Box>
      <Typography align="center" style={{ ...theme.typography.subtitle1 }}>
        {`Don't have an account? `}
        <Link component={RouterLink} to={paths.signUp().root()} underline="always">
          Sign up!
        </Link>
      </Typography>
      <Box mt={3} display='flex' justifyContent='center'>
        <GoogleLogin />
      </Box>
    </>
  );
};

interface IPasswordStepProps extends RouteComponentProps {
  value: string;
  loading: boolean;
  error?: string;
  onBack: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  email: string;
  classes: ClassNameMap<'backButton'>;
}
const PasswordStep = (props: IPasswordStepProps) => {
  const { value, loading, error, onChange, onBack, classes, history, email } = props;
  return (
    <>
      <PasswordField
        value={value}
        disabled={loading}
        onChange={onChange}
        required
        id="password-input"
      />
      <FormHelperText
        style={{
          color: theme.palette.error.main,
          fontSize: theme.typography.subtitle1.fontSize,
        }}
        error={!!error}>
        {error}
      </FormHelperText>
      <Box mt={1}>
        <Typography align="right">
          <Link
            onClick={() => history.push({ pathname: '/forgot-password', state: { email } })}
            underline="always"
            style={{ cursor: 'pointer' }}>
            Forgot password
          </Link>
        </Typography>
      </Box>
      <Box mt={3} mb={2}>
        <Button
          size="large"
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={!value}
          loading={loading}>
          Sign In
        </Button>
      </Box>
      <Button
        onClick={onBack}
        className={classes.backButton}
        variant="text"
        startIcon={<FontAwesomeIcon icon={faAngleLeft} />}>
        Back
      </Button>
    </>
  );
};
