import React from 'mdi-material-ui/React';
import { Box, Typography } from '@material-ui/core';
import useStyles from './styles';
import { Rating } from '@material-ui/lab';

export interface IReview {
  message: string;
  customer: string;
  rating: number;
}

interface IReviewCard {
  review: IReview;
  className?: string;
}

function ReviewCard({ className, review }: IReviewCard) {
  const classes = useStyles();
  return (
    <Box className={className || classes.reviewCard}>
      <Box component={'p'} className={classes.text}>
        {review.message}
      </Box>
      <Box className={classes.ratingWrapper}>
        <Typography component={'span'} className={classes.text}>
          {' '}
          {review.customer}
        </Typography>
        <Rating
          className={classes.rating}
          name="half-rating-read"
          defaultValue={review.rating}
          precision={0.5}
          size="medium"
          readOnly
        />
      </Box>
    </Box>
  );
}

export default ReviewCard;
