import useStyles from './styles';

export type CardIconColor =
  | 'primary'
  | 'secondary'
  | 'warning'
  | 'green'
  | 'red'
  | 'purple'
  | 'yellow';

export function useStats() {
  const classes = useStyles({});
  const getColorClass = (color: CardIconColor | undefined): any => {
    switch (color) {
      case 'yellow':
        return classes.iconYellow;
      case 'secondary':
      case 'warning':
      case 'red':
      case 'purple':
        return classes.iconPurple;
      default:
        return classes.iconGreen;
    }
  };

  return [getColorClass];
}
