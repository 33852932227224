import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observable, action, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import styles from './styles';
import LoginForm from './LoginForm';
import CarouselScreenWrapper from 'components/CarouselScreenWrapper/CarouselScreenWrapper';

export interface LocationState {
  from: Location;
}

type LoginProps = WithStyles<typeof styles> & RouteComponentProps;

@observer
class Login extends React.Component<LoginProps> {
  constructor(props: LoginProps) {
    super(props);
    makeObservable(this);
  }

  /** Whether we're currently logging in */
  @observable public submitting = false;

  @action.bound public handleSetSubmit = (status: boolean) => {
    this.submitting = status;
  };

  render() {
    return (
      <CarouselScreenWrapper submitting={this.submitting}>
        <LoginForm {...this.props} onStatusSubmit={this.handleSetSubmit} />
      </CarouselScreenWrapper>
    );
  }
}

export default withStyles(styles)(Login);
