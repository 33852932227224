import React from 'react';
import { Box, InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import useStyles from './styles';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

export type OutlinedInputVariant = 'normal' | 'small';

type OutlinedInputProps = Omit<TextFieldProps, 'margin' | 'variant'> & {
  variant?: OutlinedInputVariant;
};

/**
 * Input component with solid border and border radius of 16. This component is built with MUI TextField component.
 * @param variant User for rendering small and normal size of the input. When using 'small' variant, label prop should be replaced with placeholder prop.
 */
const OutlinedInput = observer(
  React.forwardRef(function OutlinedInputComponent(
    {
      variant = 'normal',
      label,
      error,
      helperText,
      style,
      fullWidth,
      className,
      InputProps,
      ...rest
    }: OutlinedInputProps,
    ref,
  ) {
    const startAdornment = InputProps?.startAdornment;
    let startAdornmentObj = startAdornment && typeof startAdornment === 'object' ? true : false;
    const _startAdornment = Array.isArray(startAdornment)
      ? startAdornment.length > 0
      : !!startAdornment;
    const { textField, textFieldWrapper, muiHelperText, startAdornmentWrapper, adornment } =
      useStyles({
        startAdornment: _startAdornment,
        startAdornmentObj,
        error: Boolean(error),
        disabled: rest.disabled,
        variant,
        multiline: rest.multiline && ((rest.value ?? '') as string).length > 53,
      });

    const inputProps = { ...InputProps, startAdornment: undefined };

    return (
      <Box width={fullWidth ? '100%' : '222px'}>
        <Box className={textFieldWrapper} style={style}>
          <Box className={startAdornmentWrapper}>
            {startAdornment && (
              <InputAdornment className={adornment} position="start">
                {startAdornment}
              </InputAdornment>
            )}
          </Box>
          <TextField
            inputRef={ref}
            label={variant === 'small' ? undefined : label}
            className={clsx(textField, className)}
            InputProps={inputProps}
            {...rest}
            fullWidth
          />
        </Box>
        <Box component="p" className={clsx(muiHelperText, 'MuiFormHelperText-root')}>
          {helperText || error}
        </Box>
      </Box>
    );
  }),
);

OutlinedInput.displayName = 'OutlinedInput';

export default OutlinedInput;
