import { IMenuGroup, IMenuItem, MenuItemType, MenuItems } from 'models/Menu';
import menuItems, { IPathProps } from './items';
import { isValueInArray } from 'utils/helper';
import { rootStore } from 'containers/App/App';
import { TScope } from 'models';

export const filterMenuItemsByScope = (currentScope: TScope, pathProps: IPathProps) => {
  const _items = menuItems(pathProps);

  const scopedItems = _items.reduce<MenuItems>((newItems, item): MenuItems => {
    if (item.type === MenuItemType.GROUP) {
      if (item.scopes && !showMenuGroup(item, currentScope)) {
        return newItems;
      }
      const { items } = item;
      const filteredItems = items.filter((item) => showItem(item, currentScope));
      if (!filteredItems.length) return newItems;
      newItems.push({ ...item, items: filteredItems });
      return newItems;
    }
    if (showItem(item, currentScope)) {
      newItems.push(item);
    }
    return newItems;
  }, []);
  return scopedItems;
};

export const filterMenuItemsByScopeOrPermission = ()=> {
   

}

const showItem = (item: IMenuItem, currentScope: TScope) => {
  if (item.hide) return false;
  /**
   * hasPermissions needs to be checked first.
   * If user does not have permission to view the item, we do not check other conditions.
   */
  return hasPermission(item) && (!item.scopes || isValueInArray(item.scopes, currentScope));
};

const showMenuGroup = (group: IMenuGroup, currentScope: TScope) => {
  if (group.hide) return false;
  return !group.scopes || isValueInArray(group.scopes, currentScope);
};

/**
 * Check if user has any permissions listed in roles array.
 * @param item Menu item
 * @returns true if user has any permissions or if role array does not exist / is empty, otherwise false
 */
const hasPermission = (item: IMenuItem) => {
  if (!item?.resources?.length) return true;
  return item.resources.some((permission) => rootStore.userStore.hasPermission(permission));
};
