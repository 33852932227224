import React from 'react';
import { observable, action, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Link as RouterLink } from 'react-router-dom';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Map, FormatListBulleted } from 'mdi-material-ui';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import * as paths from 'routes/paths';
import clsx from 'clsx';
import styles from './styles';

import {
  IconButton,
  Box,
  FormControl,
  Select,
  MenuItem,
  Paper,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  Typography,
  Tooltip,
  ClickAwayListener,
  SvgIconProps,
  SvgIcon,
} from '@material-ui/core';
import { Close, Plus } from 'mdi-material-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { omit } from 'lodash';
import moment from 'moment-timezone';
import CustomPicker, { DateRange, listOptionDate } from '../DateRangeExternalPicker/CustomPicker';
import TagInputComponent from '../TagInputComponent';
import DateRangePicker from 'components/DateRangeExternalPicker';
import OutlinedInput from 'components/Input/OutlinedInput';
import { default as DialogButton } from 'components/Button/Dialog/Button';

const findOptionDateByType = (typeName: string) => {
  return listOptionDate.find(({ type }) => type === typeName);
};

/** Raw filter for backend API consumption */
export interface RawFilter {
  id: string;
  value: string;
}

/** Filter types */
export type FilterType =
  | 'text'
  | 'number'
  | 'tags'
  | 'select'
  | 'range'
  | 'autocomplete'
  | 'date'
  | 'datePicker'
  | 'fields';

export type IntervalType = 'number' | 'date';

export type FieldType = 'text' | 'number' | 'select' | 'autocomplete' | 'tags';

/** Filter items when using 'select' filter type */
export interface FilterItem {
  label: string;
  value: string;
}

export interface Fields {
  id: string;
  label: string;
  type: FieldType;
}

export interface IntervalOptions {
  from: FilterItem;
  to: FilterItem;
  type: IntervalType;
}

export interface AdditionalValue {
  value: string | string[];
  separator?: string;
}

export interface SearchKey {
  name: string;
  phrase?: string;
}

export interface ValueOptions {
  value: string;
  additional?: AdditionalValue;
  keySearch: SearchKey;
}

export interface FilterOptions {
  fetch: (value: any) => unknown;
  displayField: ValueOptions;
  multipleChoice?: boolean;
}

/** Filter and it's attributes  */
export interface Filter {
  display: string;
  id: string;
  label: string;
  type: FilterType;
  items?: FilterItem[];
  interval?: IntervalOptions;
  options?: FilterOptions;
  value?: any;
  fields?: Fields[];
}

interface FieldRangeProps {
  interval: IntervalOptions;
  onChange: (value: Record<string, any>) => void;
  setFilterValue?: () => void;
  value: Record<string, any>;
  label?: string;
  fullWidth?: boolean;
}

const FieldRangeComponent = ({
  interval,
  onChange,
  value,
  ...other
}: FieldRangeProps) => {
  const [rangeValue, setValue] = React.useState<any>(value || {});

  const handleOnchange = ({ target }: any) => {
    const val =
      target.value === ''
        ? omit(rangeValue, target.name)
        : { ...rangeValue, [target.name]: target.value };

    setValue(val);
    onChange(val);
  };

  const updateDateRangeValue = ({ fromDate, toDate, type }: DateRange) => {
    setValue({ fromDate, toDate, type });
    const val = {
      [interval.from.value]: fromDate ? moment(fromDate).format() : fromDate,
      [interval.to.value]: toDate ? moment(toDate).format() : toDate,
      type,
    };

    onChange(val);
    //setFilterValue && setFilterValue();
  };

  return (
    <>
      {interval.type == 'date' && (
        <CustomPicker
          onChange={updateDateRangeValue}
          interval={interval}
          selected={
            Object.keys(rangeValue).length === 3
              ? {
                  fromDate: value[interval.from.value],
                  toDate: value[interval.to.value],
                  type: value && value.type,
                }
              : {}
          }
        />
      )}
      {interval.type == 'number' && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Box mr={2}>
            <OutlinedInput
              value={rangeValue[interval.from.value]}
              onChange={handleOnchange}
              label={interval.from.label}
              name={interval.from.value}
              fullWidth
            />
          </Box>
          <Box>
            <OutlinedInput
              value={rangeValue[interval.to.value]}
              onChange={handleOnchange}
              label={interval.to.label}
              name={interval.to.value}
              fullWidth
            />
          </Box>
        </Box>
      )}
    </>
  );
};

interface DateRangeProps {
  onChange: (range: DateRange) => void;
  predefined: DateRange;
}

type ButtonProps = {
  type: 'action',
  title: string,
  icon: React.ElementType<SvgIconProps>
  isActive: boolean,
  onActive: any,
} | {
  type: 'link'
  title: string,
  to: string,
  icon: React.ElementType<SvgIconProps>
}

interface ExternalActions {
  dateRange?: DateRangeProps
  button?: ButtonProps
}

interface FilterBarProps extends WithStyles<typeof styles> {
  filters: Filter[];
  onChange: (filters: Record<string, unknown>) => void;
  actions?: ExternalActions;
  defaultValue?: Record<string, unknown>;
}

/**
 * Standalone filter bar for containers/screens inspired by Google's Admin interface.
 * Accepts list of available filters and gives the user ability to set those filters.
 * When any filter value changes, 'onChange' callback returns record of all filters
 * that have values set.
 * @param filters list of all available filters
 * @param onChange callback that returns list of all filters that have values set
 */

@observer
class FilterBar extends React.Component<FilterBarProps> {
  constructor(props: FilterBarProps) {
    super(props);
    makeObservable(this);
  }

  /** List of available filters */
  @observable private filters: Filter[] = this.props.filters;

  /** Filter that is currently being edited */
  @observable private selectedFilter?: Filter;

  /** Value for controlled input. Final value gets set on 'Apply' button click */
  @observable private filterValue?: any;
  @observable private tempValue?: any;

  /** List of filters that have values set */
  @computed private get activeFilters(): Pick<Filter, 'id' | 'value' | 'options'>[] {
    return this.filters
      .filter((f) => f.value)
      .map((f) => ({
        id: f.id,
        value: f.value,
        options: f.options,
      }));
  }

  /**
   * Filters parsed as {filterName: 'filterValue', ... }
   * record to be returned with onChange callback
   */
  @computed private get rawFilters(): Record<string, unknown> {
    return this.activeFilters.reduce((data: any, activeFilter) => {
      let extraValue;

      if (Array.isArray(activeFilter.value)) {
        const isMore = [...activeFilter.value].length > 1;
        const keyName = isMore ? `${activeFilter.id}s` : activeFilter.id;

        if (activeFilter.options) {
          const keySerach: SearchKey = activeFilter.options.displayField.keySearch;
          const mapValue = [...activeFilter.value].map((item) => item[keySerach.name]);
          extraValue = { [keyName]: isMore ? mapValue : mapValue[0] };
        } else {
          extraValue = { [keyName]: isMore ? activeFilter.value : activeFilter.value[0] };
        }
      } else if (typeof activeFilter.value === 'object') {
        extraValue = activeFilter.value;
      } else {
        extraValue = { [activeFilter.id]: activeFilter.value };
      }

      return { ...data, ...extraValue };
    }, {});
  }

  /** If any filter has value set, we show 'Clear all filters' button */
  @computed private get showClearFilters(): boolean {
    return this.activeFilters.length > 0;
  }

  /** onChange callback for controlled input */
  @action.bound private updateInputValue(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    this.filterValue = e.target.value;
  }

  @action.bound private updateFildsValue(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    if (e.target.value === '') {
      delete this.tempValue[e.target.name];
      delete this.filterValue[e.target.name];
    } else {
      this.tempValue = { ...this.tempValue, [e.target.name]: e.target.value };
    }

    if (Object.keys(this.tempValue).length === this.selectedFilter?.fields?.length) {
      this.filterValue = this.tempValue;
    } else {
      this.filterValue = undefined;
    }
  }

  /** onChange callback for controlled input array */
  @action.bound private updateInputItems(items: any) {
    if (Array.isArray(items) && !items.length) {
      this.filterValue = undefined;
    } else {
      this.filterValue = items;
    }
  }

  /** onChange callback for controlled input */

  @action.bound private updateRangeValue(range: Record<string, unknown>) {
    if (Object.keys(range).length > 0) {
      this.filterValue = range;
    } else {
      this.filterValue = undefined;
    }
  }

  /** Sets selectedFilter and shows filter panel */
  @action.bound private selectFilter(e: React.ChangeEvent<{ value: unknown }>) {
    e.preventDefault();
    const filterId = e.target.value;
    this.selectedFilter = this.filters.filter((f: Filter) => f.id === filterId)[0];
  }

  /** Clears selected filter and input value */
  @action.bound private clearSelectedFilter() {
    this.selectedFilter = undefined;
    this.filterValue = undefined;
  }

  /** Apply filter and trigger callback with new filter values */
  @action.bound public setFilterValue() {
    this.filters = this.filters.map((filter) =>
      this.selectedFilter && filter.id === this.selectedFilter.id
        ? { ...filter, value: this.filterValue }
        : filter,
    );
    this.clearSelectedFilter();
    this.props.onChange(this.rawFilters);
  }

  /** Apply filter and trigger callback with new filter values */
  @action.bound public setDefaultValue(defaultValue: Record<string, unknown>) {
    defaultValue &&
      Object.keys(defaultValue).forEach((e) => {
        this.filters = this.filters.map((filter) =>
          filter.id === e ? { ...filter, value: defaultValue[e] } : filter,
        );
      });

    this.props.onChange(this.rawFilters);
  }

  @action.bound public removeFilter(filterId: string) {
    this.filters = this.filters.map((filter) =>
      filter.id === filterId ? { ...filter, value: undefined } : filter,
    );
    this.props.onChange(this.rawFilters);
  }

  @action.bound public clearAllFilters() {
    this.filters = this.filters.map((f) => ({ ...f, value: undefined }));
    this.props.onChange(this.rawFilters);
  }

  @action.bound public onKeyPressEnter(event: any) {
    event && event.keyCode === 13 && this.setFilterValue();
  }

  componentDidUpdate() {
    if (JSON.stringify(`${this.filters}`) !== JSON.stringify(`${this.props.filters}`)) {
      this.filters = this.props.filters;
    }
  }

  componentDidMount() {
    this.props.defaultValue && this.setDefaultValue(this.props.defaultValue);
  }

  render() {
    const { classes } = this.props;
    const showFilters = !!this.filters.length;
    return (
      <Box display="flex" flexDirection="row" className={classes.boxPaper}>
        {showFilters && (
          <Paper className={classes.paperBoxShadow} style={{ width: '100%' }}>
            <Box className={classes.root}>
              <Box display="flex" flexDirection="row" alignItems={'center'}>
                {/* Filter select, lists only non-active filter: */}
                {this.filters.length > 0 && (
                  <Select
                    disabled={this.filters.length === 0}
                    className={classes.select}
                    displayEmpty
                    native={false}
                    onChange={this.selectFilter}
                    disableUnderline
                    MenuProps={{
                      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                      transformOrigin: { vertical: 'top', horizontal: 'left' },
                      getContentAnchorEl: null,
                    }}
                    value=""
                    renderValue={() => (
                      <Chip
                        className={clsx(classes.filterChip, classes.addFilterChip)}
                        icon={<Plus fontSize="small" />}
                        label={
                          <Typography className={classes.selectLabel}>Add a filter</Typography>
                        }
                      />
                    )}>
                    {this.filters
                      .filter((filter) => !filter.value)
                      .map((filter) => (
                        <MenuItem key={filter.id} value={filter.id}>
                          {filter.display}
                        </MenuItem>
                      ))}
                  </Select>
                )}

                {/* Active filters: */}
                {this.filters
                  .filter((f) => f.value)
                  .map((f) => {
                    let value = '';
                    if (f.items) {
                      value = f.items.filter((i: FilterItem) => f.value === i.value)[0].label;
                    } else if (f.fields) {
                      value = Object.values(f.value).join(' - ');
                    } else if (Array.isArray(f.value)) {
                      value = `"${f.value.length} item${f.value.length > 1 ? 's' : ''}"`;
                    } else if (f.interval) {
                      if (f.value.type && f.value.type !== 'date-timer') {
                        const option = findOptionDateByType(f.value.type);
                        value = option
                          ? option?.label
                          : `"${moment(f.value[f.interval.from.value]).format('LLL')} > ${moment(
                              f.value[f.interval.to.value],
                            ).format('LLL')}"`;
                      } else {
                        value =
                          f.interval.type === 'date'
                            ? `"${moment(f.value[f.interval.from.value]).format('LLL')} > ${moment(
                                f.value[f.interval.to.value],
                              ).format('LLL')}"`
                            : `"${f.value[f.interval.from.value]} ${
                                f.value[f.interval.to.value]
                                  ? ` > ${f.value[f.interval.to.value]}`
                                  : ''
                              }"`;
                      }
                    } else if (typeof f.value === 'object' && Object.keys(f.value).length === 1) {
                      const val = Object.entries(f.value);
                      value = `${val[0][1]}`;
                    } else {
                      value = `"${f.value}"`;
                    }

                    const open = () => {
                      this.selectedFilter = f;
                      this.filterValue = f.value;
                    };
                    const remove = () => this.removeFilter(f.id);
                    return (
                      <Chip
                        key={f.id}
                        className={classes.filterChip}
                        label={`${f.display}: ${value}`}
                        color="primary"
                        onClick={open}
                        onDelete={remove}
                        deleteIcon={<Close className={classes.closeIconStyles} />}
                      />
                    );
                  })}
                {/* Filter details panel: */}
                {this.selectedFilter && (
                  <ClickAwayListener onClickAway={this.clearSelectedFilter}>
                    <Paper className={classes.filterPanel}>
                      <Box
                        p={2}
                        className={classes.filterPanelHeader}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between">
                        <Typography className={classes.headerTitle}>
                          {this.selectedFilter.display}
                        </Typography>
                        <IconButton
                          className={classes.closeIcon}
                          size="small"
                          onClick={this.clearSelectedFilter}>
                          <Close className={classes.closeIconStyles} />
                        </IconButton>
                      </Box>
                      <Box p={2} pt={3}>
                        <Box>
                          {this.selectedFilter.type === 'text' && (
                            <OutlinedInput
                              value={this.filterValue || ''}
                              onChange={this.updateInputValue}
                              label={this.selectedFilter.label}
                              fullWidth
                              inputRef={(input) => {
                                setTimeout(() => {
                                  input && input.focus();
                                }, 100);
                              }}
                              onKeyDown={(event) => this.onKeyPressEnter(event)}
                            />
                          )}

                          {this.selectedFilter.type === 'fields' && this.selectedFilter.fields && (
                            <Box
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '2rem',
                              }}>
                              {this.selectedFilter.fields.map((field) => {
                                let fieldValue = '';

                                if (this.filterValue && this.filterValue[field.id]) {
                                  fieldValue = this.filterValue[field.id];
                                } else if (this.tempValue && this.tempValue[field.id]) {
                                  fieldValue = this.tempValue[field.id];
                                }

                                return (
                                  <OutlinedInput
                                    value={fieldValue}
                                    onChange={this.updateFildsValue}
                                    label={field.label}
                                    name={field.id}
                                    fullWidth
                                    key={field.id}
                                  />
                                );
                              })}
                            </Box>
                          )}

                          {this.selectedFilter.type === 'tags' && (
                            <TagInputComponent
                              options={this.selectedFilter.options}
                              selectedTags={this.updateInputItems}
                              tags={this.filterValue}
                              inputRef={(input: any) => {
                                setTimeout(() => {
                                  input && input.focus();
                                }, 100);
                              }}
                              placeholder={'add'}
                              label={this.selectedFilter.label}
                              onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) =>
                                this.onKeyPressEnter(event)
                              }
                            />
                          )}

                          {this.selectedFilter.type === 'select' && (
                            <FormControl component="fieldset">
                              <RadioGroup
                                value={this.filterValue || ' '}
                                onChange={this.updateInputValue}
                                onKeyDown={(event) => this.onKeyPressEnter(event)}>
                                {this.selectedFilter.items &&
                                  this.selectedFilter.items.map((item: FilterItem) => (
                                    <FormControlLabel
                                      className={classes.formControlLabel}
                                      key={item.label}
                                      value={item.value}
                                      control={<Radio className={classes.radio} color="primary" />}
                                      label={item.label}
                                    />
                                  ))}
                              </RadioGroup>
                            </FormControl>
                          )}
                          {this.selectedFilter.type === 'autocomplete' && (
                            <Autocomplete
                              options={this.selectedFilter.items || []}
                              getOptionSelected={(option, value) => option.value === value.label}
                              getOptionLabel={(o) => o.label || ''}
                              onChange={(e, i: FilterItem | null) => {
                                if (i) {
                                  this.filterValue = i.value;
                                } else {
                                  this.filterValue = undefined;
                                }
                              }}
                              onKeyDown={(event) => this.onKeyPressEnter(event)}
                              renderInput={(params) => (
                                <OutlinedInput
                                  {...params}
                                  fullWidth
                                  label={this.selectedFilter!.value}
                                />
                              )}
                            />
                          )}

                          {this.selectedFilter.interval && this.selectedFilter.type === 'range' && (
                            <FieldRangeComponent
                              interval={this.selectedFilter.interval}
                              value={this.filterValue}
                              onChange={this.updateRangeValue}
                              setFilterValue={this.setFilterValue}
                              label={this.selectedFilter.label}
                              fullWidth
                            />
                          )}

                          {this.selectedFilter.type === 'date' && (
                            <> {this.selectedFilter.type} :: Cooming soon</>
                          )}
                        </Box>
                        <Box mt={4} display="flex" flexDirection="row" justifyContent="flex-end">
                          <DialogButton
                            type="submit"
                            variant="contained"
                            disabled={!this.filterValue}
                            onClick={this.setFilterValue}>
                            APPLY
                          </DialogButton>
                        </Box>
                      </Box>
                    </Paper>
                  </ClickAwayListener>
                )}

                {/* Clear all filters button: */}
                {this.showClearFilters && (
                  <Button
                    variant="text"
                    size="small"
                    color="primary"
                    className={classes.clearFilters}
                    onClick={this.clearAllFilters}>
                    Clear all filters
                  </Button>
                )}
              </Box>
            </Box>
          </Paper>
        )}
        {(this.props.actions && this.props.actions.dateRange) && (
          <Paper className={clsx(classes.externalDateRange, classes.paperBoxShadow)}>
            <Box className={classes.root}>
              <DateRangePicker
                onChange={this.props.actions.dateRange.onChange}
                predefinedRange={this.props.actions.dateRange.predefined}
                // isIntegrated
                isFormField
              />
            </Box>
          </Paper>
        )}

        {(this.props.actions && this.props.actions.button && this.props.actions.button.type == 'link') && (
          <Paper className={classes.paperBoxShadow}>
            <Box className={classes.root}>
              <Tooltip
                title={this.props.actions.button.title}
                placement="top"
                enterDelay={500}>
                <IconButton
                  color="default"
                  size="small"
                  component={RouterLink}
                  to={this.props.actions.button.to}
                  >
                    <SvgIcon component={this.props.actions.button.icon} fontSize="small"/>
                </IconButton>
              </Tooltip>
            </Box>
          </Paper>
        )}

        {(this.props.actions && this.props.actions.button && this.props.actions.button.type == 'action') && (
          <Paper
            className={clsx(
              this.props.actions.button.isActive ? classes.paperBoxShadowActive : classes.paperBoxShadow,
              classes.graphButton,
              classes.root,
            )}
            onClick={this.props.actions?.button.onActive}
           >
            <Tooltip
              title={this.props.actions.button.title}
              placement="top"
              enterDelay={500}>
              <SvgIcon component={this.props.actions.button.icon} fontSize="small"/>
            </Tooltip>
          </Paper>
        )}
      </Box>
    );
  }
}

export default withStyles(styles)(FilterBar);
