import React, { Fragment, Component } from 'react';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Link, Typography, Icon } from '@material-ui/core';

import { paths } from 'routes';
import { inject, WithUserStore } from 'types/stores';
import Api from 'api';

import styles from './styles';
import CarouselScreenWrapper from 'components/CarouselScreenWrapper/CarouselScreenWrapper';
import Button from 'components/Button/Button';
import PasswordField from 'components/PasswordField';

type ResetPasswordProps = WithStyles<typeof styles> & RouteComponentProps & WithUserStore;

type PasswordResetState = 'START' | 'ERROR' | 'SUCCESS';

type ResetPasswordField = 'newPassword' | 'newPasswordConfirm';

@inject('userStore')
@observer
class ResetPassword extends Component<ResetPasswordProps> {
  constructor(props: ResetPasswordProps) {
    super(props);
    makeObservable(this);
  }
  @observable public newPassword = '';
  @observable public newPasswordConfirm = '';
  @observable public inProgress = false;
  @observable public currentState: PasswordResetState = 'START';
  @observable public errorMsg = '';
  @observable public touchedFields: Map<ResetPasswordField, boolean> = new Map([
    ['newPassword', false],
    ['newPasswordConfirm', false],
  ]);

  /**
   * Marks the field name as touched, so that we know to display validation errors on it.
   * @param fieldName The name of the field
   */
  @action.bound public markTouched(fieldName: ResetPasswordField) {
    this.touchedFields.set(fieldName, true);
  }

  @action.bound public markAllTouched() {
    this.touchedFields.forEach((value, key) => {
      this.markTouched(key);
    });
  }
  /** Handle the new password text field change event */
  @action.bound public handleNewPasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.newPassword = e.target.value;
  }

  /** Handle the new password confirm change event */
  @action.bound public handeNewPasswordConfirmChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.newPasswordConfirm = e.target.value;
  }

  /**
   * Handles the main form submission.
   * @param e The form submitted event
   */
  @action.bound public handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.markAllTouched();
    if (this.formValid) {
      this.resetPassword();
    }
  }

  @action.bound public resetPassword = flow(function* (this: ResetPassword) {
    try {
      this.inProgress = true;
      const resp = yield Api.core.setPassword(this.newPassword);
      yield this.props.userStore?.setUserData(resp.data)
      this.currentState = 'SUCCESS';
    } catch (e: any) {
      this.currentState = 'ERROR';
      this.errorMsg = 'Something went wrong. Please try again.';
    } finally {
      this.inProgress = false;
    }
  });

  @computed public get newPasswordError(): string | undefined {
    return (
      (this.touchedFields.get('newPassword') &&
        this.newPassword.length < 8 &&
        'Your password is too short') ||
      undefined
    );
  }

  @computed public get newPasswordConfirmError(): string | undefined {
    return (
      (this.touchedFields.get('newPasswordConfirm') &&
        this.newPasswordConfirm !== this.newPassword &&
        `Please input the same password again`) ||
      undefined
    );
  }

  @computed public get formValid(): boolean {
    return !this.newPasswordError && !this.newPasswordConfirmError;
  }

  renderResetPasswordView() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Typography
          style={{ fontSize: 40 }}
          variant="h3"
          component="h1"
          gutterBottom
          align="center">
          Set Password
        </Typography>
        <Box mt={8.625} mb={4}>
          <Typography className={classes.greyText}>
            Almost there. Enter your new password, confirm it and you&apos;re good to go.
          </Typography>
        </Box>
        <form onSubmit={this.handleSubmit}>
          <PasswordField
            autoFocus
            value={this.newPassword}
            error={this.newPasswordError}
            onChange={this.handleNewPasswordChange}
            onBlur={() => this.markTouched('newPassword')}
            id="new-password"
            required
          />
          <Box mt={2}>
            <PasswordField
              value={this.newPasswordConfirm}
              onChange={this.handeNewPasswordConfirmChange}
              error={this.newPasswordConfirmError}
              onBlur={() => this.markTouched('newPasswordConfirm')}
              id="new-password-confirm"
              label="Confirm password"
              required
            />
          </Box>
          <Box mt={5} mb={2}>
            <Button
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              disabled={this.inProgress}
              fullWidth>
              Set password
            </Button>
          </Box>
        </form>
        <Link
          style={{ display: 'flex', justifyContent: 'center' }}
          component={RouterLink}
          to={paths.signIn()}
          className={classes.backLink}>
          <Box component={'span'}>sign-in</Box>
        </Link>
      </Fragment>
    );
  }

  renderErrorView() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Box className={classes.circle} />

        <Box mb={3} mt={3}>
          <Typography className={classes.greyText} align="center">
            {this.errorMsg}
          </Typography>
        </Box>
        <Button
          variant="contained"
          fullWidth
          color={'primary'}
          onAbort={()=> { this.currentState = 'START' }}
          >
          Try again
        </Button>
      </Fragment>
    );
  }

  renderSuccessView() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Typography variant="h3" component="h1" gutterBottom align="center">
          Success
        </Typography>

        <Box mb={4} className={classes.checkContainer}>
          <Icon color="primary" fontSize="large" className={classes.successIcon}>
            checkcircle
          </Icon>
          <Typography className={classes.greyText} align="center">
            Password changed successfully!
          </Typography>
        </Box>
        <Button className={classes.button} fullWidth component={RouterLink} to={paths.root()}>
        GO TO DASHBOARD
        </Button>
      </Fragment>
    );
  }

  render() {
    return (
      <CarouselScreenWrapper>
        {this.currentState === 'START' && this.renderResetPasswordView()}
        {this.currentState === 'ERROR' && this.renderErrorView()}
        {this.currentState === 'SUCCESS' && this.renderSuccessView()}
      </CarouselScreenWrapper>
    );
  }
}

export default withStyles(styles)(ResetPassword);
