import React from 'react';
import { Box } from '@material-ui/core';
import useStyles from './styles';
import { useDynamicFont } from './useDynamicFont';
import clsx from 'clsx';

interface IDynamicFont {
  children: any;
  hasCountup?: boolean;
  className?: string;
  vertical?: boolean;
  maxFontSize?: number;
  whiteSpace?: string;
  noWrap?: boolean;
}

/**
 * Dynamically adjusts font size so the content does not overflow.
 * Wrapper component should have overflow style property defined as scroll.
 * Vertical prop determines the direction of overflow we observe.
 */
export function DynamicFont({
  children,
  hasCountup,
  className,
  vertical,
  maxFontSize,
  noWrap,
}: IDynamicFont) {
  const [dynamicFontElement, handleResize] = useDynamicFont({ vertical, maxFontSize });
  const classes = useStyles({ noWrap });

  return (
    <Box
      className={clsx(classes.dynamicStyles, className ? className : classes.fontWeight)}
      {...({ ref: dynamicFontElement } as any)}>
      {hasCountup
        ? React.Children.map(children, (child) =>
            React.cloneElement(child, {
              onEnd: handleResize,
            }),
          )
        : children}
    </Box>
  );
}
