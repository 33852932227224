import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    titleInput: {
      // width: theme.spacing(100),
      fontSize: theme.typography.h4.fontSize,
      fontFamily: theme.typography.h4.fontFamily,
      color: theme.palette.text.primary,
      fontWeight: 600,
      lineHeight: '43px',
    },
    editIcon: {
      color: theme.palette.primary.main,
      padding: 0,
    },
    titleLight: {
      color: theme.palette.text.primary,
      fontWeight: 600,
    },
    button: {
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    containerAction: {
      display: 'flex',
      gap: '0.5rem',
      paddingRight: '16px',
    },
    textLogo: {
      fontSize: 28,
      fontWeight: 400,
    },
    buttonAction: {
      '&.MuiButton-root': {
        padding: '6px 13px',
        height: 'auto',
        borderRadius: '8px',
        textTransform: 'none',
      },
    },
    dialogActions: {
      paddingBottom: theme.spacing(3),
    },
    buttonText: {
      fontWeight: 500,
      fontSize: 14,
      textTransform: 'none',
    },
  });
