import React from 'react';
import { Snackbar as MuiSnackbar, SnackbarContent, Button, IconButton } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import { inject, WithToastStore } from 'types/stores';
import { observer } from 'mobx-react';
import { Close } from 'mdi-material-ui';

import styles from './styles';

type SnackbarProps = WithToastStore & WithStyles<typeof styles>;

/**
 * The component for displaying the snackbar, where the toast messages
 * are displayed.
 */
@inject('toastStore')
@observer
class Snackbar extends React.Component<SnackbarProps> {
  render() {
    const { classes } = this.props;
    const toastStore = this.props.toastStore!;
    if (!toastStore.current) {
      return null;
    }
    return (
      <MuiSnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={Boolean(toastStore.current)}
        onClose={toastStore.dismiss}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}>
        <SnackbarContent
          message={<span id={`${toastStore.current.id}`}>{toastStore.current.message}</span>}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          className={classes[toastStore.current.type] as any}
          action={[
            toastStore.current && toastStore.current.undo && (
              <Button key="undo" color="secondary" size="small" onClick={toastStore.undo}>
                UNDO
              </Button>
            ),
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={toastStore.dismiss}
              className={classes.close}>
              <Close />
            </IconButton>,
          ]}
        />
      </MuiSnackbar>
    );
  }
}

export default withStyles(styles)(Snackbar);
