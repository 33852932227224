import React, { useEffect, useRef } from 'react';

interface IUseDynimicFontProps {
  vertical?: boolean;
  maxFontSize?: number;
}

export function useDynamicFont({ vertical, maxFontSize }: IUseDynimicFontProps) {
  const dynamicFontElement = useRef<HTMLElement>();
  const observer = new ResizeObserver(dynamicFontObserver);

  useEffect(() => {
    if (dynamicFontElement.current) {
      dynamicFontElement.current.style.overflow = 'scroll';
      observer.observe(dynamicFontElement.current);
    }
    () => {
      if (dynamicFontElement.current) {
        observer.unobserve(dynamicFontElement.current);
      }
    };
  }, [dynamicFontElement]);

  function handleResize() {
    if (!dynamicFontElement?.current) {
      return;
    }

    let overflow = false;
    const minFontSize = 3;
    maxFontSize = maxFontSize ? maxFontSize : 28;

    let fontSize = minFontSize;

    while (!overflow && fontSize < maxFontSize) {
      const fontEl = dynamicFontElement.current;
      fontEl.style.fontSize = `${fontSize + 1}px`;
      fontSize = fontSize + 1;
      overflow = isOverflowing(vertical, fontEl);

      if (overflow) {
        fontEl.style.fontSize = `${fontSize - 1}px`;
        fontSize = fontSize - 1;
      }
    }
  }

  function isOverflowing(vertical: boolean | undefined, fontEl: HTMLElement) {
    if (vertical) {
      return fontEl.scrollHeight > fontEl.clientHeight;
    }
    return fontEl.scrollWidth > fontEl.clientWidth;
  }

  function dynamicFontObserver() {
    handleResize();
  }

  return [dynamicFontElement, handleResize];
}
