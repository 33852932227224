import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      marginLeft: theme.spacing(2),
    },
    minSize: {
      minWidth: theme.spacing(12),
    },
    selectedLabel: {
      width: 'max-content',
      paddingRight: '0.5rem',
      paddingLeft: '0.5rem',
      color: 'grey',
    },
    optionLabel: {
      display: 'inline',
      textTransform: 'uppercase',
      fontSize: 'small',
    },
    listItemText: {
      textTransform: 'capitalize',
      marginRight: theme.spacing(4),
    },
    icon: {
      color: theme.palette.primary.main,
      width: '15px',
      height: theme.spacing(3),
      marginLeft: theme.spacing(2),
    },
    divider: {
      '&.MuiDivider-root': {
        color: theme.palette.divider,
        borderWidth: '1px',
      },
    },
    dropdownLeft: {
      right: '0px',
      position: 'absolute',
      minWidth: theme.spacing(20),
      top: '40px',
      zIndex: 200,
      color: theme.palette.grey[600],
      filter: 'drop-shadow(0px 6px 24px #DDDDDD)',
      '& .MuiMenuItem-root': {
        fontSize: '16px',
        height: '26px',
        minHeight: '20px',
      },
      '& .MuiButton-root': {
        // margin: '8px',
      },
    },
    dropdownRight: {
      position: 'absolute',
      minWidth: theme.spacing(30),
      top: '40px',
      zIndex: 200,
      color: theme.palette.grey[600],
      filter: 'drop-shadow(0px 6px 24px #DDDDDD)',
      '& .MuiMenuItem-root': {
        fontSize: '16px',
        height: '26px',
        minHeight: '20px',
      },
      '& .MuiButton-root': {
        margin: '8px',
      },
    },
    dropdown: {
      position: 'absolute',
      width: '100%',
      minWidth: theme.spacing(30),
      top: '40px',
      marginRight: theme.spacing(2),
      zIndex: 200,
      color: theme.palette.grey[600],
      '& .MuiMenuItem-root': {
        fontSize: '16px',
        height: '26px',
        minHeight: '20px',
      },
      '& .MuiButton-root': {
        margin: '8px',
      },
    },
    selectLabel: {
      color: 'grey',
    },
  });
