import React from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import styles from './styles';
import { observer } from 'mobx-react';
import PlanCard from './PlanCard';
import ModuleCard from './ModuleCard';
import { Plan, PlanRequest } from 'models';

interface PlansPanelProps extends WithStyles<typeof styles> {
  onChange: (plan: PlanRequest) => void;
}

const PlansPanel = ({ onChange, ...props }: PlansPanelProps) => {
  const plans: Plan[] = [
    {
        "id": "cd6978e1a42591be4d8e28c41ca544b0",
        "name": "Free",
        "description": "Free plan for everyone with minimal features available",
        "billing": [],
        "type": "basic",
        "defaultBillingCycle": 'lifetime',
        "resources": []
    },
    {
        "id": "7ae28f0d31a608d0b829df2aab634426",
        "name": "Pro",
        "description": "Best for small businesses that want to scale their physical and digital store",
        "billing": [
            {
                "price": 100,
                "cycle": "month",
                "description": "Start today and cancel anytime"
            },
            {
                "price": 360,
                "description": "30% discount",
                "cycle": "year"
            },
            {
              "price": 1500,
              "description": "Pay once and enjoy forever",
              "cycle": "lifetime"
          }
        ],
        "type": "pro",
        "defaultBillingCycle": "month",
        "resources": [
            "user",
            "sample",
            "product",
            "order",
            "location"
        ]
    },
    {
        "name": "Enterprise",
        "description": "The most advanced features for companies, as well as the highest priority for support",
        "billing": [],
        "type": "custom",
        "defaultBillingCycle": "year",
        "resources": []
    }
];
  return (
    <Paper elevation={0}>
      <Box>
        <Box style={{ display: 'flex', flexWrap: 'wrap', marginTop: '2rem' }}>
          {plans.map((plan, index) => (
            <PlanCard key={index} plan={plan} onChange={onChange} />
          ))}
        </Box>
        <ModuleCard />
      </Box>
    </Paper>
  );
};

export default withStyles(styles)(observer(PlansPanel));
