import React from 'react';
import { observer } from 'mobx-react';
import {
  Stepper as MuiStepper,
  Step,
  StepLabel,
  withStyles,
  StepConnector,
  makeStyles,
  StepIconProps,
  Theme,
  createStyles,
} from '@material-ui/core';

import { useStyles } from './styles';

import theme from 'containers/App/theme';
interface StepperProps {
  steps: string[];
  currentStep: number;
  showProductStep?: boolean;
}

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      border: 'none',
    },
  },
  completed: {
    '& $line': {
      border: 'none',
    },
  },
  line: {
    border: 'none',
  },
})(StepConnector);

interface StyleProps {
  active?: boolean;
  completed?: boolean;
}

const useQontoStepIconStyles = makeStyles<Theme, StyleProps>(() =>
  createStyles({
    circle: {
      width: 40,
      height: 40,
      borderRadius: '50%',
      textAlign: 'center',
      verticalAlign: 'center',
      lineHeight: '40px',
      fontWeight: 700,
      fontSize: theme.typography.body1.fontSize,
      color: ({ active, completed }) =>
        active || completed ? theme.palette.text.primary : theme.palette.common.black,
      backgroundColor: ({ active, completed }) =>
        active || completed ? theme.palette.primary.main : theme.palette.common.white,
    },
  }),
);

function QontoStepIcon(props: StepIconProps & { index: number }) {
  const { active, completed, index } = props;
  const classes = useQontoStepIconStyles({ active, completed });

  return <div className={classes.circle}>{index + 1}</div>;
}

/** The stepper that's displayed in the signup process */
const Stepper = observer(({ steps, currentStep, showProductStep }: StepperProps) => {
  const { stepper } = useStyles();
 
  const stepperCurrentStep = showProductStep
    ? currentStep
    : currentStep < 2
    ? currentStep
    : currentStep - 1;
  return (
    <MuiStepper
      alternativeLabel
      activeStep={stepperCurrentStep}
      classes={{ root: stepper }}
      connector={<QontoConnector />}>
      {steps.map((step: string, index: number) => (
        <Step className={step} key={step}>
          <StepLabel
            StepIconComponent={(props: any) => <QontoStepIcon {...props} index={index} />}
          />
        </Step>
      ))}
    </MuiStepper>
  );
});

export default Stepper;
