/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import Api, * as api from 'api';
import { inject, WithUserStore, WithToastStore } from 'types/stores';
import type { Tenant } from 'models';
import { Address } from 'types';
import StoresPanel from 'components/StorePanel';
import UsersPanel from 'components/UsersPanel';
import { DenseStatCard } from 'components/Stats';
import CodePanel from 'components/CodePanel';
import AddressPanel from 'components/AddressPanel';
import styles from './styles';
import Masonry from '@mui/lab/Masonry';
import { RequestMetaData } from 'api';
import { CreditCard } from 'mdi-material-ui';

/** Here we define what kind of props this component takes */
interface InfoProps
  extends WithStyles<typeof styles>,
    WithUserStore,
    RouteComponentProps,
    WithToastStore {
  tenant: Tenant;
  tenantId: string;
}
/**
 * Container for account address, primary owner, locations and users panels.
 */
@inject('userStore', 'toastStore')
@observer
class Info extends React.Component<InfoProps> {
  constructor(props: InfoProps) {
    super(props);
    makeObservable(this);
  }

  @computed public get tenant() {
    return this.props.tenant;
  }

  @computed public get isAdmin(): boolean {
    return Boolean(this.props.userStore?.isAdmin);
  }

  @action.bound public getUsersByTenant = async (rmd?: RequestMetaData) => {
    const options = {
      ...rmd,
      filters: {
        ...(rmd?.filters && rmd?.filters),
        tenantId: this.props.tenantId,
      },
    } as RequestMetaData;

    return await Api.core.getUsersByTenant(options);
  };

  render() {
    const { tenantId } = this.props;
    return (
      <Box>
        <Masonry columns={{ xs: 1, md: 2 }} spacing={3} style={{ margin: 0 }}>
          <Grid container spacing={3}>
          <Grid item>
              {this.tenant && <CodePanel title="Company code" code={this.tenant.code} />}
            </Grid>
            <Grid item>
              <Box style={{ minWidth: '194px' }}>
                {this.tenant && this.tenant.address && (
                  <AddressPanel
                    addressEditable={false}
                    fullHeight
                    title="Address"
                    onUpdateAddress={undefined}>
                    {this.tenant.address}
                  </AddressPanel>
                )}
              </Box>
            </Grid>
            
          </Grid>

          <Box style={{ minWidth: '268px' }}>
            <UsersPanel
              fetch={this.getUsersByTenant}
              renderScope={['employee', 'manager', 'owner']}
              displayInviteManagersButton
            />
          </Box>
          {this.tenant && this.isAdmin && (
            <StoresPanel showAddButton={this.isAdmin} tenantId={tenantId} />
          )}
        </Masonry>
      </Box>
    );
  }
}

export default withStyles(styles)(Info);
