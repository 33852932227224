import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { UserStore } from 'types/stores';
import { useStyles } from './styles';
import { Account, ScopeKind } from 'models';
import OutlinedInput from 'components/Input/OutlinedInput';
import { Magnify } from 'mdi-material-ui';


interface GlobalScopeSearchProps {
  userStore: UserStore
  scopes: Account[]
  setScope: (scopes: Account[]) => void
  selected: ScopeKind
  onClose: () => void;
}



function GlobalScopeSearch({ userStore, scopes, setScope, selected, onClose }: GlobalScopeSearchProps) {
  const [text, setText] = useState<string>('')
  const elements = useMemo(()=> scopes, [scopes])
  const classes = useStyles();

  const handleSetText = useCallback((v: string)=> {
    setText(v);
  }, [])


  const handlerOnChange = useCallback((v: string)=> {
    const searchTerm = v.toLowerCase();

    const scopesFiltered = elements.filter((scope: Account) => {

        const { name, code } = scope.store ? scope.store : scope.tenant
        // const addressMatch = address && address.address.toLowerCase().includes(searchTerm);
        const nameMatch = name && name.toLowerCase().includes(searchTerm);
        const codeMatch = code.toLowerCase().includes(searchTerm);

        return nameMatch || codeMatch;
    });


    handleSetText(v)
    setScope(scopesFiltered)
       
  }, [elements, setScope, handleSetText])

  useEffect(()=> {
    if(selected){
      handleSetText('')
      setScope(elements)
      
    }
  }, [selected, handleSetText, elements, setScope])

  return (
    <OutlinedInput
     value={text}
      id="combo-box-demo"
      variant="small"
      onChange={({target})=> handlerOnChange(target.value)}
      InputProps={{
        classes: {
          input: classes.resize,
        },
        startAdornment: <Magnify color="inherit" />,
        endAdornment: undefined,
      }}
      fullWidth
      placeholder="Search account by name or code"
    />
  );
}


export default memo(GlobalScopeSearch);
