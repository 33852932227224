import React from 'react';
import { CompanyCode } from 'containers/DashboardLayout/CompanyCode/CompanyCode';
import { Notifications } from 'containers/DashboardLayout/Notifications/Notifications';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import { useStores } from 'containers/App/App';
import { observer } from 'mobx-react';

const WebToolbar = () => {
  return (
    <>
      <CompanyCode />
      <Notifications />
    </>
  );
};

export const ToolbarItemWrapper = observer(({ children }: { children: React.ReactNode }) => {
  const { uiStore } = useStores();
  const mobileView = uiStore.mobileView;
  const trigger = uiStore.appBar.trigger;
  const { wrapper } = useStyles({ mobileView, trigger });
  return <Box className={wrapper}>{children}</Box>;
});

export default WebToolbar;
