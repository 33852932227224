import { alpha, createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loader: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: alpha(theme.palette.common.white, 0.5),
      borderRadius: theme.shape.borderRadius,
      zIndex: 200,
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
    },
  }),
);

export default useStyles;
