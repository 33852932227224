import TenantDetails from 'containers/TenantDetails';
import StoreDetails from 'containers/StoreDetails';

import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import AnonRoute from './AnonRoute';
import AuthRoute from './AuthRoute';
import ScrollToTop from './ScrollToTop';

//Tenants
import Tenants from 'containers/Tenants';
import TenantOnBoard from 'containers/TenantOnBoard';

//Stores
import Stores from 'containers/Stores';

//Plans
import Plans from 'containers/Plans';

//Users
import Users from 'containers/Users';
import UserDetails from 'containers/UserDetails';
import InviteUsers from 'containers/InviteUsers';

import Alerts from 'containers/Alerts';
import Categories from 'containers/Categories';
import ChangeEmail from 'containers/ChangeEmail';
import ConfirmEmail from 'containers/ConfirmEmail';
import DashboardHome from 'containers/DashboardHome';
import ForgotPassword from 'containers/ForgotPassword';
import Login from 'containers/Login';
import MyAccount from 'containers/MyAccount';
import PageNotFound from 'containers/PageNotFound';
import PersonalInfo from 'containers/PersonalInfo';
import PersonalInfo2 from 'containers/PersonalInfo2';
import ProfilePicture from 'containers/ProfilePicture';
import ResetPassword from 'containers/ResetPassword';
import SignUp from 'containers/SignUp';
import SignUpSuccess from 'containers/SignUpSuccess';

import WebViewRedirect from 'containers/WebViewRedirect';
import Checkout from 'containers/Checkout';
import { paths } from 'routes';
import { ACL } from 'types';

/**
 * This component defines the routes for the whole project.
 */

const Routes = () => {
  // @ts-ignore
  const history: History<unknown> = createBrowserHistory();
  return (
    <Router history={history}>
      <ScrollToTop>
        <Switch>
          <Route path="/" exact>
            <Redirect to={paths.root()} />
          </Route>

           {/* signIn & signUp routes */}
           <AnonRoute path={paths.signIn()} exact component={Login} />
          <AnonRoute path={paths.signUp().root()} exact component={SignUp} />

          <AnonRoute
            path={paths.signUp().confirmEmailScreen()}
            exact
            render={(props) => <ConfirmEmail {...props} prevRoute={paths.signUp().root} />}
          />
      
          <AnonRoute
            path={paths.signUp().root()}
            exact
            render={(props: any) => <SignUp {...props} accountSignup />}
          />
          <AnonRoute
            path={paths.signUp().confirmEmailScreen()}
            exact
            render={(props: any) => (
              <ConfirmEmail
                {...props}
                nextRoute={paths.signUp().personalInfo()}
                prevRoute={paths.signUp().root()}
              />
            )}
          />

        <AnonRoute path={paths.forgotPassword()} exact component={ForgotPassword} />
        <AnonRoute path={paths.resetPassword(':userId/:code')} exact component={ForgotPassword} />

          <AuthRoute path={paths.signUp().personalInfo()} exact component={PersonalInfo} />
          <AuthRoute path={paths.signUp().personalInfo2()} exact component={PersonalInfo2} />
          <AuthRoute path={paths.signUp().profilePicture()} exact component={ProfilePicture} />
          <AuthRoute path={paths.signUp().success()} exact component={SignUpSuccess} />
          <AuthRoute path={paths.resetPassword()} exact component={ResetPassword} />
          <AuthRoute path={paths.myAccount().root()} component={MyAccount} />
          <AuthRoute path={paths.acceptInvitations()} exact component={DashboardHome} />

          <AuthRoute path={paths.root()} exact component={DashboardHome} />

          <Route path={paths.changeEmail()} exact component={ChangeEmail} />

          {/* Users routes */}
          <AuthRoute path={paths.users().root()} exact component={Users} scopes={['admin', 'owner', 'manager']} />

          <AuthRoute
            path={paths.invitations()}
            exact
            component={InviteUsers}
            scopes={['admin', 'manager', 'owner']}
          />

          <AuthRoute
            path={paths.userDetails(`:id`).root()}
            component={UserDetails}
            scopes={['admin', 'owner', 'manager']}
          />

         

          {/* Plan routes */}
          <AuthRoute path={paths.plans()} exact component={Plans} scopes={['admin']} />

          {/* Tenant routes */}
          <AnonRoute path={paths.onBoardTenant().root()} component={TenantOnBoard} />
          <AuthRoute path={paths.tenants()} exact component={Tenants} scopes={['admin']} />
          <AuthRoute
            path={paths.tenantDetails(`:tenantId`).root()}
            component={TenantDetails}
            scopes={['admin', 'owner']}
            exact
          />
          
          {/* Stores routes */}
          <AuthRoute path={paths.stores()} exact component={Stores} scopes={['admin', 'owner']} />
          <AuthRoute
            path={paths.storeDetails(`:storeId`).root()}
            component={StoreDetails}
            scopes={['admin', 'owner', 'manager']}
            exact
          />

          {/* Product routes */}

          <AuthRoute path={paths.categories()} exact component={Categories} scopes={['admin']} />
          <AuthRoute path={paths.alerts()} exact component={Alerts} scopes={['admin']} />

          <AuthRoute
            path={paths.checkoutPreview()}
            exact
            render={(props: any) => <Checkout {...props} hidePayment={false} />}
          />
         
          {/* 404 Route: */}
          <Route component={PageNotFound} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default Routes;
