import { useStores } from 'containers/App/App';

export function useScope() {
  const { userStore } = useStores();

  function isScope(scopes: string | string[]) {
    if (Array.isArray(scopes)) {
      return !!scopes.find((scope: string) => scope === userStore.scope.kind);
    } else {
      return (scopes as unknown as string) === userStore.scope.kind;
    }
  }

  return [isScope];
}
