import { makeStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default makeStyles((theme) => ({
  root: {},
  fullHeight: {
    height: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
    paddingLeft:  theme.spacing(2),
    paddingTop:  theme.spacing(2),
    paddingBottom: '8px',
  },
  actions: {
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
    alignItems: 'start',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  value: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
    fontSize: '18px',
    fontWeight: 400,
    overflowWrap: 'anywhere'
  },
  label: {
    textAlign: 'left',
    width: '100%',
    maxWidth: '150px',
    flexShrink: 0,
    marginRight: theme.spacing(2),
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.common.black,
  },
  primaryTextWithIndicator: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  list: {
    maxHeight: theme.spacing(48),
    overflow: 'auto',
  },
  listTall: {
    maxHeight: theme.spacing(76),
    overflow: 'auto',
  },
  listShort: {
    maxHeight: theme.spacing(24),
    // height: theme.spacing(24),
    // paddingBottom: theme.spacing(6),
    overflow: 'auto',
  },
  searchInput: {
    margin: '8px 0',
    paddingLeft: '18px',
    paddingRight: '24px',
  },
  icon: {
    marginRight: theme.spacing(2),
    color: theme.palette.grey[500],
  },
  countBadge: {
    color: theme.palette.grey[500],
  },
  select: {
    minWidth: '120px',
    '&.fullWidth': {
      width: '100%',
    },
  },
  noBorderSelect: {
    border: 'none',
    padding: '0px !important',
    height: 'auto',
    '& > div': { marginRight: theme.spacing(0.75) },
    '& > svg': {
      color: theme.palette.text.primary,
      fontSize: 25,
    },
  },
  lightText: {
    textTransform: 'uppercase',
    color: theme.palette.grey[500],
  },
  loadingSkeleton: {
    margin: '5.9px 0',
  },
  listItemAvatar: {
    minWidth: 0,
    marginRight: theme.spacing(2),
    borderRadius: '8px'
  },
}));
