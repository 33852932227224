import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      gap: theme.spacing(3),
    },
    openStyles: {
      width: 21,
      color: theme.palette.text.primary,
    },
    closeStyles: {
      height: 21,
      width: 21,
      color: theme.palette.text.primary,
    },
    iconButton: {
      padding: 0,
    },
  }),
);

export default useStyles;
