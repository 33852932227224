import React from 'react';
import { Chip } from '@material-ui/core';
import { useStyles } from './styles';
import { useChipStatusTag } from './useChipStatusTag';

export enum ChipStatusColors {
  RED = 'red',
  GREEN = 'green',
  YELLOW = 'yellow',
  ORANGE = 'orange',
  BLUE = 'blue',
  GREY = 'grey',
  PURPLE = 'purple',
}

type ChipStatus =
  | {
      status: boolean;
    }
  | { label: string; color: ChipStatusColors };

const ChipStatusTag = (props: ChipStatus) => {
  const [getClass] = useChipStatusTag();
  const classes = useStyles();

  let status, label, color;
  if ('status' in props) {
    status = props.status;
  } else {
    label = props.label;
    color = props.color;
  }

  if (label && color) {
    const style = getClass(color);
    return (
      <Chip
        style={{ width: '100%' }}
        variant="default"
        size="medium"
        label={label}
        className={style}
      />
    );
  }

  return status ? (
    <Chip
      variant="outlined"
      size="medium"
      label="ACTIVE"
      color="primary"
      className={classes.active}
    />
  ) : (
    <Chip
      variant="outlined"
      size="medium"
      label="INACTIVE"
      color="secondary"
      className={classes.inactive}
    />
  );
};

export default ChipStatusTag;
